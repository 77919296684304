<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="title-container">
    <div class="mt-4">
        <h5>Documents</h5>
        <p>All documents related to this property</p>
        <div class="d-flex buttonAddLeft">
            <button class="center-content add-button buttonColor" (click)="generateOwnershipReportCall()">
                <mat-icon class="buttonIcon">picture_in_picture</mat-icon>
                <span class="paragraph buttonAdd ">Ownership Report</span>
            </button>
            <button class="center-content add-button buttonColor" (click)="archiveuploadedDocuments()">
                <mat-icon class="buttonIcon">archive</mat-icon>
                <span class="paragraph buttonAdd ">Archive</span>
            </button>
            <div class="margin-left-10"></div>
            <button class="center-content add-button buttonColor" (click)="allUploadedDocument()">
                <mat-icon class="buttonIcon">dns</mat-icon>
                <span class="paragraph buttonAdd">All</span>
            </button>
            <div class="margin-left-10"></div>
            <button (click)="openNewDocumentDialog()" class="center-content add-button buttonColor">
                <mat-icon class="buttonIcon">add</mat-icon>
                <span class="paragraph buttonAdd ">Add document</span>
            </button>
        </div>
    </div>
    <div class="mt-4">
        <h6>Planning Report and Addendum's</h6>
    </div>

</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSource" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>
            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let document" class="bold">
                    #{{ document?.documentNumber }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedFileName">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedFileName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedByUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ document?.exportedByUserEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    {{ formatDate(document?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let document">
                    <a [href]="document?.downloadUrl" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                </mat-cell>
            </ng-container>
        </mat-table>
        <mat-paginator class="hidden" [length]="pageTotal" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)">
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Uploads from Sell your Property</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourceSellUploads" width="100%" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsUploadsSellProperty"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsUploadsSellProperty;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.id">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index" class="bold">
                    #{{ i + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.name">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.documentType">
                    Document Type
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.fullUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.userEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.userEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ formatDate(sellPropertyDocuments?.documentInfo?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    <a [href]="sellPropertyDocuments?.documentInfo?.fileDownloadURL" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                    <button mat-button (click)="deleteUploadFile(sellPropertyDocuments)">
                        <mat-icon class="material-icons-outlined download-icon">archive</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    <button mat-button (click)="edit(element)">Edit</button>
                    <button mat-button (click)="delete(element)">Delete</button>
                </td>
            </ng-container>
        </mat-table>

        <mat-paginator class="hidden" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
            [length]="SellUploadsTotalItems" (page)="pageEventSellDocuments($event)" [le]>
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Pictures of property</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourcePicture" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsUploadsSellProperty"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsUploadsSellProperty;"></mat-row>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.id">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index" class="bold">
                    #{{ i+ 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.name">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.fullUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.userEmail">
                    Exported By (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentInfo?.userEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ formatDate(sellPropertyDocuments?.documentInfo?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    <a [href]="sellPropertyDocuments?.documentInfo?.fileDownloadURL" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                    <button mat-button (click)="deleteUploadFile(sellPropertyDocuments)">
                        <mat-icon class="material-icons-outlined download-icon">archive</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

        </mat-table>
        <mat-paginator class="hidden" [length]="SellUploadsPictureTotalItems" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="pageEventSellDocumentPicture($event)">
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Additional Uploads</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourceAddition" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsAdditionalUpload"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsAdditionalUpload;"></mat-row>
            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index" class="bold">
                    #{{ i + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedFileName">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ additionalImage?.documentInfo?.name }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Exported By (Name)
                </mat-header-cell>
                <mat-cell *matCellDef="let sellPropertyDocuments; let i = index">
                    {{ sellPropertyDocuments?.documentType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserName">
                    Network/Urban Planner
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ additionalImage?.documentInfo?.fullUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserEmail">
                    Network/Urban Planner (Email)
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ additionalImage?.documentInfo?.userEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    {{ formatDate(additionalImage?.documentInfo.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let additionalImage; let i = index">
                    <a [href]="additionalImage?.documentInfo?.fileDownloadURL" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                    <button mat-button (click)="deleteUploadFile(additionalImage)">
                        <mat-icon class="material-icons-outlined archive-icon">archive</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
        </mat-table>
        <mat-paginator class="hidden" [length]="SellUploadsAdditionalTotalItems" [pageSize]="AdditionalPageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="pageEventSellDocumentAdditional($event)">
        </mat-paginator>
    </div>
</mat-card>

<div class="title-container">
    <div class="mt-4">
        <h6>Ownership report</h6>
    </div>
</div>
<mat-card class="card-container">
    <div class="documents-table-container">
        <mat-table [dataSource]="dataSourceOwnershipReport" matSort>
            <mat-header-row *matHeaderRowDef="displayedColumnsAdditionalUpload"></mat-header-row>
            <mat-row class="paragraph"
                *matRowDef="let row; let i = index; columns: displayedColumnsUploadsSellProperty;"></mat-row>
            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentNumber">
                    Document ID
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index" class="bold">
                    #{{ i + 1 }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedFileName">
                    Document Name
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index">
                    {{ ownershipdata?.exportedFileName}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documentType">
                <mat-header-cell *matHeaderCellDef mat-sort-header="documentInfo.fullUserName">
                    Report type
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index">
                    {{ ownershipdata?.reportType }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserName">
                    Exported User
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index">
                    {{ ownershipdata?.exportedByUserName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header="exportedByUserEmail">
                    Email
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index">
                    {{ ownershipdata?.exportedByUserEmail }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">
                    Export Date
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index">
                    {{ formatDate(ownershipdata?.createdDate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef mat-sort-header="downloadUrl">
                    Download
                </mat-header-cell>
                <mat-cell *matCellDef="let ownershipdata; let i = index">
                    <a [href]="ownershipdata?.downloadUrl" target="_blank" download>
                        <mat-icon class="material-icons-outlined download-icon">download</mat-icon>
                    </a>
                    <button mat-button (click)="deleteUploadFile(ownershipdata)">
                        <mat-icon class="material-icons-outlined archive-icon">archive</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
        </mat-table>
        <mat-paginator class="hidden" [length]="OwnershipReportTotalItems" [pageSize]="OwnershipReportPageTotal"
            [pageSizeOptions]="pageSizeOptions" (page)="pageEventOwnership($event)">
        </mat-paginator>
    </div>
</mat-card>