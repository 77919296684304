import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';

import { MetroDto, ProfessionDto, UserDto, RoleDto, UserRoleDto } from '../../../../../app/services/property-matrixV2/models';
import { NotificationService } from '../../../../../app/shared/services/notification-service/notification.service';
import { LookupService, UserManagementService } from '../../../../../app/services/property-matrixV2/services';

@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class AddEditUserDialogComponent implements OnInit {

  roleList: RoleDto[] = [];
  professionList: ProfessionDto[] = [];
  metroList: MetroDto[] = [];
  loading: boolean = false;
  userDtoItem: UserDto = {};
  userDataForm: FormGroup | null | undefined;
  selectedRole: RoleDto | null = null;
  selectedProfession: ProfessionDto | null = { id: 'ae6b167f-23c3-495c-a981-6bd200db65a8', intValue: 11, value: 'None' };
  selectedMetro: MetroDto | null = null;
  user: UserDto | null = null;
  fullUserDetail: UserDto | null = null;
  titleText: String = "Add New User";
  viewOnly: boolean;
  showPassword = false;

  constructor(
    private userManagementService: UserManagementService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditUserDialogComponent>,
  ) {
    this.user = data.result;
    this.viewOnly = data.viewOnly;
  }

  setTitle(): void {
    if (this.data.viewOnly) {
      this.titleText = "View User";
    }
    else if (this.data.result != null) {
      this.titleText = "Edit User";
    }
  }

  getRoles() {
    try {
      this.loading = true;
      this.lookupService.apiV1LookupGetUserRolesGet().subscribe({
        next: (rolesList: RoleDto[]) => {
          this.roleList = rolesList.filter(r => [
            'Admin Portal Administrator',
            'Assistant',
            'Consultant Administrator',
            'Head Administrator',
            'Head Of Finances',
            'Head Of Junior Planners',
            'Junior Planner',
            'Network Economist',
            'Planner Portal Administrator',
            'Senior Agent',
            'Senior Architectural Technologist',
            'Senior Bond Originator',
            'Senior Conveyancer',
            'Senior Planner',
            'Senior Valuer',
            'Extended Profession',
          ].includes(r.name!))
            .sort((a, b) => a.name!.localeCompare(b.name!));
          this.loading = false;
        },
        error: (_error: any) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load roles for users.');
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load roles for users.');
    }
  }

  async getProfessions() {
    try {
      this.loading = true;
      await this.lookupService.apiV1LookupGetProfessionsGet().subscribe({
        next: (professionList: ProfessionDto[]) => {
          this.loading = false;
          this.professionList = professionList;
        },
        error: (_error: any) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load profession for users.');
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load profession for users.');
    }
  }

  async getMetro() {
    try {
      this.loading = true;
      await this.lookupService.apiV1LookupGetAllMetroItemsGet().subscribe({
        next: (metroList: MetroDto[]) => {
          this.loading = false;
          this.metroList = metroList;
        },
        error: (_error: any) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load metro items for users.');
        }
      });
    }
    catch (e) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Could not load metro items for users.');
    }
  }

  ngOnInit(): void {
    this.setTitle();
    this.getRoles();
    this.getProfessions();
    this.getMetro();
    this.prepareForms();
  }

  prepareForms(): void {
    try {
      if (this.user != null) {
        this.userDataForm = this.fb.group({
          firstName: [this.user.name, [Validators.required]],
          surname: [this.user.surname, [Validators.required]],
          email: [this.user.email, [Validators.required]],
          userRoles: [this.user.userRoles],
          profession: [this.user.profession],
          password: [this.user.password, [Validators.required]],
          metro: [null],
          mobileNr: [this.user.mobile],
          accountEndorsed: [this.user.endorsed],
          associatedNetworkPlanner: [""],
        });
        this.selectedMetro = this.user.metro ?? {};
        this.selectedProfession = this.user.profession ?? {};
        if (this.user.userRoles) {
          let roleDto: RoleDto = {};
          for (let index = 0; index < this.user.userRoles.length; index++) {
            const element = this.user.userRoles[index];
            roleDto = {
              name: element.roleName,
              id: element.roleid ?? "",
              isDeleted: element.isDeleted,
              createdBy: element.createdBy,
              createdDate: element.createdDate != null ? Date.parse(element.createdDate).toString() : null,
            }
          };
          this.selectedRole = roleDto;
        }
      }
      else {
        this.userDataForm = this.fb.group({
          firstName: ["", [Validators.required]],
          surname: ["", [Validators.required]],
          email: ["", [Validators.required]],
          userRoles: [null],
          profession: [null],
          password: ["", [Validators.required]],
          mobileNr: [""],
          metro: [null],
          associatedNetworkPlanner: [""],
          accountEndorsed: [true],
        });
        this.selectedMetro = null;
        this.selectedProfession = null;
        this.selectedRole = null;
      }
    }
    catch (e) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", "Error saving a user, please contact your administrator.");
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmSave(): void {
    try {
      if (this.selectedRole == null) {
        this.notificationService.showErrorMessage("Validation failed", "Please select a user role that's required to continue.");
        return;
      }

      if (this.userDataForm?.valid) {
        this.loading = true;
        var RoleList: RoleDto[] = [];
        var UserRoleList: UserRoleDto[] = [];

        UserRoleList.push({ roleid: this.selectedRole.id, userid: null, roleName: this.selectedRole.name });
        RoleList.push(this.selectedRole);

        if (this.userDataForm == null) {
          this.notificationService.showErrorMessage("Validation failed", "Please select a user role that's required to continue.");
          return;
        }
        this.loading = true;
        let userData = this.userDtoItem = {
          confirmEmail: (this.userDataForm!.get("email")?.value) ?? undefined,
          confirmMobile: (this.userDataForm!.get("mobileNr")?.value) ?? undefined,
          name: (this.userDataForm!.get("firstName")?.value) ?? undefined,
          surname: (this.userDataForm!.get("surname")?.value) ?? undefined,
          email: (this.userDataForm!.get("email")?.value) ?? undefined,
          mobile: (this.userDataForm!.get("mobileNr")?.value) ?? undefined,
          password: (this.userDataForm!.get("password")?.value) ?? undefined,
          profession: this.selectedProfession ?? undefined,
          metro: this.selectedMetro ?? undefined,
          endorsed: (this.userDataForm!.get("accountEndorsed")?.value) ?? undefined,
          userRoles: UserRoleList,
          id: this.data.result == null ? "00000000-0000-0000-0000-000000000000" : this.data.result.id,
        };

        if (this.data.result != null) {
          this.userManagementService.apiV1UserManagementEditUserPost({
            body: userData
          }).subscribe({
            next: () => {
              this.loading = false;
              this.notificationService.showSuccessMessage('Success', 'User registration was successful.');
              this.dialogRef.close();
            },
            error: (_error: any) => {
              this.loading = false;
              this.notificationService.showErrorMessage('Error', 'An error occurred while registering user.');
            }
          });
        } else if (this.data.result == null) {
          this.userManagementService.apiV1UserManagementAddUserPost({
            body: userData
          }).subscribe({
            next: () => {
              this.loading = false;
              this.notificationService.showSuccessMessage('Success', 'User registration was successful.');
              this.dialogRef.close();
            },
            error: (_error: any) => {
              this.loading = false;
              this.notificationService.showErrorMessage('Error', 'An error occurred while registering user.');
            }
          });
        }
      } else {
        this.validateAllFormFields(this.userDataForm as FormGroup);
      }
    }
    catch (e) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", "Error saving a user, please contact your administrator.");
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    try {
      Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control) {
          if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
          } else {
            control.markAsTouched({ onlySelf: true });
          }
        } else {
          console.warn(`Control for field "${field}" is null or undefined.`);
        }
      });
    }
    catch (e) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", "Error saving a user, please contact your administrator.");
    }
  }

  compareProfession(profession1: ProfessionDto, profession2: ProfessionDto) {
    return profession1 && profession2 ? profession1.id === profession2.id : profession1 === profession2;
  }

  compareUserRole(role1: RoleDto, role2: RoleDto) {
    return role1 && role2 ? role1.name === role2.name : role1 === role2;
  }

  compareMetro(metro1: MetroDto, metro2: MetroDto) {
    return metro1 && metro2 ? metro1.id === metro2.id : metro1 === metro2;
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
