<main>
    <div class="paragraph p-2">
        <mat-card class="row overview-menu">
            <div class="col listing-title-container">
                <button (click)="goToAdminListings()" class="center-content back-button">
                    <mat-icon class="circle-button">arrow_back</mat-icon>
                    <span class="bold paragraph back-button-label">
                        {{ listingAddress ?? 'No address available' }}
                    </span>
                </button>
            </div>
            <div class="col overview-tabs-container">
                <mat-tab-group class="overview-tab-group" [selectedIndex]="tabNumber - 1" animationDuration="0ms"
                    (selectedIndexChange)="selectTab($event)">
                    <mat-tab *ngFor="let item of overviewItems">
                        <ng-template mat-tab-label>
                            <mat-icon class="material-icons-outlined tab-icon">
                                {{ item.icon }}
                            </mat-icon>
                            <span class="tab-title">
                                {{ item.value }}
                            </span>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card>
        <div class="overview-item">
            <div *ngIf="tabNumber == 1">
                <app-listing-tasks [selectedListing]="selectedListing" [selectedListingGroup]="selectedListingGroup"
                    [listingType]="listingType">
                </app-listing-tasks>
            </div>
            <div *ngIf="tabNumber == 2">
                <app-listing-under-offer></app-listing-under-offer>
            </div>
            <div *ngIf="tabNumber == 3">
                <app-listing-users></app-listing-users>
            </div>
            <div *ngIf="tabNumber == 4">
                <app-listing-documents [planningReportId]="planningReportId"
                    [selectedListing]="selectedListing"></app-listing-documents>
            </div>
        </div>
    </div>
</main>