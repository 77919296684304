import { PropertyListingDto, ListingLifeCycleState, MultiplePropertyListingDto, RoleDto, UserDto } from '../../../../app/services/property-matrixV2/models';
import { ListingGroupStateService } from '../../../../app/shared/services/listing-group-state-service/listing-group-state.service';
import { PropertyListingService, UserManagementService } from '../../../../app/services/property-matrixV2/services';
import { ListingStateService } from '../../../../app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../app/shared/services/notification-service/notification.service';
import { KeyValuePair } from '../../../../app/common/global-models/key-value-pair';
import { GlobalMethods } from '../../../../app/common/global-methods';
import { NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

import { CreateGroupComponent } from './create-group/create-group.component';

@Component({
  selector: 'app-admin-listings',
  templateUrl: './admin-listings.component.html',
  styleUrls: ['./admin-listings.component.scss', '../../../../css/2-modules/_admin-portal.scss']
})
export class AdminListingsComponent implements OnInit {

  propertyListings: PropertyListingDto[];
  propertyListingGroups: MultiplePropertyListingDto[];
  filteredListings: PropertyListingDto[];

  isSideMenuVisible: boolean = true;
  currentHeading: string = '';
  currentDescription: string = '';
  reportTypeIndex: number = 1;
  currentUserIndex: number = 1;
  selectedListingIds: string[] = [];
  groupName: string;
  activeUsers: UserDto[] = [];
  reportReferenceNumber: string;
  menuType: string = 'listings';
  singleListing: string = 'single';
  multipleListing: string = 'multiple';
  reportTypeTabNumber: number = 1;
  currentUserWithActivetask: UserDto;
  currentUser: UserDto;
  roles: RoleDto[] = [];
  stateNumber: number = 1;

  loading: boolean = false;

  reportTypes: KeyValuePair[] = [
    { key: 1, value: 'Single', icon: "home" },
    { key: 2, value: 'Multiple', icon: "holiday_village" }
  ];

  users = [];

  constructor(
    private router: Router,
    private listingService: PropertyListingService,
    private listingStateService: ListingStateService,
    private listingGroupStateService: ListingGroupStateService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private userService: UserManagementService,
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
    this.getUsers();
    this.getMultiplePropertyListings();
    this.getRoles();
  }

  getRoles() {
    this.userService.apiV1UserManagementGetRolesGet().subscribe(result => {
      this.roles = result;
    });
  }

  toggleSideMenu() {
    this.isSideMenuVisible = !this.isSideMenuVisible;
  }

  onSideMenuChange(change: { heading: string, description: string, state: ListingLifeCycleState, key: number }): void {
    GlobalMethods.scrollToTop();
    this.currentHeading = change.heading;
    this.currentDescription = change.description;
    this.filterListingsByState(change.state);
    this.getPropertyListings(change.key);
    this.stateNumber = change.key;
  }

  onReportTypeChange(item: number): void {
    this.reportTypeIndex = item;
  }

  getPropertyListings(state: number = 1): void {
    this.loading = true;
    if (state == 1) {
      this.listingService.apiV1PropertyListingGetAllPropertyListingForCurrentUserGet().subscribe({
        next: (result: PropertyListingDto[]) => {
          this.propertyListings = result;
          this.setup();
          this.loading = false;
        },
        error: (_error) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load property listings.');
        }
      });
    }
    else if (state == 2) {
      this.listingService.apiV1PropertyListingGetAllPropertyListingsGet().subscribe({
        next: (result: PropertyListingDto[]) => {
          this.propertyListings = result;
          this.setup();
          this.loading = false;
        },
        error: (_error) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load property listings.');
        }
      });
    }
    else{
      this.loading = false;
    }
  }

  setup(){
    if (this.propertyListings.length > 0) {
      let count = 0;
      this.propertyListings.forEach(listing => {
        count++;
        const roleColor = '#' + Math.floor(Math.random() * 0x7FFFFF).toString(16).padStart(6, '0');
        if (listing.taskList[0].user == null) {
          listing.taskList[0].user = this.activeUsers.find(f => f.userRoles.some(a => a.roleName === 'Planner Portal Administrator')
            || f.userRoles.some(a => a.roleName === 'Planner Portal User') && f.id == this.currentUser.id);
          this.users.push({
            key: count,
            name: listing.taskList[0].user.name + ' ' + listing.taskList[0].user.surname,
            role: listing.taskList[0].user.userRoles[0].roleName,
            roleInitial: listing.taskList[0].user.userRoles[0].roleName.charAt(0),
            roleColor: roleColor
          });
        }
        else {
          this.currentUserWithActivetask = listing.taskList.find(f => f.taskStatus.taskStatusNumber.toString().startsWith('2'))?.user
            || listing.taskList.find(f => f.taskStatus.taskStatusNumber.toString().startsWith('3'))?.user;
          if (this.currentUserWithActivetask == null) {
            this.currentUserWithActivetask = listing.taskList[0].user;
          }
          if (this.currentUserWithActivetask.userRoles[0].roleName == null) {
            this.currentUserWithActivetask.userRoles[0].roleName = this.roles.find(f => f.id == this.currentUserWithActivetask.userRoles[0].roleid).name;
          }
          this.users.push({
            key: count,
            name: this.currentUserWithActivetask.name + ' ' + this.currentUserWithActivetask.surname,
            role: this.currentUserWithActivetask.userRoles[0].roleName,
            roleInitial: this.currentUserWithActivetask.userRoles[0]?.roleName?.charAt(0) || '',
            roleColor: roleColor
          });
        }
      });
      this.filterListingsByState(ListingLifeCycleState.Assigned); // Default filter to My Tasks
    } else {
      this.notificationService.showWarningMessage("Warning", "There are no listing for the current logged-in user found.");
      console.log("There are no listing for the current logged-in user found.");
    }
  }

  getMultiplePropertyListings(): void {
    this.loading = true;
    this.listingService.apiV1PropertyListingGetAllMultiplePropertyListingsGet().subscribe({
      next: (result: MultiplePropertyListingDto[]) => {
        this.propertyListingGroups = result;
        this.loading = false;
      },
      error: (_error) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not load property listing groups.');
      }
    });
  }

  onListingCardSelected(listingId: string) {
    if (!this.selectedListingIds.includes(listingId)) {
      this.selectedListingIds.push(listingId);
    } else {
      const index = this.selectedListingIds.indexOf(listingId);
      if (index > -1) {
        this.selectedListingIds.splice(index, 1);
      }
    }
  }

  onCreateGroup() {
    const dialogRef = this.dialog.open(CreateGroupComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.groupName = result.groupName;
        this.saveMultiplePropertyListing();
      }
    });
  }

  isCreateGroupButtonVisible() {
    return this.selectedListingIds?.length > 0;
  }

  get propertyListingGroupToSave(): MultiplePropertyListingDto {
    return {
      propertyListingIds: this.selectedListingIds,
      groupName: this.groupName,
      coverImage: this.propertyListingGroups[0]?.coverImage,
      lifeCycleState: ListingLifeCycleState.Assigned,
      reportReferenceNumber: this.reportReferenceNumber,
      currentUser: this.users[this.currentUserIndex - 1],
      createdDate: new Date().toISOString(),
    };
  }

  saveMultiplePropertyListing(): void {
    this.listingService.apiV1PropertyListingAddOrUpdateMultiplePropertyListingPost({
      body: this.propertyListingGroupToSave
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'Successfully saved property group details.');
      },
      error: (_error) => {
        this.notificationService.showErrorMessage('Error', 'Could not save property group details.');
      }
    });
  }

  goToListingOverview(listing: PropertyListingDto): void {
    this.listingStateService.setSelectedListing(listing);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'listingType': this.singleListing
      }
    };
    this.router.navigate(['admin/listings/listing-overview'], navigationExtras);
  }

  goToListingGroupOverview(group: MultiplePropertyListingDto): void {
    this.listingGroupStateService.setSelectedListingGroup(group);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'listingType': this.multipleListing
      }
    };
    this.router.navigate(['admin/listings/listing-overview'], navigationExtras);
  }

  getUserIndex(listingIndex: number): number {
    return (listingIndex % this.users.length) + 1;
  }

  navigateToReportTypeTab(index: number) {
    this.reportTypeTabNumber = index;
    GlobalMethods.scrollToTop();
  }

  filterListingsByState(state: ListingLifeCycleState): void {
    if (this.propertyListings) {
      this.filteredListings = this.propertyListings.filter(listing => listing.lifeCycleState === state);
    }
  }

  searchListings(event: any): void {
    const searchStr = event.target.value.trim().toLowerCase();

    if (!searchStr) {
      this.filteredListings = this.propertyListings;
      return;
    }

    this.filteredListings = this.propertyListings.filter(listing =>
      listing.propertyDetail?.propertyDescription?.toLowerCase().includes(searchStr) ||
      listing.propertyDetail?.propertyAddress?.toLowerCase().includes(searchStr) ||
      listing.propertyDetail?.addressDetail?.streetNumber?.toLowerCase().includes(searchStr) ||
      listing.propertyDetail?.addressDetail?.streetName?.toLowerCase().includes(searchStr) ||
      listing.propertyDetail?.addressDetail?.suburb?.toLowerCase().includes(searchStr) ||
      listing.propertyDetail?.addressDetail?.city?.toLowerCase().includes(searchStr) ||
      listing.propertyDetail?.addressDetail?.complexName?.toLowerCase().includes(searchStr) ||
      listing.surveyorGeneralKey?.toLowerCase().includes(searchStr) ||
      listing.planningReportId?.toLowerCase().includes(searchStr) ||
      listing.taskList?.forEach(f => f.user?.name.toLowerCase().includes(searchStr)) ||
      listing.taskList?.forEach(f => f.user?.surname?.toLowerCase().includes(searchStr))
    );
  }
  getUsers(): void {
    this.userService.apiV1UserManagementGetUsersGet().subscribe(user => {
      this.activeUsers = user;
      this.getPropertyListings();
    });
  }

  getCurrentUser(): void {
    this.userService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }

  filterListingBasedOnTab(event: any) {
    switch (event) {
      case 1:
        this.filterBasedOnSelection('Published');
        break;
      case 2:
        this.filterBasedOnSelection('UnderOffer');
        break;
      case 3:
        this.filterBasedOnSelection('Sold');
        break;
      default:
        this.filterBasedOnSelection("All");
        break;
    }
  }

  filterBasedOnSelection(filter:string){
    if (filter == 'All') {
      this.filteredListings = this.propertyListings;
    }
    else{
      this.filteredListings = this.propertyListings.filter(listing => listing.lifeCycleState === ListingLifeCycleState[filter]);
    }
  }
}
