<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="advert-publish" id="advert-publish">
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
            <div class="row paragraph mt-3" [hidden]="!showDevelopedOnly">
                <div class="col-12">
                    <mat-card class="property-card" [hidden]="!showDevelopedOnly">
                        <div class="row no-gutters">
                            <div class="col-6 p-2 property-image">
                                <img [src]="selectedListing?.image" class="listing-image" alt="Property Image" />
                            </div>
                            <div class="col-6 p-4 property-details">
                                <div class="property-price">R {{ advertGeneratorDeveloped.propertyInfoPrice }}</div>
                                <div class="property-info">{{ advertGeneratorDeveloped.propertyInfoSizeTypeLocation }}
                                </div>
                                <div class="property-label-development">Possible Development:</div>
                                <div class="property-description">{{
                                    advertGeneratorDeveloped.propertyInfoShortDescription }}</div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3" [hidden]="!showDevelopAbleOnly">
                <div class="col-12">
                    <mat-card class="property-card">
                        <div class="row no-gutters">
                            <div class="col-6 p-2 property-image">
                                <img [src]="selectedListing?.image" class="listing-image" alt="Property Image" />
                            </div>
                            <div class="col-6 p-4 property-details">
                                <div class="property-price">R {{ advertGeneratorDeveloped.propertyInfoPrice }}</div>
                                <div class="property-info">{{ advertGeneratorDeveloped.propertyInfoSizeTypeLocation }}
                                </div>
                                <div class="property-label-development">Possible Development:</div>
                                <div class="property-description">{{
                                    advertGeneratorDeveloped.propertyInfoShortDescription }}</div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="saveAdvertAppointed()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>