import { EstateAgentRegistrationStateService } from '../../../../../app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service';
import { FileManagementExtensionService } from '../../../../../app/shared/services/file-management-extension-service/file-management-extension.service';
import { NotificationService } from '../../../../../app/shared/services/notification-service/notification.service';
import { EstateAgentDocumentDto } from '../../../../../app/services/property-matrixV2/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estate-agent-submit-documents',
  templateUrl: './estate-agent-submit-documents.component.html',
  styleUrls: ['./estate-agent-submit-documents.component.scss']
})
export class EstateAgentSubmitDocumentsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  documents: { description: string; required: boolean }[] = [];
  isPrincipal: boolean = false;

  constructor(
    private _notificationService: NotificationService,
    private _fileManagementExtensionService: FileManagementExtensionService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService
  ) { }

  ngOnInit(): void {
    this.updateDocumentList();
    this._estateAgentRegistrationStateService.formState$.subscribe(() => {
      this.updateDocumentList();
    });
  }

  updateDocumentList(): void {
    this.isPrincipal = this._estateAgentRegistrationStateService.getFormState().isPrincipal;

    if (this.isPrincipal) {
      this.documents = [
        { description: 'Signed Collaboration Agreement', required: true },
        { description: 'Copy of ID (or Passport - only if non-resident)', required: true },
        { description: 'Compliant Fidelity Fund Certificate (individual)', required: true },
        { description: 'Compliant Fidelity Fund Certificate (company)', required: true },
        { description: 'Bank account confirmation letter', required: true },
        { description: 'Recent face-only photo of yourself', required: true },
        { description: 'Company VAT certificate', required: false },
        { description: 'Qualifications & designations (optional)', required: false },
        { description: 'Company logo (only smaller/independent brokers/agencies)', required: false }
      ];
    } else {
      this.documents = [
        { description: 'Copy of ID (or Passport - only if non-resident)', required: true },
        { description: 'Compliant Fidelity Fund Certificate', required: true },
        { description: 'Recent face-only photo of yourself', required: true },
        { description: 'Qualifications & designations (optional)', required: false }
      ];
    }
  }

  isFileUploaded(index: number): boolean {
    const state = this._estateAgentRegistrationStateService.getFormState();
    return !!state.documents[index];
  }

  onFileSelected(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      this.uploadFile(file, index);
    }
  }

  async uploadFile(file: File, index: number): Promise<void> {
    try {
      const result = await this._fileManagementExtensionService.uploadFile(file);
      const document: EstateAgentDocumentDto = {
        id: result,
        documentInfo: {
          id: result,
          name: file.name,
          extension: file.type
        }
      };
      this._estateAgentRegistrationStateService.updateDocument(index, document);
    } catch (_error) {
      this._notificationService.showErrorMessage('Error', 'An error occurred while uploading the file.');
    }
  }

  onRemoveFile(index: number, event: Event): void {
    event.stopPropagation();
    this._estateAgentRegistrationStateService.removeDocument(index);
  }

  downloadFile(index: number, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const document = this._estateAgentRegistrationStateService.getFormState().documents[index];
    if (document) {
      this._fileManagementExtensionService.getFileDownloadUrl(document.id).subscribe({
        next: (blobUrl: string) => {
          if (blobUrl) {
            window.open(blobUrl, '_blank');
          } else {
            this._notificationService.showErrorMessage('Error', 'Could not retrieve file URL.');
          }
        },
        error: (_error: any) => {
          this._notificationService.showErrorMessage('Error', 'An error occurred while retrieving the file.');
        }
      });
    }
  }

  allRequiredFilesUploaded(): boolean {
    return this.documents
      .filter(doc => doc.required)
      .every((_, index) => this.isFileUploaded(index));
  }

  onSubmit(): void {
    if (this.allRequiredFilesUploaded()) {
      this.pageNumberEvent.emit(3);
      this.formSubmittedEvent.emit();
    }
  }

  async onLinkClick(linkName: string): Promise<void> {
    let filePath: string | null = null;

    if (linkName === 'Collaboration Agreement') {
      filePath = '../../../../../assets/pdf/Collaboration Agreement - Principal Agent_RM2.pdf';
      window.open(filePath, '_blank');
    }
  }
}
