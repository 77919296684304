import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MultiplePropertyListingDto, PropertyListingDto, TaskListDto, UserDto } from '../../../../../..//app/services/property-matrixV2/models';
import { AdvertService, LookupService, PropertyListingService, UserManagementService } from '../../../../../..//app/services/property-matrixV2/services';
import { ListingStateService } from '../../../../../..//app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../../..//app/shared/services/notification-service/notification.service';

@Component({
  selector: 'app-allocate-advert-create',
  templateUrl: './allocate-advert-create.component.html',
  styleUrls: ['./allocate-advert-create.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class AllocateAdvertCreateComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;
  listingType: string;
  currentUser: UserDto;

  sections = [
    { key: 1, value: 'Choose Advert Creator', checked: true },
  ];
  loading: boolean = false;
  AdminUsersList: UserDto[] = [];
  selectedUser: UserDto | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private propertyListingService: PropertyListingService,
    private userManagementService: UserManagementService,
    private lookupService: LookupService,
    private listingStateService: ListingStateService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });

    this.getUsers();
    this.getCurrentUser();

    if (this.selectedListing.advertCreator) {
      this.selectedUser = this.selectedListing.advertCreator;
    }
  }
  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  saveAdvertAppointed(): void {

    try {
      if (this.selectedUser == null) {
        this.notificationService.showErrorMessage("Error", "Please select a user and save again.");
        return;
      }
      this.selectedListing.advertCreator = this.selectedUser;
      this.loading = true;
      this.propertyListingService.apiV1PropertyListingAddOrUpdateAdvertCreatorToPropertyListingPost({ body: this.selectedListing }).subscribe({
        next: () => {
          this.loading = false;
          this.notificationService.showSuccessMessage('Success', 'Successfully saved property details.');
          this.setPropertyListingTask();

        },
        error: (_error) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not save property details.');
        }
      });
    }
    catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Could not fetch users.' + err);
    }
  }

  navigateToPlanningReport(): void {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }

  compareUser(role1: UserDto, role2: UserDto) {
    return role1 && role2 ? role1.name === role2.name : role1 === role2;
  }

  async getUsers() {
    try {
      this.loading = true;
      await this.lookupService.apiV1LookupGetAdvertCreatorUsersGet().subscribe({
        next: (users: UserDto[]) => {
          this.loading = false;
          this.AdminUsersList = users;
        },
        error: (_error: any) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not fetch users.');
        }
      });
    }
    catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Could not fetch users.' + err);
    }
    finally {
      this.loading = false;
    }
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    this.selectedListing.taskList.forEach(task => {
      if (task.taskNumber.number === 9) {
        if (task.taskStatus.taskStatusNumber != 3) {
          task.user = this.selectedUser;
          changedTaskLists.push(task);
        }
      }
      if (task.taskNumber.number === 10) {
        if (task.taskStatus.taskStatusNumber == 1) {
          task.user = this.selectedUser;
          changedTaskLists.push(task);
        }
      }
      if (task.taskNumber.number === 11) {
        if (task.taskStatus.taskStatusNumber == 1) {
          task.user = this.selectedUser;
          changedTaskLists.push(task);
        }
      }
      if (task.taskNumber.number === 12) {
        if (task.taskStatus.taskStatusNumber == 1) {
          task.user = this.selectedUser;
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result != null) {
          this.navigateToPlanningReport();
        }
        else {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
    else{
      this.navigateToPlanningReport();
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }
}
