import { UrbanPlannerRegistrationStateService } from 'src/app/shared/services/registration/urban-planner-registration-state/urban-planner-registration-state.service';
import { FileManagementExtensionService } from 'src/app/shared/services/file-management-extension-service/file-management-extension.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UrbanPlannerDocumentDto } from 'src/app/services/property-matrixV2/models';

@Component({
  selector: 'app-urban-planner-submit-documents',
  templateUrl: './urban-planner-submit-documents.component.html',
  styleUrls: ['./urban-planner-submit-documents.component.scss']
})
export class UrbanPlannerSubmitDocumentsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();
  @Output() documentsSubmitted = new EventEmitter<void>();

  documents = [];

  constructor(
    private _notificationService: NotificationService,
    private _fileManagementExtensionService: FileManagementExtensionService,
    private _urbanPlannerRegistrationStateService: UrbanPlannerRegistrationStateService
  ) { }

  ngOnInit(): void {
    this.updateDocumentList();
    this._urbanPlannerRegistrationStateService.formState$.subscribe(() => {
      this.updateDocumentList();
    });
  }

  updateDocumentList(): void {
    this.documents = [
      { description: 'Identification Documents: ID (RSA resident) or Passport (non-resident)', required: true },
      { description: 'Up to date registration certificate with SACPLAN', required: true },
      { description: 'Certified copy: Town and regional planning degree certificate', required: true },
      { description: 'A photo of yourself to be used for office & admin purposes only', required: true },
      { description: 'A logo of your company to be used for marketing purposes', required: true },
      { description: 'Certified copy 1: additional professional qualification and/or designation', required: true },
      { description: 'Certified copy 2: additional professional qualification and/or designation', required: false },
      { description: 'Certified copy 3: additional professional qualification and/or designation', required: false },
    ];
  }

  isFileUploaded(index: number): boolean {
    const state = this._urbanPlannerRegistrationStateService.getFormState();
    return !!state.documents[index];
  }

  onFileSelected(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      this.uploadFile(file, index);
    }
  }

  async uploadFile(file: File, index: number): Promise<void> {
    try {
      const result = await this._fileManagementExtensionService.uploadFile(file);
      const document: UrbanPlannerDocumentDto = {
        id: result,
        documentInfo: {
          id: result,
          name: file.name,
          extension: file.type
        }
      };
      this._urbanPlannerRegistrationStateService.updateDocument(index, document);
    } catch (_error) {
      this._notificationService.showErrorMessage('Error', 'An error occurred while uploading the file.');
    }
  }

  onRemoveFile(index: number, event: Event): void {
    event.stopPropagation();
    this._urbanPlannerRegistrationStateService.removeDocument(index);
  }

  downloadFile(index: number, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const document = this._urbanPlannerRegistrationStateService.getFormState().documents[index];
    if (document) {
      this._fileManagementExtensionService.getFileDownloadUrl(document.id).subscribe({
        next: (blobUrl: string) => {
          if (blobUrl) {
            window.open(blobUrl, '_blank');
          } else {
            this._notificationService.showErrorMessage('Error', 'Could not retrieve file URL.');
          }
        },
        error: (_error: any) => {
          this._notificationService.showErrorMessage('Error', 'An error occurred while retrieving the file.');
        }
      });
    }
  }

  allRequiredFilesUploaded(): boolean {
    return this.documents
      .filter(doc => doc.required)
      .every((_, index) => this.isFileUploaded(index));
  }

  onSubmit(): void {
    if (this.allRequiredFilesUploaded()) {
      this.pageNumberEvent.emit(3);
      this.formSubmittedEvent.emit();
      this.documentsSubmitted.emit();
    }
  }
}
