import { GlobalMethods } from '../../../../app/common/global-methods';
import { NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-choices',
  templateUrl: './register-choices.component.html',
  styleUrls: ['./register-choices.component.scss']
})
export class RegisterChoicesComponent implements OnInit {

  constructor(
    public _dialog: MatDialog,
    private _router: Router
  ) { }

  ngOnInit(): void {
    GlobalMethods.scrollToTop();
  }

  navigateWithUserType(route: string, userType: string): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        userType: userType
      }
    };

    if (userType === "estate-agent") {
      navigationExtras.queryParams!['isPrincipal'] = 'true';
    }

    this._router.navigate([route], navigationExtras);
  }
}
