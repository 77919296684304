import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AdvertCreatorFiltersDto, AdvertDto, AdvertLocationDto, AdvertSectorDto, AdvertTypesDto, MultiplePropertyListingDto, PropertyListingDto, TaskListDto, UserDto } from '../../../../../../app/services/property-matrixV2/models';
import { AdvertService, LookupService, PropertyListingService, UserManagementService } from '../../../../../../app/services/property-matrixV2/services';
import { AdvertLookupCreateComponent } from '../../../../../../app/shared/components/advert-lookup-create/advert-lookup-create.component';
import { ListingStateService } from '../../../../../../app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../../../app/shared/services/notification-service/notification.service';

@Component({
  selector: 'app-advert-filter-create',
  templateUrl: './advert-filter-create.component.html',
  styleUrls: ['./advert-filter-create.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class AdvertFilterCreateComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;
  @Input() listingType: string;

  sections = [
    { key: 1, value: '1. Location', checked: true },
    { key: 2, value: '2. Sector', checked: true },
    { key: 3, value: '3. Type', checked: true },
    { key: 4, value: '4. Selling Price', checked: true },
    { key: 5, value: '5. Size: Land Parcel', checked: true },
  ];


  FormAdvertFilterCreate: FormGroup;
  advertSectorList: AdvertSectorDto[] = [];
  advertLocationList: AdvertLocationDto[] = [];
  advertTypesList: AdvertTypesDto[] = [];
  loading: boolean = false;
  SgErfExtent: string | null | undefined;
  sellerAskingPriceExcludeVATcommission: string | null | undefined;
  selectedLocation: AdvertLocationDto[] = [];
  selectedSector: AdvertSectorDto[] = [];
  selectedTypes: AdvertTypesDto[] = [];
  advertFilterDTO: AdvertCreatorFiltersDto = {};
  advertDTOList: AdvertDto[] = [];
  currentUser: UserDto;
  advertFilterDTOid: string;
  selectedSectorId: string;

  constructor(
    private lookupService: LookupService,
    private notificationService: NotificationService,
    private router: Router,
    private listingStateService: ListingStateService,
    private advertService: AdvertService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userManagementService: UserManagementService,
    private propertyListingService: PropertyListingService,
  ) { }

  ngOnInit(): void {
    this.loadCheckboxes();
    this.getAdvertPrePopulatedData();
    this.getCurrentUser();
  }

  async getAdvertLocation() {
    try {
      await this.lookupService.apiV1LookupGetAdvertLocationsGet().subscribe({
        next: (advertLocationList: AdvertLocationDto[]) => {
          this.advertLocationList = advertLocationList;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
    }
  }

  async getSectorData() {
    try {
      await this.lookupService.apiV1LookupGetAdvertSectorsGet().subscribe({
        next: (advertSectorList: AdvertSectorDto[]) => {
          this.advertSectorList = advertSectorList;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load advert sector data.');
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
    }
  }

  async getAdvertTypes() {
    try {
      await this.lookupService.apiV1LookupGetAdvertTypesGet().subscribe({
        next: (advertTypesList: AdvertTypesDto[]) => {
          this.advertTypesList = advertTypesList;

        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load Advert types data.');
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
    }
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  goToListingOverViewRoute(): void {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }

  openLookupAddEditAdvertsDialog(): void {
    try {
      const dialogRef = this.dialog.open(AdvertLookupCreateComponent, {
        width: '400px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loadCheckboxes();
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
    }
  }

  goToAdminListings() {
    this.router.navigate(['admin/listings']);
  }

  saveDetails(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;

        this.selectedLocation = this.getSelectedLocation();
        this.selectedSector = this.getSelectedSectors();
        this.selectedTypes = this.getSelectedTypes();

        if (this.advertFilterDTO == null) {
          this.advertFilterDTO = {
            advertLocation: this.selectedLocation,
            advertSector: this.selectedSector,
            advertTypes: this.selectedTypes,
            sellerAskingPriceExcludeVATCommission: this.sellerAskingPriceExcludeVATcommission,
            sgErfExtent: this.SgErfExtent,
            isDeleted: false,
            propertyListingId: this.selectedListing.id,
          }
        } else {
          this.advertFilterDTO.advertLocation = this.selectedLocation;
          this.advertFilterDTO.advertSector = this.selectedSector;
          this.advertFilterDTO.advertTypes = this.selectedTypes;
          this.advertFilterDTO.sellerAskingPriceExcludeVATCommission = this.sellerAskingPriceExcludeVATcommission;
          this.advertFilterDTO.sgErfExtent = this.SgErfExtent;
          this.advertFilterDTO.isDeleted = false;
          this.advertFilterDTO.propertyListingId = this.selectedListing.id;
          this.advertFilterDTO.id = this.advertFilterDTOid;
        }

        this.advertService.apiV1AdvertSaveAdvertDataPost({
          body: this.advertFilterDTO
        }).subscribe({
          next: (result: any) => {
            if (result) {
              this.loading = false;
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert filters.');
              this.loading = false;
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert filters.');
            this.loading = false;
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", ex.toString());
        this.loading = false;
        resolve(false);
        reject("Error caught on service call save Advert filters.");
      }
    });
  }

  async saveConfirmDetails(): Promise<void> {
    try {
      this.loading = true;
      let savedData = await this.saveDetails();
      if (savedData == true) {
        this.listingStateService.setSelectedListing(this.selectedListing);
        this.setPropertyListingTask();
        this.notificationService.showSuccessMessage("Saved", "Saved advert filters successfully.")
        this.router.navigate(['admin/listings/listing-overview'], {
          queryParams: {
            listingType: this.listingType
          }
        });
      } else {
        this.notificationService.showErrorMessage("Error", "Error on saving advert filters")
      }
      this.loading = false;
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert filters because of errors.');
      this.loading = false;
    }
  }

  async saveData(): Promise<void> {
    try {
      this.loading = true;
      let savedData = await this.saveDetails();
      if (savedData == true) {
        this.listingStateService.setSelectedListing(this.selectedListing);
        this.notificationService.showSuccessMessage("Saved", "Saved advert filters successfully.")
      } else {
        this.notificationService.showErrorMessage("Error", "Error on saving advert filters")
      }
      this.loading = false;
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert filters because of errors.');
      this.loading = false;
    }
  }

  getSelectedTypes() {
    return this.advertTypesList.filter(type => type.isChecked);
  }

  getSelectedSectors() {
    return this.advertSectorList.filter(sector => sector.isChecked);
  }

  getSelectedLocation() {
    return this.advertLocationList.filter(location => location.isChecked);
  }

  prepareFormFields(): void {
    this.FormAdvertFilterCreate = this.fb.group({
      location: ["", [Validators.required]],
      sector: ["", [Validators.required]],
      type: ["", [Validators.required]],
      sellerAskingPriceExcludeVATcommission: ["", [Validators.required]],
      sgErfExtent: ["", [Validators.required]],
    });
  }

  loadCheckboxes(): void {
    this.getAdvertLocation();
    this.getSectorData();
    this.getAdvertTypes();
  }

  async getAdvertPrePopulatedData(): Promise<void> {
    try {
      this.loading = true;
      await this.advertService.apiV1AdvertGetAdvertFilterDataGet().subscribe({
        next: (AdvertDtoList: AdvertDto[]) => {
          this.advertDTOList = AdvertDtoList;
          this.setPrePopulatedFields(this.advertDTOList);
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
          this.loading = false;
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load advert location data.');
      this.loading = false;
    }
  }

  setPrePopulatedFields(advertDTOList: AdvertDto[]): void {
    try {
      for (let index = 0; index < advertDTOList.length; index++) {
        const element = advertDTOList[index];
        if (element.creatorFiltersDTO?.propertyListingId == this.selectedListing.id) {
          this.advertFilterDTO = element;
          this.advertFilterDTOid = element.creatorFiltersDTO.id;

          this.sellerAskingPriceExcludeVATcommission = element.creatorFiltersDTO?.sellerAskingPriceExcludeVATCommission;
          this.SgErfExtent = element.creatorFiltersDTO?.sgErfExtent;

          for (let index = 0; index < element.advertFilterLocationLinks!.length; index++) {
            const advertLocationItem = element.advertFilterLocationLinks![index];
            const advertLocation = advertLocationItem.advertLocation;
            for (let index = 0; index < this.advertLocationList.length; index++) {
              const advertLocationCheckbox = this.advertLocationList[index];
              if (advertLocationCheckbox.description == advertLocation!.description) {
                advertLocationCheckbox.isChecked = true;
              }
            }
          };

          for (let index = 0; index < element.advertFilterSectorLinks!.length; index++) {
            const advertSectorItem = element.advertFilterSectorLinks![index];
            const advertSector = advertSectorItem.advertSector;
            this.selectedSectorId = advertSectorItem.advertSector.id
            for (let index = 0; index < this.advertSectorList.length; index++) {
              const advertSectorCheckbox = this.advertSectorList[index];
              if (advertSectorCheckbox.description == advertSector!.description) {
                advertSectorCheckbox.isChecked = true;
              }
            }
          };

          for (let index = 0; index < element.advertFilterTypeLinks!.length; index++) {
            const advertTypeItem = element.advertFilterTypeLinks![index];
            const advertSector = advertTypeItem.advertTypes;
            for (let index = 0; index < this.advertTypesList.length; index++) {
              const advertTypeCheckbox = this.advertTypesList[index];
              if (advertTypeCheckbox.description == advertSector!.description) {
                advertTypeCheckbox.isChecked = true;
              }
            }
          };
        }
      }
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not set populated data.');
      this.loading = false;
    }
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    this.selectedListing.taskList?.forEach(task => {
      if (task.taskNumber?.number === 10) {
        if (task.taskStatus?.taskStatusNumber != 3) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result != null) {
          this.navigateToPlanningReport();
        }
        else {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
    else {
      this.navigateToPlanningReport();
    }
  }

  onCheckboxChange(selectedSection: any) {
    this.advertSectorList.forEach(section => {
      if (section !== selectedSection) {
        section.isChecked = false;
      }
    });
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }

  navigateToPlanningReport() {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }
}
