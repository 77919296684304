<main class="main-container">
    <div class="row paragraph mt-3 align-items-center">
        <div class="col-6 heading-2">
            Step 3: Submit Documents
        </div>
        <div class="col-6 text-end" *ngIf="this.isPrincipal">
            <button mat-button style="color: orange; border: 1px solid orange;" (click)="onLinkClick('Collaboration Agreement')">
                <mat-icon class="material-icons-outlined">download</mat-icon>
                Download Content
            </button>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="upload-section" *ngFor="let doc of documents; let i = index"
            [ngClass]="{ 'required-border': doc.required }">
            <mat-card class="upload-card" [ngClass]="{ 'no-border': isFileUploaded(i) }" (click)="fileInput.click()">
                <div>
                    <mat-checkbox [checked]="isFileUploaded(i)" disabled></mat-checkbox>
                    <span class="paragraph ms-2">{{ doc.description }}</span>
                </div>
                <div class="upload-actions">
                    <span *ngIf="!isFileUploaded(i)" class="paragraph">Click to upload</span>
                    <button mat-button *ngIf="!isFileUploaded(i)" matTooltip="Upload" matTooltipPosition="right"
                        matTooltipShowDelay="1000">
                        <mat-icon class="material-icons-outlined">upload</mat-icon>
                    </button>
                    <button mat-button *ngIf="isFileUploaded(i)" (click)="downloadFile(i, $event)" matTooltip="Download"
                        matTooltipPosition="left" matTooltipShowDelay="1000">
                        <mat-icon class="material-icons-outlined">download</mat-icon>
                    </button>
                    <button mat-button *ngIf="isFileUploaded(i)" (click)="onRemoveFile(i, $event)" matTooltip="Delete"
                        matTooltipPosition="right" matTooltipShowDelay="1000">
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                    </button>
                </div>
                <input type="file" class="file-input" (change)="onFileSelected($event, i)" hidden #fileInput>
            </mat-card>
        </div>
    </div>
    <div class="row paragraph mt-5">
        <div class="text-center">
            <button class="button" type="submit" [disabled]="!allRequiredFilesUploaded()" (click)="onSubmit()">
                SUBMIT
            </button>
        </div>
    </div>
</main>