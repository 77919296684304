<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main>
    <mat-card class="upload-card">
        <div class="upload-section" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (click)="fileInput.click()">
            <input class="file-input" type="file" #fileInput multiple (change)="onFileInput($event)"
                placeholder="file" />
            <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
            <p class="paragraph">Drag & drop or</p>
            <p class="paragraph">click to upload.</p>
        </div>
    </mat-card>
    <div *ngFor="let file of files; let i = index" class="file-card">
        <mat-card class="file-item-card">
            <mat-card-content class="card-content">
                <a class="paragraph file-name" matTooltip="Download file" matTooltipPosition="right" [href]="file.url"
                    [download]="file.name">
                    {{ file.name }}
                </a>
                <button class="delete-button" matTooltip="Delete file" matTooltipPosition="left"
                    (click)="deleteFile(file.id, i)">
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
            </mat-card-content>
        </mat-card>
    </div>
</main>