<main>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <!-- Multiple Report -->
    <div *ngIf="isMultiple">
        <mat-card class="mb-3">
            <mat-tab-group mat-stretch-tabs class="report-tab-group" [selectedIndex]="reportTypeTabNumber - 1"
                (selectedIndexChange)="navigateToReportTypeTab($event + 1)">
                <mat-tab *ngFor="let type of reportTypes">
                    <ng-template mat-tab-label>
                        <span class="tab-title">
                            {{ type.value }}
                        </span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
        <div *ngIf="isGeneralInformation">
            <app-planning-report *ngIf="generalPlanningReportData" [planningReportData]="generalPlanningReportData"
                [listingId]="listingId" [multipleListingId]="multipleListingId" [isMultiple]="isMultiple"
                [isGeneralInformation]="isGeneralInformation">
            </app-planning-report>
        </div>
        <div *ngIf="!isGeneralInformation">
            <mat-card class="mb-3">
                <div class="flex-container">
                    <mat-select class="paragraph" (change)="updateSelectedListing($event.target.value)"
                        placeholder="updateSelectedListing">
                        <mat-option *ngFor="let listing of propertyListings" [value]="listing.id">
                            {{ listing.addressTitle }}
                        </mat-option>
                    </mat-select>
                    <mat-tab-group mat-stretch-tabs class="report-tab-group" [selectedIndex]="reportItemTabNumber - 1"
                        (selectedIndexChange)="navigateToReportItemTab($event + 1)">
                        <mat-tab *ngFor="let item of reportItems">
                            <ng-template mat-tab-label>
                                <span class="tab-title">
                                    {{ item.value }}
                                </span>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-card>
            <div *ngIf="reportItemTabNumber === 1">
                <app-planning-report *ngIf="planningReportData" [planningReportData]="planningReportData"
                    [listingId]="listingId" [multipleListingId]="multipleListingId" [isMultiple]="isMultiple"
                    [isGeneralInformation]="isGeneralInformation" (tabNumberEvent)="navigateToReportItemTab($event)">
                </app-planning-report>
            </div>
            <div *ngIf="reportItemTabNumber === 2">
                <app-addendum-one *ngIf="addendumOneData" [addendumOneData]="addendumOneData" [listingId]="listingId"
                    (tabNumberEvent)="navigateToReportItemTab($event)">
                </app-addendum-one>
            </div>
            <div *ngIf="reportItemTabNumber === 3">
                <app-addendum-two *ngIf="addendumTwoData" [addendumTwoData]="addendumTwoData" [listingId]="listingId"
                    (tabNumberEvent)="navigateToReportItemTab($event)">
                </app-addendum-two>
            </div>
            <div *ngIf="reportItemTabNumber === 4">
                <app-addendum-three *ngIf="addendumThreeData" [addendumThreeData]="addendumThreeData"
                    [listingId]="listingId" (tabNumberEvent)="navigateToReportItemTab($event)">
                </app-addendum-three>
            </div>
            <div *ngIf="reportItemTabNumber === 5">
                <app-export-report *ngIf="exportReportData" [exportReportData]="exportReportData"
                    [listingId]="listingId" [multipleListingId]="multipleListingId" [isMultiple]="isMultiple"
                    (tabNumberEvent)="navigateToReportItemTab($event)">
                </app-export-report>
            </div>
        </div>
    </div>
    <!-- Single Report -->
    <div *ngIf="!isMultiple">
        <mat-card class="mb-3">
            <mat-tab-group mat-stretch-tabs class="report-tab-group" [selectedIndex]="reportItemTabNumber - 1"
                (selectedIndexChange)="navigateToReportItemTab($event + 1)">
                <mat-tab *ngFor="let item of reportItems">
                    <ng-template mat-tab-label>
                        <span class="tab-title">
                            {{ item.value }}
                        </span>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
        <div *ngIf="reportItemTabNumber === 1">
            <app-planning-report *ngIf="planningReportData" [planningReportData]="planningReportData"
                [listingId]="listingId" [isMultiple]="isMultiple" [isGeneralInformation]="isGeneralInformation"
                (tabNumberEvent)="navigateToReportItemTab($event)">
            </app-planning-report>
        </div>
        <div *ngIf="reportItemTabNumber === 2">
            <app-addendum-one *ngIf="addendumOneData" [addendumOneData]="addendumOneData" [listingId]="listingId"
                (tabNumberEvent)="navigateToReportItemTab($event)">
            </app-addendum-one>
        </div>
        <div *ngIf="reportItemTabNumber === 3">
            <app-addendum-two *ngIf="addendumTwoData" [addendumTwoData]="addendumTwoData" [listingId]="listingId"
                (tabNumberEvent)="navigateToReportItemTab($event)">
            </app-addendum-two>
        </div>
        <div *ngIf="reportItemTabNumber === 4">
            <app-addendum-three *ngIf="addendumThreeData" [addendumThreeData]="addendumThreeData"
                [listingId]="listingId" (tabNumberEvent)="navigateToReportItemTab($event)">
            </app-addendum-three>
        </div>
        <div *ngIf="reportItemTabNumber === 5">
            <app-export-report *ngIf="exportReportData" [exportReportData]="exportReportData" [listingId]="listingId"
                [isMultiple]="isMultiple" (tabNumberEvent)="navigateToReportItemTab($event)"
                [selectedListing]="selectedListing">
            </app-export-report>
        </div>
    </div>
</main>