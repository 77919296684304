import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { PropertyListingService, UserManagementService } from '../../../../../../app/services/property-matrixV2/services';
import { ListingStateService } from '../../../../../../app/shared/services/listing-state-service/listing-state.service';
import { PropertyListingDto, RoleDto, UserDto } from '../../../../../../app/services/property-matrixV2/models';

@Component({
  selector: 'app-listing-users',
  templateUrl: './listing-users.component.html',
  styleUrls: ['./listing-users.component.scss']
})
export class ListingUsersComponent implements OnInit {

  displayedColumns: string[] = ['fullName', 'profession', 'email', 'status', 'action'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() selectedListing: PropertyListingDto;
  dataSource: MatTableDataSource<any>;
  loading = false;
  Users: any[] = [];
  roles: RoleDto[] = [];
  listingId: string | null = null;


  constructor(
    private listingService: PropertyListingService,
    private listingStateService: ListingStateService,
    private userManagementService: UserManagementService,
  ) { }

  ngOnInit(): void {
    this.userManagementService.apiV1UserManagementGetRolesGet().subscribe(result => {
      this.roles = result;
      this.listingStateService.selectedListing$.subscribe({
        next: (listing: PropertyListingDto) => {
          this.selectedListing = listing;
          this.listingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
            this.selectedListing = result;
            this.getUsers();
            this.dataSource = new MatTableDataSource(this.Users);
            this.dataSource.sort = this.sort;
          });
        }
      });
    });
  }

  getUsers() {
    if (this.selectedListing && this.selectedListing.taskList) {
      this.selectedListing.taskList.forEach(task => {
        if (task.user && !this.Users.some(u => u.id === task.user.id)) {
          let newUser: any = task.user;
          newUser.userRoles[0].roleName = this.roles.find(r => r.id === newUser.userRoles[0].roleid).name;
          newUser.status = task.userStatusText != null ? task.userStatusText : task.comment;
          this.Users.push(task.user);
        }
      });
    }
  }

  RemoveUser(user: any) {
    //remove user from listing group
  }
}
