<main  [ngClass]="{'control-disabled': disableComponent}">
    <div class="heading-3 mb-3">
        Projected Scenario - Land Parcel {{ index + 1 | numberToWords }}
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Size of land parcel (ha)</label>
            <input class="col-12 mt-1" type="number" placeholder="1.2" [(ngModel)]="scenario.sizeOfLandParcel" min="0"
                   step="0.01" [readonly]="disableComponent">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Proposed density (units/ha)</label>
            <input class="col-12 mt-1" type="number" placeholder="20" [(ngModel)]="scenario.density" min="0"
                   step="0.01" [readonly]="disableComponent">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Proposed coverage (%)</label>
            <input class="col-12 mt-1" type="number" placeholder="70" [(ngModel)]="scenario.coverage" min="0" [readonly]="disableComponent"
                   step="0.01" max="100" name="coverage" #coverage="ngModel">
            <mat-error *ngIf="coverage.invalid && (coverage.dirty || coverage.touched)">
                <span *ngIf="coverage.errors?.min">Value must be positive.</span>
                <span *ngIf="coverage.errors?.max">Value must be less than or equal to 100.</span>
            </mat-error>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Proposed height (storeys)</label>
            <input class="col-12 mt-1" type="number" placeholder="3" [(ngModel)]="scenario.height" [readonly]="disableComponent">
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Proposed FAR</label>
            <input class="col-12 mt-1" type="number" placeholder="0.2" [(ngModel)]="scenario.floorAreaRatio" [readonly]="disableComponent"
                   [value]="scenario.floorAreaRatio | number:'1.2-2'">
        </div>
    </div>
    <div class="row paragraph mt-3" *ngIf="municipality == null || municipality == ''">
        <div class="col-12">
            <label class="col-12 bold">Scheme name</label>
            <mat-select class="col-12 mt-1" [(value)]="scenario.schemeId" (selectionChange)="onSchemeChange($event)" [disabled]="disableComponent">
                <mat-option *ngFor="let scheme of schemeData" [value]="scheme.id">
                    {{ scheme.description }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-3">
        <div class="col-12">
            <label class="col-12 bold">Proposed zoning/right</label>
            <mat-select class="col-12 mt-1" [(value)]="scenario.zoningId" (selectionChange)="onZoningChange($event)" [disabled]="disableComponent">
                <mat-option *ngFor="let zoning of zoningData" [value]="zoning.id">
                    {{ zoning.description }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">Proposed land uses</label>
            <textarea class="col-12 mt-1 coral-text text-area" placeholder="proposed land uses" [readonly]="disableComponent"
                      readonly>{{ possibleProposedLandUses }}</textarea>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">
                Select the proposed land uses according to the applicable land use
                rights.
            </label>
            <mat-select class="col-12 mt-1" multiple [(value)]="scenario.proposedLandUseIds" [disabled]="disableComponent"
                        (selectionChange)="onProposedLandUsesChange($event)">
                <mat-option *ngFor="let landUse of proposedLandUses" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">Possible additional uses</label>
            <textarea class="col-12 mt-1 coral-text text-area" placeholder="possible additional uses" [readonly]="disableComponent"
                      readonly>{{ possibleAdditionalLandUses }}</textarea>
        </div>
    </div>
    <div class="row paragraph mt-4">
        <div class="col-12">
            <label class="col-12 bold">
                Select the proposed additional uses according to the applicable land use
                rights.
            </label>
            <mat-select class="col-12 mt-1" multiple [(value)]="scenario.additionalLandUseIds" [disabled]="disableComponent"
                        (selectionChange)="onAdditionalLandUsesChange($event)">
                <mat-option *ngFor="let landUse of additionalLandUses" [value]="landUse.id">
                    {{ landUse.descriptionShort }}
                </mat-option>
            </mat-select>
        </div>
    </div>
</main>
