import { EstateAgentRegistrationStateService } from "src/app/shared/services/registration/estate-agent-registration-state/estate-agent-registration-state.service";
import { NotificationService } from "src/app/shared/services/notification-service/notification.service";
import { EstateAgentCompanyInformationDto } from "src/app/services/property-matrixV2/models";
import { RegistrationService } from "src/app/services/property-matrixV2/services";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-estate-agent-company-information',
  templateUrl: './estate-agent-company-information.component.html',
  styleUrls: ['./estate-agent-company-information.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class EstateAgentCompanyInformationComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  isPrincipal: boolean = false;
  companyInfoForm: FormGroup | null | undefined;

  constructor(
    private _formBuilder: FormBuilder,
    private _registrationService: RegistrationService,
    private _estateAgentRegistrationStateService: EstateAgentRegistrationStateService,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    try {
      this.isPrincipal = this._estateAgentRegistrationStateService.getFormState().isPrincipal;

      this.companyInfoForm = this._formBuilder.group({
        individualFidelityFundCertificateNumber: [null, Validators.required],
        companyFidelityFundCertificateNumber: [null],
        individualReferenceNumber: [null],
        isAffiliatedWithAgency: [false],
        isPrincipal: [false],
        agencyName: [null],
        agencyBranch: [null],
        firmName: [null],
        tradingName: [null],
        firmPinNumber: [null, Validators.required],
        unitNumber: [null],
        complexName: [null],
        streetNumber: [null, Validators.required],
        streetName: [null, Validators.required],
        suburb: [null, Validators.required],
        city: [null, Validators.required],
        accountHolderName: [null, Validators.required],
        bankName: [null, Validators.required],
        branchName: [null],
        branchNumber: [null, Validators.required],
        accountType: [null, Validators.required],
        accountNumber: [null, Validators.required],
        vatNumber: [null],
        principalName: [null],
        principalSurname: [null],
        principalIdNumber: [null],
      });

      this.updateFormValidators();

      this.companyInfoForm.get('isAffiliatedWithAgency').valueChanges.subscribe(() => {
        this.updateFormValidators();
      });

      this.companyInfoForm.get('isPrincipal').valueChanges.subscribe(() => {
        this.updateFormValidators();
      });

      this._estateAgentRegistrationStateService.formState$.subscribe((formState) => {
        const currentCompanyInfo = formState.companyInformation || {};

        this.companyInfoForm.patchValue({
          individualFidelityFundCertificateNumber: currentCompanyInfo.individualFidelityFundCertificateNumber,
          companyFidelityFundCertificateNumber: currentCompanyInfo.companyFidelityFundCertificateNumber,
          individualReferenceNumber: currentCompanyInfo.individualReferenceNumber,
          isAffiliatedWithAgency: currentCompanyInfo.isAffiliatedWithAgency || false,
          agencyName: currentCompanyInfo.agencyName,
          agencyBranch: currentCompanyInfo.agencyBranch,
          firmName: currentCompanyInfo.firmName,
          tradingName: currentCompanyInfo.tradingName,
          firmPinNumber: currentCompanyInfo.firmPinNumber,
          unitNumber: currentCompanyInfo.unitNumber,
          complexName: currentCompanyInfo.complexName,
          streetNumber: currentCompanyInfo.streetNumber,
          streetName: currentCompanyInfo.streetName,
          suburb: currentCompanyInfo.suburb,
          city: currentCompanyInfo.city,
          accountHolderName: currentCompanyInfo.accountHolderName,
          bankName: currentCompanyInfo.bankName,
          branchName: currentCompanyInfo.branchName,
          branchNumber: currentCompanyInfo.branchNumber,
          accountType: currentCompanyInfo.accountType,
          accountNumber: currentCompanyInfo.accountNumber,
          vatNumber: currentCompanyInfo.vatNumber
        });

        if (!currentCompanyInfo.principalName && formState.principalUserId) {
          this.fetchPrincipalDetails(formState.principalUserId);
        } else if (currentCompanyInfo.principalName) {
          this.companyInfoForm.patchValue({
            principalName: currentCompanyInfo.principalName,
            principalSurname: currentCompanyInfo.principalSurname,
            principalIdNumber: currentCompanyInfo.principalIdNumber
          });
        }
      });
    } catch (err) {
      this._notificationService.showErrorMessage("Error", "Error found on setting network agent details.");
      console.error("Error found on setting network agent details.");
    }
  }

  updateFormValidators() {
    const isAffiliatedWithAgency = this.companyInfoForm.get('isAffiliatedWithAgency').value;
    const isPrincipal = this.isPrincipal;

    if (isAffiliatedWithAgency) {
      this.companyInfoForm.get('agencyName').setValidators([Validators.required]);
      this.companyInfoForm.get('agencyBranch').setValidators([Validators.required]);
      this.companyInfoForm.get('firmName').clearValidators();
      this.companyInfoForm.get('tradingName').clearValidators();
    } else {
      this.companyInfoForm.get('firmName').setValidators([Validators.required]);
      this.companyInfoForm.get('tradingName').setValidators([Validators.required]);
      this.companyInfoForm.get('agencyName').clearValidators();
      this.companyInfoForm.get('agencyBranch').clearValidators();
    }

    if (isPrincipal) {
      this.companyInfoForm.get('companyFidelityFundCertificateNumber').setValidators([Validators.required]);
      this.companyInfoForm.get('principalName').clearValidators();
      this.companyInfoForm.get('principalSurname').clearValidators();
      this.companyInfoForm.get('principalIdNumber').clearValidators();
    } else {
      this.companyInfoForm.get('individualFidelityFundCertificateNumber').clearValidators();
      this.companyInfoForm.get('companyFidelityFundCertificateNumber').clearValidators();
      this.companyInfoForm.get('firmPinNumber').clearValidators();
      this.companyInfoForm.get('streetNumber').clearValidators();
      this.companyInfoForm.get('streetName').clearValidators();
      this.companyInfoForm.get('suburb').clearValidators();
      this.companyInfoForm.get('city').clearValidators();
      this.companyInfoForm.get('accountHolderName').clearValidators();
      this.companyInfoForm.get('bankName').clearValidators();
      this.companyInfoForm.get('branchNumber').clearValidators();
      this.companyInfoForm.get('accountType').clearValidators();
      this.companyInfoForm.get('accountNumber').clearValidators();
      this.companyInfoForm.get('agencyName').clearValidators();
      this.companyInfoForm.get('agencyBranch').clearValidators();
      this.companyInfoForm.get('firmName').clearValidators();
      this.companyInfoForm.get('tradingName').clearValidators();
      this.companyInfoForm.get('principalName').setValidators([Validators.required]);
      this.companyInfoForm.get('principalSurname').setValidators([Validators.required]);
      this.companyInfoForm.get('principalIdNumber').setValidators([Validators.required]);
    }

    this.companyInfoForm.get('agencyName').updateValueAndValidity();
    this.companyInfoForm.get('agencyBranch').updateValueAndValidity();
    this.companyInfoForm.get('firmName').updateValueAndValidity();
    this.companyInfoForm.get('tradingName').updateValueAndValidity();
    this.companyInfoForm.get('companyFidelityFundCertificateNumber').updateValueAndValidity();
    this.companyInfoForm.get('principalName').updateValueAndValidity();
    this.companyInfoForm.get('principalSurname').updateValueAndValidity();
    this.companyInfoForm.get('principalIdNumber').updateValueAndValidity();
  }

  fetchPrincipalDetails(principalUserId: string) {
    this._registrationService.apiV1RegistrationGetUserByEstateAgentPrincipalUserIdGet({
      principalUserId: principalUserId
    }).subscribe({
      next: (response) => {
        this.companyInfoForm.patchValue({
          principalName: response.name,
          principalSurname: response.surname,
          principalIdNumber: response.idNumber
        });
      },
      error(err) {
        this.notificationService.showErrorMessage("Error", "Error getting the User By EstateAgent Principal UserId");

      },
    });
  }

  get isAffiliatedWithAgency() {
    return this.companyInfoForm.get('isAffiliatedWithAgency').value;
  }

  onSubmit() {
    try {
      if (this.companyInfoForm.valid) {
        const formValue: EstateAgentCompanyInformationDto = { ...this.companyInfoForm.value };
        this._estateAgentRegistrationStateService.updateCompanyInformation(formValue);
        this.pageNumberEvent.emit(2);
        this.formSubmittedEvent.emit();
      }
    }
    catch (_err) {
      this._notificationService.showErrorMessage("Error", "Error found on submitting the agent company details.");
    }
  }
}
