import { PropertyMatrixStrictHttpResponseV2 } from 'src/app/services/property-matrixV2/property-matrix-strict-http-response-v-2';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

import { AddendumThreeDto, MultiplePropertyListingDto, PlanningReportDto, PropertyListingDto, RiskscapeDeedsCurrentOwnerInfoDto, RiskscapeExistingLandUseAndFeatureDto, RiskscapePropertyDetailsDto, RiskscapePropertyInfoDto, AddressDto, ContactDetailDto, OrganizationInvoiceDetailsDto, PropertyDetailDto, UploadFileDto, AddressDetailDto, RiskscapePositionLookupDto, DecodedImageResultDtoOperationResult, DecodedImageResultDto, RiskscapeAddressDto, TaskListDto } from '../../../../../../app/services/property-matrixV2/models';
import { FileManagementService, MapsService, PlanningReportService, PropertyListingService, SellMyPropertyService } from '../../../../../../app/services/property-matrixV2/services';
import { ActionOption, ColumnConfig, DataTableComponent } from '../../../../../../app/shared/components/data-table/data-table.component';
import { ProvideLatLonDialogComponent } from '../../../../../shared/components/provide-lat-lon-dialog/provide-lat-lon-dialog.component';
import { UserManagementService } from '../../../../../../app/services/property-matrixV2/services/user-management.service';
import { ListingStateService } from '../../../../../../app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../../../app/shared/services/notification-service/notification.service';
import { MapStateCheckService } from '../../../../../../app/shared/services/map-state-check/map-state-check.service';
import { ReportingService } from '../../../../../../app/shared/services/reporting-service/reporting.service';
import { IndividualInvoiceDetailsVm } from '../../../../../../app/services/property-matrix/models';
import { PropertyMatrixSharedService } from '../../../../../../app/property-matrix-shared.service';
import { UserDto } from '../../../../../../app/services/property-matrixV2/models';
import { channel } from 'node:process';

@Component({
  selector: 'app-confirm-property-seller-details',
  templateUrl: './confirm-property-seller-details.component.html',
  styleUrls: ['./confirm-property-seller-details.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class ConfirmPropertySellerDetailsComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto = {};
  @Input() selectedListingGroup: MultiplePropertyListingDto = {};
  @Input() listingType: string = "";

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  sections = [
    { key: 1, value: '1. Property Boundary', checked: true },
    { key: 2, value: '2. Property Details', checked: true },
    { key: 3, value: '3. Address Details', checked: true },
    { key: 4, value: '4. Location For Pin', checked: true },
    { key: 5, value: '5. Current Owner Information', checked: true },
    { key: 6, value: '6. Confirm to complete', checked: true },
  ];

  sectionsIncorrect = [
    { key: 1, value: '1. Property Boundary', checked: true },
    { key: 2, value: '2. Boundary Information from Seller', checked: true },
    { key: 3, value: '3. Property Details', checked: true },
    { key: 4, value: '4. Property Information From Seller', checked: true },
    { key: 5, value: '5. Re-order Riskscape data', checked: true },
    { key: 6, value: '6. Property Details Corrections', checked: true },
    { key: 7, value: '7. Address Details', checked: true },
    { key: 8, value: '8. Location For Pin', checked: true },
    { key: 9, value: '9. Current Owner Information', checked: true },
    { key: 10, value: '10. Confirm to complete', checked: true },
  ];

  data = [
    { name: "", idNumber: "", maritalStatus: "", ownerType: "", ownerShare: "" },
  ];

  displayedColumns: string[] = ['name', 'idNumber', 'maritalStatus', 'ownerType', 'ownerShare'];

  columns: ColumnConfig[] = [
    { columnDef: 'name', header: 'Name', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'idNumber', header: 'ID Number', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'maritalStatus', header: 'MARITAL STATUS', headerAlign: 'center', cellAlign: 'center', width: 20 },
    { columnDef: 'ownerType', header: 'OWNER TYPE', headerAlign: 'center', cellAlign: 'center', width: 20 },
    { columnDef: 'ownerShare', header: 'OWNER SHARE', headerAlign: 'center', cellAlign: 'center', width: 20 },
  ];

  actionOptions: ActionOption[] = [

  ];

  orderBlackRiskscapeData: String = ''
  propertyDataValid: boolean = false;
  riskscapePropertyInfoData: RiskscapePropertyInfoDto = {};
  riskscapePropertyInfoNewData: RiskscapePropertyInfoDto = {};
  riskscapeExistingLandUseAndFeatureData: RiskscapeExistingLandUseAndFeatureDto = {};
  riskscapeDeedsCurrentOwnerInfoData: RiskscapeDeedsCurrentOwnerInfoDto | undefined = {};
  riskscapeDeedsCurrentOwnerInfoTableData: RiskscapeDeedsCurrentOwnerInfoDto[] = [];
  riskscapePropertyDetails: RiskscapePropertyDetailsDto = {};
  UpdatedPropertyDetail: PropertyDetailDto = {};
  LatLongCoordinates: string = "";
  LatLongCoordinatesNew: string = "";
  file: Blob;
  fileUrl: string = "";
  hideButtons = false;
  localityMapFileId: string = "";
  PropertyBoundaryImageURL: string = "";
  imageId: string = "";
  loading: boolean = false;
  propertyDescriptionAddressCorrect: boolean = false;
  propertyBoundaryCorrect: boolean = false;
  sellerInformationCorrect: boolean = false;
  confidentImportRiskscapeData: boolean = false;
  dataRiskscapeDeedsCurrentOwnerInfo = new MatTableDataSource(this.riskscapeDeedsCurrentOwnerInfoTableData);

  isPropertySelected: boolean = false;
  selectedButton: number = 0;
  confirmPropertyDetail: number = 0;
  selectedUnitNumber: any = "00000";
  addressLatLonData: any = null;
  propertyType: string = "";
  loadingCadaster: boolean = false;
  cadastre: UploadFileDto | null = null;
  cadasterPicture: any = 1;
  cadasterPictureBase64: string = "";
  selectedBoundaryButton: number = 0;
  cityTown: string = "";
  unitNumber: string = "";
  suburbName: string = "";
  propertyKey: string = "";
  shortAddress: string = "";
  erfOrPortionNumber: string = "";
  confirmPropertyBoundary: number = 0;
  attemptCount: number = 0;
  maxAttempts: number = 5;

  selectedPropertyOnMap: any;
  hideLatLonPanel: boolean = false;
  latitude: number | null = null;
  longitude: number | null = null;

  individualInvoiceDetails: IndividualInvoiceDetailsVm = {
    name: '',
    surname: '',
  }

  organizationInvoiceDetails: OrganizationInvoiceDetailsDto = {
    organizationName: '',
    companyRegistrationNumber: '',
    vatNumber: '',
  }

  contactInvoiceDetails: ContactDetailDto = {
    isTheContactDetailsToAppearOnInvoice: undefined,
    name: '',
    surname: '',
    landLine: '',
    mobile: '',
    emailAddress: '',
    confirmEmailAddress: '',
  }

  physicalAddress: AddressDto = {
    unitNumber: '',
    complexName: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    city: '',
    postalCode: '',
    addressFull: '',
    propertyDescription: '',
  };

  propertyDetailForm?: PropertyDetailDto = {
    invoiceIssuedTo: undefined,
    isTheOrganizationRegisteredForVat: null,
    isTheContactDetailsToAppearOnInvoice: null,
    isTheAddressToAppearOnInvoice: null,
    propertyAddress: '',
    propertyBoundaryConcern: '',
    wishToSell: undefined,
    mainPropertyUse: undefined,
    approximateBuildingAge: undefined,
    reasonForNotInterested: undefined,
    hasCopiesOfApprovedBuildingPlans: undefined,
    jointProperties: undefined,
    hasAskingPrice: false,
    allBuildingsDoneWithApprovedBuildingPlans: undefined,
    sellMoreThanOneProperty: false,
    propertyDescription: '',
    confirmCorrectAddressDetails: false,
    mainPropertyUseOther: '',
    approximateBuildingAgeOther: 0,
    hasValueAlteringInfluences: false,
    valueAlteringInfluencesDescription: '',
    tenantedBuildingsAmount: 0,
    hasImprovementPotential: false,
    futurePotential: '',
    latitude: '',
    longitude: '',
    correctAddress: false,
    askingPrice: 0,
    physicalAddress: this.physicalAddress,
    contactInvoiceDetails: this.contactInvoiceDetails,
    organizationInvoiceDetails: this.organizationInvoiceDetails,
    individualInvoiceDetails: this.individualInvoiceDetails,
    riskscapeInfo: {
      neighbourhoodId: '',
      sectionalTitleUnitNumber: '',
      subplaceId: '',
      surveyorGeneralKey: ''
    },
    riskscapeAddressGeocode: {
      data: []
    },
    riskscapeAddress: {
      data: undefined
    },
    riskscapePositionLookup: {
      data: undefined
    },
    riskscapePropertyDetails: {
      data: undefined
    },

  };

  currentUser: UserDto;

  cdr: ChangeDetectorRef;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listingStateService: ListingStateService,
    private planningReportService: PlanningReportService,
    private reportingService: ReportingService,
    private propertyListingService: PropertyListingService,
    private fileManagementService: FileManagementService,
    private userManagementService: UserManagementService,
    private notificationService: NotificationService,
    private sharedService: PropertyMatrixSharedService,
    private sellMyPropertyService: SellMyPropertyService,
    private _mapsService: MapsService,
    private _mapStateCheckService: MapStateCheckService,
    private dialog: MatDialog,
  ) {
    this.file = new Blob();

    this.dataTable = new DataTableComponent(this.cdr);
    this.sort = new MatSort();
    this.paginator = this.dataTable.paginator;
  }

  ngOnInit(): void {
    try {
      if (this.selectedListing) {
        this.setCorrectAddress();
        this.loadRequiredCadastre();
        this.getReportPlanning();
        this.getCurrentOwnerInfo();
      } else {
        this.notificationService.showErrorMessage("Error", "The property listing could not be found.");
        this.goToAdminListings();
      }

    } catch (err) {
      this.notificationService.showErrorMessage("Error", "Error found on loading confirm property and seller.");
    }
    this.getCurrentUser();
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    try {
      section.checked = !section.checked;
      this._mapStateCheckService.updateMapState(true);
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "Error found mapping state check service.");
    }
  }

  saveDetails(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;
        if (this.propertyDataValid == false) {
          if (!this.physicalAddress) {
            this.notificationService.showErrorMessage("Error", "The address details has errors. please make sure all fields have values and try again.");
            return;
          }
          if (!this.propertyDetailForm?.riskscapePropertyDetails?.data) {
            this.notificationService.showErrorMessage("Error", "The map coordinates lat long is not found. please make sure all fields have values and try again.");
            this.loading = false;
            return;
          }

          this.selectedListing.propertyDetail!.addressDetail = {
            "isDeleted": false,
            "id": this.physicalAddress.id,
            "unitNumber": this.physicalAddress.unitNumber,
            "complexName": this.physicalAddress.complexName,
            "streetNumber": this.physicalAddress.streetNumber,
            "streetName": this.physicalAddress.streetName,
            "suburb": this.physicalAddress.suburb,
            "city": this.physicalAddress.city,
            "postalCode": this.physicalAddress.postalCode,
            "propertyDescription": this.physicalAddress.propertyDescription,
            "currentLandUse": this.physicalAddress.currentLandUse,
            "propertyAge": this.physicalAddress.propertyAge,
          };

          this.selectedListing.propertyDetail!.physicalAddress = {
            "id": this.physicalAddress.id ?? undefined,
            "ownershipDetailsId": this.physicalAddress.ownershipDetailsId ?? undefined,
            "unitNumber": this.physicalAddress.unitNumber ?? undefined,
            "complexName": this.physicalAddress.complexName ?? undefined,
            "streetNumber": this.physicalAddress.streetNumber ?? undefined,
            "streetName": this.physicalAddress.streetNumber ?? undefined,
            "suburb": this.physicalAddress.suburb ?? undefined,
            "city": this.physicalAddress.city ?? undefined,
            "postalCode": this.physicalAddress.postalCode ?? undefined,
            "propertyDescription": this.physicalAddress.propertyDescription ?? undefined,
            "currentLandUse": this.physicalAddress.currentLandUse ?? undefined,
            "municipality": this.physicalAddress.municipality ?? undefined,
            "metro": this.physicalAddress.metro ?? undefined,
            "addressFull": this.physicalAddress.addressFull ?? undefined,
            "propertyAge": this.physicalAddress.propertyAge ?? undefined
          };

          if (this.propertyDetailForm.riskscapePropertyDetails.data && this.propertyDetailForm?.riskscapePropertyDetails?.data?.position) {
            const position = this.propertyDetailForm?.riskscapePropertyDetails?.data?.position;
            this.selectedListing.propertyDetail!.latitude = position.lat?.toString() ?? "";
            this.selectedListing.propertyDetail!.longitude = position.lon?.toString() ?? "";
          }
        } else {

          if (!this.riskscapePropertyInfoData) {
            this.notificationService.showErrorMessage("Error", "The address details has errors. please make sure all fields have values and try again.");
            return;
          }
          if (!this.riskscapePropertyInfoNewData) {
            this.notificationService.showErrorMessage("Error", "he Property details has errors. please make sure all fields have values and try again.");
            return;
          }

          this.selectedListing.propertyDetail!.addressDetail = {
            "isDeleted": false,
            "id": this.riskscapePropertyInfoData.id,
            "unitNumber": this.riskscapePropertyInfoData.sectionalTitleUnitNumber,
            "complexName": this.riskscapePropertyInfoData.complexName,
            "streetNumber": this.riskscapePropertyInfoData.streetNumber,
            "streetName": this.riskscapePropertyInfoData.streetName,
            "suburb": this.riskscapePropertyInfoData.suburbName,
            "city": this.riskscapePropertyInfoData.city,
            "postalCode": '',
            "currentLandUse": '',
            "propertyAge": 0,
          };
          this.selectedListing.propertyDetail!.physicalAddress = {
            "id": this.riskscapePropertyInfoNewData.id,
            "complexName": this.riskscapePropertyInfoNewData.complexName,
            "streetNumber": this.riskscapePropertyInfoNewData.streetNumber,
            "streetName": this.riskscapePropertyInfoNewData.streetNumber,
            "suburb": this.riskscapePropertyInfoNewData.suburbName,
            "city": this.riskscapePropertyInfoNewData.city,
            "municipality": this.riskscapePropertyInfoNewData.municipality,
            "ownershipDetailsId": 0,
          };
        }
        if (this.selectedListing.propertyDetail) {
          this.selectedListing.propertyDetail.propertyAddress = this.selectedListing.propertyDetail.propertyAddress;
          this.selectedListing.propertyDetail.propertyDescription = this.selectedListing.propertyDetail.propertyDescription;
          this.selectedListing.propertyDetail.confirmCorrectAddressDetails = this.propertyDescriptionAddressCorrect;
          this.selectedListing.propertyDetail.agreeWithPropertyBoundary = this.propertyBoundaryCorrect;
          this.selectedListing.propertyDetail.correctAddress = this.propertyDescriptionAddressCorrect;
          this.selectedListing.propertyDetail.sellerInformationCorrect = this.sellerInformationCorrect;
          this.selectedListing.propertyDetail.confidentImportRiskscapeData = this.confidentImportRiskscapeData
          this.selectedListing.propertyDetail.orderRiskscapeData = this.orderBlackRiskscapeData == "order" ? true : false;

          if (this.LatLongCoordinatesNew) {
            this.selectedListing.propertyDetail.latitude = this.latitude!.toString();
            this.selectedListing.propertyDetail.longitude = this.longitude!.toString();
          } else if (this.LatLongCoordinates) {
            this.selectedListing.propertyDetail.latitude = this.latitude!.toString();
            this.selectedListing.propertyDetail.longitude = this.longitude!.toString();
          }
          else {
            this.notificationService.showWarningMessage("Warning", "The map coordinates does not have value, make sure it has values to continue.");
            return;
          }
        } else {
          this.notificationService.showErrorMessage("Error", "The selected listing property is empty. ");
          return;
        }

        this.propertyListingService.apiV1PropertyListingEditOrUpdatePropertyDetailPost({
          body: this.selectedListing.propertyDetail, propertyListingId: this.selectedListing.id
        }).subscribe({
          next: (retult: any) => {
            this.loading = false;
            this.loadingCadaster = false;
            if (retult) {
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Property Details.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.loading = false;
            this.loadingCadaster = false;
            this.notificationService.showErrorMessage('Error', 'Error Updating Property Details');
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", 'Error Updating Property Details');
        this.loading = false;
        this.loadingCadaster = false;
        resolve(false);
        reject("Error caught on service call save details.");
      }
    });
  }

  openLocationDialog(): void {
    const dialogRef = this.dialog.open(ProvideLatLonDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.latitude = result.latitude;
        this.longitude = result.longitude;
        this.LatLongCoordinatesNew = `${this.latitude} ${this.longitude}`;

        if (this.propertyDetailForm?.riskscapePropertyDetails?.data?.position) {
          this.propertyDetailForm.riskscapePropertyDetails.data.position.lat = this.latitude;
          this.propertyDetailForm.riskscapePropertyDetails.data.position.lon = this.longitude;
        } else {
          this.notificationService.showWarningMessage("Warning", "Position is not available in propertyDetailForm.");
          console.warn("Position is not available in propertyDetailForm.");
        }
      }
    });
  }

  async saveConfirmDetails(): Promise<void> {
    try {
      this.loading = true;
      let savedData = await this.saveDetails();
      if (savedData == true) {
        this.listingStateService.setSelectedListing(this.selectedListing);
        let changedTaskLists = [] as TaskListDto[];
        this.selectedListing.taskList.forEach(task => {
          if (task.taskNumber.number === 2) {
            if (task.taskStatus.taskStatusNumber != 3) {
              task.user = this.currentUser;
              changedTaskLists.push(task);
            }
          }
          if (task.taskNumber.number === 3) {
            if (task.taskStatus.taskStatusNumber == 1) {
              task.user = this.currentUser;
              changedTaskLists.push(task);
            }
          }
        });

        if (changedTaskLists.length > 0) {
          this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
            if (result == null) {
              this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
            }
          });
        }
        else{
          this.navigateToPlanningReport();
        }

        this.notificationService.showSuccessMessage("Saved", "Saved successfully.");
        this.loading = false;
        this.navigateToPlanningReport();
      } else {
        this.loading = false;
        this.notificationService.showErrorMessage("Error", "Error on saving details")
      }
    }
    catch (ex) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Could not complete the Confirm property and seller details because of errors.');
    }
  }

  async saveData(): Promise<void> {
    try {
      this.loading = true;
      let savedData = await this.saveDetails();
      if (savedData == true) {
        this.listingStateService.setSelectedListing(this.selectedListing);
        this.notificationService.showSuccessMessage("Saved", "Saved successfully.")
      } else {
        this.notificationService.showErrorMessage("Error", "Error on saving details")
      }
      this.loading = false;
    }
    catch (ex) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Could not complete the Confirm property and seller details because of errors.');
    }
  }
  async getCurrentOwnerInfo(): Promise<void> {
    try {
      this.riskscapeDeedsCurrentOwnerInfoTableData = [];

      let result = this.planningReportService.apiV1PlanningReportGetAddendumThreeDetailsGet({ propertyListingId: this.selectedListing.id }).subscribe({
        error(err) {
          this.notificationService.showErrorMessage('Error', 'Error getting the Addendum data');
        },
        next: (reportData: AddendumThreeDto) => {
          this.loading = false;
          if (reportData) {
            this.riskscapeDeedsCurrentOwnerInfoData = reportData?.riskscapeDeedsCurrentOwnerInfo;
            if (this.riskscapeDeedsCurrentOwnerInfoData) {
              this.riskscapeDeedsCurrentOwnerInfoTableData.push(this.riskscapeDeedsCurrentOwnerInfoData);
              this.dataRiskscapeDeedsCurrentOwnerInfo.data = this.riskscapeDeedsCurrentOwnerInfoTableData;
              this.dataRiskscapeDeedsCurrentOwnerInfo.sort = this.sort;
              this.dataRiskscapeDeedsCurrentOwnerInfo.paginator = this.paginator;
            } else {
              this.notificationService.showErrorMessage("Error", "No Addendum data found");
            }
          } else {
            this.notificationService.showErrorMessage("Error", "There are no riskscape current owner information found.");
          }
        },
      });
    } catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", "There are no riskscape current owner information found.");
    }
  }

  getReportPlanning(): void {
    try {
      let result = this.planningReportService.apiV1PlanningReportGetPlanningReportDetailsGet({ propertyListingId: this.selectedListing.id }).subscribe({
        error(err) {
          this.loading = false;
          this.notificationService.showErrorMessage("Error", "Error getting the Addendum data");
        },
        next: (PlanningReport: PlanningReportDto) => {
          this.loading = false;
          if (PlanningReport) {
            this.riskscapePropertyInfoData = PlanningReport?.riskscapePropertyInfo || {};
            this.riskscapePropertyInfoNewData = PlanningReport?.riskscapePropertyInfo || {};
            this.riskscapeExistingLandUseAndFeatureData = PlanningReport?.riskscapeExistingLandUseAndFeature || {};
            this.getPropertyBoundary(this.riskscapePropertyInfoData.surveyorGeneralKey ?? "");
            this.selectedUnitNumber = this.riskscapePropertyInfoData.sectionalTitleUnitNumber;
            this.getPropertyDetailsData(this.riskscapePropertyInfoData.surveyorGeneralKey ?? "", this.riskscapePropertyInfoData.sectionalTitleUnitNumber ?? "");
          }
        }
      });
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "Error getting the Addendum data");
    }
  }

  addNewRow(): void {
    const newRow = {
      name: '',
      idNumber: '',
      maritalStatus: '',
      ownerType: '',
      ownerShare: ''
    };

    this.riskscapeDeedsCurrentOwnerInfoTableData.push(newRow);
  }

  setPropertyBoundaryImageURL(): void {
    this.PropertyBoundaryImageURL = this.selectedListing.image ?? "";
  }
  getPropertyBoundary(propertyKey: string): void {
    try {
      this.PropertyBoundaryImageURL = "";
      this.loading = true;
      let result = this._mapsService.apiV1MapsGetDecodedRiskscapePropertyImageGet({ propertyKey: propertyKey }).subscribe({
        next: (riskscapePropertyImage: any) => {
          this.loading = false;
          if (riskscapePropertyImage) {
            this.imageId = riskscapePropertyImage.imageId ?? "";
            this.PropertyBoundaryImageURL = riskscapePropertyImage.imageUrl ?? "";
            this.hideButtons = true;
          }
        },
        error(err) {
          this.loading = false;
          this.notificationService.showErrorMessage("Error", "Error getting the Property Boundaries");
        },
      });
    } catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", "Error getting the Property Boundaries.");
    }
  }

  getPropertyDetailsData(propertyKey: string, unitNumber: string): void {
    try {
      this.loading = true;
      let result = this._mapsService.apiV1MapsGetPropertyDetailsGet({ propertyKey: propertyKey, unitNumber }).subscribe({
        error(err) {
          this.loading = false;
          this.notificationService.showErrorMessage("Error", "Error getting the Property Details map service data.");
        },
        next: (riskscapePropertyDetails: any) => {
          this.loading = false;
          if (riskscapePropertyDetails) {
            this.riskscapePropertyDetails = riskscapePropertyDetails;
            this.latitude = riskscapePropertyDetails.position.lat;
            this.longitude = riskscapePropertyDetails.position.lon;
            this.LatLongCoordinates = riskscapePropertyDetails.position.lat + " " + riskscapePropertyDetails.position.lon;
          }
        },
      });
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "Error getting the Property Details map service data.");
      this.loading = false;
    }
  }

  async setFile(event: { target: { files: any[]; }; }) {
    try {
      this.localityMapFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.file = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileUrl = event.target.result;
        this.hideButtons = true;
      };

      reader.readAsDataURL(this.file);
    }
    catch (error) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  setCorrectAddress(): void {
    try {
      if (this.selectedListing.propertyDetail) {

        this.propertyDataValid = this.selectedListing.propertyDetail.confirmCorrectAddressDetails ?? false;

        switch (this.selectedListing.propertyDetail.agreeWithPropertyBoundary) {
          case null: {
            this.confirmPropertyBoundary = 2;
            break;
          }
          case true: {
            this.confirmPropertyBoundary = 3;
            break;
          }
          case false: {
            this.confirmPropertyBoundary = 1;
            break;
          }
          default:
            {
              break;
            }
        }

        this.propertyDescriptionAddressCorrect = this.selectedListing.propertyDetail.correctAddress ?? false;
        this.propertyBoundaryCorrect = this.selectedListing.propertyDetail.agreeWithPropertyBoundary ?? false;
        this.sellerInformationCorrect = this.selectedListing.propertyDetail.sellerInformationCorrect ?? false;
        this.confidentImportRiskscapeData = this.selectedListing.propertyDetail.confidentImportRiskscapeData ?? false;
      } else {
        this.notificationService.showWarningMessage("Warning", "The selected listing is null.");
      }
    }
    catch (error) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'Error setting the listing item.');
    }
  }

  replace() {
    try {
      this.fileUrl = "";
      this.PropertyBoundaryImageURL = "";
      this.hideButtons = false;
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "Can not replace image because of an error found.");
    }
  }

  deleteFile(): void {
    try {
      if (this.imageId) {
        this.notificationService.showConfirmMessage('Confirm Deletion', 'Are you sure you want to delete this listing?').then((confirmed) => {
          if (confirmed) {
            this.fileManagementService.apiV1FileManagementDeleteFileFileIdDelete({ fileId: this.imageId }).subscribe({
              next: () => {
                this.notificationService.showSuccessMessage('Success', 'Listing deleted successfully.');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              },
              error: (_error: any) => {
                this.notificationService.showErrorMessage('Error', 'Could not delete listing.');
              }
            });
          }
        });
      } else {
        this.notificationService.showErrorMessage("Error", "The file Id not found");
      }
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "Error found when trying to delete image.");
    }
  }

  handleSelectedPropertyChange(propertyIdentification: any): void {
    try {
      if (propertyIdentification !== null) {

        this.selectedPropertyOnMap = propertyIdentification;
        this.isPropertySelected = true;
        this.confirmPropertyDetail = 1;
      } else {
        this.isPropertySelected = false;
        this.confirmPropertyDetail = 1;
        this.notificationService.showWarningMessage(
          'Warning',
          'Address not found or Riskscape is unavailable. Please re-enter the address or try again later.');
      }
    } catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", 'Address not found or Riskscape is unavailable. Please re-enter the address or try again later.');
    }
  }

  doRiskscapeData(): void {
    try {
      if (this.attemptCount >= this.maxAttempts) {
        this.notificationService.showErrorMessage(
          'Limit Reached',
          'You have reached the maximum number of attempts allowed.'
        );
        return;
      }
      if ((this.selectedPropertyOnMap !== null) && (this.isPropertySelected == true)) {

        this.attemptCount = this.attemptCount + 1;
        this.isPropertySelected = true;
        this.confirmPropertyDetail = this.selectedButton;
        this.getAddressFromLatLon(this.selectedPropertyOnMap.position.lat, this.selectedPropertyOnMap.position.lon);
        this.selectedUnitNumber = this.selectedPropertyOnMap.unit_number;
        this.riskscapePropertyInfoNewData.sectionalTitleUnitNumber = this.selectedPropertyOnMap.unit_number;
      } else {
        this.isPropertySelected = false;
        this.confirmPropertyDetail = 1;
        this.notificationService.showWarningMessage(
          'Warning',
          'Address not found or Riskscape is unavailable. Please re-enter the address or try again later.');
      }
    } catch (err) {
      this.notificationService.showErrorMessage("Error", 'Address not found or Riskscape is unavailable. Please re-enter the address or try again later.');
    }
  }

  selectButton(buttonNumber: number) {
    this.selectedButton = buttonNumber;
    this.confirmPropertyDetail = buttonNumber;
  }

  selectBoundaryButton(buttonNumber: number) {
    this.selectedBoundaryButton = buttonNumber;
    this.confirmPropertyBoundary = buttonNumber;
  }

  handleScriptLoadStatus(statusMessage: string): void {
    if (statusMessage) {
      this.notificationService.showWarningMessage(
        'Oops! Something went wrong.',
        statusMessage
      );
    }
  }

  getAddressFromLatLon(lat: string, lon: string) {
    try {
      this.loading = true;
      this._mapsService.apiV1MapsGetPropertyDetailsFromLatLonGet({
        lat: lat,
        lon: lon
      }).subscribe({
        next: (result: RiskscapePositionLookupDto) => {
          this.loading = false;
          if (result) {
            this.propertyDetailForm!.riskscapeAddressGeocode!.data = null;
            this.propertyDetailForm!.riskscapePositionLookup = result;
            this.addressLatLonData = this.propertyDetailForm?.riskscapePositionLookup;
            this.propertyType = this.addressLatLonData.type;
            if (this.addressLatLonData.attributes.property_key) {
              this.getAddressDetails(this.addressLatLonData.attributes.address_id);
              this.getAddressImage(this.addressLatLonData.attributes.property_key);
              this.getPropertyDetails(this.addressLatLonData.attributes.property_key, this.selectedUnitNumber);
              this.propertyDetailForm!.riskscapeInfo!.surveyorGeneralKey = this.addressLatLonData.attributes.property_key;
              this.propertyDetailForm!.riskscapeInfo!.sectionalTitleUnitNumber = this.selectedUnitNumber;
              this.riskscapePropertyInfoNewData.surveyorGeneralKey = this.addressLatLonData.attributes.property_key;
            } else {
              this.notificationService.showErrorMessage("Error", "The riskscape position lookup property key for does not have value: " + this.addressLatLonData.attributes.property_key);
            }
          } else {
            this.notificationService.showErrorMessage("Error", "Error getting the Riskscape Position Lookup data.")
          }
        },
        error: (_error: any) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not find address.');
        }
      });
    } catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", 'Could not find address.');
    }
  }

  getAddressDetails(addressId: string) {
    try {
      this.loading = true;
      this._mapsService.apiV1MapsGetAddressDetailsGet$Response({
        addressId: addressId
      }).subscribe({
        next: (result: any) => {
          this.loading = false;
          if (result && result.body) {
            const riskscapeAddressDto: RiskscapeAddressDto = result.body;
            this.propertyDetailForm!.riskscapeAddress = riskscapeAddressDto;
            if (this.propertyDetailForm!.riskscapePropertyDetails?.data?.position) {
              const position = this.propertyDetailForm?.riskscapeAddress?.data?.position;
              this.propertyDetailForm!.latitude = `${position?.lat?.toString()} ?? null`;
              this.propertyDetailForm!.longitude = `${position?.lat?.toString()} ?? null`;
            } else {
              this.notificationService.showErrorMessage("Error", "Could not get map coordinates from Riskscape.");
            }

            this.propertyDetailForm!.physicalAddress = this.physicalAddress = {
              unitNumber: this.selectedUnitNumber,
              complexName: this.propertyDetailForm?.riskscapeAddress?.data?.complex ?? '',
              streetNumber: this.propertyDetailForm?.riskscapeAddress?.data?.street_number ?? '0',
              streetName: this.propertyDetailForm?.riskscapeAddress?.data?.street ?? '0',
              suburb: this.propertyDetailForm?.riskscapeAddress?.data?.subplace ?? '',
              city: this.propertyDetailForm?.riskscapeAddress?.data?.city_town ?? '',
              postalCode: this.propertyDetailForm?.riskscapeAddress?.data?.postal_code ?? '0',
              addressFull: this.propertyDetailForm?.riskscapeAddress?.data?.full_address ?? '',
              currentLandUse: '',
              metro: this.propertyDetailForm?.riskscapeAddress?.data?.district,
              municipality: this.propertyDetailForm?.riskscapeAddress?.data?.municipality,
              ownershipDetailsId: 0,
              propertyAge: 0,
              propertyDescription: '',
              id: addressId,
            }
            this.unitNumber = this.physicalAddress.unitNumber == undefined ? 'N/A' : this.physicalAddress.unitNumber;
            this.erfOrPortionNumber = result.data.erfNumber == undefined ? 'N/A' : result.data.erfNumber;
            this.shortAddress = this.physicalAddress.streetNumber + ' ' + this.physicalAddress.streetName;
            this.suburbName = this.physicalAddress.suburb == undefined ? 'N/A' : this.physicalAddress.suburb;
            this.cityTown = this.physicalAddress.city == undefined ? 'N/A' : this.physicalAddress.city;

            this.riskscapePropertyInfoNewData.city = this.physicalAddress.city;
            this.riskscapePropertyInfoNewData.complexName = this.physicalAddress.complexName;
            this.riskscapePropertyInfoNewData.portionNumber = this.erfOrPortionNumber;
            this.riskscapePropertyInfoNewData.province = this.propertyDetailForm?.riskscapeAddress?.data?.province;
            this.riskscapePropertyInfoNewData.streetName = this.physicalAddress.streetName;
            this.riskscapePropertyInfoNewData.streetNumber = this.physicalAddress.streetNumber;
            this.riskscapePropertyInfoNewData.subPlaceName = this.physicalAddress.suburb;
            this.riskscapePropertyInfoNewData.suburbName = this.propertyDetailForm?.riskscapeAddress?.data?.subplace;
          } else {
            this.notificationService.showErrorMessage('Error', 'Could not load address details.');
          }

          this.loading = false;
          this.loadingCadaster = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load address details.');
          this.loading = false;
        }
      });
    } catch (err) {
      this.loading = false;
      this.notificationService.showErrorMessage("Error", 'Could not load address details.');
    }
  }

  async getAddressImage(propertyKey: string) {
    try {
      this.loading = true;
      this.loadingCadaster = true;
      let result = await this.sharedService.getAddressImage(propertyKey);
      if (result?.ok && result.body) {
        this.loading = false;
        this.loadingCadaster = false;
        const resultJson = JSON.parse(result.body);
        this.cadasterPictureBase64 = resultJson.encoded;
        const base64Response = await fetch(`data: image / jpeg; base64, ${this.cadasterPictureBase64} `);
        const blob = await base64Response.blob();
        let objectURL = URL.createObjectURL(blob);
        this.cadasterPicture = await this.sharedService.getImageFromDataUri(objectURL);
        this.cadastre!.file = blob;
      } else {
        this.notificationService.showErrorMessage('Error', 'Could not find address image.');
      }
      this.loading = false;
      this.loadingCadaster = false;
    } catch (err) {
      this.notificationService.showErrorMessage("Error", String(err));
      this.loading = false;
    }
  }

  async getPropertyDetails(propertyKey: string, unitNumber: string) {
    try {
      this.loading = true;
      await this._mapsService.apiV1MapsGetPropertyDetailsGet$Response({
        propertyKey: propertyKey,
        unitNumber: unitNumber
      }).subscribe({
        next: (result: any) => {
          this.loading = false;
          if (result && result.body) {
            this.propertyDetailForm!.riskscapePropertyDetails!.data = result.body;
            if (this.propertyDetailForm?.riskscapePropertyDetails?.data) {
              this.riskscapePropertyInfoNewData.deedsErfExtent = this.propertyDetailForm?.riskscapePropertyDetails?.data.extent ?? 0;
              this.riskscapePropertyInfoNewData.erfNumber = this.propertyDetailForm?.riskscapePropertyDetails?.data.erf_number ?? "";
              this.riskscapePropertyInfoNewData.fullAddress = this.propertyDetailForm?.riskscapePropertyDetails?.data.full_address ?? "";
              this.riskscapePropertyInfoNewData.id = this.propertyDetailForm?.riskscapePropertyDetails?.data.address_id ?? "";
              this.riskscapePropertyInfoNewData.municipality = this.propertyDetailForm?.riskscapePropertyDetails?.data.municipality ?? "";
              this.riskscapePropertyInfoNewData.suburbName = this.propertyDetailForm?.riskscapePropertyDetails?.data.subplace ?? "";
              this.riskscapePropertyInfoNewData.propertyType = this.propertyDetailForm?.riskscapePropertyDetails?.data.property_type ?? "";
              if (this.propertyDetailForm?.riskscapePropertyDetails?.data?.position) {
                const position = this.propertyDetailForm.riskscapePropertyDetails.data.position;
                this.propertyDetailForm.latitude = position.lat?.toString() ?? null;
                this.propertyDetailForm.longitude = position.lon?.toString() ?? null;
                this.latitude = position.lat ?? 0;
                this.longitude = position.lon ?? 0;
                this.LatLongCoordinatesNew = `${position.lat?.toString() ?? ""} ${position.lon?.toString() ?? ""} `;
              } else {
                this.notificationService.showErrorMessage("Error", "Could not get map coordinates from Riskscape.");
              }
            }
          } else {
            this.notificationService.showErrorMessage("Error", "Could not get map coordinates from Riskscape.");
          }
          this.loading = false;
        },
        error: (_error: any) => {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load property details.');
          this.loading = false;
        }
      });
    } catch (err) {
      this.notificationService.showErrorMessage("Error", 'Could not load property details.');
      this.loading = false;
    }
  }

  loadRequiredCadastre() {
    try {
      this.loading = true;
      this.sellMyPropertyService.apiV1SellMyPropertyLoadRequiredCadastreGet().subscribe({
        next: (res: UploadFileDto) => {
          this.cadastre = res;
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load required cadastre.');
        }
      });
    } catch (err) {
      this.notificationService.showErrorMessage("Error", 'Could not load required cadastre.');
      this.loading = false;
    }
  }

  orderRiskscapeData(): void {
    try {
      this.orderBlackRiskscapeData = "order";
      this.doRiskscapeData();
    } catch (err) {
      this.notificationService.showErrorMessage("Error", 'Error found on ordering riskscape data.');
      this.loading = false;
    }
  }

  blackRiskscapeData(): void {
    this.orderBlackRiskscapeData = "black";
  }

  goToAdminListings() {
    this.router.navigate(['admin/listings']);
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }

  navigateToPlanningReport(): void {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }
}
