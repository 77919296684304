import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { MultiplePropertyListingDto, PropertyListingDto, TaskListDto } from '../../../../../..//app/services/property-matrixV2/models';
import { ListingStateService } from '../../../../../..//app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../../..//app/shared/services/notification-service/notification.service';
import { UserManagementService, LookupService } from '../../../../../..//app/services/property-matrixV2/services';
import { PropertyListingService } from '../../../../../../app/services/property-matrixV2/services';
import { UserDto } from '../../../../../..//app/services/property-matrixV2/models';

@Component({
  selector: 'app-allocate-planners',
  templateUrl: './allocate-planners.component.html',
  styleUrls: ['./allocate-planners.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class AllocatePlannersComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;

  sections = [
    { key: 1, value: 'Allocation', checked: true },
    { key: 2, value: 'Network Agent', checked: true },
    { key: 3, value: 'Planning Report', checked: true },
  ];
  reportType = [
    { key: 1, type: 'Level One Report', checked: false },
    { key: 2, type: 'Level Two Report', checked: false },
    { key: 3, type: 'Manual Report', checked: false },
  ];

  listingType: string;
  seniorPlanners: UserDto[] = [];
  juniorPlanners: UserDto[] = [];
  selectedSeniorPlanner: string | null | undefined = null;
  selectedJuniorPlanner: string | null | undefined = null;
  currentUser: UserDto | undefined;
  networkAgentType: number = 0;
  networkAgentTypeStatement: string = "";
  selectedReportType: string | null | undefined;

  constructor(
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private lookupService: LookupService,
    private listingStateService: ListingStateService,
    private propertyListingService: PropertyListingService,
    private router: Router,
    private notificationService: NotificationService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.getCurrentUser();
    this.getSeniorPlanners();
    this.getJuniorPlanners();

    this.prePopulateData();
  }

  getSeniorPlanners(): void {
    this.userManagementService.apiV1UserManagementGetSeniorPlannerGet().subscribe(result => {
      if (result != null) {
        this.seniorPlanners = result;
        this.initializePlannerSelections();
      } else {
        this.notificationService.showWarningMessage('No Senior Planners', 'No senior planners found.');
      }
    });
  }

  getJuniorPlanners(): void {
    this.userManagementService.apiV1UserManagementGetJuniorPlannerGet().subscribe(result => {
      if (result != null) {
        this.juniorPlanners = result;
        this.initializePlannerSelections();
      } else {
        this.notificationService.showWarningMessage('No Junior Planners', 'No junior planners found.');
      }
    });
  }

  initializePlannerSelections(): void {
    if (!this.selectedListing || !this.selectedListing.taskList || !this.seniorPlanners.length || !this.juniorPlanners.length) {
      return;
    }

    const juniorPlannerTask = this.selectedListing.taskList.find(task => task.taskNumber?.number === 4);
    const seniorPlannerTask = this.selectedListing.taskList.find(task => task.taskNumber?.number === 5);

    if (juniorPlannerTask && juniorPlannerTask.user) {
      this.selectedJuniorPlanner = juniorPlannerTask.user.id;
    }

    if (seniorPlannerTask && seniorPlannerTask.user) {
      this.selectedSeniorPlanner = seniorPlannerTask.user.id;
    }
  }

  onSeniorPlannerChange(event: any): void {
    this.selectedSeniorPlanner = event.value;
  }

  onJuniorPlannerChange(event: any): void {
    this.selectedJuniorPlanner = event.value;
  }

  onReportTypeChange(event: any): void {
    this.selectedReportType = event.value;
  }

  savePlanners(): void {
    let changedTaskLists = [] as TaskListDto[];
    if (this.selectedJuniorPlanner == null || this.selectedSeniorPlanner == null) {
      this.notificationService.showWarningMessage('No Planner Selected', 'Please select both a junior and senior planner.');
      return;
    }

    if (this.networkAgentType == 1) {
      this.networkAgentTypeStatement = "The property is listed directly bt the Agent";
    } else if (this.networkAgentType == 2) {
      this.networkAgentTypeStatement = "This property was referred to the Agent by PM";
    } else {
      this.notificationService.showWarningMessage('No network agent Selected', 'Please select network agent to continue.');
      return;
    }

    const selectedJuniorPlannerUser = this.juniorPlanners.find(planner => planner.id === this.selectedJuniorPlanner);
    const selectedSeniorPlannerUser = this.seniorPlanners.find(planner => planner.id === this.selectedSeniorPlanner);

    if (!selectedJuniorPlannerUser || !selectedSeniorPlannerUser) {
      this.notificationService.showErrorMessage('Error', 'Selected planners not found in the list.');
      return;
    }

    this.selectedListing.juniorPlanner = this.juniorPlanners.find(planner => planner.id === this.selectedJuniorPlanner);;
    this.selectedListing.seniorPlanner = this.seniorPlanners.find(planner => planner.id === this.selectedSeniorPlanner);
    this.selectedListing.networkAgentStatement = this.networkAgentTypeStatement;
    this.selectedListing.isPropertyListedDirectlyByAgent = this.networkAgentType == 1 ? true : false;
    this.selectedListing.allocatePlannerReportSelection = this.selectedReportType;

    this.propertyListingService.apiV1PropertyListingAddOrUpdatePlannersToPropertyListingPost({ body: this.selectedListing }).subscribe(result => {
      this.SavePlannerToService(result);
      if (result == true) {
        this.selectedListing.taskList?.forEach(task => {
          if (task.taskNumber?.number === 1) {
            if (task.taskStatus?.taskStatusNumber != 3) {
              task.user = this.currentUser;
              changedTaskLists.push(task);
            }
          }
          if (task.taskNumber?.number === 2) {
            if (task.taskStatus?.taskStatusNumber == 1) {
              task.user = this.currentUser;
              changedTaskLists.push(task);
            }
          }
          if (task.taskNumber?.number === 4) {
            if (task.taskStatus?.taskStatusNumber == 1) {
              task.user = selectedJuniorPlannerUser;
              task.userStatusText = this.networkAgentType == 1 ? "Direct Agent Listing" : "Referal from PM";
              changedTaskLists.push(task);
            }
          }
          if (task.taskNumber?.number === 5) {
            if (task.taskStatus?.taskStatusNumber == 1) {
              task.user = selectedSeniorPlannerUser;
              task.userStatusText = this.networkAgentType == 1 ? "Direct Agent Listing" : "Referal from PM";
              changedTaskLists.push(task);
            }
          }
        });

        if (changedTaskLists.length > 0) {
          this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
            if (result != null) {
              this.notificationService.showSuccessMessage('Planners Updated', 'Planners have been updated successfully.');
              this.navigateToPlanningReport();
            } else {
              this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
            }
          });
        }
        else {
          this.navigateToPlanningReport();
        }
      }
    });
  }

  private SavePlannerToService(successful: boolean): void {
    if (successful == true) {
      this.notificationService.showSuccessMessage("Success", "Successfully saved planners to listing.");
      this.listingStateService.setSelectedListing(this.selectedListing);
    }
    else {
      this.notificationService.showErrorMessage("Error", "Error saving planners to listing.");
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      } else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }

  navigateToPlanningReport() {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }

  prePopulateData(): void {
    if (this.selectedListing) {
      if (this.selectedListing.juniorPlanner != null) {
        this.selectedJuniorPlanner = this.selectedListing.juniorPlanner.id;
      }
      if (this.selectedListing.seniorPlanner != null) {
        this.selectedSeniorPlanner = this.selectedListing.seniorPlanner.id;
      }
      if (this.selectedListing.allocatePlannerReportSelection) {
        this.selectedReportType = this.selectedListing.allocatePlannerReportSelection;
      }
      if (this.selectedListing.networkAgentStatement) {
        this.networkAgentTypeStatement = this.selectedListing.networkAgentStatement;

        if (this.selectedListing.isPropertyListedDirectlyByAgent) {
          if (this.selectedListing.isPropertyListedDirectlyByAgent == true) {
            this.networkAgentType = 1
          } else {
            this.networkAgentType = 2;
          }
        }
      }
    }
  }
}
