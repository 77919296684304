import { PotentialConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';

@Component({
  selector: 'app-potential-land-parcel',
  templateUrl: './potential-land-parcel.component.html',
  styleUrls: ['./potential-land-parcel.component.scss']
})
export class PotentialLandParcelComponent implements OnInit, OnChanges {

  @Input() scope: PotentialConstructionScopeDto;
  @Input() index: number;
  @Input() amountOfLandParcels: number;
  @Input() substituteUsableArea: number;
  @Input() disableComponent: boolean = false;

  @Output() scopeChange = new EventEmitter<PotentialConstructionScopeDto>();

  squareMetersConversion: number = 10000;
  previousMetricUnit: string | null = null;
  potentialUsableAreaInHectares: number | null = null;
  potentialUsableAreaInSquareMeters: number | null = null;

  loadedScope: PotentialConstructionScopeDto;

  metricUnits: KeyValuePair[] = [
    { key: 'm²', value: 'Square Meters (m²)' },
    { key: 'ha', value: 'Hectares (ha)' },
  ];

  constructor() { }

  ngOnInit(): void {
    this.loadedScope = { ...this.scope };
    this.initializeMetricUnit();
  }

  ngOnChanges(): void {
    this.calculateValues();
  }

  initializeMetricUnit(): void {
    if (this.scope.metricUnit) {
      this.previousMetricUnit = this.scope.metricUnit;
    } else {
      this.scope.metricUnit = 'ha';
      this.previousMetricUnit = 'ha';
    }
    this.calculatePotentialUsableArea();
  }

  onMetricUnitChange(newUnit: string): void {
    this.scope.propertySize = this.convertArea(this.scope.propertySize, this.previousMetricUnit, newUnit);
    this.scope.unusableArea = this.convertArea(this.scope.unusableArea, this.previousMetricUnit, newUnit);
    this.previousMetricUnit = newUnit;
    this.scope.metricUnit = newUnit;
  }

  calculatePotentialUsableArea(): void {
    if (this.amountOfLandParcels === 1 && this.substituteUsableArea) {
      this.scope.potentialUsableArea = this.substituteUsableArea;
    } else {
      this.scope.potentialUsableArea = this.scope.propertySize - this.scope.unusableArea;
    }

    if (this.scope.potentialUsableArea < 0) {
      this.scope.potentialUsableArea = 0;
    }

    this.potentialUsableAreaInHectares = this.convertArea(this.scope.potentialUsableArea, this.scope.metricUnit, 'ha');
    this.potentialUsableAreaInSquareMeters = this.convertArea(this.scope.potentialUsableArea, this.scope.metricUnit, 'm²');

    if (this.scope.metricUnit === 'ha') {
      this.scope.potentialUsableArea = this.roundToFixed(this.potentialUsableAreaInHectares, 2);
    } else if (this.scope.metricUnit === 'm²') {
      this.scope.potentialUsableArea = this.roundToFixed(this.potentialUsableAreaInSquareMeters, 2);
    } else {
      this.scope.potentialUsableArea = this.roundToFixed(this.scope.potentialUsableArea, 2);
    }
  }

  calculateValues(): void {
    if (this.scope) {
      let usableArea: number = null;

      if (this.amountOfLandParcels <= 1 && this.substituteUsableArea) {
        usableArea = this.substituteUsableArea;
      } else if (this.amountOfLandParcels > 1 && this.potentialUsableAreaInHectares) {
        usableArea = this.potentialUsableAreaInHectares;
      } else {
        return;
      }

      this.scope.potentialMaximumOpenSpaceRequired = Math.max(0, Math.floor(usableArea * (this.scope.potentialOpenSpaceRequired / 100) * 10000) / 10000);
      this.scope.potentialMaximumUnitsOnUsableSpace = Math.max(0, Math.floor(usableArea * this.scope.potentialDensity));
      this.scope.potentialMaximumSquareMetersBasedOnFloorAreaRatio = Math.max(0, Math.floor(usableArea * this.scope.potentialFloorAreaRatio * this.squareMetersConversion));
      //this.scope.potentialMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, Math.floor(usableArea * this.scope.potentialHeight * (this.scope.potentialCoverage / 100) * this.squareMetersConversion));
      this.scope.potentialMaximumSquareMetersBasedOnCoverageAndHeight = Math.max(0, Math.floor((usableArea * (this.scope.potentialCoverage / 100)) * this.scope.potentialHeight * this.squareMetersConversion));
      this.scope.potentialMaximumUnitsAverageSize = Math.max(0, Math.floor(this.scope.potentialMaximumSquareMetersBasedOnCoverageAndHeight / this.scope.potentialMaximumUnitsOnUsableSpace));

      this.scope.potentialParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.scope.potentialMaximumUnitsOnUsableSpace * this.scope.potentialParkingSpaces));
      this.scope.potentialVisitorsParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.scope.potentialVisitorsParkingSpaces * this.scope.potentialMaximumUnitsOnUsableSpace));
      this.scope.potentialParkingSpacesPerHundredSquareMetersWithMaxRights = Math.max(0, Math.floor(this.scope.potentialMaximumSquareMetersBasedOnFloorAreaRatio / 100 * this.scope.potentialParkingSpacesPerHundredSquareMeters));
      this.scope.potentialTotalParkingSpacesWithMaxRights = Math.max(0, Math.floor(this.scope.potentialParkingSpacesWithMaxRights + this.scope.potentialParkingSpacesPerHundredSquareMetersWithMaxRights));
      this.scope.potentialParkingSpacesAvailableOnVacantLandWithMaxRights = Math.max(0, Math.floor((1 - (this.scope.potentialCoverage / 100)) * ((usableArea - this.scope.potentialMaximumOpenSpaceRequired) * this.squareMetersConversion) / 23));

      this.scopeChange.emit(this.scope);
    }
  }

  private convertArea(area: number, fromUnit: string, toUnit: string): number {
    if (fromUnit === 'ha' && toUnit === 'm²') {
      return area * 10000;
    } else if (fromUnit === 'm²' && toUnit === 'ha') {
      return area / 10000;
    } else {
      return area;
    }
  }

  private roundToFixed(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }
}
