import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListingService } from 'src/app/services/property-matrixV2/services';
import { PropertyListingDto, MultiplePropertyListingDto, UserDto, TaskListDto } from 'src/app/services/property-matrixV2/models';
import { UserManagementService } from 'src/app/services/property-matrixV2/services';
import { ListingStateService } from 'src/app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';

@Component({
  selector: 'app-advert-send-link',
  templateUrl: './advert-send-link.component.html',
  styleUrls: ['./advert-send-link.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class AdvertSendLinkComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto | undefined;
  @Input() selectedListingGroup: MultiplePropertyListingDto | undefined;
  @Input() isMultiple: boolean | undefined;
  @Input() sectorType: string | undefined;
  @Output() booleanEvent = new EventEmitter<boolean>();

  sections = [
    { key: 1, value: '', checked: true },
  ];

  currentUser: UserDto;
  listingType: string;

  constructor(
    private userManagementService: UserManagementService,
    private propertyListingService: PropertyListingService,
    private listingStateService: ListingStateService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.getCurrentUser();
  }

  sendLink(): void {
    this.setPropertyListingTask();
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    this.selectedListing?.taskList?.forEach(task => {
      if (task.taskNumber?.number === 12) {
        if (task.taskStatus?.taskStatusNumber != 3) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result != null) {
          this.navigateToPlanningReport();
        }
        else {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
    else{
      this.navigateToPlanningReport();
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }

  navigateToPlanningReport() {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing?.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }
}