import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

import { ActionOption, ColumnConfig, DataTableComponent } from '../../../../../app/shared/components/data-table/data-table.component';
import { ConfigUserPermissionService } from '../../../../../app/services/property-matrixV2/custom-services/userPermission.service';
import { InviteEstateAgentDialogComponent } from './invite-estate-agent-dialog/invite-estate-agent-dialog.component';
import { RegistrationService, UserManagementService } from '../../../../../app/services/property-matrixV2/services';
import { NotificationService } from '../../../../../app/shared/services/notification-service/notification.service';
import { UserActionDialogComponent } from '../../admin-users/user-action-dialog/user-action-dialog.component';
import { PropertyMatrixSharedService } from '../../../../../app/property-matrix-shared.service';
import { UserDto } from '../../../../../app/services/property-matrixV2/models';
import { User } from '../../../../../app/models/User';

@Component({
  selector: 'app-admin-profile-company',
  templateUrl: './admin-profile-company.component.html',
  styleUrls: ['./admin-profile-company.component.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class AdminProfileCompanyComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent;

  data = [
    { fullName: '', specialize: '', registerDate: '', actions: '' },
  ];

  columns: ColumnConfig[] = [
    { columnDef: 'fullName', header: 'Name And Surname', headerAlign: 'left', cellAlign: 'left', width: 30 },
    { columnDef: 'specialize', header: 'Specialize', headerAlign: 'left', cellAlign: 'left', width: 30 },
    { columnDef: 'registerDate', header: 'Register Date', headerAlign: 'center', cellAlign: 'center', width: 25 },
    { columnDef: 'actions', header: 'Actions', isAction: true, headerAlign: 'center', cellAlign: 'center', width: 15 }
  ];

  actionOptions: ActionOption[] = [
    { label: 'Archive User', action: (user: UserDto) => this.openActionDialog('archive', user) },
    { label: 'Remove', action: (user: UserDto) => this.openActionDialog('remove', user) },
    { label: 'Delink Principle', action: (user: UserDto) => this.openActionDialog('delink', user) },
  ];

  requiredRolesFull: string[] = [];
  requiredRolesView: string[] = [];
  currentUserRoles: string[];
  networkAgents: UserDto[] = [];
  loggedInUser: User | null = null;
  networkAgentPrinciple: UserDto[] = [];
  loading: boolean = false;
  constructor(
    public _dialog: MatDialog,
    private _datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private _keycloakService: KeycloakService,
    private _userManagement: UserManagementService,
    private _notificationService: NotificationService,
    private _registrationService: RegistrationService,
    private sharedService: PropertyMatrixSharedService,
    private _userPermissionService: ConfigUserPermissionService,
  ) { }

  ngOnInit(): void {
    this.getUserDetail();
    this.getUserPermissionList();
    this.getNetworkAgentWithPrincipleId();
  }

  openInviteEstateAgentDialog(): void {
    if (this.currentUserRoles.includes('Principle Agent')) {
      const InviteEstateAgentDialog = this._dialog.open(InviteEstateAgentDialogComponent);
      InviteEstateAgentDialog.afterClosed().subscribe(() => {
        this.getNetworkAgentWithPrincipleId();
      });
    } else if (this.getActionsForUser().includes('edit')) {
      const InviteEstateAgentDialog = this._dialog.open(InviteEstateAgentDialogComponent);
      InviteEstateAgentDialog.afterClosed().subscribe(() => {
        this.getNetworkAgentWithPrincipleId();
      });
    } else {
      this._notificationService.showWarningMessage("Insufficient role", "Current logged in user  " + this.loggedInUser?.userName + " does not have edit permissions or role linked.");
    }
  }

  private openActionDialog(actionType: string, user: UserDto): void {
    if (this.currentUserRoles.includes('Principle Agent')) {
      const userDto = this.GetCurrentUserDTOForID(user.id.toString());

      if (userDto != null) {
        const dialogRef = this._dialog.open(UserActionDialogComponent, {
          width: '30vw',
          height: '30vh',
          data: { actionType, userDto }
        });

        dialogRef.afterClosed().subscribe(() => {
          this._snackBar.open('This action is currently unavailable.', 'Close', {
            duration: 3000
          });
          this.getNetworkAgentWithPrincipleId();
        });
      } else {
        this._notificationService.showErrorMessage("Error", "The current agent can not be null.");
      }
    } else if (this.getActionsForUser().includes('edit')) {
      const userDto = this.GetCurrentUserDTOForID(user.id);

      if (userDto != null) {

        const dialogRef = this._dialog.open(UserActionDialogComponent, {
          width: '30vw',
          height: '30vh',
          data: { actionType, userDto }
        });

        dialogRef.afterClosed().subscribe(() => {
          this._snackBar.open('This action is currently unavailable.', 'Close', {
            duration: 3000
          });
          this.getNetworkAgentWithPrincipleId();
        });
      } else {
        this._notificationService.showErrorMessage("Error", "The current agent can not be null.");
      }
    } else {
      this._notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  private GetCurrentUserDTOForID(id: string): UserDto | null {
    let user: UserDto | null = null;
    this.networkAgents.forEach(element => {
      if (element.id == id) {
        user = element;
      }
    });
    return user;
  }

  private getNetworkAgents(): void {
    this.loading = true;
    this._registrationService.apiV1RegistrationGetNetworkAgentsGet({
      userId: this.getUserId()
    }).subscribe({
      next: (networkAgents: UserDto[]) => {
        if (networkAgents) {
          this.networkAgents = networkAgents;
          this.populateTableData(networkAgents);
          this.dataTable.dataSource = new MatTableDataSource(this.data);
          this.dataTable.dataSource.sort = this.dataTable.sort;
          this.dataTable.dataSource.paginator = this.dataTable.paginator;
          this.dataTable.dataSource.filterPredicate = (data: any, filter: string) => {
            const searchStr = filter.toLowerCase();
            return data.fullName.toLowerCase().includes(searchStr) ||
              data.email.toLowerCase().includes(searchStr) ||
              data.tags.toLowerCase().includes(searchStr);
          };
        } else {
          this._notificationService.showWarningMessage("", "There were no network agents found for the logged in user " + this.loggedInUser?.userName);
        }
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not fetch network agents.');
      }
    });
    this.loading = false;
  }

  private populateTableData(users: UserDto[]): void {
    if (users != null) {
      this.data = users.map(user => {
        const { name, surname, estateAgent, createdDate, id } = user;
        const fullName = `${name ?? ''} ${surname ?? ''}`;
        const sectors = estateAgent?.personalInformation?.sectors ?? [];
        const specialize = sectors.map(sector => sector.value).filter(Boolean).join(', ') || 'None';
        const registerDate = this._datePipe.transform(createdDate, 'dd/MM/yy');

        return {
          id: user.id,
          fullName,
          specialize,
          registerDate,
          actions: '',
        };
      });
    } else {
      this._notificationService.showInfoMessage("No records", "No network agents found.");
    }
  }

  private getUserId(): string | undefined {
    return this._keycloakService.getKeycloakInstance().tokenParsed?.sub;
  }

  private getUserDetail() {
    let currentUser = this.sharedService.getUserModel();
    if (currentUser != null) {
      this.loggedInUser = currentUser;
      for (let index = 0; index < currentUser.roles.length; index++) {
        var tokenRoles = currentUser.roles[index];
        this.currentUserRoles.push(tokenRoles);
      }
    } else {
      this.loadCurrentUserRoles();
    }
  }

  private loadCurrentUserRoles(): void {
    this._userManagement.apiV1UserManagementGetUserTokenGet().subscribe(roleBasedAccessDto => {
      this.currentUserRoles = roleBasedAccessDto.roles ?? [];
    });
  }

  private getUserPermissionList(): void {
    this._userPermissionService.getRequiredRoles().subscribe(data => {
      this.requiredRolesFull = data.requiredRolesFull;
      this.requiredRolesView = data.requiredRolesView;
    });
  }

  private getActionsForUser() {
    if (this.hasRequiredRoleFull()) {
      return ['view', 'edit'];
    } else if (this.hasRequiredRoleView()) {
      return ['view'];
    }
    return [];
  }

  hasRequiredRoleFull(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesFull.includes(role));
  }

  hasRequiredRoleView(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesView.includes(role));
  }

  private getNetworkAgentWithPrincipleId(): void {
    this.loading = true;
    this._registrationService.apiV1RegistrationGetNetworkAgentWithPrincipleIdGet({
      principleId: this.getUserId()
    }).subscribe({
      next: (networkAgentList: UserDto[]) => {
        if (networkAgentList) {
          this.loading = false;
          this.networkAgents = networkAgentList;
          this.populateTableData(networkAgentList);
          this.dataTable.dataSource = new MatTableDataSource(this.data);
          this.dataTable.dataSource.sort = this.dataTable.sort;
          this.dataTable.dataSource.paginator = this.dataTable.paginator;
          this.dataTable.dataSource.filterPredicate = (data: any, filter: string) => {
            const searchStr = filter.toLowerCase();
            return data.fullName.toLowerCase().includes(searchStr) ||
              data.email.toLowerCase().includes(searchStr) ||
              data.tags.toLowerCase().includes(searchStr);
          };
        } else {
          this._notificationService.showWarningMessage("Warning", "There were no network agents found for the logged in user.");
        }
        this.loading = false;
      },
      error: (_error: any) => {
        this._notificationService.showErrorMessage('Error', 'Could not fetch network agents.');
        this.loading = false;
      }
    });
  }
}
