import { UrbanPlannerRegistrationStateService } from 'src/app/shared/services/registration/urban-planner-registration-state/urban-planner-registration-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SacplanRegistrationTypeDto } from 'src/app/services/property-matrixV2/models';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opportunity-step',
  templateUrl: './opportunity-step.component.html',
  styleUrls: ['./opportunity-step.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class OpportunityStepComponent implements OnInit {

  userType: string | null = null;
  showSacplanError: boolean = false;
  isProfessionalPlanner: boolean = null;

  sacplanRegistrationNumber: FormControl;
  sacplanRegistrationTypes: SacplanRegistrationTypeDto[] = [];
  selectedSacplanRegistrationType: SacplanRegistrationTypeDto;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _lookupService: LookupService,
    private _notificationService: NotificationService,
    private _urbanPlannerRegistrationStateService: UrbanPlannerRegistrationStateService
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.userType = params['userType'];
    });

    if (this.userType === 'urban-planner') {
      this.loadRegistrationTypes();

      this.sacplanRegistrationNumber = new FormControl('', [
        Validators.required,
        Validators.pattern(/^[A-Z]\d{4}\/(19|20)\d{2}$/)
      ]);

      this.sacplanRegistrationNumber.statusChanges.subscribe(status => {
        this.showSacplanError = status === 'INVALID' && (this.sacplanRegistrationNumber.dirty || this.sacplanRegistrationNumber.touched);
      });
    }

    GlobalMethods.scrollToTop();
  }

  setSacplanRegistrationNumber(sacplanRegistrationNumber: string): void {
    this._urbanPlannerRegistrationStateService.setSacplanRegistrationNumber(sacplanRegistrationNumber);
  }

  selectSacplanRegistrationType(event: any): void {
    this.selectedSacplanRegistrationType = this.sacplanRegistrationTypes.find(x => x.id == event);
    if (this.selectedSacplanRegistrationType) {
      this.setSacplanRegistrationTypeId(this.selectedSacplanRegistrationType);
      if (this.selectedSacplanRegistrationType.value == 'Professional planner') {
        this.isProfessionalPlanner = true;
      } else {
        this.isProfessionalPlanner = false;
      }
    }
  }

  setSacplanRegistrationTypeId(sacplanRegistrationType: SacplanRegistrationTypeDto): void {
    this._urbanPlannerRegistrationStateService.setSacplanRegistrationTypeId(sacplanRegistrationType);
  }

  proceed(): void {
    this._route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };

      if (this.userType == 'estate-agent') {
        this._router.navigate(['/collaboration-agreement'], navigationExtras);
      } else if (this.userType == 'urban-planner') {
        if (this.selectedSacplanRegistrationType == null || this.sacplanRegistrationNumber.value == '') {
          GlobalMethods.tinyErrorAlert('Error', 'Please ensure all required fields are completed.');
          return;
        } else if (this.isProfessionalPlanner == false) {
          GlobalMethods.tinyErrorAlert('Error', 'Only professional planners, registered with SACPLAN can partner with Property Matrix.');
          return;
        } else {
          this._router.navigate(['/collaboration-agreement'], navigationExtras);
        }
      }
    });
  }

  private loadRegistrationTypes(): void {
    this._lookupService.apiV1LookupGetSacplanRegistrationTypesGet().subscribe({
      next: (response: SacplanRegistrationTypeDto[]) => {
        this.sacplanRegistrationTypes = response;
        this.sacplanRegistrationTypes.sort((a, b) => a.intValue - b.intValue);
      },
      error: (_error) => {
        this._notificationService.showErrorMessage('Error', 'Failed to load registration types.');
      }
    });
  }
}
