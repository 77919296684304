import { RegistrationService as RegistrationServices } from 'src/app/services/property-matrixV2/services/registration.service';
import { RegistrationService } from 'src/app/services/property-matrixV2/custom-services/registration.service';
import { UserManagementService, } from 'src/app/services/property-matrixV2/services/user-management.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { UserDto } from 'src/app/services/property-matrixV2/models/user-dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-action-dialog',
  templateUrl: './user-action-dialog.component.html',
  styleUrls: ['./user-action-dialog.component.scss', '../../../../../css/2-modules/_admin-portal.scss']
})
export class UserActionDialogComponent implements OnInit {

  actionType: string;
  user: UserDto;

  constructor(
    public dialogRef: MatDialogRef<UserActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userManagementService: UserManagementService,
    private registrationServices: RegistrationServices,
    private notificationService: NotificationService
  ) {
    this.actionType = data.actionType;
    this.user = data.userDto;
  }

  ngOnInit(): void { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.actionType === 'unverify') {
      this.unverifyUser();
    } else if (this.actionType === 'remove') {
      this.deleteUserId();
    } else if (this.actionType === 'delink') {
      this.removePrinciple();
    } else if (this.actionType === 'archive') {
      this.archiveEstateAgent();
    }
    else {
      this.closeDialog();
    }
  }

  unverifyUser(): void {
    this.userManagementService.apiV1UserManagementUnverifyUserUserIdPut({
      userId: this.user.id
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'User unverified successfully.');
        this.dialogRef.close({ action: 'unverify', user: this.user });
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not unverify user.');
        console.error(error);
      }
    });
  }

  removeUser(): void {
    this.userManagementService.apiV1UserManagementRemoveUserUserIdDelete({
      userId: this.user.id
    }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'User removed successfully.');
        this.dialogRef.close({ action: 'remove', user: this.user });
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not remove user.');
      }
    });
  }

  deleteUserId(): void {
    this.userManagementService.apiV1UserManagementDeleteCurrentUserPost({ userId: this.user.id }).subscribe({
      next: () => {
        this.notificationService.showSuccessMessage('Success', 'User removed successfully.');
        this.dialogRef.close({ action: 'remove', user: this.user });
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not remove user.');
      }
    });
  }

  removePrinciple(): void {
    if (this.user.isPrincipal == null) {
      this.user.isPrincipal = this.user.estateAgent.isPrincipal ?? false;
    }
    if (this.user.estateAgent) {
      this.registrationServices.apiV1RegistrationDeLinkPrincipleForUserIdAsyncPost({ body: this.user.estateAgent }).subscribe({
        next: () => {
          this.notificationService.showSuccessMessage('Success', 'User delink successfully.');
          this.dialogRef.close({ action: 'remove', user: this.user });
        },
        error: (error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not delink user.');
        }
      });
    } else {
      this.notificationService.showWarningMessage("Error", "Error with de-linking the agent from principle.");
    }
  }

  archiveEstateAgent(): void {
    if (this.user.estateAgent) {
      this.userManagementService.apiV1UserManagementArchiveEstateAgentUserPost({ body: this.user.estateAgent }).subscribe({
        next: () => {
          this.notificationService.showSuccessMessage('Success', 'User archived successfully.');
          this.dialogRef.close({ action: 'remove', user: this.user });
        },
        error: (error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not archived user.');
        }
      });
    } else {
      this.notificationService.showWarningMessage("Error", "Error with de-linking the agent from principle.");
    }
  }
}
