import { LandUseDto, ProposedDevelopmentScenarioDto, SchemeDto, UseRightTypeDto, ZoningDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupService } from 'src/app/services/property-matrixV2/services';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-land-parcel',
  templateUrl: './land-parcel.component.html',
  styleUrls: ['./land-parcel.component.scss', '../../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class LandParcelComponent implements OnInit {

  @Input() scenario: ProposedDevelopmentScenarioDto;
  @Input() index: number;
  @Input() municipality: string;
  @Input() disableComponent: boolean = false;

  @Output() scenarioChange = new EventEmitter<ProposedDevelopmentScenarioDto>();

  schemeData: SchemeDto[];
  zoningData: ZoningDto[];
  proposedLandUses: LandUseDto[];
  additionalLandUses: LandUseDto[];

  proposedLandUsesTbcGuid: string | null = null;
  proposedLandUsesNoneGuid: string | null = null;
  additionalLandUsesTbcGuid: string | null = null;
  additionalLandUsesNoneGuid: string | null = null;

  consentUsesUseRightTypeId: string | null = null;
  usesPermittedOnSiteUseRightTypeId: string | null = null;

  possibleProposedLandUses: string | null = null;
  possibleAdditionalLandUses: string | null = null;

  constructor(
    private snackBar: MatSnackBar,
    private lookupService: LookupService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.loadUseRightTypes();
  }

  async loadUseRightTypes(): Promise<void> {
    this.lookupService.apiV1LookupGetUseRightTypesGet().subscribe({
      next: (response: UseRightTypeDto[]) => {
        this.consentUsesUseRightTypeId = response.find(item => item.description === 'Consent Uses (Additional uses/Secondary Rights/Uses/Special Consent/Written Consent A or B)')?.id;
        this.usesPermittedOnSiteUseRightTypeId = response.find(item => item.description === 'Uses permitted on site (Primary rights/Uses)')?.id;

        if (this.municipality == null || this.municipality == '') {
          this.loadAllSchemes();
        } else {
          this.loadSelectedScheme();
        }
        if (this.scenario?.zoningId != null) {
          this.loadZoningData();
          this.loadPossibleProposedLandUses();
          this.loadPossibleAdditionalLandUses();
        }
        if (this.scenario?.proposedLandUseIds != null) {
          this.loadProposedLandUses();
        }
        if (this.scenario?.additionalLandUseIds != null) {
          this.loadAdditionalLandUses();
        }
      }
    });
  }

  async loadSelectedScheme(): Promise<void> {
    this.lookupService.apiV1LookupGetSelectedSchemeGet({
      metroDescription: this.municipality
    }).subscribe({
      next: (response: SchemeDto[]) => {
        this.schemeData = response.sort((a, b) => a.description.localeCompare(b.description));
        const validScheme = response.find(scheme => scheme.description !== "None" && scheme.description !== "TBC");
        if (validScheme) {
          this.scenario.schemeId = validScheme.id;
        } else {
          this.notificationService.showErrorMessage('Error', 'No valid scheme found.');
        }
        this.loadZoningData();
        this.loadProposedLandUses();;
        this.loadAdditionalLandUses();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load selected scheme.');
      }
    });
  }

  async loadAllSchemes(): Promise<void> {
    this.lookupService.apiV1LookupGetAllSchemesGet().subscribe({
      next: (response: SchemeDto[]) => {
        const noneEntry = response.filter(item => item.description === 'None');
        const tbcEntry = response.filter(item => item.description === 'TBC');
        const otherEntries = response.filter(item => item.description !== 'None' && item.description !== 'TBC');

        const sortedEntries = otherEntries.sort((a, b) => a.description.localeCompare(b.description));

        const finalSortedEntries = [...noneEntry, ...tbcEntry, ...sortedEntries];

        this.schemeData = finalSortedEntries;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load all schemes.');
      }
    });
  }

  async loadZoningData(): Promise<void> {
    this.lookupService.apiV1LookupGetZoningDataGet({
      schemeId: this.scenario?.schemeId
    }).subscribe({
      next: (response: ZoningDto[]) => {
        const noneEntry = response.filter(item => item.description === 'None');
        const tbcEntry = response.filter(item => item.description === 'TBC');
        const otherEntries = response.filter(item => item.description !== 'None' && item.description !== 'TBC');

        const sortedEntries = otherEntries.sort((a, b) => a.description.localeCompare(b.description));

        const finalSortedEntries = [...noneEntry, ...tbcEntry, ...sortedEntries];

        this.zoningData = finalSortedEntries;

        this.loadPossibleProposedLandUses();
        this.loadPossibleAdditionalLandUses();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load zoning data.');
      }
    });
  }

  async loadPossibleProposedLandUses(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeZoningUseRightGet({
      schemeId: this.scenario?.schemeId,
      zoningId: this.scenario?.zoningId,
      useRightTypeId: this.usesPermittedOnSiteUseRightTypeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const entries = response
          .filter(item => item.descriptionShort !== 'None' && item.descriptionShort !== 'TBC')
          .sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));

        this.possibleProposedLandUses = entries.map(item => item.descriptionLong).join('\n');
      }
    });
  }

  async loadProposedLandUses(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeGet({
      schemeId: this.scenario?.schemeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const noneEntry = response.find(item => item.descriptionShort === 'None');
        const tbcEntry = response.find(item => item.descriptionShort === 'TBC');
        const otherEntries = response.filter(item => item.descriptionShort !== 'None' && item.descriptionShort !== 'TBC');

        if (noneEntry) {
          this.proposedLandUsesNoneGuid = noneEntry.id;
        }
        if (tbcEntry) {
          this.proposedLandUsesTbcGuid = tbcEntry.id;
        }

        const sortedEntries = otherEntries.sort((a, b) => a.descriptionShort.localeCompare(b.descriptionShort));
        const finalSortedEntries = [...(noneEntry ? [noneEntry] : []), ...(tbcEntry ? [tbcEntry] : []), ...sortedEntries];

        this.proposedLandUses = finalSortedEntries;
      }
    });
  }

  async loadPossibleAdditionalLandUses(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeZoningUseRightGet({
      schemeId: this.scenario?.schemeId,
      zoningId: this.scenario?.zoningId,
      useRightTypeId: this.consentUsesUseRightTypeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const entries = response
          .filter(item => item.descriptionShort !== 'None' && item.descriptionShort !== 'TBC')
          .sort((a, b) => a.descriptionLong.localeCompare(b.descriptionLong));

        this.possibleAdditionalLandUses = entries.map(item => item.descriptionLong).join('\n');
      }
    });
  }

  async loadAdditionalLandUses(): Promise<void> {
    this.lookupService.apiV1LookupGetLandUsesBySchemeGet({
      schemeId: this.scenario?.schemeId
    }).subscribe({
      next: (response: LandUseDto[]) => {
        const noneEntry = response.find(item => item.descriptionShort === 'None');
        const tbcEntry = response.find(item => item.descriptionShort === 'TBC');
        const otherEntries = response.filter(item => item.descriptionShort !== 'None' && item.descriptionShort !== 'TBC');

        if (noneEntry) {
          this.additionalLandUsesNoneGuid = noneEntry.id;
        }
        if (tbcEntry) {
          this.additionalLandUsesTbcGuid = tbcEntry.id;
        }

        const sortedEntries = otherEntries.sort((a, b) => a.descriptionShort.localeCompare(b.descriptionShort));
        const finalSortedEntries = [...(noneEntry ? [noneEntry] : []), ...(tbcEntry ? [tbcEntry] : []), ...sortedEntries];

        this.additionalLandUses = finalSortedEntries;
      }
    });
  }

  onSchemeChange(event: MatSelectChange): void {
    this.scenario.schemeId = event.value;
    this.loadZoningData();
    this.loadProposedLandUses();
    this.loadAdditionalLandUses();
    this.emitChanges();
  }

  onZoningChange(event: MatSelectChange): void {
    this.scenario.zoningId = event.value;
    this.loadPossibleProposedLandUses();
    this.loadPossibleAdditionalLandUses();
    this.emitChanges();
  }

  onProposedLandUsesChange(event: MatSelectChange): void {
    const selectedIds = event.value;

    if (selectedIds.includes(this.proposedLandUsesNoneGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "None" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.scenario.proposedLandUseIds = [this.proposedLandUsesNoneGuid];
    }
    else if (selectedIds.includes(this.proposedLandUsesTbcGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "TBC" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.scenario.proposedLandUseIds = [this.proposedLandUsesTbcGuid];
    }
    else {
      this.scenario.proposedLandUseIds = selectedIds.filter((id: string) => id !== this.proposedLandUsesNoneGuid && id !== this.proposedLandUsesTbcGuid);
    }

    this.emitChanges();
  }

  onAdditionalLandUsesChange(event: MatSelectChange): void {
    const selectedIds = event.value;

    if (selectedIds.includes(this.additionalLandUsesNoneGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "None" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.scenario.additionalLandUseIds = [this.additionalLandUsesNoneGuid];
    }
    else if (selectedIds.includes(this.additionalLandUsesTbcGuid)) {
      if (selectedIds.length > 1) {
        this.snackBar.open('You cannot select other options when "TBC" is selected.', 'Close', {
          duration: 3000
        });
      }
      this.scenario.additionalLandUseIds = [this.additionalLandUsesTbcGuid];
    }
    else {
      this.scenario.additionalLandUseIds = selectedIds.filter((id: string) => id !== this.additionalLandUsesNoneGuid && id !== this.additionalLandUsesTbcGuid);
    }

    this.emitChanges();
  }

  emitChanges(): void {
    this.scenarioChange.emit(this.scenario);
  }
}
