import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { catchError, finalize, of } from 'rxjs';

import { MultiplePropertyListingDto, PropertyListingDto, AdvertGeneratorDto, AdvertCreatorFiltersDto } from '../../../../../../app/services/property-matrixV2/models';
import { NotificationService } from '../../../../../../app/shared/services/notification-service/notification.service';
import { ReportKeyDialogComponent } from '../report-overview/report-key-dialog/report-key-dialog.component';
import { AdvertStateService } from '../../../../../shared/services/advert-service/advert-state.service';
import { KeyValuePair } from '../../../../../../app/common/global-models/key-value-pair';
import { AdvertService } from '../../../../../services/property-matrixV2/services';
import { GlobalMethods } from '../../../../../../app/common/global-methods';

@Component({
  selector: 'app-advert-generate',
  templateUrl: './advert-generate.component.html',
  styleUrls: ['./advert-generate.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})

export class AdvertGenerateComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto = {};
  @Input() selectedListingGroup: MultiplePropertyListingDto = {};
  @Input() isMultiple: boolean = false;
  @Input() listingType: string = "";

  loading: boolean = false;
  reportItemTabNumber: number = 1;
  reportTypeTabNumber: number = 1;

  reportItems: KeyValuePair[] = [
    { key: 1, value: 'Advert for Developed' },
    { key: 2, value: 'Advert for Develop-able' },
    { key: 3, value: 'Advert Publish' },
  ];

  tabObject: any[] = [
    { id: 1, value: 'Advert for Developed', isDisabled: false },
    { id: 2, value: 'Advert for Develop-able', isDisabled: false },
    { id: 3, value: 'Advert Publish', isDisabled: false },
  ];

  advertGenerateDTO: AdvertGeneratorDto | undefined = {}
  developed: string = "developed";
  developable: string = "developable";
  doNotPublishPropertyUnderDevelopment: boolean = true;
  doNotPublishPropertyUnderDevelopable: boolean = true;
  advertCreatorFiltersDto: AdvertCreatorFiltersDto = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private advertService: AdvertService,
    private advertStateService: AdvertStateService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.getAdvertFilterDataForListing();
  }

  goToListingOverview(): void {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }

  setFirstAvailableTab() {
    if (this.tabObject[0]?.isDisabled) {
      this.reportItemTabNumber = 2;
    } else {
      this.reportItemTabNumber = 1;
    }
  }

  navigateToReportItemTab(index: number): void {
    let indexToSelect = index - 1;

    while (this.tabObject[indexToSelect]?.isDisabled) {
      indexToSelect++;
      if (indexToSelect >= this.tabObject.length) {
        break;
      }
    }

    this.reportItemTabNumber = indexToSelect + 1;
    GlobalMethods.scrollToTop();
  }

  openReportKeyDialog1(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }

  saveAdvertEntirely1(): void {
    try {
      this.loading = true;

      this.advertGenerateDTO = this.advertStateService.getAdvertGenerateState();
      if (this.advertGenerateDTO == undefined) {
        console.error("Will not save Advert Generate object because the item is empty. Please contact administrator if persists.");
        this.notificationService.showErrorMessage("Error", "Will not save Advert Generate object because the item is empty. Please contact administrator if persists.");
        return;
      }

      this.advertService.apiV1AdvertSaveAdvertGeneratorReportPost({
        body: this.advertGenerateDTO
      }).subscribe({
        next: (result: any) => {
          if (result) {
            this.advertStateService.setSelectedAdvertGeneratorReport(this.advertGenerateDTO!);
            this.notificationService.showSuccessMessage("Saved", "Saved advert filters successfully.");
            this.goToListingOverview();
          } else {
            this.notificationService.showErrorMessage("Error", 'Error Updating Advert generator.');
          }
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Error Updating Advert generator.');
          this.loading = false;
        }
      });
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert generator because of errors.');
      this.loading = false;
    }
  }

  completeAdvertEntirely1(): void {
    try {
      this.loading = true;

      this.advertGenerateDTO = this.advertStateService.getAdvertGenerateState();
      if (this.advertGenerateDTO == undefined) {
        console.error("Will not save Advert Generate object because the item is empty. Please contact administrator if persists.");
        this.notificationService.showErrorMessage("Error", "Will not save Advert Generate object because the item is empty. Please contact administrator if persists.");
        return;
      }

      this.advertService.apiV1AdvertSaveAdvertGeneratorReportPost({
        body: this.advertGenerateDTO
      }).subscribe({
        next: (result: any) => {
          if (result) {
            this.advertStateService.setSelectedAdvertGeneratorReport(this.advertGenerateDTO!);
            this.notificationService.showSuccessMessage("Saved", "Saved advert filters successfully.");
            this.goToListingOverview();
          } else {
            this.notificationService.showErrorMessage("Error", 'Error Updating Advert generator.');
          }
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Error Updating Advert generator.');
          this.loading = false;
        }
      });
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert generator because of errors found.');
      this.loading = false;
    }
  }

  getAdvertFilterDataForListing(): void {
    this.loading = true;
    this.advertService.apiV1AdvertGetAdvertFiltersCreatedByIdGet({ propertyListingId: this.selectedListing.id })
      .pipe(
        catchError((err) =>
          of(this.notificationService.showErrorMessage("Error", "Error getting the filtered advert data."))
        ),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result: AdvertCreatorFiltersDto | void) => {
        if (result) {
          this.advertCreatorFiltersDto = result;
          if (this.advertCreatorFiltersDto.advertSector?.length == 1) {
            console.log(this.advertCreatorFiltersDto.advertSector);
            const disableTabs = new Set<number>();
            this.advertCreatorFiltersDto.advertSector.forEach(element => {
              if (element.description === 'Developed') {
                disableTabs.add(2);
              } else if (element.description === 'Developable') {
                disableTabs.add(1);
              } else if (element.description === 'Both') {

              }
              this.tabObject.forEach(tab => {
                if (disableTabs.has(tab.id)) {
                  tab.isDisabled = true;
                }
              });
            });
          }
          this.setFirstAvailableTab();
        }
      });
  }
}
