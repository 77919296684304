import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PropertyListingDto, MultiplePropertyListingDto, AdvertGeneratorDto, TaskListDto, UserDto, AdvertCreatorFiltersDto } from '../../../../../../app/services/property-matrixV2/models';
import { AdvertService, PropertyListingService, UserManagementService } from '../../../../../../app/services/property-matrixV2/services';
import { ListingStateService } from '../../../../../../app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../../../app/shared/services/notification-service/notification.service';

@Component({
  selector: 'app-advert-publish',
  templateUrl: './advert-publish.component.html',
  styleUrls: ['./advert-publish.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class AdvertPublishComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto | undefined;
  @Input() selectedListingGroup: MultiplePropertyListingDto | undefined;
  @Input() isMultiple: boolean | undefined;
  @Input() sectorType: string | undefined;
  @Output() booleanEvent = new EventEmitter<boolean>();
  @Input() advertCreatorFiltersDto: AdvertCreatorFiltersDto = {}
  sections = [
    { key: 1, value: 'Advert for Developed Preview:', checked: true },
    { key: 2, value: 'Advert for Developable Preview:', checked: true },
  ];

  loading: boolean = false;
  advertGeneratorDeveloped: AdvertGeneratorDto = {};
  advertGeneratorDevelopable: AdvertGeneratorDto = {};
  currentUser: UserDto;
  listingType: string;
  showDevelopedOnly: boolean = false;
  showDevelopAbleOnly: boolean = false;

  constructor(
    private advertService: AdvertService,
    private userManagementService: UserManagementService,
    private propertyListingService: PropertyListingService,
    private listingStateService: ListingStateService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.getDevelopedAdvert();
    this.getDevelopableAdvert();
    this.getCurrentUser();
    this.hideShowPublishedItem();
  }

  getDevelopedAdvert() {
    this.advertService.apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet({ propertyListingId: this.selectedListing?.id }).subscribe(res => {
      this.advertGeneratorDeveloped = res.data as AdvertGeneratorDto;
    });
  }

  getDevelopableAdvert() {
    this.advertService.apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet({ propertyListingId: this.selectedListing?.id }).subscribe(res => {
      this.advertGeneratorDevelopable = res.data as AdvertGeneratorDto;
    });
  }

  saveAdvertAppointed() {
    this.setPropertyListingTask();
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    this.selectedListing?.taskList?.forEach(task => {
      if (task.taskNumber?.number === 11) {
        if (task.taskStatus?.taskStatusNumber != 3) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
      if (task.taskNumber?.number === 12) {
        if (task.taskStatus?.taskStatusNumber == 1) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result != null) {
          this.navigateToPlanningReport();
        }
        else {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
    else {
      this.navigateToPlanningReport();
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }

  navigateToPlanningReport() {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing?.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }

  hideShowPublishedItem(): void {
    if (this.advertCreatorFiltersDto && this.advertCreatorFiltersDto.advertSector) {
      this.advertCreatorFiltersDto?.advertSector?.forEach(element => {
        if (element.description === 'Developed') {
          this.showDevelopedOnly = false;
          this.showDevelopAbleOnly = true;
        } else if (element.description === 'Developable') {
          this.showDevelopedOnly = true;
          this.showDevelopAbleOnly = false;
        } else if (element.description === 'Both') {
          this.showDevelopedOnly = true;
          this.showDevelopAbleOnly = true;
        }
      });
    } else {
      this.notificationService.showErrorMessage("Error", "The panels can not be hidden according to the filter saved for advert because advertCreatorFiltersDto is empty.");
    }
  }
}