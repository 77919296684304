<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="advert-generate-developable" id="advert-generate-developable">
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="row paragraph">
            <div class="col-12 padding" *ngIf="section.key == 0" [hidden]="!section.checked">
                <mat-checkbox [(ngModel)]="doNotPublishPropertyUnderDevelopment" class="mat-checkbox padding paragraph">
                    I do not want to publish a property advert under Develop-able.
                </mat-checkbox>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <form id="AdvertGenerateDevelopableFormPropertyInfo"
                        [formGroup]="advertGenerateDevelopableFormPropertyInfo">
                        <div class="row paragraph">
                            <div class="col-12 mt-2">
                                <div class="form-group">
                                    <label class="col-12 bold">Price</label>
                                    <input class="col-12 mt-1" type="text" placeholder="R4.5m"
                                        formControlName="price" />
                                </div>
                                <div
                                    *ngIf="advertGenerateDevelopableFormPropertyInfo.get('price').touched && advertGenerateDevelopableFormPropertyInfo.get('price').invalid">
                                    <div class="errorText"
                                        *ngIf="advertGenerateDevelopableFormPropertyInfo.get('price').hasError('required')">
                                        Price is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="form-group">
                                    <label class="col-12 bold">Heading on: Size, type, location</label>
                                    <input class="col-12 mt-1" type="text"
                                        placeholder="960m Residential 2 property in Brooklyn, Pretoria"
                                        formControlName="headingOneSizeTypeLocation" />
                                </div>
                                <div
                                    *ngIf="advertGenerateDevelopableFormPropertyInfo.get('headingOneSizeTypeLocation').touched && advertGenerateDevelopableFormPropertyInfo.get('headingOneSizeTypeLocation').invalid">
                                    <div class="errorText"
                                        *ngIf="advertGenerateDevelopableFormPropertyInfo.get('headingOneSizeTypeLocation').hasError('required')">
                                        Heading One Size Type Location is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="form-group">
                                    <label class="col-12 bold">Heading two: Advert type</label>
                                    <input class="col-12 mt-1" type="text"
                                        placeholder="Possible Development/Highest Best/On Offer"
                                        formControlName="headingTwoAdvertType" />
                                </div>
                                <div
                                    *ngIf="advertGenerateDevelopableFormPropertyInfo.get('headingTwoAdvertType').touched && advertGenerateDevelopableFormPropertyInfo.get('headingTwoAdvertType').invalid">
                                    <div class="errorText"
                                        *ngIf="advertGenerateDevelopableFormPropertyInfo.get('headingTwoAdvertType').hasError('required')">
                                        Heading two: Advert type is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-2">
                                <div class="form-group">
                                    <label class="col-12 bold">Short description of property?</label>
                                    <input class="col-12 mt-1" type="text" placeholder="John"
                                        formControlName="shortDescriptionOfProperty" />
                                </div>
                                <div
                                    *ngIf="advertGenerateDevelopableFormPropertyInfo.get('shortDescriptionOfProperty').touched && advertGenerateDevelopableFormPropertyInfo.get('shortDescriptionOfProperty').invalid">
                                    <div class="errorText"
                                        *ngIf="advertGenerateDevelopableFormPropertyInfo.get('shortDescriptionOfProperty').hasError('required')">
                                        Short description of property is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <div class="col-12 mt-1" *ngFor="let status of advertStatusFilteredList; let i = index">
                        <mat-checkbox [(ngModel)]="status.isChecked">
                            {{ status.description}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <div class="row paragraph" *ngFor="let keyFeature of advertKeyFeaturesList; let i = index">
                        <button (click)="removeAdvertKeyFeature(i)" type="button"
                            class="mat-icon-button circle-button">X
                            <mat-icon>remove</mat-icon>
                        </button>
                        <div class="col-12 mt-2">
                            <div class="form-group">
                                <label class="col-12 bold">{{ "Feature " + i }}</label>
                                <input class="col-12 mt-1" type="text" placeholder="24/7 security"
                                    [(ngModel)]="keyFeature.description" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="col-12 mt-3 center-content paragraph">
                            <button (click)="addNewKeyFeatureItem()" class="center-content add-button">
                                <mat-icon class="circle-button">add</mat-icon>
                                <span class="bold add-button-label">Add Key Feature</span>
                            </button>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 4" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <div class="row paragraph mt-3">
                        <div class="col-12">
                            <div class="upload-background">
                                <div class="upload-block" appDragAndDrop>
                                    <input type="file" (change)="setPropertyReportFile($event)"
                                        class="upload-block-inner" multiple accept="image/*" id="PropertyReportImage"
                                        placeholder="image">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-icon class="file-icon">cloud_upload</mat-icon>
                                        </div>
                                        <div class="col-12">
                                            Click to upload.
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="fileUrlPropertyReport">
                                        <div class="col-12">
                                            <div class="image-upload">
                                                <img [src]="fileUrlPropertyReport" alt="Image Preview" id="preview"
                                                    class="upload-block-inner">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="hidePropertyReportButtons">
                        <div class="col-12 button-group mt-3 mb-3">
                            <a class="footer-button background-charcoal text-center" [href]="fileUrlPropertyReport"
                                [download]="fileUrlPropertyReport?.name">
                                Download
                            </a>
                            <button type="button" class="footer-button background-charcoal"
                                (click)="replacePropertyReport()">Replace</button>
                            <button type="button" class="footer-button background-charcoal"
                                (click)="deletePropertyReport()">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 5" [hidden]="!section.checked">
            <div id="PropertySingleImage-Picture">
                <div class="row">
                    <div class="col-12 mt-3">
                        <p class="paragraph">Upload ONE, single image/picture to display on pop-up</p>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <div class="upload-background">
                                    <div class="upload-block" appDragAndDrop>
                                        <input type="file" (change)="setPropertyImageFile($event)"
                                            class="upload-block-inner" multiple accept="image/*" id="input"
                                            placeholder="image">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                                            </div>
                                            <div class="col-12">
                                                Click to upload.
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="filePropertyImageUrl">
                                            <div class="col-12">
                                                <div class="image-upload">
                                                    <img [src]="filePropertyImageUrl" alt="Image Preview" id="preview"
                                                        class="upload-block-inner">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="hidePropertyImageButtons">
                            <div class="col-12 button-group mt-3 mb-3">
                                <a class="footer-button background-charcoal text-center" [href]="filePropertyImageUrl"
                                    [download]="filePropertyImage?.name">
                                    Download
                                </a>
                                <button type="button" class="footer-button background-charcoal"
                                    (click)="replacePropertyImage()">Replace</button>

                                <button type="button" class="footer-button background-charcoal"
                                    (click)="deletePropertyImage()">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="propertyImagesAdditional">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 mt-3">
                                <p class="paragraph">Upload additional images/pictures to browse</p>
                            </div>
                        </div>

                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <mat-card class="upload-card">
                                    <div class="upload-section" (dragover)="onDragOver($event)" (drop)="onDrop($event)"
                                        (click)="fileInput.click()">
                                        <input class="file-input" type="file" #fileInput multiple
                                            (change)="onFileInput($event)" placeholder="file" />
                                        <mat-icon class="material-icons-outlined">cloud_upload</mat-icon>
                                        <p class="paragraph">Drag & drop or</p>
                                        <p class="paragraph">click to upload.</p>
                                    </div>
                                </mat-card>
                                <div *ngFor="let file of files; let i = index" class="file-card">
                                    <mat-card class="file-item-card">
                                        <mat-card-content class="card-content">
                                            <a class="paragraph file-name" matTooltip="Download file"
                                                matTooltipPosition="right" [href]="file.url" [download]="file.name">
                                                {{ file.name }}
                                            </a>
                                            <button class="delete-button" matTooltip="Delete file"
                                                matTooltipPosition="left" (click)="deleteFile(file.id, i)">
                                                <mat-icon class="material-icons-outlined">delete</mat-icon>
                                            </button>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="hideButtons">
                            <div class="col-12 button-group mt-3 mb-3">
                                <a class="footer-button background-charcoal text-center" [href]="fileUrl"
                                    [download]="file?.name">
                                    Download
                                </a>
                                <button type="button" class="footer-button background-coral"
                                    (click)="replace()">Replace</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 6" [hidden]="!section.checked">
            <div class="row paragraph mt-3" [hidden]="hideLatLonPanel">
                <div class="col-12">
                    <button class="col-3 display-right footer-button background-coral"
                        (click)="openLocationDialog()">Set</button>
                </div>
                <div class="col-12 hidden">
                    <div *ngIf="latitude && longitude">
                        <p>Selected Latitude: {{ latitude }}</p>
                        <p>Selected Longitude: {{ longitude }}</p>
                    </div>
                </div>
                <div class="col-12">
                    <label class="col-12 bold">Lat-long Coordinates</label>
                    <input class="col-12 mt-1 riskscape-data" type="text" readonly [(ngModel)]="LatLongCoordinatesNew"
                        placeholder="Lat-long Coordinates" />
                </div>
            </div>
            <div class="row paragraph mt-3" [hidden]="!hideLatLonPanel">
                <div class="col-12">
                    <label class="col-12 bold">Lat-long Coordinates</label>
                    <input class="col-12 mt-1 riskscape-data" type="text" readonly [(ngModel)]="LatLongCoordinatesNew"
                        placeholder="Lat-long Coordinates" />
                </div>
            </div>
        </div>
    </mat-card>

    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button type="button" class="footer-button background-coral"
                (click)="saveAdvertDevelopedData()">Save</button>
            <button type="button" class="footer-button background-green"
                (click)="completeAdvertDevelopedData()">Complete</button>
        </div>
    </div>
</div>