import { FileManagementService } from 'src/app/services/property-matrixV2/services';
import { FooterDocumentDto } from 'src/app/services/property-matrixV2/models';
import { Component, OnInit } from '@angular/core';

import { ReportingService } from '../../services/reporting-service/reporting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerDocuments: FooterDocumentDto[] = [];
  termsAndConditions: FooterDocumentDto;
  privacyPolicy: FooterDocumentDto;
  cookiePreferences: FooterDocumentDto;
  paiaManual: FooterDocumentDto;
  legalDisclaimer: FooterDocumentDto;

  constructor(
    private reportingService: ReportingService,
    private fileManagementService: FileManagementService
  ) { }

  ngOnInit(): void {
  }

  async onLinkClick(linkName: string): Promise<void> {
    let filePath: string | null = null;

    if (linkName === 'Terms & Conditions') {
      filePath = '../../../../../assets/pdf/Matrix Terms and Conditions LM.pdf';
      window.open(filePath, '_blank');
    } else if (linkName === 'Privacy Policy') {
      filePath = '../../../../../assets/pdf/Matrix Privacy Policy LM.pdf';
      window.open(filePath, '_blank');
    } else if (linkName === 'Cookie Preferences') {
      filePath = '../../../../../assets/pdf/Matrix Cookie Policy LM.pdf';
      window.open(filePath, '_blank');
    } else if (linkName === 'PAIA Manual') {
      filePath = '../../../../../assets/pdf/Matrix PAIA Manual LM.pdf';
      window.open(filePath, '_blank');
    } else if (linkName === 'Legal Disclaimer') {
      filePath = 'Coming soon';
      //window.open(filePath, '_blank');
    }
  }
}
