<div>
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3" *ngIf="isNetworkPlannerNominated">
                <div class="col-12">
                    <label class="col-12 bold">Seller did not nominate a NP (Network/Urban Planner) in listing</label>
                </div>
            </div>
            <div id="AddNew" class="col-12 pb-2" *ngIf="!isNetworkPlannerNominated">
                <div class="row-container pt-2 pb-2 ">
                    <span class="paragraph user-dialog-subheading">Details provided by Seller</span>
                </div>
                <form class="user-form" [formGroup]="FormAllocateNetworkPlanner" id="FormAllocatenetworkPlanner">
                    <div class="row paragraph">
                        <div class="col-12 mt-2">
                            <div class="form-group">
                                <label class="col-4 bold">Planner Name</label>
                                <input class="col-6 mt-1" type="text" placeholder="John"
                                    formControlName="plannerName" />
                            </div>
                            <div
                                *ngIf="FormAllocateNetworkPlanner.get('plannerName').touched && FormAllocateNetworkPlanner.get('plannerName').invalid">
                                <div class="errorText"
                                    *ngIf="FormAllocateNetworkPlanner.get('plannerName').hasError('required')">
                                    Planner Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="form-group"></div>
                            <label class="col-4 bold">Last Name</label>
                            <input class="col-6 mt-1" type="text" placeholder="Doe" formControlName="plannerSurname" />
                            <div
                                *ngIf="FormAllocateNetworkPlanner.get('plannerSurname').touched && FormAllocateNetworkPlanner.get('plannerSurname').invalid">
                                <div class="errorText"
                                    *ngIf="FormAllocateNetworkPlanner.get('plannerSurname').hasError('required')">
                                    Planner Surname is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="form-group">
                                <label class="col-4 bold">Company:</label>
                                <input class="col-6 mt-1" type="text" placeholder="Company name"
                                    formControlName="plannerCompany" />
                            </div>
                            <div
                                *ngIf="FormAllocateNetworkPlanner.get('plannerCompany').touched && FormAllocateNetworkPlanner.get('plannerCompany').invalid">
                                <div class="errorText"
                                    *ngIf="FormAllocateNetworkPlanner.get('plannerCompany').hasError('required')">
                                    Planner Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="form-group">
                                <label class="col-4 bold">City/Town:</label>
                                <input class="col-6 mt-1" type="text" placeholder="Pretoria"
                                    formControlName="plannerCityTown" />
                            </div>
                            <div
                                *ngIf="FormAllocateNetworkPlanner.get('plannerCityTown').touched && FormAllocateNetworkPlanner.get('plannerCityTown').invalid">
                                <div class="errorText"
                                    *ngIf="FormAllocateNetworkPlanner.get('plannerCityTown').hasError('required')">
                                    Planner Name is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="form-group">
                                <label class="col-4 bold">Email:</label>
                                <input class="col-6 mt-1" type="text" placeholder="johndoe@gmail.com"
                                    formControlName="plannerEmail" />
                                <div
                                    *ngIf="FormAllocateNetworkPlanner.get('plannerEmail').touched && FormAllocateNetworkPlanner.get('plannerEmail').invalid">
                                    <div class="errorText"
                                        *ngIf="FormAllocateNetworkPlanner.get('plannerEmail').hasError('required')">
                                        Email is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="form-group">
                                <label class="col-4 bold">Mobile/Office nr:</label>
                                <input class="col-6 mt-1" type="text" placeholder="074 231 4544"
                                    formControlName="plannerMobileOfficeNr" />
                                <div
                                    *ngIf="FormAllocateNetworkPlanner.get('plannerMobileOfficeNr').touched && FormAllocateNetworkPlanner.get('plannerMobileOfficeNr').invalid">
                                    <div class="errorText"
                                        *ngIf="FormAllocateNetworkPlanner.get('plannerMobileOfficeNr').hasError('required')">
                                        Mobile is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold">
                        <span>Select the correct statement:</span>
                    </label>
                </div>
                <div class="col-12">
                    <mat-radio-group [(ngModel)]="networkPlannerType">
                        <div class="radio-container">
                            <span class="radio-button-text">
                                <mat-radio-button class="radio" [value]="1">
                                    This Planner is allocated by PM (Property Matrix)
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container">
                            <span class="radio-button-text">
                                <mat-radio-button class="radio" [value]="2">
                                    The Seller nominated a Planner
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container">
                            <span class="radio-button-text">
                                <mat-radio-button class="radio" [value]="3">
                                    NP (Network/Urban Planner) referred property to PM (Property Matrix)
                                </mat-radio-button>
                            </span>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
            <div class=" row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold">
                        Assign Network/Urban Planner
                    </label>
                </div>
                <div class="col-12">
                    <label class="col-12">
                        <mat-select class="col-12 mt-1" [(ngModel)]="selectedNetworkPlanner">
                            <mat-option value=null>None</mat-option>
                            <mat-option *ngFor="let item of UserListNew" [value]="item">
                                {{ item.name + " " + item.surname }}
                            </mat-option>
                        </mat-select>
                    </label>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="saveNetworkPlanner()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>