import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { catchError, finalize, of } from 'rxjs';
import { DatePipe } from '@angular/common';

import { PlanningReportService, ReportGeneratorService, UserManagementService } from '../../../../../../app/services/property-matrixV2/services';
import { PropertyListingDto, ReportDocumentDto, SellMyPropertyDocumentDto } from '../../../../../../app/services/property-matrixV2/models';
import { ConfigUserPermissionService } from '../../../../../../app/services/property-matrixV2/custom-services/userPermission.service';
import { sellYourPropertyUploadDocumentTabTypes } from '../../../../../../app/shared/interfaces/sell-your-property-filetypes';
import { ColumnConfig, DataTableComponent } from '../../../../../../app/shared/components/data-table/data-table.component';
import { NotificationService } from '../../../../../../app/shared/services/notification-service/notification.service';
import { ListingDocumentAddEditComponent } from '../listing-document-add-edit/listing-document-add-edit.component';
import { UserActionDialogComponent } from '../../../admin-users/user-action-dialog/user-action-dialog.component';
import { User } from '../../../../../../app/models/User';

export interface ActionOption {
  label: string;
  action: (user: any) => void;
}

@Component({
  selector: 'app-listing-documents',
  templateUrl: './listing-documents.component.html',
  styleUrls: ['./listing-documents.component.scss'],
  providers: [DatePipe]
})
export class ListingDocumentsComponent implements OnInit {

  @Input() planningReportId: string = "";
  @Input() selectedListing: PropertyListingDto = {};
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(DataTableComponent) dataTable: DataTableComponent | undefined;

  loading: boolean = false;
  documents: ReportDocumentDto[] = [];
  displayedColumns: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  displayedColumnsUploadsSellProperty: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  displayedColumnsPropertyPics: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  displayedColumnsAdditionalUpload: string[] = ['number', 'file', 'user', 'email', 'date', 'download'];
  dataSource = new MatTableDataSource(this.documents);

  sellPropertyDocuments: SellMyPropertyDocumentDto[] = [];
  sellPropertyDocumentsNotDeleted: SellMyPropertyDocumentDto[] = [];
  sellPropertyDocumentsDeleted: SellMyPropertyDocumentDto[] = [];
  ownershipDocuments: ReportDocumentDto[] = [];
  additionImages: SellMyPropertyDocumentDto[] = [];
  dataSourceSellUploads = new MatTableDataSource(this.sellPropertyDocuments);
  dataSourcePicture = new MatTableDataSource(this.sellPropertyDocuments);
  dataSourceAddition = new MatTableDataSource(this.additionImages);
  dataSourceOwnershipReport = new MatTableDataSource(this.ownershipDocuments);
  data = [
    { number: '', file: '', email: '', user: '', date: '', download: '', actions: '' },
  ];

  columns: ColumnConfig[] = [
    { columnDef: 'number', header: 'Document ID', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'file', header: 'File', headerAlign: 'left', cellAlign: 'left', width: 10 },
    { columnDef: 'user', header: 'User', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'email', header: 'Email', headerAlign: 'left', cellAlign: 'left', width: 10 },
    { columnDef: 'date', header: 'Date', headerAlign: 'left', cellAlign: 'left', width: 10 },
    { columnDef: 'download', header: 'Download', headerAlign: 'left', cellAlign: 'left', width: 20 },
    { columnDef: 'actions', header: 'Actions', isAction: true, headerAlign: 'center', cellAlign: 'center', width: 10 }
  ];

  actionOptions: ActionOption[] = [
    { label: 'Remove', action: (SellMyPropertyDocument: SellMyPropertyDocumentDto) => this.openActionDialog('remove', SellMyPropertyDocument) },
    { label: 'Edit', action: (SellMyPropertyDocument: SellMyPropertyDocumentDto) => this.openEditUploadDialog('edit', SellMyPropertyDocument) },
  ];

  pageSize = 5;
  pageSizeOptions: number[] = [5, 10, 20, 50, 75, 100];
  pageTotal: number = 0;
  totalItems: number = 0;

  SellUploadsPageSize = 5;
  SellUploadsTotalItems: number = 0;
  SellUploadsPageTotal: number = 0;

  SellUploadsPicturePageSize = 5;
  SellUploadsPicturePageTotal: number = 0;
  SellUploadsPictureTotalItems: number = 0;

  AdditionalPageSize = 5;
  AdditionalPageTotal: number = 0;
  SellUploadsAdditionalTotalItems: number = 0;

  OwnershipReportTotalItems: number = 0;
  OwnershipReportPageTotal: number = 0;

  requiredRolesFull: string[] = [];
  requiredRolesView: string[] = [];
  currentUserRoles: string[] = [];

  constructor(
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private _userPermissionService: ConfigUserPermissionService,
    private _reportGeneratorService: ReportGeneratorService,
    private userManagementService: UserManagementService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.refreshDocuments();
    this.getUserDetail();
    this.getUserPermissionList();
  }

  getDocuments() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs to be exported before documents will be available here.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportDocumentsGet({
        planningReportId: this.planningReportId
      }).subscribe({
        next: (documents: ReportDocumentDto[]) => {
          this.documents = documents;
          this.dataSource.data = this.documents.filter(d => d.reportType == "LevelTwoSingleReport" || d.reportType == "LevelOneSingleReport");
          this.dataSource.sort = this.sort;
          this.totalItems = this.dataSource.data.length;
          this.SellUploadsPageTotal = this.calculateTotalPages(this.totalItems, this.SellUploadsPageSize);
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load report documents.');
          this.loading = false;
        }
      });
    }
  }

  populateTableData(sellMyPropertyDocument: SellMyPropertyDocumentDto[]) {
    return sellMyPropertyDocument.map(sellDocument => ({
      number: sellDocument?.documentInfo?.id,
      file: sellDocument?.documentInfo?.name,
      user: sellDocument?.documentInfo?.fullUserName,
      email: sellDocument?.documentInfo?.userEmail,
      date: (sellDocument?.documentInfo?.createdDate != null) ? this.formatDate(sellDocument?.documentInfo?.createdDate) : "",
      download: sellDocument.documentInfo?.fileDownloadURL,
      actions: '',
    }));
  }

  getSellPropertyDocuments() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs id.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportUploadedSellPropertyDocumentsGet({
        propertyDetailId: this.selectedListing.propertyDetail?.id
      }).subscribe({
        next: (UploadedDocument: SellMyPropertyDocumentDto[]) => {
          this.sellPropertyDocuments = UploadedDocument;
          this.allUploadedDocument();
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load Sell property uploads.');
          this.loading = false;
        }
      });
    }
  }

  getAdditionalImages() {
    if (this.planningReportId === null || this.planningReportId === undefined) {
      this.notificationService.showInfoMessage('Notice', 'The planning report needs to be exported before documents will be available here.');
    } else {
      this.loading = true;
      this.planningReportService.apiV1PlanningReportGetReportUploadedSellPropertyDocumentsGet({
        propertyDetailId: this.selectedListing.propertyDetail?.id
      }).subscribe({
        next: (UploadedDocument: SellMyPropertyDocumentDto[]) => {
          if (UploadedDocument) {
            this.additionImages = UploadedDocument;
            this.dataSourceAddition.data = this.additionImages.filter(a => a.documentType == sellYourPropertyUploadDocumentTabTypes.NewAddedAdditionalDocument.toString());
            this.dataSourceAddition.sort = this.sort;
            this.dataSourceAddition.paginator = this.dataSourceAddition.paginator;
            this.dataSourceAddition.filterPredicate = (data: any, filter: string) => {
              const searchStr = filter.toLowerCase();
              return data.fullName.toLowerCase().includes(searchStr) ||
                data.email.toLowerCase().includes(searchStr) ||
                data.tags.toLowerCase().includes(searchStr);
            };
          } else {
            this.notificationService.showErrorMessage("Error", "Could not find any additional documents for current listing propertyDetail Id");
          }
          this.loading = false;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not find any sell documents for current listing propertyDetail Id.');
          this.loading = false;
        }
      });
    }
  }

  refreshDocuments(): void {
    this.getDocuments();
    this.getSellPropertyDocuments();
    this.getAdditionalImages();
    this.getOwnershipReportDataForListing();
  }

  formatDate(date: string): string | null {
    return this.datePipe.transform(date, 'dd MMMM yyyy hh:mm');
  }

  openNewDocumentDialog(): void {
    const AddNewUploadDialog = this.dialog.open(ListingDocumentAddEditComponent, {
      data: {
        fields: null,
        properDetailId: this.selectedListing.propertyDetail?.id,
      },
      width: '30vw',
      height: '90vh'
    });
    AddNewUploadDialog.afterClosed().subscribe(result => {
      this.refreshDocuments();
    });
  }

  openActionDialog(actionType: string, user: SellMyPropertyDocumentDto): void {
    if (this.getActionsForUser().includes('edit')) {
      const dialogRef = this.dialog.open(UserActionDialogComponent, {
        width: '30vw',
        height: '30vh',
        data: { actionType, userDto: user }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.refreshDocuments();
        }
      });
    } else {
      this.notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  openEditUploadDialog(actionType: string, sellMyPropertyDocument: SellMyPropertyDocumentDto): void {
    if (this.getActionsForUser().includes('edit')) {
      if (sellMyPropertyDocument != null) {

        const editCurrentListingDocumentDialog = this.dialog.open(ListingDocumentAddEditComponent, {
          data: {
            fields: sellMyPropertyDocument,
            properDetailId: this.selectedListing?.propertyDetail?.id,
          },
          width: '30vw',
          height: '90vh'
        });
        editCurrentListingDocumentDialog.afterClosed().subscribe(result => {
          this.refreshDocuments();
        });
      } else {
        this.notificationService.showErrorMessage('Error', 'No current data selected.');
      }
    } else {
      this.notificationService.showWarningMessage("Insufficient role", "Current logged in user does not have edit permissions or role linked.");
    }
  }

  pageEvent(event: any) {
    this.pageSize = event.pageSize;
    this.pageTotal = this.calculateTotalPages(this.totalItems, this.pageSize);
  }

  pageEventSellDocuments(event: any) {
    this.SellUploadsPageSize = event.pageSize;
    this.calculateTotalPages(this.SellUploadsTotalItems, this.SellUploadsPageSize);
  }

  pageEventSellDocumentPicture(event: any) {
    this.SellUploadsPicturePageSize = event.pageSize;
    this.SellUploadsPictureTotalItems = this.calculateTotalPages(this.SellUploadsTotalItems, this.SellUploadsPicturePageSize);
  }

  pageEventOwnership(event: any): void {
    this.SellUploadsPicturePageSize = event.pageSize;
    this.SellUploadsPictureTotalItems = this.calculateTotalPages(this.SellUploadsTotalItems, this.SellUploadsPicturePageSize);
  }
  pageEventSellDocumentAdditional(event: any) {
    this.AdditionalPageSize = event.pageSize;
    this.SellUploadsAdditionalTotalItems = this.calculateTotalPages(this.SellUploadsAdditionalTotalItems, this.AdditionalPageSize);
  }

  async deleteUploadFile(sellMyPropertyDocument: SellMyPropertyDocumentDto): Promise<void> {
    await this.planningReportService.apiV1PlanningReportArchiveDeleteSellPropertyDocumentsPost({ body: sellMyPropertyDocument }).subscribe({
      error(err) {
        this.notificationService.showErrorMessage("Error", "Error found on archiving document on Listing property Document tab. Please contact your administrator.");
        console.error("Error found on archiving document on Listing property Document tab. Please contact your administrator.");
      },
      next(saved: boolean) {
        if (saved) {
          this.notificationService.showErrorMessage("Success", "Archiving document on Listing property Document tab successful. Please contact your administrator.");
        } else {
          this.notificationService.showErrorMessage("Error", "Error found on archiving document on Listing property Document tab. Please contact your administrator.");
          console.error("Error found on archiving document on Listing property Document tab. Please contact your administrator.");
        }
      },
    });
  }

  private getActionsForUser() {
    if (this.hasRequiredRoleFull()) {
      return ['view', 'edit'];
    } else if (this.hasRequiredRoleView()) {
      return ['view'];
    }
    return [];
  }

  private hasRequiredRoleFull(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesFull.includes(role));
  }

  private hasRequiredRoleView(): boolean {
    return this.currentUserRoles.some(role => this.requiredRolesView.includes(role));
  }

  private getUserDetail() {
    let user = sessionStorage.getItem('user');
    if (user != null) {
      let currentUser = JSON.parse(JSON.parse(user)) as User;

      for (let index = 0; index < currentUser.roles.length; index++) {
        var tokenRoles = currentUser.roles[index];
        this.currentUserRoles.push(tokenRoles);
      }
    } else {
      this.loadCurrentUserRole();
    }
  }

  private loadCurrentUserRole(): void {

    this.userManagementService.apiV1UserManagementGetUserTokenGet().subscribe(roleBasedAccessDto => {
      if (roleBasedAccessDto) {
        this.currentUserRoles = roleBasedAccessDto.roles ?? [];
      }
      else {
        this.notificationService.showErrorMessage("Error", "Could not find the current logged in user.please log out and try again.");
        console.error("Could not find the current logged in user.please log out and try again.");
      }
    });
  }

  private getUserPermissionList(): void {
    this._userPermissionService.getRequiredRoles().subscribe(data => {
      this.requiredRolesFull = data.requiredRolesFull;
      this.requiredRolesView = data.requiredRolesView;
    });
  }

  calculateTotalPages(totalItems: number, pageSize: number): number {
    return Math.ceil(totalItems / pageSize);
  }

  archiveuploadedDocuments(): void {
    this.loading = true;
    this.sellPropertyDocumentsDeleted = this.sellPropertyDocuments.filter(d => d.documentInfo?.isDeleted == true);
    this.dataSourceSellUploads.data = this.sellPropertyDocumentsDeleted.filter(d => d.documentType == sellYourPropertyUploadDocumentTabTypes.RequiredDocument.toString() || d.documentType == sellYourPropertyUploadDocumentTabTypes.PropertyInfoDocument.toString() || d.documentType == sellYourPropertyUploadDocumentTabTypes.AdditionalDocument.toString());

    this.dataSourceSellUploads.sort = this.sort;
    this.dataSourceSellUploads.paginator = this.dataSourceSellUploads.paginator;
    this.SellUploadsTotalItems = this.dataSourceSellUploads.data.length;
    this.SellUploadsPageTotal = this.calculateTotalPages(this.SellUploadsTotalItems, this.SellUploadsPageSize);

    this.dataSourcePicture.data = this.sellPropertyDocumentsDeleted.filter(x => x.documentType == sellYourPropertyUploadDocumentTabTypes.PictureDocument.toString());
    this.dataSourcePicture.sort = this.sort;
    this.dataSourcePicture.paginator = this.dataSourceSellUploads.paginator;
    this.SellUploadsPictureTotalItems = this.dataSourcePicture.data.length;
    this.SellUploadsPageTotal = this.calculateTotalPages(this.SellUploadsPictureTotalItems, this.SellUploadsPageSize);

    this.additionImages = this.sellPropertyDocumentsDeleted;
    this.dataSourceAddition.data = this.additionImages.filter(a => a.documentType == sellYourPropertyUploadDocumentTabTypes.NewAddedAdditionalDocument.toString());
    this.dataSourceAddition.sort = this.sort;
    this.dataSourceAddition.paginator = this.dataSourceSellUploads.paginator;
    this.SellUploadsAdditionalTotalItems = this.dataSourceAddition.data.length;
    this.SellUploadsPageTotal = this.calculateTotalPages(this.SellUploadsAdditionalTotalItems, this.AdditionalPageSize);

    this.loading = false;
  }

  allUploadedDocument(): void {
    this.loading = true;
    this.sellPropertyDocumentsNotDeleted = this.sellPropertyDocuments.filter(d => d.documentInfo?.isDeleted == false);
    this.dataSourceSellUploads.data = this.sellPropertyDocumentsNotDeleted.filter(d => d.documentType == sellYourPropertyUploadDocumentTabTypes.RequiredDocument.toString() || d.documentType == sellYourPropertyUploadDocumentTabTypes.PropertyInfoDocument.toString() || d.documentType == sellYourPropertyUploadDocumentTabTypes.AdditionalDocument.toString());

    this.dataSourceSellUploads.sort = this.sort;
    this.dataSourceSellUploads.paginator = this.dataSourceSellUploads.paginator;
    this.SellUploadsTotalItems = this.dataSourceSellUploads.data.length;
    this.SellUploadsPageTotal = this.calculateTotalPages(this.SellUploadsTotalItems, this.SellUploadsPageSize);

    this.dataSourcePicture.data = this.sellPropertyDocumentsNotDeleted.filter(x => x.documentType == sellYourPropertyUploadDocumentTabTypes.PictureDocument.toString());
    this.dataSourcePicture.sort = this.sort;
    this.dataSourcePicture.paginator = this.dataSourceSellUploads.paginator;
    this.SellUploadsPictureTotalItems = this.dataSourcePicture.data.length;
    this.SellUploadsPageTotal = this.calculateTotalPages(this.SellUploadsPictureTotalItems, this.SellUploadsPageSize);

    this.additionImages = this.sellPropertyDocumentsNotDeleted;
    this.dataSourceAddition.data = this.additionImages.filter(a => a.documentType == sellYourPropertyUploadDocumentTabTypes.NewAddedAdditionalDocument.toString());
    this.dataSourceAddition.sort = this.sort;
    this.dataSourceAddition.paginator = this.dataSourceSellUploads.paginator;
    this.SellUploadsAdditionalTotalItems = this.dataSourceAddition.data.length;
    this.SellUploadsPageTotal = this.calculateTotalPages(this.SellUploadsAdditionalTotalItems, this.AdditionalPageSize);

    this.loading = false;
  }

  getOwnershipReportDataForListing(): void {
    this.loading = true;
    this.planningReportService.apiV1PlanningReportGetReportDocumentsGet({ planningReportId: this.selectedListing.id })
      .pipe(
        catchError((err) =>
          of(this.notificationService.showErrorMessage("Error", "Error getting the Planning reports Ownership."))
        ),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result: ReportDocumentDto[]) => {
        if (result != undefined) {
          this.ownershipDocuments = result;
          this.dataSourceOwnershipReport.data = this.ownershipDocuments.filter(a => a.reportType == "OwnershipAdditionalDocumentReport");
          this.dataSourceOwnershipReport.sort = this.sort;
          this.dataSourceOwnershipReport.paginator = this.dataSourceOwnershipReport.paginator;
          this.OwnershipReportTotalItems = this.dataSourceOwnershipReport.data.length;
          this.OwnershipReportPageTotal = this.calculateTotalPages(this.SellUploadsAdditionalTotalItems, this.AdditionalPageSize);
        }
      });
  }

  async generateOwnershipReportCall(): Promise<void> {
    try {
      this.loading = true;
      let reportGeneratedDone = this.generateOwnershipReport();
      if (await reportGeneratedDone == true) {
        this.notificationService.showSuccessMessage("Done genereated", "Ownership report successfully genereted.")
        this.getOwnershipReportDataForListing();
      } else {
        this.notificationService.showErrorMessage("Error", "Error on saving advert filters")
      }
      this.loading = false;
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert filters because of errors.');
      this.loading = false;
    }
  }

  async generateOwnershipReport(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;
        this._reportGeneratorService.apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet({ listingId: this.selectedListing.id })
          .pipe(
            catchError((err) =>
              of(this.notificationService.showErrorMessage("Error", "Error getting the Planning reports Ownership."))
            ),
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe((result: any) => {
            this.notificationService.showSuccessMessage("Succesful", "Started generated ownership report.");
            resolve(true);
          });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", ex.toString());
        this.loading = false;
        resolve(false);
        reject("Error caught on service call save Advert filters.");
      }
    });
  }

}
