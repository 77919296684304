import { SellMyPropertyDocumentDto } from 'src/app/services/property-matrixV2/models';
import { FileManagementService } from 'src/app/services/property-matrixV2/services';
import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs';

import { FileManagementExtensionService } from '../../services/file-management-extension-service/file-management-extension.service';
import { PropertyListingStateService } from '../../services/sell-your-property/property-listing-state.service';
import { NotificationService } from '../../services/notification-service/notification.service';
import { FileExtended } from '../../interfaces/file-extended';

@Component({
  selector: 'app-file-upload-multiple',
  templateUrl: './file-upload-multiple.component.html',
  styleUrls: ['./file-upload-multiple.component.scss'],
})
export class FileUploadMultipleComponent implements OnInit {

  files: FileExtended[] = [];
  @Input() fileType: string;
  loading: boolean = false;

  constructor(
    private fileManagementService: FileManagementService,
    private _propertyListingStateService: PropertyListingStateService,
    private fileManagementExtensionService: FileManagementExtensionService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void { }

  onDragOver(event: Event): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files = event.dataTransfer.files;
      this.addFiles(files);
    }
  }

  async onFileInput(event: Event): Promise<void> {
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;
    if (files && files.length > 0) {
      this.addFiles(files);
      element.value = '';
    } else {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  deleteFile(fileId: string, index: number): void {
    this.loading = true;
    this.fileManagementService.apiV1FileManagementDeleteFileFileIdDelete({ fileId })
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: () => {
          this.files.splice(index, 1);
          this.notificationService.showSuccessMessage('Success', 'File deleted successfully.');
        },
        error: () => {
          this.notificationService.showErrorMessage('Error', 'File could not be deleted.');
        },
      });
  }

  private addFiles(files: FileList): void {
    let index: number = 0;
    Array.from(files).forEach((file: File) => {
      const FileExtended = file as FileExtended;
      if (!this.files.some((f) => f.name === FileExtended.name)) {
        this.uploadFile(FileExtended, index);
      } else {
        this.notificationService.showWarningMessage('Warning', 'Duplicate files are not allowed.');
      }
      index = index + 1;
    });
  }

  private async uploadFile(file: FileExtended, index: number): Promise<void> {
    try {
      this.loading = true;
      const result = await this.fileManagementExtensionService.uploadFile(file);
      const document: SellMyPropertyDocumentDto = {
        id: result,
        documentInfo: {
          id: result,
          name: file.name,
          extension: file.type
        }
      };
      this._propertyListingStateService.updateDocumentMulti(index, document, this.fileType);
      file.id = result;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        file.url = e.target.result;
        this.files.push(file);
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'File uploaded successfully.');
      };
      reader.readAsDataURL(file);
    } catch (error) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'File could not be uploaded.');
    }
  }
}
