import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ListingGroupStateService } from '../../../../shared/services/listing-group-state-service/listing-group-state.service';
import { MultiplePropertyListingDto, PropertyListingDto } from '../../../../../app/services/property-matrixV2/models';
import { ListingStateService } from '../../../../shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../shared/services/notification-service/notification.service';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent implements OnInit {

  selectedListing: PropertyListingDto;
  selectedListingGroup: MultiplePropertyListingDto;

  taskNumber: number;
  taskName: string;
  listingType: string;
  listingAddress: string | null | undefined;
  createdDate: string | null | undefined;
  isMultiple: boolean = false;
  showReferenceAndGroup: boolean = false;
  showReportOverview: boolean = false;
  showAllocatedNetworkPlanner: boolean = false;
  showConfirmPropertySellerDetail: boolean = false;
  showAdvertFilterCreate: boolean = false;
  showAdvertCreate: boolean = false;
  showDeedsReportAppointed: boolean = false;
  showAdvertGenerator: boolean = false;
  showExportGroup: boolean = false;
  showAllocatePlanners: boolean = false;
  showAdvertSendLink: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listingStateService: ListingStateService,
    private listingGroupStateService: ListingGroupStateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.taskNumber = params['taskNumber'];
      this.taskName = params['taskName'];
      this.listingType = params['listingType'];

      if (this.taskNumber == 1) {
        this.showAllocatePlanners = true;
      }
      if (this.taskNumber == 4 || this.taskNumber == 5 || this.taskNumber == 7 || this.taskNumber == 8) {
        this.showReportOverview = true;
      }
      if (this.taskNumber == 6) {
        this.showAllocatedNetworkPlanner = true;
      }
      if (this.taskNumber == 2) {
        this.showConfirmPropertySellerDetail = true;
      }
      if (this.taskNumber == 3) {
        this.showDeedsReportAppointed = true;
      }
      if (this.taskNumber == 9) {
        this.showAdvertCreate = true;
      }
      if (this.taskNumber == 10) {
        this.showAdvertFilterCreate = true;
      }
      if (this.taskNumber == 11) {
        this.showAdvertGenerator = true;
      }
      if (this.taskNumber == 12) {
        this.showAdvertSendLink = true;
      }
    });

    if (this.listingType === 'single') {
      this.listingStateService.selectedListing$.subscribe((listing: PropertyListingDto) => {
        this.selectedListing = listing;
        this.listingAddress = listing.addressTitle;
        this.createdDate = listing.createdDate;
        this.isMultiple = false;
      });
    }
    else if (this.listingType === 'multiple') {
      this.listingGroupStateService.selectedListingGroup$.subscribe((group: any) => {
        this.selectedListingGroup = group.value;
        this.listingAddress = group.value.groupName;
        this.createdDate = group.value.createdDate;
        this.isMultiple = true;
      });
    }
    else {
      this.notificationService.showErrorMessage('Error', 'Selected listing/group or ID is undefined.');
    }
  }

  goToListingOverview() {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }
}
