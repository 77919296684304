<div class="allocate-planners">
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class=" row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold">
                        Assign Senior Planner
                    </label>
                </div>
                <div class="col-12">
                    <label class="col-12">
                        <mat-select class="col-12 mt-1" [(ngModel)]="selectedSeniorPlanner"
                            (selectionChange)="onSeniorPlannerChange($event)">
                            <mat-option value=null>None</mat-option>
                            <mat-option *ngFor="let item of seniorPlanners" [value]="item.id">
                                {{ item.name + " " + item.surname }}
                            </mat-option>
                        </mat-select>
                    </label>
                </div>
                <div class="col-12 mt-2">
                    <label class="col-12 bold">
                        Assign Junior Planner
                    </label>
                </div>
                <div class="col-12">
                    <label class="col-12">
                        <mat-select class="col-12 mt-1" [(ngModel)]="selectedJuniorPlanner"
                            (selectionChange)="onJuniorPlannerChange($event)">
                            <mat-option value=null>None</mat-option>
                            <mat-option *ngFor="let item of juniorPlanners" [value]="item.id">
                                {{ item.name + " " + item.surname }}
                            </mat-option>
                        </mat-select>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold padding">Select the correct statement</label>
                </div>
                <div class="col-12">
                    <mat-radio-group [(ngModel)]="networkAgentType" class="margin">
                        <div class="radio-container">
                            <span class="radio-button-text padding">
                                <mat-radio-button class="radio" [value]="1">
                                    The property is listed directly by the Agent.
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container padding">
                            <span class="radio-button-text mt-1">
                                <mat-radio-button class="radio" [value]="2">
                                    This property was referred to the Agent by PM
                                </mat-radio-button>
                            </span>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
            <div class=" row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold">
                        Report Selection
                    </label>
                </div>
                <div class="col-12">
                    <label class="col-12">
                        <mat-select class="col-12 mt-1" [(ngModel)]="selectedReportType"
                            (selectionChange)="onReportTypeChange($event)">
                            <mat-option value=null>None</mat-option>
                            <mat-option *ngFor="let item of reportType" [value]="item.type">
                                {{ item.type }}
                            </mat-option>
                        </mat-select>
                    </label>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="savePlanners()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>