import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

import { MultiplePropertyListingDto, PropertyListingDto, TaskListDto, UserDto } from '../../../../../../app/services/property-matrixV2/models';
import { PropertyListingService, UserManagementService } from '../../../../../..//app/services/property-matrixV2/services';
import { ListingStateService } from '../../../../../..//app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from '../../../../../../app/shared/services/notification-service/notification.service';

@Component({
  selector: 'app-deeds-report-appointment',
  templateUrl: './deeds-report-appointment.component.html',
  styleUrls: ['./deeds-report-appointment.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class DeedsReportAppointmentComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;

  DeedsReportAppointedType: any;
  listingType: string;
  deedsReportAppointed: null | string = null;
  currentUser: UserDto;

  loading: boolean = false;
  sections = [
    { key: 1, value: 'Network Conveyancer', checked: true },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private propertyListingService: PropertyListingService,
    private listingStateService: ListingStateService,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService
  ) { }

  ngOnInit(
  ): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.checkRadioButtonChecked();
    this.getCurrentUser();
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  saveDeedsReportAppointed(): void {

    switch (this.DeedsReportAppointedType) {
      case 1: {
        this.deedsReportAppointed = "The Seller choose to make use of Property Matrix team";
        break;
      }
      case 2: {
        this.deedsReportAppointed = "The Seller nominated a external conveyancer";
        break;
      }
      default: {
        this.notificationService.showErrorMessage('Error', 'Please select a statement.');
        return;
      }
    }

    this.selectedListing.networkConveyancer = this.deedsReportAppointed;
    this.propertyListingService.apiV1PropertyListingUpdateNetworkConveyancerToPropertyListingPost({ body: this.selectedListing }).subscribe({
      next: () => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Successfully saved property details.');
        this.listingStateService.setSelectedListing(this.selectedListing);
        this.setPropertyListingTask();
      },
      error: (_error) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not save property details.');
      }
    });
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    this.selectedListing.taskList.forEach(task => {
      if (task.taskNumber.number === 3) {
        if (task.taskStatus.taskStatusNumber != 3) {
          task.user = this.currentUser;
          task.userStatusText = this.DeedsReportAppointedType == 1 ? "Property Matrix (PM) Legal Team" : "External Conveyancer";
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result != null) {
          this.notificationService.showSuccessMessage('Planners Updated', 'Planners have been updated successfully.');
          this.navigateToPlanningReport();
        }
        else {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
    else {
      this.navigateToPlanningReport();
    }
  }

  navigateToPlanningReport(): void {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }

  checkRadioButtonChecked(): void {
    if (this.selectedListing && this.selectedListing.networkConveyancer) {
      if (this.selectedListing.networkConveyancer === "The Seller choose to make use of Property Matrix team") {
        this.DeedsReportAppointedType = 1;
      } else if (this.selectedListing.networkConveyancer === "The Seller nominated a external conveyancer") {
        this.DeedsReportAppointedType = 2;
      } else {
        this.notificationService.showWarningMessage("Warning", "There was no statement selected.")
      }
    }
    if (this.selectedListing && this.selectedListing.isExternalConveyancer != null) {
      this.DeedsReportAppointedType = (this.selectedListing.isExternalConveyancer == true) ? 2 : 1;
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }
}
