<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="title-container">
    <div class="mt-4">
        <h5>Matrix Network</h5>
        <p>All users related to this property</p>
    </div>
</div>
<mat-card class="card-container">
    <div class="users-table-container">
        <mat-table [dataSource]="dataSource" class="task-table" matSort>
            <ng-container matColumnDef="fullName">
                <mat-header-cell *matHeaderCellDef> FULL NAME </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.name + ' ' + user.surname}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="profession">
                <mat-header-cell *matHeaderCellDef> PROFESSION </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.userRoles[0]?.roleName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> EMAIL </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> STATUS </mat-header-cell>
                <mat-cell *matCellDef="let user"> {{user.status}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action" stickyEnd>
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <button mat-button [matMenuTriggerFor]="menu" class="btn-more">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="grid-action-menu">
                        <div mat-menu-item *ngIf="!user.isDeleted">
                            <button mat-menu-item (click)="onEditClick(user)">Edit</button>
                        </div>
                        <div mat-menu-item>
                            <button mat-menu-item (click)="onDeleteClick(user)">{{user.isDeleted ? 'Enable' : 'Delete'}}</button>
                        </div>
                    </mat-menu>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row class="paragraph" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="dataSource.data.length === 0" class="no-records">
            No Users found.
        </div>
    </div>
</mat-card>