import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { GlobalMethods } from '../../../../app/common/global-methods';

@Component({
  selector: 'app-registration-step',
  templateUrl: './registration-step.component.html',
  styleUrls: ['./registration-step.component.scss']
})
export class RegistrationStepComponent implements OnInit {

  userType: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };
      this.userType = params['userType'], navigationExtras;
    });

    GlobalMethods.scrollToTop();
  }

  proceed() {
    this.route.queryParams.subscribe(params => {
      const navigationExtras = {
        queryParams: { ...params }
      };
      this.router.navigate(['/' + this.userType], navigationExtras);
    });
  }
}
