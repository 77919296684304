<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="add-edit-user-dialog" id="add-edit-user-dialog">
    <main class="dialog-container">
        <div>
            <span class="paragraph bold user-dialog-heading">{{titleText}}</span>
            <div class="row-container pt-1 pb-1">
                <span class="paragraph user-dialog-subheading">Invite a new user to join the platform</span>
            </div>
            <form class="user-form" [formGroup]="userDataForm" id="FormEditAddUserForm">
                <div class="row paragraph">
                    <div class="col-12 mt-2">
                        <div class="form-group">
                            <label class="col-12 bold">First Name</label>
                            <input class="col-12 mt-1" type="text" placeholder="John" formControlName="firstName"
                                [readonly]="viewOnly" />
                        </div>
                        <div *ngIf="userDataForm.get('firstName').touched && userDataForm.get('firstName').invalid">
                            <div class="errorText" *ngIf="userDataForm.get('firstName').hasError('required')">
                                First Name is
                                required.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="form-group"></div>
                        <label class="col-12 bold">Last Name</label>
                        <input class="col-12 mt-1" type="text" placeholder="Doe" formControlName="surname"
                            [readonly]="viewOnly" />
                        <div *ngIf="userDataForm.get('surname').touched && userDataForm.get('surname').invalid">
                            <div class="errorText" *ngIf="userDataForm.get('surname').hasError('required')">
                                Last Name is
                                required.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="form-group">
                            <label class="col-12 bold">Email</label>
                            <input class="col-12 mt-1" type="text" placeholder="johndoe@gmail.com"
                                formControlName="email" [readonly]="viewOnly" />
                            <div *ngIf="userDataForm.get('email').touched && userDataForm.get('email').invalid">
                                <div class="errorText" *ngIf="userDataForm.get('email').hasError('required')">
                                    Email is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <label class="col-12 bold">Role</label>
                        <mat-select class="col-12 mt-1" [(value)]="selectedRole" [compareWith]="compareUserRole"
                            [disabled]="viewOnly">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let roleItem of roleList" [value]="roleItem">
                                {{roleItem.name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-12 mt-2">
                        <div class="form-group" *ngIf="selectedRole?.name === 'Extended Profession'">
                            <label class="col-12 bold">Profession</label>
                            <mat-select class="col-12 mt-1" [(value)]="selectedProfession" [disabled]="viewOnly"
                                [compareWith]="compareProfession">
                                <mat-option *ngFor="let professionItem of professionList" [value]="professionItem">
                                    {{professionItem.value}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-12 mt-2 hidden">
                        <label class="col-12 bold">Associated Network Planner (Optional)</label>
                        <input class="col-12 mt-1" type="text" placeholder="Associated Network Planner"
                            formControlName="associatedNetworkPlanner" [readonly]="viewOnly" />
                    </div>
                    <div class="col-12 mt-2">
                        <div class="form-group">
                            <label class="col-12 bold">Password</label>
                            <div class="input-container">
                                <input class="col-12 mt-1" type="{{ showPassword ? 'text' : 'password' }}"
                                    placeholder="Password" formControlName="password" [readonly]="viewOnly" />
                                <mat-icon class="password-icon" (click)="togglePasswordVisibility()">
                                    {{ showPassword ? 'visibility_off' : 'visibility' }}
                                </mat-icon>
                            </div>
                            <div *ngIf="userDataForm.get('password').touched && userDataForm.get('password').invalid">
                                <div class="errorText" *ngIf="userDataForm.get('password').hasError('required')">
                                    Password is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2 hidden">
                        <div class="form-group">
                            <label class="col-12 bold">Mobile (Optional)</label>
                            <input class="col-12 mt-1" type="text" placeholder="Mobile" formControlName="mobileNr" />
                        </div>
                    </div>
                    <div class="col-12 mt-2 hidden">
                        <div class="form-group">
                            <label class="col-12 bold">Metro</label>
                            <mat-select class="col-12 mt-1" [(value)]="selectedMetro" [compareWith]="compareMetro"
                                [disabled]="viewOnly">
                                <mat-option *ngFor="let metroItem of metroList" [value]="metroItem">
                                    {{metroItem.description}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-12 mt-2 ">
                        <div class="row paragraph mt-4">
                            <div class="form-group ">
                                <label class="bold mb-2">
                                    Endorsed this current account?
                                </label>
                                <mat-radio-group formControlName="accountEndorsed">
                                    <mat-radio-button class="mt-1 first-radio" [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button class="mt-1" [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <div
                                    *ngIf="userDataForm.get('accountEndorsed').touched && userDataForm.get('accountEndorsed').invalid">
                                    <div class="errorText"
                                        *ngIf="userDataForm.get('accountEndorsed').hasError('required')">
                                        Account
                                        Endorsed is
                                        required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="button-group mt-3">
                <button class="footer-button background-cloud" (click)="closeDialog()" *ngIf="!viewOnly" type="button">
                    Cancel
                </button>
                <button class="footer-button background-coral" (click)="confirmSave()" *ngIf="!viewOnly" type="button">
                    Confirm
                </button>
                <button class="footer-button background-cloud" (click)="closeDialog()" *ngIf="viewOnly" type="button">
                    Close
                </button>
            </div>
        </div>
    </main>
</div>