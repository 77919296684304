import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provide-lat-lon-dialog',
  templateUrl: './provide-lat-lon-dialog.component.html',
  styleUrls: ['./provide-lat-lon-dialog.component.scss']
})
export class ProvideLatLonDialogComponent implements OnInit {

  latitude: number | null = null;
  longitude: number | null = null;

  constructor(
    public dialogRef: MatDialogRef<ProvideLatLonDialogComponent>
  ) { }

  ngOnInit(): void {

  }
  onSave(): void {
    this.dialogRef.close({ latitude: this.latitude, longitude: this.longitude });
  }
  onCancel(): void {
    this.dialogRef.close();
  }
}
