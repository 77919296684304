<div class="loading" *ngIf="loading == true">Loading&#8230;</div>

<div class="dialog-container">
    <span class="paragraph bold list-dialog-heading">{{titleText}}</span>
    <div class="row-container pt-1 pb-1">
        <span class="paragraph list-dialog-subheading">Professional information</span>
    </div>
    <form class="list-form" [formGroup]="uploadNewDataForm" id="FormEditAddlistUploadForm">
        <div class="row paragraph">
            <div class="col-12 mt-2 hidden">
                <div class="form-group">
                    <label class="col-12 bold">Property Detail ID</label>
                    <input class="col-12 mt-1" type="text" placeholder="Property Detail ID"
                        formControlName="propertyDetailId" />
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="form-group">
                    <label class="col-12 bold">Document ID</label>
                    <input class="col-12 mt-1" type="text" placeholder="Document ID" formControlName="DocumentID"
                        readonly />
                </div>
                <div *ngIf="uploadNewDataForm.get('DocumentID').touched && uploadNewDataForm.get('DocumentID').invalid">
                    <div class="errorText" *ngIf="uploadNewDataForm.get('DocumentID').hasError('required')">
                        Document ID is
                        required.
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="form-group"></div>
                <label class="col-12 bold">DOCUMENT NAME</label>
                <input class="col-12 mt-1" type="text" placeholder="DocumentName" formControlName="DocumentName" />
                <div
                    *ngIf="uploadNewDataForm.get('DocumentName').touched && uploadNewDataForm.get('DocumentName').invalid">
                    <div class="errorText" *ngIf="uploadNewDataForm.get('DocumentName').hasError('required')">
                        Document Name
                        required.
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="form-group">
                    <label class="col-12 bold">Category</label>
                    <mat-select class="col-12 mt-1" [(value)]="selectedCategory" [compareWith]="compareCategory">
                        <mat-option *ngFor="let CategoryItem of CategoryList" [value]="CategoryItem">
                            {{CategoryItem}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="form-group">
                    <label class="col-12 bold">UPLOADED BY</label>
                    <input class="col-12 mt-1" type="text" placeholder="uploadedBy" formControlName="uploadedBy"
                        readonly />
                    <div
                        *ngIf="uploadNewDataForm.get('uploadedBy').touched && uploadNewDataForm.get('uploadedBy').invalid">
                        <div class="errorText" *ngIf="uploadNewDataForm.get('uploadedBy').hasError('required')">
                            uploaded By is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="form-group">
                    <label class="col-12 bold">UPLOADED DATE</label>
                    <input class="col-12 mt-1" type="text" placeholder="uploadedDate" formControlName="uploadedDate"
                        readonly />
                    <div
                        *ngIf="uploadNewDataForm.get('uploadedDate').touched && uploadNewDataForm.get('uploadedDate').invalid">
                        <div class="errorText" *ngIf="uploadNewDataForm.get('uploadedDate').hasError('required')">
                            uploaded Date is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-2">
                <div class="form-group">
                    <app-file-upload-multiple [fileType]="sellYourPropertyFiletypesDefault"></app-file-upload-multiple>
                </div>
            </div>
        </div>
    </form>
    <div class="button-group mt-3">
        <button class="footer-button background-cloud" (click)="closeDialog()" type="button">
            Close
        </button>
        <button class="footer-button background-coral" (click)="confirmSave()" type="button">
            Save
        </button>
    </div>
</div>