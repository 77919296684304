<main>
    <div class="container pt-3 pb-3">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-sm-8 col-xs-6">
                <mat-card>
                    <div class="d-flex justify-content-center align-items-center pb-2">
                        <div class="float-left">
                            <button class="center-content back-button" (click)="goToListingOverview()">
                                <mat-icon class="circle-button">arrow_back</mat-icon>
                            </button>
                        </div>
                        <span class="paragraph report-title">
                            {{ taskName ?? 'No task name available' }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center pb-2">
                        <span class="paragraph report-address">
                            {{ listingAddress ?? 'No address available' }}
                        </span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-sm-8 col-xs-6">
                <app-reference-and-group *ngIf="showReferenceAndGroup" [selectedListing]="selectedListing"
                    [selectedListingGroup]="selectedListingGroup" [isMultiple]="isMultiple" [createdDate]="createdDate">
                </app-reference-and-group>
                <app-report-overview *ngIf="showReportOverview" [selectedListing]="selectedListing"
                    [selectedListingGroup]="selectedListingGroup" [isMultiple]="isMultiple">
                </app-report-overview>
                <app-allocate-network-planner *ngIf="showAllocatedNetworkPlanner" [selectedListing]="selectedListing"
                    [selectedListingGroup]="selectedListingGroup"></app-allocate-network-planner>
                <app-confirm-property-seller-details *ngIf="showConfirmPropertySellerDetail"
                    [selectedListing]="selectedListing" [listingType]="listingType"
                    [selectedListingGroup]="selectedListingGroup"></app-confirm-property-seller-details>
                <app-deeds-report-appointment *ngIf="showDeedsReportAppointed" [selectedListing]="selectedListing"
                    [selectedListingGroup]="selectedListingGroup"></app-deeds-report-appointment>
                <app-advert-filter-create *ngIf="showAdvertFilterCreate" [selectedListing]="selectedListing"
                    [listingType]="listingType"
                    [selectedListingGroup]="selectedListingGroup"></app-advert-filter-create>
                <app-allocate-advert-create *ngIf="showAdvertCreate" [selectedListing]="selectedListing"
                    [listingType]="listingType"
                    [selectedListingGroup]="selectedListingGroup"></app-allocate-advert-create>
                <app-export-report *ngIf="showExportGroup" [selectedListing]="selectedListing"
                    [listingType]="listingType" [selectedListingGroup]="selectedListingGroup"></app-export-report>
                <app-allocate-planners *ngIf="showAllocatePlanners" [selectedListing]="selectedListing" [listingType]="listingType"
                    [selectedListingGroup]="selectedListingGroup"></app-allocate-planners>
                <app-advert-generate *ngIf="showAdvertGenerator" [selectedListing]="selectedListing"
                    [listingType]="listingType" [selectedListingGroup]="selectedListingGroup"></app-advert-generate>
                <app-advert-send-link *ngIf="showAdvertSendLink" [selectedListing]="selectedListing"
                    [listingType]="listingType" [selectedListingGroup]="selectedListingGroup"></app-advert-send-link>
            </div>
        </div>
    </div>
</main>