import { Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface ColumnConfig {
  columnDef: string;
  header: string;
  isBoolean?: boolean;
  isAction?: boolean;
  isDisabled?: boolean;
  isFilterable?: boolean;
  headerAlign?: 'left' | 'center' | 'right';
  cellAlign?: 'left' | 'center' | 'right';
  width?: number;
  headerButton?: {
    icon: string;
    action: () => void;
    color?: string;
  };
}

export interface ActionOption {
  label: string;
  action: (user: any) => void;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() columns: ColumnConfig[];
  @Input() actionOptions: ActionOption[];

  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  filterValue: string = '';

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  selectedFilters: { [key: string]: any } = {};

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(filterValue: string): void {
    this.filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = this.filterValue;
  }

  getUniqueColumnValues(columnDef: string): any[] {
    const columnValues = this.data.map(item => item[columnDef]);
    const uniqueValues = Array.from(new Set(columnValues.filter(value => value !== '')));
    return uniqueValues;
  }

  toggleFilter(event: MatCheckboxChange, columnDef: string, filterValue: any): void {
    if (event.checked) {
      this.selectedFilters[columnDef] = filterValue;
      this.dataSource.filter = `${filterValue}`;
    } else {
      if (this.selectedFilters[columnDef] === filterValue) {
        delete this.selectedFilters[columnDef];
        this.dataSource.filter = '';
      }
    }
    this.cdr.detectChanges();
  }

  isFilterSelected(columnDef: string, filterValue: any): boolean {
    return this.selectedFilters[columnDef] === filterValue;
  }
}