import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AreaAvailableDto } from 'src/app/services/property-matrixV2/models';
import { KeyValuePair } from 'src/app/common/global-models/key-value-pair';

@Component({
  selector: 'app-area-available',
  templateUrl: './area-available.component.html',
  styleUrls: ['./area-available.component.scss', '../../../../../../../../css/2-modules/_admin-portal.scss']
})

export class AreaAvailableComponent implements OnInit {

  @Input() planningReportId: string;
  @Output() areaAvailableChange = new EventEmitter<AreaAvailableDto>();
  @Output() potentialUsablePropertyChange = new EventEmitter<number>();

  areaAvailableData: AreaAvailableDto = {};
  previousMetricUnit: string | null = null;

  metricUnits: KeyValuePair[] = [
    { key: 'm²', value: 'Square Meters (m²)' },
    { key: 'ha', value: 'Hectares (ha)' },
  ];

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    this.loadAreaAvailable();
  }

  async loadAreaAvailable(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetAreaAvailableGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response: any) => {
        this.areaAvailableData = response;
        if (this.areaAvailableData.metricUnit) {
          this.previousMetricUnit = this.areaAvailableData.metricUnit;
        } else {
          this.areaAvailableData.metricUnit = 'ha';
          this.previousMetricUnit = 'ha';
        }
        this.calculatePotentialUsableSpace();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load area available data.');
      }
    });
  }

  onMetricUnitChange(newUnit: string): void {
    this.areaAvailableData.propertySize = this.convertArea(this.areaAvailableData.propertySize, this.previousMetricUnit, newUnit);
    this.areaAvailableData.unusableSpace = this.convertArea(this.areaAvailableData.unusableSpace, this.previousMetricUnit, newUnit);
    this.previousMetricUnit = newUnit;
    this.areaAvailableData.metricUnit = newUnit;
    this.calculatePotentialUsableSpace();
  }

  onPropertySizeChange(): void {
    this.calculatePotentialUsableSpace();
  }

  onUnusableSpaceChange(): void {
    this.calculatePotentialUsableSpace();
  }

  private calculatePotentialUsableSpace(): void {
    let potentialUsableSpace = this.areaAvailableData.propertySize - this.areaAvailableData.unusableSpace;

    if (potentialUsableSpace < 0) {
      potentialUsableSpace = 0;
    }

    this.areaAvailableData.potentialUsableSpace = this.roundToFixed(potentialUsableSpace, 2);

    if (this.areaAvailableData.metricUnit === 'ha') {
      const potentialUsableSpaceInHectares = this.convertArea(this.areaAvailableData.potentialUsableSpace, this.areaAvailableData.metricUnit, 'ha');
      this.areaAvailableData.potentialUsableSpace = this.roundToFixed(potentialUsableSpaceInHectares, 2);
    }

    if (this.areaAvailableData.metricUnit === 'm²') {
      const potentialUsableSpaceInSquareMeters = this.convertArea(this.areaAvailableData.potentialUsableSpace, this.areaAvailableData.metricUnit, 'm²');
      this.areaAvailableData.potentialUsableSpace = this.roundToFixed(potentialUsableSpaceInSquareMeters, 2);
    }

    this.emitChanges();
  }

  private roundToFixed(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals);
    return Math.floor(value * factor) / factor;
  }

  private convertArea(area: number, fromUnit: string, toUnit: string): number {
    if (fromUnit === 'ha' && toUnit === 'm²') {
      return area * 10000;
    } else if (fromUnit === 'm²' && toUnit === 'ha') {
      return area / 10000;
    } else {
      return area;
    }
  }

  emitChanges(): void {
    this.areaAvailableChange.emit(this.areaAvailableData);
    const potentialUsableSpaceInHectares = this.convertArea(this.areaAvailableData.potentialUsableSpace, this.areaAvailableData.metricUnit, 'ha');
    this.potentialUsablePropertyChange.emit(this.roundToFixed(potentialUsableSpaceInHectares, 4));
  }
}
