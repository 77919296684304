<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div id="advert-filter-create" class="advert-filter-create">
    <div>
        <div class="row">
            <div class="col-12 pull-right">
                <button class="footer-button background-coral padding display-right hidden"
                    (click)="openLookupAddEditAdvertsDialog()">Add</button>
            </div>
        </div>
        <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
            <div class="col-12 heading-2">
                <div class="row">
                    <div class="col-11">{{ section.value }}</div>
                    <div class="col-1 collapse-expand">
                        <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                            <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                            <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="col-12 mt-1" *ngFor="let location of advertLocationList; let i = index">
                            <mat-checkbox [(ngModel)]="location.isChecked">
                                {{ location.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="col-12 mt-1" *ngFor="let section of advertSectorList; let i = index">
                            <mat-checkbox [(ngModel)]="section.isChecked" (change)="onCheckboxChange(section)">
                                {{ section.description}}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12 mt-1" *ngFor="let type of advertTypesList; let i = index">
                        <mat-checkbox [(ngModel)]="type.isChecked">
                            {{ type.description}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 4" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Seller asking price. Excluding VAT and commission.</label>
                                <input class="col-12 mt-1 riskscape-data coral-text" type="text" placeholder="R50 000"
                                    [(ngModel)]="sellerAskingPriceExcludeVATcommission" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 5" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <span><span class="bold">S.G erf extent</span> (sqm for smaller as 1ha; hectares for 1ha
                                    and bigger)</span>
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <input class="col-12 mt-1 riskscape-data coral-text" type="text" placeholder="125 000m"
                                    [(ngModel)]="SgErfExtent" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="saveData()" class="footer-button background-coral">Save</button>
            <button (click)="saveConfirmDetails()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>