<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="deeds-report-appointment" id="deeds-report-appointment">
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="!section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold padding">Select the correct statement</label>
                </div>
                <div class="col-12">
                    <mat-radio-group [(ngModel)]="DeedsReportAppointedType" class="margin">
                        <div class="radio-container">
                            <span class="radio-button-text padding">
                                <mat-radio-button class="radio" [value]="1">
                                    The Seller choose to make use of the Property Matrix team.
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container padding">
                            <span class="radio-button-text mt-1">
                                <mat-radio-button class="radio" [value]="2">
                                    The Seller nominated an external conveyancer.
                                </mat-radio-button>
                            </span>
                        </div>
                    </mat-radio-group>
                </div>
                <div class="col-12">
                    <mat-checkbox [(ngModel)]="doNotPublishPropertyUnderDevelopment"
                        class="mat-checkbox padding paragraph">
                        I have ordered the Deeds Report <a> If applicable</a>
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="saveDeedsReportAppointed()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>