import { FileManagementExtensionService } from 'src/app/shared/services/file-management-extension-service/file-management-extension.service';
import { PropertyListingStateService } from 'src/app/shared/services/sell-your-property/property-listing-state.service';
import { sellYourPropertyUploadDocumentTabTypes } from 'src/app/shared/interfaces/sell-your-property-filetypes';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { SellMyPropertyDocumentDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AdditionalInformationDialogComponent } from './additional-information-dialog/additional-information-dialog.component';

@Component({
  selector: 'app-listing-upload-documents',
  templateUrl: './listing-upload-documents.component.html',
  styleUrls: ['./listing-upload-documents.component.scss']
})
export class ListingUploadDocumentsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();
  @Output() documentsSubmitted = new EventEmitter<void>();

  documents = [];
  additionalDocuments = [];
  pictureDocuments = [];
  propertyDocuments = [];

  sellYourPropertyFiletypesAddition = sellYourPropertyUploadDocumentTabTypes.AdditionalDocument;
  sellYourPropertyFiletypesPicture = sellYourPropertyUploadDocumentTabTypes.PictureDocument;
  sellYourPropertyFiletypesProperty = sellYourPropertyUploadDocumentTabTypes.PropertyInfoDocument;

  constructor(
    public _dialog: MatDialog,
    private _notificationService: NotificationService,
    private _propertyListingStateService: PropertyListingStateService,
    private _fileManagementExtensionService: FileManagementExtensionService
  ) { }

  ngOnInit(): void {
    this.updateDocumentList();
    this._propertyListingStateService.formState$.subscribe(() => {
      this.updateDocumentList();
    });
  }

  updateDocumentList(): void {
    this.documents = [
      { description: 'Instruction Letter', required: true },
      { description: 'Sole and exclusive mandate', required: true },
      { description: 'SIF: Site Information Form', required: true },
    ];
    this.additionalDocuments = [];
    this.pictureDocuments = [];
    this.propertyDocuments = [];
  }

  isFileUploaded(index: number): boolean {
    const state = this._propertyListingStateService.getFormState();
    return !!state.documents[index];
  }

  onFileSelected(event: any, index: number): void {
    const file = event.target.files[0];
    if (file) {
      this.uploadFile(file, index);
    }
  }

  async uploadFile(file: File, index: number): Promise<void> {
    try {
      const result = await this._fileManagementExtensionService.uploadFile(file);
      const document: SellMyPropertyDocumentDto = {
        id: result,
        documentInfo: {
          id: result,
          name: file.name,
          extension: file.type
        }
      };
      this._propertyListingStateService.updateDocument(index, document);
    } catch (_error) {
      this._notificationService.showErrorMessage('Error', 'An error occurred while uploading the file.');
    }
  }

  onRemoveFile(index: number, event: Event): void {
    event.stopPropagation();
    this._propertyListingStateService.removeDocument(index);
  }

  downloadFile(index: number, event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const document = this._propertyListingStateService.getFormState().documents[index];
    if (document) {
      this._fileManagementExtensionService.getFileDownloadUrl(document.id).subscribe({
        next: (blobUrl: string) => {
          if (blobUrl) {
            window.open(blobUrl, '_blank');
          } else {
            this._notificationService.showErrorMessage('Error', 'Could not retrieve file URL.');
          }
        },
        error: (_error: any) => {
          this._notificationService.showErrorMessage('Error', 'An error occurred while retrieving the file.');
        }
      });
    }
  }

  allRequiredFilesUploaded(): boolean {
    return this.documents
      .filter(doc => doc.required)
      .every((_, index) => this.isFileUploaded(index));
  }

  openAdditionalInformationDialog(event: Event): void {
    event.preventDefault();
    this._dialog.open(AdditionalInformationDialogComponent, {
      width: '60vw'
    });
  }

  onSubmit(): void {
    if (this.allRequiredFilesUploaded()) {
      this.pageNumberEvent.emit(3);
      this.formSubmittedEvent.emit();
      this.documentsSubmitted.emit();
    }
  }
}
