<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="advert-generate" id="advert-generate">
    <div class="container">
        <mat-card class="mb-3 tab-items">
            <mat-tab-group class="report-tab-group" [selectedIndex]="reportItemTabNumber - 1"
                (selectedIndexChange)="navigateToReportItemTab($event + 1)">
                <mat-tab *ngFor="let item of tabObject" [disabled]="item.isDisabled">
                    <ng-template mat-tab-label class="bold">
                        <strong class="tab-title bold">
                            {{ item.value }}
                        </strong>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
            <div class="floating-card pull-right">
                <button type="button" class="icon-button" matTooltip="Key">
                    <mat-icon class="material-icons-outlined" (click)="openReportKeyDialog1()">info</mat-icon>
                </button>
                <button type="button" class="icon-button" matTooltip="Save" (click)="saveAdvertEntirely1()">
                    <mat-icon class="material-icons-outlined">save</mat-icon>
                </button>
            </div>
        </mat-card>
    </div>

    <div *ngIf="reportItemTabNumber === 1" class="col-12 pb-2 padding-left-right">
        <app-advert-generate-developed [selectedListing]="selectedListing" [listingType]="listingType"
            [selectedListingGroup]="selectedListingGroup" [sectorType]="developed"
            [doNotPublishPropertyUnderDevelopment]="doNotPublishPropertyUnderDevelopment"></app-advert-generate-developed>
    </div>

    <div *ngIf="reportItemTabNumber === 2" class="col-12 pb-2 padding-left-right">
        <app-advert-generate-developable [selectedListing]="selectedListing" [listingType]="listingType"
            [selectedListingGroup]="selectedListingGroup" [sectorType]="developable"
            [doNotPublishPropertyUnderDevelopable]="doNotPublishPropertyUnderDevelopable"></app-advert-generate-developable>
    </div>

    <div *ngIf="reportItemTabNumber === 3" class="col-12 pb-2 padding-left-right">
        <app-advert-publish [selectedListing]="selectedListing" [listingType]="listingType"
            [selectedListingGroup]="selectedListingGroup" [sectorType]="publish"
            [advertCreatorFiltersDto]="advertCreatorFiltersDto"></app-advert-publish>
    </div>
</div>