import { PropertyListingStateService } from 'src/app/shared/services/sell-your-property/property-listing-state.service';
import { AskingPriceConfidenceReasonDto, WishToSellDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LookupService, MapsService } from 'src/app/services/property-matrixV2/services';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';

import { JointPropertiesAreOwnedDto } from './../../../../services/property-matrixV2/models/joint-properties-are-owned-dto';
import { JointPropertiesDialogComponent } from './joint-properties-dialog/joint-properties-dialog.component';

@Component({
  selector: 'app-listing-property-details',
  templateUrl: './listing-property-details.component.html',
  styleUrls: ['./listing-property-details.component.scss', '../../../../../css/2-modules/_forms.scss']
})
export class ListingPropertyDetailsComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() formSubmittedEvent = new EventEmitter<void>();

  propertyDetailsForm: FormGroup;

  selectedWishToSell: WishToSellDto;
  wishToSellOptions: WishToSellDto[] = [];
  selectedJointOwnershipType: JointPropertiesAreOwnedDto;
  jointOwnershipTypes: JointPropertiesAreOwnedDto[] = [];
  askingPriceConfidenceReasons: AskingPriceConfidenceReasonDto[] = [];

  loading: boolean = false;

  cityTown: string | null = null;
  erfPortion: string | null = null;
  unitNumber: string | null = null;
  suburbName: string | null = null;
  propertyKey: string | null = null;
  shortAddress: string | null = null;
  cadastreImageUrl: string | null = null;

  selectDetails: number | null = null;
  selectBoundary: number | null = null;

  attemptCount: number = 0;
  maxAttempts: number = 3;
  showConfirmation: boolean = false;
  pendingPropertyIdentification: any;

  constructor(
    public _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _mapsService: MapsService,
    private _lookupService: LookupService,
    private _notificationService: NotificationService,
    private _propertyListingStateService: PropertyListingStateService,
  ) { }

  ngOnInit(): void {
    this.fetchWishToSellOptions();
    this.fetchJointOwnershipTypeOptions();
    this.fetchAskingPriceConfidenceReasons();

    this.propertyDetailsForm = this._formBuilder.group({
      wishToSell: [null, Validators.required],
      jointProperties: [null],
      agreeWithPropertyBoundary: [null, Validators.required],
      propertyBoundaryConcern: [null],
      confirmCorrectAddressDetails: [null, Validators.required],
      propertyAddress: [null],
      propertyDescription: [null],
      askingPrice: [null, [Validators.required]],
      isAskingPriceMarketRelated: new FormControl(null, [Validators.required, CustomValidators.mustBeTrue()]),
      hasInformedSellerOfExpenses: new FormControl(null, [Validators.required, CustomValidators.mustBeTrue()]),
      askingPriceConfidenceReasons: new FormArray([], CustomValidators.minSelectedCheckboxes(1)),
      otherAskingPriceConfidenceReason: [null],
      riskscapeInfo: this._formBuilder.group({
        addressId: [null],
        neighbourhoodId: [null],
        sectionalTitleUnitNumber: [null],
        subplaceId: [null],
        surveyorGeneralKey: [null],
      }),
      riskscapeAddress: this._formBuilder.group({
        data: [null]
      }),
      riskscapePositionLookup: this._formBuilder.group({
        data: [null]
      }),
      riskscapePropertyDetails: this._formBuilder.group({
        data: [null]
      }),
      cadastreId: [null]
    });

    this._propertyListingStateService.formState$.subscribe((formState) => {
      this.selectedWishToSell = formState?.propertyDetails?.wishToSell;
      this.selectedJointOwnershipType = formState?.propertyDetails?.jointProperties;
    });

    this.propertyDetailsForm.get('wishToSell').valueChanges.subscribe(value => {
      if (value?.intValue === 3) {
        this.propertyDetailsForm.get('jointProperties').setValidators([Validators.required]);
      } else {
        this.propertyDetailsForm.get('jointProperties').clearValidators();
      }
      this.propertyDetailsForm.updateValueAndValidity();
    });

    this.propertyDetailsForm.get('agreeWithPropertyBoundary').valueChanges.subscribe(value => {
      if (value === false) {
        this.propertyDetailsForm.get('propertyBoundaryConcern').setValidators([Validators.required, Validators.maxLength(250)]);
      } else {
        this.propertyDetailsForm.get('propertyBoundaryConcern').clearValidators();
      }
      this.propertyDetailsForm.updateValueAndValidity();
    });

    this.propertyDetailsForm.get('confirmCorrectAddressDetails').valueChanges.subscribe(value => {
      if (value === false) {
        this.propertyDetailsForm.get('propertyAddress').setValidators([Validators.required, Validators.maxLength(250)]);
        this.propertyDetailsForm.get('propertyDescription').setValidators([Validators.required, Validators.maxLength(250)]);
      } else {
        this.propertyDetailsForm.get('propertyAddress').clearValidators();
        this.propertyDetailsForm.get('propertyDescription').clearValidators();
      }
      this.propertyDetailsForm.updateValueAndValidity();
    });

    this.propertyDetailsForm.get('askingPriceConfidenceReasons').valueChanges.subscribe((value: boolean[]) => {
      const otherReasonControl = this.propertyDetailsForm.get('otherAskingPriceConfidenceReason');
    
      if (this.isOtherConfidenceReasonChecked()) {
        otherReasonControl.setValidators([Validators.required, Validators.maxLength(250)]);
      } else {
        otherReasonControl.clearValidators();
        otherReasonControl.reset();
      }
      otherReasonControl.updateValueAndValidity();
    });
  }

  onWishToSellChange(event: MatRadioChange): void {
    this.selectedWishToSell = event.value;
  }

  onJointOwnershipTypeChange(event: MatRadioChange): void {
    this.selectedJointOwnershipType = event.value;
  }

  openDialog(option: any): void {
    if (option.intValue === 2) {
      this._dialog.open(JointPropertiesDialogComponent, {
        width: '60vw'
      });
    }
  }

  handleSelectedPropertyChange(propertyIdentification: any): void {
    if (this.attemptCount >= this.maxAttempts) {
      this._notificationService.showErrorMessage(
        'Limit Reached',
        'You have reached the maximum number of attempts allowed.'
      );
      return;
    }

    // Save the pending property and show the confirmation UI
    this.pendingPropertyIdentification = propertyIdentification;
    this.showConfirmation = true;
  }

  confirmSelection(): void {
    if (this.attemptCount < this.maxAttempts) {
      this.attemptCount++;

      const propertyIdentification = this.pendingPropertyIdentification;
      this.showConfirmation = false;
      this.loading = true;
      this.propertyKey = propertyIdentification.property_key;
      this.erfPortion = propertyIdentification.erf_number;
      this.unitNumber = propertyIdentification.unit_number;
      this.setRiskscapeInfo(propertyIdentification);
      this.getAddressDetails(propertyIdentification.address_id);
      this.getPropertyDetailsFromLatLon(
        propertyIdentification.position.lat,
        propertyIdentification.position.lon
      );
      this.getPropertyDetails(propertyIdentification.property_key, propertyIdentification.unit_number);
      this.getRiskscapePropertyImage(propertyIdentification.property_key);
    }
  }

  cancelSelection(): void {
    this.showConfirmation = false;
    this.pendingPropertyIdentification = null;
  }

  private setRiskscapeInfo(propertyIdentification: any) {
    const riskscapeInfoGroup = this.propertyDetailsForm.get('riskscapeInfo') as FormGroup;
    riskscapeInfoGroup.get('addressId')?.setValue(propertyIdentification.address_id);
    riskscapeInfoGroup.get('neighbourhoodId')?.setValue(propertyIdentification.neighbourhood_id);
    riskscapeInfoGroup.get('sectionalTitleUnitNumber')?.setValue(propertyIdentification.unit_number);
    riskscapeInfoGroup.get('subplaceId')?.setValue(propertyIdentification.subplace_id);
    riskscapeInfoGroup.get('surveyorGeneralKey')?.setValue(propertyIdentification.property_key);
  }

  private getAddressDetails(addressId: string) {
    this._mapsService.apiV1MapsGetAddressDetailsGet$Response({
      addressId: addressId
    }).subscribe({
      next: (response: any) => {
        const riskscapeAddressGroup = this.propertyDetailsForm.get('riskscapeAddress') as FormGroup;
        riskscapeAddressGroup.get('data')?.setValue(response.body);
        this.shortAddress = response.body.short_address;
        this.suburbName = response.body.suburb;
        this.cityTown = response.body.city_town;
        this.propertyDetailsForm.patchValue({
          propertyAddress: response.body.short_address,
          propertyDescription: response.body.full_address,
        });
      },
      error: (_error) => {
        this.loading = false;
        this._notificationService.showErrorMessage('Error', 'Could not fetch property details. Please try again.');
      }
    });
  }

  private getPropertyDetailsFromLatLon(lat: string, lon: string) {
    this._mapsService.apiV1MapsGetPropertyDetailsFromLatLonGet$Response({
      lat: lat,
      lon: lon
    }).subscribe({
      next: (response: any) => {
        const riskscapePositionLookupGroup = this.propertyDetailsForm.get('riskscapePositionLookup') as FormGroup;
        riskscapePositionLookupGroup.get('data')?.setValue(response.body);
      },
      error: (_error: any) => {
        this.loading = false;
        this._notificationService.showErrorMessage('Error', 'Could not find address.');
      }
    });
  }

  private getPropertyDetails(propertyKey: string, unitNumber: string) {
    this._mapsService.apiV1MapsGetPropertyDetailsGet$Response({
      propertyKey: propertyKey,
      unitNumber: unitNumber
    }).subscribe({
      next: (response: any) => {
        const riskscapePropertyDetailsGroup = this.propertyDetailsForm.get('riskscapePropertyDetails') as FormGroup;
        riskscapePropertyDetailsGroup.get('data')?.setValue(response.body);
      },
      complete: () => {
        this.loading = false;
      },
      error: (_error: any) => {
        this.loading = false;
        this._notificationService.showErrorMessage('Error', 'Could not load property details.');
      }
    });
  }

  private getRiskscapePropertyImage(propertyKey: string) {
    this._mapsService.apiV1MapsGetDecodedRiskscapePropertyImageGet$Response({
      propertyKey: propertyKey
    }).subscribe({
      next: (response: any) => {
        this.cadastreImageUrl = response.body.imageUrl;
        this.propertyDetailsForm.patchValue({
          cadastreId: response.body.imageId
        });
      },
      error: (_error) => {
        this.loading = false;
        this._notificationService.showErrorMessage('Error', 'Could not fetch cadastre image. Please try again.');
      }
    });
  }

  confirmPropertyBoundary(value: number) {
    this.selectBoundary = value;
    if (value === 3) {
      this.propertyDetailsForm.patchValue({
        agreeWithPropertyBoundary: true
      });
    } else {
      this.propertyDetailsForm.patchValue({
        agreeWithPropertyBoundary: false
      });
    }
  }

  confirmPropertyDetails(value: number) {
    this.selectDetails = value;
    if (value === 3) {
      this.propertyDetailsForm.patchValue({
        confirmCorrectAddressDetails: true
      });
    } else {
      this.propertyDetailsForm.patchValue({
        confirmCorrectAddressDetails: false
      });
    }
  }

  private fetchWishToSellOptions(): void {
    this._lookupService.apiV1LookupGetWishToSellOptionsGet().subscribe({
      next: (response: WishToSellDto[]) => {
        this.wishToSellOptions = response.sort((a, b) => a.intValue - b.intValue);
      }
    });
  }

  private fetchJointOwnershipTypeOptions(): void {
    this._lookupService.apiV1LookupGetJointOwnershipTypeOptionsGet().subscribe({
      next: (response: JointPropertiesAreOwnedDto[]) => {
        this.jointOwnershipTypes = response.sort((a, b) => a.intValue - b.intValue);
      }
    });
  }

  private fetchAskingPriceConfidenceReasons(): void {
    this._lookupService.apiV1LookupGetAskingPriceConfidenceReasonsGet().subscribe({
      next: (response: AskingPriceConfidenceReasonDto[]) => {
        this.askingPriceConfidenceReasons = response.sort((a, b) => a.intValue - b.intValue);
      },
      complete: () => {
        this.addAskingPriceConfidenceReasonCheckboxes();
      }
    });
  }

  private addAskingPriceConfidenceReasonCheckboxes(): void {
    this.askingPriceConfidenceReasons.forEach(() => {
      const control = new FormControl(false);
      (this.propertyDetailsForm.controls.askingPriceConfidenceReasons as FormArray).push(control);
    });
  }

  isOtherConfidenceReasonChecked(): boolean {
    const confidenceReasonsArray = this.propertyDetailsForm.controls.askingPriceConfidenceReasons as FormArray;
  
    if (!Array.isArray(this.askingPriceConfidenceReasons) || this.askingPriceConfidenceReasons.length === 0) {
      return false;
    }  
    const otherConfidenceReasonIndex = this.askingPriceConfidenceReasons.findIndex(
      reason => reason?.value === 'Other'
    );  
    if (otherConfidenceReasonIndex === -1 || otherConfidenceReasonIndex >= confidenceReasonsArray.length) {
      return false;
    }  
    return !!confidenceReasonsArray.at(otherConfidenceReasonIndex)?.value;
  }

  get agreeWithPropertyBoundary(): boolean {
    return this.propertyDetailsForm.get('agreeWithPropertyBoundary').value;
  }

  get confirmCorrectAddressDetails(): boolean {
    return this.propertyDetailsForm.get('confirmCorrectAddressDetails').value;
  }

  onSubmit(): void {
    if (this.propertyDetailsForm.valid) {

      const selectedAskingPriceConfidenceReasons = this.propertyDetailsForm.value.askingPriceConfidenceReasons
        .map((checked: boolean, i: number) => checked ? this.askingPriceConfidenceReasons[i] : null)
        .filter((askingPriceConfidenceReason: AskingPriceConfidenceReasonDto | null) => askingPriceConfidenceReason !== null);

      const formValue: any = {
        ...this.propertyDetailsForm.value,
        wishToSell: this.selectedWishToSell,
        jointProperties: this.selectedJointOwnershipType || null,
        askingPriceConfidenceReasons: selectedAskingPriceConfidenceReasons
      };

      this._propertyListingStateService.updatePropertyDetails(formValue);
      this.pageNumberEvent.emit(2);
      this.formSubmittedEvent.emit();
    } else {
      this._notificationService.showErrorMessage('Error', 'Could not submit property details form. Please check the form and try again.');
    }
  }
}
