import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AdvertGeneratorDto } from '../../../services/property-matrixV2/models';
import { AdvertService } from '../../../services/property-matrixV2/services';

@Injectable({
  providedIn: 'root'
})
export class AdvertStateService {

  private selectedAdvertGenerateFormState = new BehaviorSubject<AdvertGeneratorDto | undefined>(this.loadInitialGeneratedAdvert());
  selectedAdvertGenerate$ = this.selectedAdvertGenerateFormState.asObservable();

  constructor(private advertService: AdvertService) { }

  setSelectedAdvertGeneratorReport(advertGeneratorDto: AdvertGeneratorDto): void {
    sessionStorage.setItem('setSelectedAdvertGeneratorReport', JSON.stringify(advertGeneratorDto));
    this.selectedAdvertGenerateFormState.next(advertGeneratorDto);
  }

  clearSelectedAdvertGeneratorReport(): void {
    sessionStorage.removeItem('setSelectedAdvertGeneratorReport');
    this.selectedAdvertGenerateFormState.next(undefined);
  }

  loadAdvertGeneratorReportById(advertGeneratorId: string): void {
    this.advertService.apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet({
      advertGeneratorId: advertGeneratorId
    }).subscribe({
      next: (advertGeneratorItem: AdvertGeneratorDto) => {
        this.setSelectedAdvertGeneratorReport(advertGeneratorItem);
      },
      error: (_error: any) => {
        console.error('Could not load  advert generator report from API');
      }
    });
  }

  private loadInitialGeneratedAdvert(): AdvertGeneratorDto | undefined {
    const advertGeneratorItem = sessionStorage.getItem('setSelectedAdvertGeneratorReport');
    return advertGeneratorItem ? JSON.parse(advertGeneratorItem) : undefined;
  }

  getAdvertGenerateState(): AdvertGeneratorDto | undefined {
    return this.selectedAdvertGenerateFormState.value;
  }
}