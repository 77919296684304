import { MultiplePropertyListingDto, PropertyListingDto, TaskListDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { LookupService, PropertyListingService, UserManagementService } from 'src/app/services/property-matrixV2/services';
import { ListingStateService } from 'src/app/shared/services/listing-state-service/listing-state.service';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-allocate-network-planner',
  templateUrl: './allocate-network-planner.component.html',
  styleUrls: ['./allocate-network-planner.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class AllocateNetworkPlannerComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;

  sections = [
    { key: 1, value: 'Agent listing', checked: true },
    { key: 2, value: 'Network/Urban Planner Status', checked: true },
    { key: 3, value: 'Allocate Planner', checked: true },
  ];

  isNetworkPlannerNominated: boolean = false;
  selectedNetworkPlanner: UserDto = null;
  user: UserDto = null;
  UserList: UserDto[] = [];
  UserListNew: UserDto[] = [];
  FormAllocateNetworkPlanner: FormGroup;
  networkPlannerType = 1;
  listingType: string;
  currentUser: UserDto;

  constructor(
    private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private lookupService: LookupService,
    private listingStateService: ListingStateService,
    private propertyListingService: PropertyListingService,
    private router: Router,
    private notificationService: NotificationService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.listingType = params['listingType'];
    });
    this.getCurrentUser();
    this.getNetworkPlannerUserList();
    this.prepareFormFields();
    this.checkIfAnyNetworkPlannerNominated();
  }

  getNetworkPlannerUserList(): void {
    try {
      this.lookupService.apiV1LookupGetNetworkPlannerUsersGet().subscribe({
        next: (userList: UserDto[]) => {
          this.UserListNew = userList;
        },
        error: (_error: any) => {
          this.notificationService.showErrorMessage('Error', 'Could not load Network/Urban planner user list items for users.');
        }
      });
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load Network/Urban planner user list items for users.');
    }
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  prepareFormFields(): void {
    this.FormAllocateNetworkPlanner = this.fb.group({
      plannerName: ["", [Validators.required]],
      plannerSurname: ["", [Validators.required]],
      plannerCompany: ["", [Validators.required]],
      plannerCityTown: ["", [Validators.required]],
      plannerEmail: ["", [Validators.required]],
      plannerMobileOfficeNr: ["", [Validators.required]],
    });
    this.selectedNetworkPlanner = null;
  }

  saveNetworkPlanner(): void {
    try {
      if (this.selectedNetworkPlanner != null) {
        this.selectedListing.networkPlanner = this.selectedNetworkPlanner;

        this.propertyListingService.apiV1PropertyListingAddOrUpdateNetworkPlannerToPropertyListingPost({
          body: this.selectedListing
        }).subscribe({
          next: (retult: any) => {
            this.notificationService.showSuccessMessage("Save", 'Added Network/Urban planner user to current listing successful.');
            this.setPropertyListingTask();
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Could not save Network/Urban planner user.');
          }
        });
      } else {
        this.notificationService.showWarningMessage("Please select Network/Urban planner.", "No Network/Urban planner user selected.");
      }
    }
    catch (e) {
      this.notificationService.showErrorMessage('Error', 'Could not load Network/Urban planner user list items for users.');
    }
  }

  checkIfAnyNetworkPlannerNominated(): void {
    if (this.selectedListing.networkPlanner) {
      this.isNetworkPlannerNominated = false;
      this.FormAllocateNetworkPlanner.get("plannerName").setValue(this.selectedListing.networkPlanner.name);
      this.FormAllocateNetworkPlanner.get("plannerSurname").setValue(this.selectedListing.networkPlanner.surname);
      this.FormAllocateNetworkPlanner.get("plannerCompany").setValue(this.selectedListing.networkPlanner.profession);
      this.FormAllocateNetworkPlanner.get("plannerCityTown").setValue(this.selectedListing.addressTitle);
      this.FormAllocateNetworkPlanner.get("plannerEmail").setValue(this.selectedListing.networkPlanner.email);
      this.FormAllocateNetworkPlanner.get("plannerMobileOfficeNr").setValue(this.selectedListing.networkPlanner.mobile);
    } else {
      this.isNetworkPlannerNominated = true;
    }
  }

  navigateToPlanningReport() {
    this.propertyListingService.apiV1PropertyListingGetPropertyListingByIdGet({ listingId: this.selectedListing.id }).subscribe(result => {
      this.selectedListing = result;
      this.listingStateService.setSelectedListing(this.selectedListing);
      this.router.navigate(['admin/listings/listing-overview'], {
        queryParams: {
          listingType: this.listingType
        }
      });
    });
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    let taskStatusComment = "";
    if (this.networkPlannerType == 1) {
      taskStatusComment = "Allocated by PM";
    } else if (this.networkPlannerType == 2) {
      taskStatusComment = "Nominated by Seller";
    } else if (this.networkPlannerType == 3) {
      taskStatusComment = "Network-Urban Planner(NP) referred property";
    }

    this.selectedListing.taskList.forEach(task => {
      if (task.taskNumber.number === 6) {
        if (task.taskStatus.taskStatusNumber != 3) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
      if (task.taskNumber.number === 7) {
        if (task.taskStatus.taskStatusNumber == 1) {
          task.user = this.selectedNetworkPlanner;
          task.userStatusText = taskStatusComment;
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result != null) {
          this.navigateToPlanningReport();
        }
        else {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
    else {
      this.navigateToPlanningReport();
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }
}
