<div id="advert-lookup-create" class="advert-lookup-create">
    <div class="dialog-container">
        <div class="dialog-header">
            <span>Enter Lookup description for advert filters</span>
            <button (click)="onCancel()" class="display-right button hidden">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="dialog-content">
            <div class="row">
                <div class="col-12">
                    <mat-radio-group [(ngModel)]="advertType" class="margin">
                        <div class="radio-container">
                            <span class="radio-button-text padding">
                                <mat-radio-button class="radio" [value]="1">
                                    Add advert location.
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container padding">
                            <span class="radio-button-text mt-1">
                                <mat-radio-button class="radio" [value]="2">
                                    Add Advert sector.
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container">
                            <span class="radio-button-text padding">
                                <mat-radio-button class="radio" [value]="3">
                                    Add Advert type
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container">
                            <span class="radio-button-text padding">
                                <mat-radio-button class="radio" [value]="4">
                                    Add Status for Generate Advert
                                </mat-radio-button>
                            </span>
                        </div>
                        <div class="radio-container">
                            <span class="radio-button-text padding">
                                <mat-radio-button class="radio" [value]="5">
                                    Add Generate Advert Key Feature
                                </mat-radio-button>
                            </span>
                        </div>

                    </mat-radio-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Description</mat-label>
                        <input matInput type="text" [(ngModel)]="description" placeholder="Enter Description">
                    </mat-form-field>
                </div>
            </div>
            <!-- </form> -->
        </div>
        <mat-dialog-actions>
            <button class=" button button-background-coral margin-right" (click)="onCancel()">Cancel</button>
            <button class=" button button-background-green margin-left" (click)="saveDetails()">Save</button>
        </mat-dialog-actions>
    </div>
</div>