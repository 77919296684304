import { ContractDetailDto, JuristicPersonDetailDto, OwnershipDetailDto, PersonalDetailDto, PropertyDetailDto, PropertyOwnershipTypeDto, SellMyPropertyDocumentDto, SellMyPropertyDto } from 'src/app/services/property-matrixV2/models';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { sellYourPropertyUploadDocumentTabTypes } from '../../interfaces/sell-your-property-filetypes';

@Injectable({
  providedIn: 'root'
})
export class PropertyListingStateService {

  private formState = new BehaviorSubject<SellMyPropertyDto>({
    ownershipDetail: {
      ownershipPersonalDetail: [],
      juristicPersonDetail: []
    },
    propertyDetails: null,
    contractDetail: {
      conveyancerDetail: {
        companyName: '',
        contactPerson: '',
        emailAddress: '',
        mobileNumber: '',
        officeNumber: '',
      },
      urbanPlannerDetail: {
        city: '',
        company: '',
        email: '',
        name: '',
        phone: '',
        surname: ''
      },
    },
    documents: [],
    additionalDocuments: [],
    pictureDocuments: [],
    propertyDocuments: []
  });

  formState$ = this.formState.asObservable();

  constructor() { }

  updateOwnershipDetails(ownershipDetails: OwnershipDetailDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: ownershipDetails
    });
  }

  updatePropertyOwnershipType(propertyOwnershipType: PropertyOwnershipTypeDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: {
        ...currentState.ownershipDetail,
        propertyOwnershipType: propertyOwnershipType
      }
    });
  }

  updateNaturalPersonDetails(naturalPersons: PersonalDetailDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: {
        ...currentState.ownershipDetail,
        ownershipPersonalDetail: naturalPersons
      }
    });
  }

  updateJuristicPersonDetails(juristicPersons: JuristicPersonDetailDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      ownershipDetail: {
        ...currentState.ownershipDetail,
        juristicPersonDetail: juristicPersons
      }
    });
  }

  updatePropertyDetails(propertyDetails: PropertyDetailDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      propertyDetails: propertyDetails
    });
  }

  updateContractDetails(contractDetails: ContractDetailDto) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      contractDetail: contractDetails
    });
  }

  updateDocuments(documents: SellMyPropertyDocumentDto[]) {
    const currentState = this.formState.value;
    this.formState.next({
      ...currentState,
      documents: documents
    });
  }

  updateDocument(index: number, document: SellMyPropertyDocumentDto) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = document;
    this.formState.next({
      ...currentState,
      documents: updatedDocuments
    });
  }

  updateDocumentMulti(index: number, document: SellMyPropertyDocumentDto, fileType: string) {
    const currentState = this.formState.value;
    let updatedDocuments;

    if (fileType == sellYourPropertyUploadDocumentTabTypes.AdditionalDocument.toString()) {
      updatedDocuments = [...currentState.additionalDocuments];
      if (currentState.additionalDocuments.length != 0) {
        index = currentState.additionalDocuments.length;
      }
      this.formState.next({
        ...currentState,
        additionalDocuments: updatedDocuments
      });
    }
    else if (fileType == sellYourPropertyUploadDocumentTabTypes.PictureDocument.toString()) {
      updatedDocuments = [...currentState.pictureDocuments];
      if (currentState.pictureDocuments.length != 0) {
        index = currentState.pictureDocuments.length;
      }
      this.formState.next({
        ...currentState,
        pictureDocuments: updatedDocuments
      });
    }
    else if (fileType == sellYourPropertyUploadDocumentTabTypes.PropertyInfoDocument.toString()) {
      updatedDocuments = [...currentState.propertyDocuments];
      if (currentState.propertyDocuments.length != 0) {
        index = currentState.propertyDocuments.length;
      }
      this.formState.next({
        ...currentState,
        propertyDocuments: updatedDocuments
      });
    }
    else {
      updatedDocuments = [...currentState.documents];
      this.formState.next({
        ...currentState,
        documents: updatedDocuments
      });
    }
    updatedDocuments[index] = document;
  }

  removeDocument(index: number) {
    const currentState = this.formState.value;
    const updatedDocuments = [...currentState.documents];
    updatedDocuments[index] = null;
    // updatedDocuments.splice(index, 1);
    this.formState.next({
      ...currentState,
      documents: updatedDocuments
    });
  }

  getFormState(): SellMyPropertyDto {
    return this.formState.value;
  }

  clearSelectedListing(): void {
    this.formState.next({
      ownershipDetail: {
        ownershipPersonalDetail: [],
        juristicPersonDetail: []
      },
      propertyDetails: null,
      contractDetail: {
        conveyancerDetail: {
          companyName: '',
          contactPerson: '',
          emailAddress: '',
          mobileNumber: '',
          officeNumber: '',
        },
        urbanPlannerDetail: {
          city: '',
          company: '',
          email: '',
          name: '',
          phone: '',
          surname: ''
        },
      },
      documents: [],
      additionalDocuments: [],
      pictureDocuments: [],
      propertyDocuments: []
    });
  }
}
