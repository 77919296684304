<main>
    <div class="loading" *ngIf="loading == true">Loading&#8230;</div>
    <div class="container">
        <mat-card class="row mb-3" *ngFor="let section of sectionsNew">
            <div class="col-12 heading-2">
                <div class="row">
                    <div class="col-11">{{ section.value }}</div>
                    <div class="col-1 collapse-expand">
                        <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                            <mat-icon *ngIf="!section.checked">expand_less</mat-icon>
                            <mat-icon *ngIf="section.checked">expand_more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 1" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Project Description</label>
                        <input class="col-12 mt-1 matrix-data" type="text" [(ngModel)]="exportReportDto.projectName"
                            placeholder="projectName">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Date</label>
                        <input class="col-12 mt-1" matInput [matDatepicker]="picker" placeholder="Click to select date"
                            [(ngModel)]="exportReportDto.date" (click)="picker.open()">
                        <mat-datepicker #picker touchUi="true"></mat-datepicker>
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Reference Number</label>
                        <input class="col-12 mt-1 matrix-data" type="text" placeholder="TSH/LIS/2390/001"
                            [(ngModel)]="exportReportDto.referenceNumber">
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 2" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Prepared for</label>
                        <input class="col-12 mt-1" type="text" placeholder="Name of Client/Seller/Agent?"
                            [(ngModel)]="exportReportDto.preparedFor">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Company of Agent/Seller</label>
                        <input class="col-12 mt-1" type="text" placeholder="Company Name"
                            [(ngModel)]="exportReportDto.sellerCompany">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <label class="col-12 bold">Company Logo</label>
                    <div class="col-12 mt-1">
                        <div class="upload-background">
                            <div class="upload-block" appDragAndDrop>
                                <input type="file" (change)="setSellerCompanyLogoFile($event)"
                                    class="upload-block-inner" multiple accept="image/*" id="inputSellerCompanyLogo"
                                    placeholder="image">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                    <div class="col-12">
                                        Click to upload.
                                    </div>
                                </div>
                                <div class="row" *ngIf="sellerCompanyLogoFileUrl">
                                    <div class="col-12">
                                        <div class="image-upload">
                                            <img [src]="sellerCompanyLogoFileUrl" alt="Image Preview"
                                                id="previewSellerCompanyLogo" class="upload-block-inner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="sellerCompanyHideButtons">
                    <div class="col-12 button-group mt-3 mb-3">
                        <a class="footer-button background-charcoal text-center" [href]="sellerCompanyLogoFileUrl"
                            [download]="sellerCompanyLogoFile?.name">
                            Download
                        </a>
                        <button type="button" class="footer-button background-coral"
                            (click)="replaceSellerCompanyLogoFile()">
                            Replace
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 3" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Prepared by</label>
                        <input class="col-12 mt-1" type="text" placeholder="Name of Network/Urban Planner"
                            [(ngModel)]="exportReportDto.preparedBy">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Company of Planner</label>
                        <input class="col-12 mt-1" type="text" placeholder="Company Name"
                            [(ngModel)]="exportReportDto.plannerCompany">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <label class="col-12 bold">Company Logo</label>
                    <div class="col-12 mt-1">
                        <div class="upload-background">
                            <div class="upload-block" appDragAndDrop>
                                <input type="file" (change)="setPlannerCompanyLogoFile($event)"
                                    class="upload-block-inner" multiple accept="image/*" id="inputPlannerCompanyLogo"
                                    placeholder="image">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                    <div class="col-12">
                                        Click to upload.
                                    </div>
                                </div>
                                <div class="row" *ngIf="plannerCompanyLogoFileUrl">
                                    <div class="col-12">
                                        <div class="image-upload">
                                            <img [src]="plannerCompanyLogoFileUrl" alt="Image Preview"
                                                id="previewPlannerCompanyLogo" class="upload-block-inner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="plannerCompanyHideButtons">
                    <div class="col-12 button-group mt-3 mb-3">
                        <a class="footer-button background-charcoal text-center" [href]="plannerCompanyLogoFileUrl"
                            [download]="plannerCompanyLogoFile?.name">
                            Download
                        </a>
                        <button type="button" class="footer-button background-coral"
                            (click)="replacePlannerCompanyLogoFile()">
                            Replace
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 4" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Signed by</label>
                        <input class="col-12 mt-1" type="text" placeholder="Name of Senior Planner"
                            [(ngModel)]="exportReportDto.seniorPlannerName">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <label class="col-12 bold">Senior Planner Signature</label>
                    <div class="col-12 mt-1">
                        <div class="upload-background">
                            <div class="upload-block" appDragAndDrop>
                                <input type="file" (change)="setSeniorPlannerSignatureFile($event)"
                                    class="upload-block-inner" multiple accept="image/*"
                                    id="inputSeniorPlannerSignature" placeholder="image">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                    <div class="col-12">
                                        Click to upload.
                                    </div>
                                </div>
                                <div class="row" *ngIf="seniorPlannerSignatureFileUrl">
                                    <div class="col-12">
                                        <div class="image-upload">
                                            <img [src]="seniorPlannerSignatureFileUrl" alt="Image Preview"
                                                id="previewSeniorPlannerSignature" class="upload-block-inner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="seniorPlannerSignatureHideButtons">
                    <div class="col-12 button-group mt-3 mb-3">
                        <a class="footer-button background-charcoal text-center" [href]="seniorPlannerSignatureFileUrl"
                            [download]="seniorPlannerSignatureFile?.name">
                            Download
                        </a>
                        <button type="button" class="footer-button background-coral"
                            (click)="replaceSeniorPlannerSignatureFile()">
                            Replace
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.id == 5" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Signed by</label>
                        <input class="col-12 mt-1" type="text" placeholder="Name of Network/Urban Planner"
                            [(ngModel)]="exportReportDto.networkPlannerName">
                    </div>
                </div>
                <div class="row paragraph mt-3">
                    <label class="col-12 bold">Network/Urban Planner Signature</label>
                    <div class="col-12 mt-1">
                        <div class="upload-background">
                            <div class="upload-block" appDragAndDrop>
                                <input type="file" (change)="setNetworkPlannerSignatureFile($event)"
                                    class="upload-block-inner" multiple accept="image/*"
                                    id="inputNetworkPlannerSignature" placeholder="image">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-icon class="file-icon">cloud_upload</mat-icon>
                                    </div>
                                    <div class="col-12">
                                        Click to upload.
                                    </div>
                                </div>
                                <div class="row" *ngIf="networkPlannerSignatureFileUrl">
                                    <div class="col-12">
                                        <div class="image-upload">
                                            <img [src]="networkPlannerSignatureFileUrl" alt="Image Preview"
                                                id="previewNetworkPlannerSignature" class="upload-block-inner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="networkPlannerSignatureHideButtons">
                    <div class="col-12 button-group mt-3 mb-3">
                        <a class="footer-button background-charcoal text-center" [href]="networkPlannerSignatureFileUrl"
                            [download]="networkPlannerSignatureFile?.name">
                            Download
                        </a>
                        <button type="button" class="footer-button background-coral"
                            (click)="replaceNetworkPlannerSignatureFile()">
                            Replace
                        </button>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="button-group mb-3">
        <button (click)="goToAddendumThree()" class="footer-button background-cloud">Previous</button>
        <button (click)="saveExportReportDetails()" class="footer-button background-coral">Save</button>
        <button (click)="exportLevelOnePlanningReport()" class="footer-button background-green">Export L1</button>
        <button (click)="exportLevelTwoPlanningReport()" class="footer-button background-green">Export L2</button>
    </div>
    <mat-card class="floating-card">
        <button class="icon-button" matTooltip="Key" (click)="openReportKeyDialog()">
            <mat-icon class="material-icons-outlined">info</mat-icon>
        </button>
        <button class="icon-button" matTooltip="Save Export Details" (click)="saveExportReportDetails()">
            <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
    </mat-card>
</main>