<mat-card [ngClass]="{'selected': selected}">
    <div class="container">
        <div class="row justify-content-end">
            <div class="circle-button-container col-2">
                <button *ngIf="!listing?.isPartOfMultiple" class="select-button" matTooltip="Select Property"
                    matTooltipShowDelay="1000" [matMenuTriggerFor]="menu">
                    <mat-icon class="circle-button">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="toggleSelectListing()">{{ getSelectButtonText() }}</button>
                    <button mat-menu-item
                        (click)="listing.lifeCycleState === 'Archived' ? unarchiveListing() : archiveListing()">
                        {{ getArchiveButtonText() }}
                    </button>
                    <button mat-menu-item (click)="deleteListing()">Delete Listing</button>
                </mat-menu>
            </div>
        </div>
        <div class="row justify-content-between p-2">
            <div class="card-heading-container col-6">
                <span class="paragraph card-heading">
                    {{ this.surveyorGeneralKey }}
                </span>
            </div>
            <div class="paragraph report-status col-4">
                {{ listing?.lifeCycleState ?? 'On Time' }}
            </div>
        </div>
        <div class="row justify-content-between p-2 pt-0">
            <div class="card-heading-container col-6">
                <span class="paragraph card-heading">
                    {{ listing?.planningReportId | slice:0:12 }}
                </span>
            </div>
            <div class="paragraph report-status agent-referal-status col-4">
                {{ listing?.lifeCycleState ?? 'On Time' }}
            </div>
        </div>
        <div class="divider mb-2"></div>
        <div class="row justify-content-between">
            <div class="col-6">
                <div *ngIf="!listing?.isPartOfMultiple" class="paragraph listing-card-footer row">
                    <div class="icon-container col-2 p-0">
                        <mat-icon class="user-icon">person</mat-icon>
                        <div class="role-icon" [ngStyle]="{'background-color': getCurrentUser().roleColor}">
                            {{ getCurrentUser().roleInitial ?? 'P' }}
                        </div>
                    </div>
                    <div class="paragraph professional-title col-8">
                        {{ getCurrentUser().name ?? 'No professional available' }}
                    </div>
                </div>
            </div>
            <div class="col-4" *ngif="true">
                <div class="row justify-content-between listing-card">
                    <div class="col-3">
                        <div class="circle-icon-container margin-left-20">
                            <mat-icon>settings</mat-icon>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="circle-icon-container margin-left-20">
                            <mat-icon>home</mat-icon>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="circle-icon-container margin-left-20 bold">
                            L1
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="listing-image-container">
            <img [src]="listing?.image" (error)="onError($event)">
            <div *ngIf="listing?.isPartOfMultiple" class="image-overlay-left">
                <div class="multiple-icon-container col-2 p-0">
                    <mat-icon class="material-icons-outlined multiple-icon">
                        holiday_village
                    </mat-icon>
                </div>
            </div>
        </div>
        <div class="listing-address-container row">
            <div class="paragraph listing-address-title col-10">
                {{ this.addressTitle }}
            </div>
        </div>
        <div class="paragraph listing-address">
            {{ this.completeAddress }}
        </div>
        <div *ngIf="!listing?.isPartOfMultiple" class="divider"></div>
        <div *ngIf="!listing?.isPartOfMultiple" class="paragraph listing-card-footer row justify-content-end">
            <div class="circle-button-container col-2">
                <button class="center-content next-button" (click)="goToListingOverview()">
                    <mat-icon class="next-button-icon">keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
        <div *ngIf="listing?.isPartOfMultiple" class="paragraph listing-card-footer row">
            <div class="paragraph professional-title col-8">
                Property: {{ listing?.number ?? 'N/A' }}
            </div>
        </div>
    </div>
</mat-card>