import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit } from '@angular/core';
import { catchError, of } from 'rxjs';

import { NotificationService } from '../../../shared/services/notification-service/notification.service';
import { UserManagementService } from '../../../services/property-matrixV2/services';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private keycloakService: KeycloakService,
    private _userManagementService: UserManagementService,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.CheckIfLoggedInUserHasWebUserRole();
  }

  private CheckIfLoggedInUserHasWebUserRole(): void {
    this._userManagementService.apiV1UserManagementGetCurrentUserGet({}).pipe(
      catchError(error => {
        this._notificationService.showErrorMessage('Error', 'Could not load current logged in user to check the role.');
        console.error(error);
        return of(null);
      })
    ).subscribe(async userDto => {
      if (userDto && userDto.userRoles) {
        const containsRoles = userDto.userRoles.some(r => (r.roleName === 'Website User'));
        let adminRoleFound: boolean = [
          'Head Administrator',
          'Agent Portal Administrator',
          'Planner Portal Administrator',
          'Consultant Administrator',
          'Admin Portal Administrator'].some(role => userDto.userRoles.some(e => e.roleName.includes(role)));
        if (containsRoles || userDto.endorsed == false && adminRoleFound == false) {
          await this._notificationService.showConfirmMessage('User', 'User will not be allowed in Admin Portal.', 'OK', 'Close').then((confirmed) => {
            this.navigateToHome();
          });
        }
      } else {
        this._notificationService.showErrorMessage('Error', 'Could not load current logged in user to check the role.');
      }
    });
  }

  public navigateToHome(): void {
    this._router.navigateByUrl('/');
  }
}
