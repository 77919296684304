<div class="provide-lat-lon-dialog" id="provide-lat-lon-dialog">
    <div class="dialog-container">
        <div class="dialog-header">
            <span>Enter Location</span>
            <button (click)="onCancel()" class="display-right button hidden">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="dialog-content">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Latitude</mat-label>
                    <input matInput type="number" [(ngModel)]="latitude" placeholder="Enter Latitude">
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Longitude</mat-label>
                    <input matInput type="number" [(ngModel)]="longitude" placeholder="Enter Longitude">
                </mat-form-field>
            </div>
        </div>
        <mat-dialog-actions>
            <button class=" button button-background-coral margin-right" (click)="onCancel()">Cancel</button>
            <button class=" button button-background-green margin-left" (click)="onSave()">Save</button>
        </mat-dialog-actions>
    </div>
</div>