<main class="main-container">
    <div class="content">
        <div class="row text-center mb-5 mb-md-0">
            <div class="col-12">
                <h1 class="welcome">
                    Welcome
                </h1>
            </div>
            <div class="col-12">
                <h2 class="listing-as">
                    I am listing a property as a:
                </h2>
            </div>
        </div>
        <div class="row text-center mt-4 align-items-stretch">
            <div class="col-12 col-md-6 d-flex flex-column align-items-center mb-5 mb-md-0">
                <div class="d-flex flex-column justify-content-between h-100">
                    <h3 class="seller-type">Property Owner</h3>
                    <h1 class="seller-number">1</h1>
                    <p class="seller-description">
                        I am a property owner that wishes to sell my property via this platform.
                    </p>
                    <button class="button mt-auto" type="button" (click)="onProceed('/selling-my-property', 'property-owner',false)">Proceed</button>
                </div>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column align-items-center">
                <div class="d-flex flex-column justify-content-between h-100">
                    <h3 class="seller-type">Broker/Agent</h3>
                    <h1 class="seller-number">2</h1>
                    <p class="seller-description">
                        I am a Broker/Agent that wishes to list a property with Property Matrix.
                    </p>
                    <button class="button mt-auto" type="button" (click)="onProceed('/sell-your-property/overview', 'broker-agent',true)">Proceed</button>
                </div>
            </div>
        </div>
    </div>
</main>