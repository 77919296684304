<div class="dialog-container">
    <div class="dialog-header">
        <span>NOT AVAILABLE</span>
        <button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="dialog-content">
        <div>
            This service can be expected on the website as part of a future launch.<br>Email us at manager&#64;propertymatrix.co.za for assistance.
        </div>
        <button mat-button (click)="closeDialog()" class="got-it-button">
            Got It
        </button>
    </div>
</div>