/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyMatrixConfigurationV2, PropertyMatrixConfigurationV2Params } from './property-matrix-configuration-v-2';

import { AdvertService } from './services/advert.service';
import { ContractDetailService } from './services/contract-detail.service';
import { FileManagementService } from './services/file-management.service';
import { HealthService } from './services/health.service';
import { LookupService } from './services/lookup.service';
import { MapsService } from './services/maps.service';
import { OwnershipDetailService } from './services/ownership-detail.service';
import { PlanningReportService } from './services/planning-report.service';
import { PropertyListingService } from './services/property-listing.service';
import { RegistrationService } from './services/registration.service';
import { ReportGeneratorService } from './services/report-generator.service';
import { SellMyPropertyService } from './services/sell-my-property.service';
import { UserManagementService } from './services/user-management.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdvertService,
    ContractDetailService,
    FileManagementService,
    HealthService,
    LookupService,
    MapsService,
    OwnershipDetailService,
    PlanningReportService,
    PropertyListingService,
    RegistrationService,
    ReportGeneratorService,
    SellMyPropertyService,
    UserManagementService,
    PropertyMatrixConfigurationV2
  ],
})
export class PropertyMatrixApiModuleV2 {
  static forRoot(params: PropertyMatrixConfigurationV2Params): ModuleWithProviders<PropertyMatrixApiModuleV2> {
    return {
      ngModule: PropertyMatrixApiModuleV2,
      providers: [
        {
          provide: PropertyMatrixConfigurationV2,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: PropertyMatrixApiModuleV2,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('PropertyMatrixApiModuleV2 is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
