/* tslint:disable */
/* eslint-disable */
export enum ListingLifeCycleState {
  Assigned = 'Assigned',
  Managed = 'Managed',
  Published = 'Published',
  UnderOffer = 'UnderOffer',
  Sold = 'Sold',
  Archived = 'Archived',
  Done = 'Done'
}
