/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SellMyPropertyDocumentDto } from '../models/sell-my-property-document-dto';
import { SellMyPropertyDto } from '../models/sell-my-property-dto';
import { UploadFileDto } from '../models/upload-file-dto';

@Injectable({
  providedIn: 'root',
})
export class SellMyPropertyService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1SellMyPropertySaveSellingMyPropertyFormPost
   */
  static readonly ApiV1SellMyPropertySaveSellingMyPropertyFormPostPath = '/api/v1/SellMyProperty/SaveSellingMyPropertyForm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SellMyPropertySaveSellingMyPropertyFormPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1SellMyPropertySaveSellingMyPropertyFormPost$Response(params?: {
    context?: HttpContext
    body?: SellMyPropertyDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<string>> {

    const rb = new RequestBuilder(this.rootUrl, SellMyPropertyService.ApiV1SellMyPropertySaveSellingMyPropertyFormPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SellMyPropertySaveSellingMyPropertyFormPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1SellMyPropertySaveSellingMyPropertyFormPost(params?: {
    context?: HttpContext
    body?: SellMyPropertyDto
  }
): Observable<string> {

    return this.apiV1SellMyPropertySaveSellingMyPropertyFormPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiV1SellMyPropertyUploadSellMyPropertyFilesPost
   */
  static readonly ApiV1SellMyPropertyUploadSellMyPropertyFilesPostPath = '/api/v1/SellMyProperty/UploadSellMyPropertyFiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SellMyPropertyUploadSellMyPropertyFilesPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SellMyPropertyUploadSellMyPropertyFilesPost$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, SellMyPropertyService.ApiV1SellMyPropertyUploadSellMyPropertyFilesPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SellMyPropertyUploadSellMyPropertyFilesPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SellMyPropertyUploadSellMyPropertyFilesPost(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1SellMyPropertyUploadSellMyPropertyFilesPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1SellMyPropertyLoadRequiredDocumentsGet
   */
  static readonly ApiV1SellMyPropertyLoadRequiredDocumentsGetPath = '/api/v1/SellMyProperty/LoadRequiredDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SellMyPropertyLoadRequiredDocumentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SellMyPropertyLoadRequiredDocumentsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UploadFileDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SellMyPropertyService.ApiV1SellMyPropertyLoadRequiredDocumentsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UploadFileDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SellMyPropertyLoadRequiredDocumentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SellMyPropertyLoadRequiredDocumentsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UploadFileDto>> {

    return this.apiV1SellMyPropertyLoadRequiredDocumentsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UploadFileDto>>) => r.body as Array<UploadFileDto>)
    );
  }

  /**
   * Path part for operation apiV1SellMyPropertyLoadRequiredCadastreGet
   */
  static readonly ApiV1SellMyPropertyLoadRequiredCadastreGetPath = '/api/v1/SellMyProperty/LoadRequiredCadastre';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SellMyPropertyLoadRequiredCadastreGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SellMyPropertyLoadRequiredCadastreGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UploadFileDto>> {

    const rb = new RequestBuilder(this.rootUrl, SellMyPropertyService.ApiV1SellMyPropertyLoadRequiredCadastreGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UploadFileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SellMyPropertyLoadRequiredCadastreGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1SellMyPropertyLoadRequiredCadastreGet(params?: {
    context?: HttpContext
  }
): Observable<UploadFileDto> {

    return this.apiV1SellMyPropertyLoadRequiredCadastreGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UploadFileDto>) => r.body as UploadFileDto)
    );
  }

  /**
   * Path part for operation apiV1SellMyPropertyAddAdditionalDocumentsPost
   */
  static readonly ApiV1SellMyPropertyAddAdditionalDocumentsPostPath = '/api/v1/SellMyProperty/AddAdditionalDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1SellMyPropertyAddAdditionalDocumentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1SellMyPropertyAddAdditionalDocumentsPost$Response(params?: {
    context?: HttpContext
    body?: Array<SellMyPropertyDocumentDto>
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, SellMyPropertyService.ApiV1SellMyPropertyAddAdditionalDocumentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1SellMyPropertyAddAdditionalDocumentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiV1SellMyPropertyAddAdditionalDocumentsPost(params?: {
    context?: HttpContext
    body?: Array<SellMyPropertyDocumentDto>
  }
): Observable<boolean> {

    return this.apiV1SellMyPropertyAddAdditionalDocumentsPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

}
