import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

import { AddressDetailDto, JuristicPersonDetailDto, MaritalStatusDto, OwnershipDetailDto, PersonalDetailDto, PropertyOwnershipTypeDto } from '../../../../app/services/property-matrixV2/models';
import { UserActionDialogComponent } from '../../admin-portal/admin-users/user-action-dialog/user-action-dialog.component';
import { NotificationService } from '../../../../app/shared/services/notification-service/notification.service';
import { JuristicPersonDialogComponent } from './juristic-person-dialog/juristic-person-dialog.component';
import { NaturalPersonDialogComponent } from './natural-person-dialog/natural-person-dialog.component';
import { LookupService } from '../../../../app/services/property-matrixV2/services';
import { KeyValuePair } from '../../../../app/common/global-models/key-value-pair';
import { GlobalConstants } from '../../../../app/common/global-constants';
import { GlobalMethods } from '../../../../app/common/global-methods';

@Component({
  selector: 'app-ownership-details',
  templateUrl: './ownership.component.html',
  styleUrls: ['./ownership-details.component.scss', '../../../../css/2-modules/_forms.scss']
})
export class OwnershipDetailsComponent implements OnInit {

  @ViewChild('ownershipDetailsTable') ownershipDetailsTable: MatTable<any>;
  @ViewChild('juristicPersonsTable') juristicPersonsTable: MatTable<any>;

  @Output() pageNumberEvent = new EventEmitter<number>();
  @Output() ownershipDetailsEvent = new EventEmitter<OwnershipDetailDto>();

  maritalStatus: MaritalStatusDto = {};
  MaritalStatusDto: MaritalStatusDto[] = [];
  propertyOwnershipTypeDto: PropertyOwnershipTypeDto[] = [];
  coOwners: PersonalDetailDto[] = [];
  juristicPersons: JuristicPersonDetailDto[] = [];

  emailPattern: string = GlobalConstants.emailPattern;
  pageNumber = 1;
  counter: number = 0;
  counterJuristicPerson: number = 0;
  confirmConsent: boolean = false;
  areYouLegalRepresentative: boolean = false
  personIsLegalRepOfEntity: number = 0;
  moreThanOneOwner: number = 0;
  numberOfCoOwners: number = 0;
  spouseIsSouthAfricanResident: number = 0;
  ownerIsSouthAfricanResident: number = 0;
  propertyOwnershipTypeValue: string = '';
  propertyOwnershipTypeId: string = '';
  isSpouseRsaResident: boolean = true;

  displayedColumnsNaturalPerson: string[] = ['number', 'name', 'spouse', 'edit', 'delete'];

  ownershipDetailsCoOwner: any[] = [
    { number: '1', name: 'Test Surname', spouse: 'Spouse Name', edit: 'edit', status: 'delete' }
  ];

  ownershipDetailsJuristicPerson: any[] = [
    { number: '1', name: 'Test Surname', spouse: 'Full Name of representative', edit: 'edit', status: 'delete' }
  ];

  propertyOwnershipType: KeyValuePair[] = [
    { key: 1, value: 'Natural person(s)' },
    { key: 2, value: 'Juristic person(s) - (company, cc, NPO, trust, etc.)' },
    { key: 3, value: 'Both natural person(s) and juristic person(s)' }
  ];

  basicYesNo: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];

  maritalStatuses: KeyValuePair[] = [
    { key: 1, value: 'Unmarried' },
    { key: 2, value: 'Married out of community' },
    { key: 3, value: 'Married in community' }
  ];

  multipleOwners: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No, there are multiple owners' }
  ];

  legalRepresentativeRadio: KeyValuePair[] = [
    { key: 1, value: 'Yes' },
    { key: 2, value: 'No' }
  ];

  ownerDetails: PersonalDetailDto = {
    fullNames: '',
    surname: '',
    idNumber: '',
    maritalStatus: this.maritalStatus,
    emailAddress: '',
    confirmedConsent: false,
    confirmedEmail: false,
    spouseDetail: {
      emailAddress: '',
      idNumber: '',
      fullNames: '',
      mobileNumber: '',
      confirmEmail: '',
      surname: '',
      confirmedEmail: false
    }
  }

  juristicPerson: JuristicPersonDetailDto = {
    fullName: '',
    registrationNumberOfEntity: '',
    vatNumber: '',
    legalRepresentative: {
      emailAddress: '',
      identityNumber: '',
      fullNames: '',
      mobilePhoneNumber: '',
      workPhoneNumber: '',
      confirmEmail: '',
      surname: '',
      confirmedEmail: false
    }
  }

  physicalAddress: AddressDetailDto = {
    streetName: '',
    streetNumber: null,
    suburb: '',
    unitNumber: null,
    complexName: null,
    city: ''
  }

  displayedColumns: string[] = ['co-owner', 'action1', 'action2'];

  queryBody: OwnershipDetailDto = {
    buildingAddressDetail: this.physicalAddress,
    juristicPersonDetail: this.juristicPersons,
    ownershipPersonalDetail: this.coOwners,
    propertyOwnershipType: null,
  }

  coOwner: PersonalDetailDto = {
    maritalStatus: null,
    confirmedConsent: false,
    emailAddress: '',
    confirmedEmail: false,
    isRsaResident: true,
    isSpouseRsaResident: true,
    idNumber: '',
    passportNumber: '',
    fullNames: '',
    mobileNumber: '',
    surname: '',
    spouseDetail: {
      emailAddress: '',
      idNumber: '',
      fullNames: '',
      mobileNumber: '',
      surname: '',
    }
  }

  constructor(
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    this.GetPropertyOwnershipType();
  }

  clearCoOwner() {
    this.coOwner = {
      maritalStatus: null,
      confirmedConsent: false,
      emailAddress: '',
      confirmedEmail: false,
      idNumber: '',
      fullNames: '',
      mobileNumber: '',
      surname: '',
      spouseDetail: {
        emailAddress: '',
        confirmedEmail: false,
        idNumber: '',
        fullNames: '',
        mobileNumber: '',
        surname: '',
      }
    }
  }

  clearJuristicPerson() {
    this.juristicPerson = {
      fullName: '',
      registrationNumberOfEntity: '',
      vatNumber: '',
      areYouLegalRepresentative: null,
      legalRepresentative: {
        emailAddress: '',
        identityNumber: '',
        fullNames: '',
        mobilePhoneNumber: '',
        workPhoneNumber: '',
        confirmEmail: '',
        surname: '',
        confirmedEmail: false
      }
    }
  }

  submitForm() {
    this.queryBody.propertyOwnershipType = this.propertyOwnershipTypeDto.find(x => x.id == this.propertyOwnershipTypeId)
    this.gotoPropertyDetails();
  }

  GetPropertyOwnershipType() {
    this.lookupService.apiV1LookupGetPropertyOwnershipTypeGet().subscribe({
      next: (result: PropertyOwnershipTypeDto[]) => {
        this.propertyOwnershipTypeDto = result;
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load property ownership types.');
      }
    });
  }

  openAddNaturalPersonDialog() {
    const dialogRef = this.dialog.open(NaturalPersonDialogComponent, {
      width: '61vw',
      height: '80vh',
      data: {
        coOwner: this.coOwner,
        index: 0,
        showDelete: false
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.counter++;
        result.orderNumber = this.counter;
        this.coOwners.push(result);
        this.ownershipDetailsTable.renderRows();
        this.clearCoOwner();
      }
    });
  }

  openEditNaturalPersonDialog(coOwner: PersonalDetailDto) {
    const dialogRef = this.dialog.open(NaturalPersonDialogComponent, {
      width: '75vw',
      height: '80vh',
      data: { coOwner: coOwner, index: 0, showDelete: false },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ownershipDetailsTable.renderRows();
      }
    });
  }

  deleteNaturalPerson(coOwner: PersonalDetailDto, index: number) {
    const dialogRef = this.dialog.open(NaturalPersonDialogComponent, {
      data: { coOwner: coOwner, index: index, showDelete: true, deleteCoOwner: false },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.deleteCoOwner == true) {
          this.coOwners.splice(index, 1);
          this.ownershipDetailsTable.renderRows();
        }
      }
    });
  }

  openAddJuristicPersonDialog() {
    const dialogRef = this.dialog.open(JuristicPersonDialogComponent, {
      width: '61vw',
      height: '80vh',
      data: { juristicPerson: this.juristicPerson, index: 0, showDelete: false },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.counterJuristicPerson++;
        result.orderNumber = this.counterJuristicPerson;
        this.juristicPersons.push(result);
        this.juristicPersonsTable.renderRows();
        this.clearJuristicPerson();
      }
    });
  }

  openEditJuristicPersonDialog(coOwner: JuristicPersonDetailDto) {
    const dialogRef = this.dialog.open(JuristicPersonDialogComponent, {
      width: '75vw',
      height: '80vh',
      data: { juristicPerson: coOwner, index: 0, showDelete: false },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.juristicPersonsTable.renderRows();
      }
    });
  }

  deleteJuristicPerson(coOwner: JuristicPersonDetailDto, index: number) {
    const dialogRef = this.dialog.open(JuristicPersonDialogComponent, {
      data: { juristicPerson: coOwner, index: index, showDelete: true, deleteCoOwner: false },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.deleteCoOwner == true) {
          this.juristicPersons.splice(index, 1);
          this.juristicPersonsTable.renderRows();
        }
      }
    });
  }

  resetLegalEntityFields(value: any, Id: string) {
    this.propertyOwnershipTypeValue = value;
    this.propertyOwnershipTypeId = Id;
    this.queryBody.propertyOwnershipType = this.propertyOwnershipTypeDto.find(x => x.id == this.propertyOwnershipTypeId)
  }

  gotoPropertyDetails() {
    this.ownershipDetailsEvent.emit(this.queryBody);
    this.pageNumberEvent.emit(2);
    GlobalMethods.scrollToTop();
  }

  editUser(userProfile: any) {
    this.dialog.open(NaturalPersonDialogComponent, {
      width: '500px',
      panelClass: 'dialog-container',
      data: { userProfile: userProfile }
    });
  }

  removeUser(userProfile: any) {
    this.dialog.open(UserActionDialogComponent, {
      width: '500px',
      data: { actionType: 'remove', userDto: userProfile }
    });
  }
}
