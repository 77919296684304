import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PotentialConstructionScopeDto } from 'src/app/services/property-matrixV2/models';
import { PlanningReportService } from 'src/app/services/property-matrixV2/services';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-potential-construction-scope',
  templateUrl: './potential-construction-scope.component.html',
  styleUrls: ['./potential-construction-scope.component.scss',
    '../../../../../../../../css/2-modules/_admin-portal.scss']
})
export class PotentialConstructionScopeComponent implements OnInit, OnChanges {

  @Input() planningReportId: string;
  @Input() amountOfLandParcels: number;
  @Input() potentialUsableProperty: number;
  @Input() potentialConstructionScopeParagraph: string | null;
  @Input() disableComponent: boolean = false;

  @Output() potentialConstructionScopesChange = new EventEmitter<PotentialConstructionScopeDto[]>();
  @Output() potentialConstructionScopeParagraphChange = new EventEmitter<string>();

  potentialConstructionScopes: PotentialConstructionScopeDto[] = [];
  potentialConstructionScopeParagraphText: string | null = null;

  constructor(
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService
  ) { }

  ngOnInit(): void {
    if (this.potentialConstructionScopeParagraph != null) {
      this.potentialConstructionScopeParagraphText = this.potentialConstructionScopeParagraph;
    }
    this.loadPotentialConstructionScopes();
    this.handleAmountOfLandParcelsChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.amountOfLandParcels) {
      this.handleAmountOfLandParcelsChange();
    }
  }

  async loadPotentialConstructionScopes(): Promise<void> {
    this.planningReportService.apiV1PlanningReportGetPotentialConstructionScopesGet({
      planningReportId: this.planningReportId
    }).subscribe({
      next: (response) => {
        this.potentialConstructionScopes = response;
        this.emitChanges();
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load potential construction scopes.');
      }
    });
  }

  async handleAmountOfLandParcelsChange() {
    if (this.amountOfLandParcels > 3) {
      this.potentialConstructionScopes = [];
    } else {
      const currentLength = this.potentialConstructionScopes.length;
      if (this.amountOfLandParcels > currentLength) {
        for (let i = currentLength; i < this.amountOfLandParcels; i++) {
          this.potentialConstructionScopes.push(this.createPotentialConstructionScope());
        }
      } else if (this.amountOfLandParcels < currentLength) {
        this.potentialConstructionScopes.splice(this.amountOfLandParcels, currentLength - this.amountOfLandParcels);
      }
    }
    this.emitChanges();
  }

  createPotentialConstructionScope(): PotentialConstructionScopeDto {
    return {
      id: uuidv4(),
      metricUnit: null,
      potentialCoverage: null,
      potentialDensity: null,
      potentialFloorAreaRatio: null,
      potentialHeight: null,
      potentialMaximumOpenSpaceRequired: null,
      potentialMaximumSquareMetersBasedOnCoverageAndHeight: null,
      potentialMaximumSquareMetersBasedOnFloorAreaRatio: null,
      potentialMaximumUnitsAverageSize: null,
      potentialMaximumUnitsOnUsableSpace: null,
      potentialOpenSpaceRequired: null,
      potentialParkingSpaces: null,
      potentialParkingSpacesAvailableOnVacantLandWithMaxRights: null,
      potentialParkingSpacesPerHundredSquareMeters: null,
      potentialParkingSpacesPerHundredSquareMetersWithMaxRights: null,
      potentialUsableArea: null,
      potentialParkingSpacesWithMaxRights: null,
      potentialTotalParkingSpacesWithMaxRights: null,
      potentialVisitorsParkingSpaces: null,
      potentialVisitorsParkingSpacesWithMaxRights: null,
      propertySize: null,
      proposedPropertySize: null,
      unusableArea: null,
      existingLandUseSurveyorGeneralErfExtent: null
    };
  }

  onScopeChange(index: number, updatedScope: PotentialConstructionScopeDto) {
    this.potentialConstructionScopes[index] = updatedScope;
    this.emitChanges();
  }

  onPotentialConstructionScopeParagraphChange(value: string) {
    this.potentialConstructionScopeParagraphText = value;
    this.potentialConstructionScopeParagraphChange.emit(this.potentialConstructionScopeParagraphText);
  }

  emitChanges(): void {
    this.potentialConstructionScopesChange.emit(this.potentialConstructionScopes);
  }
}
