<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="allocate-advert-create" id="allocate-advert-create">
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 heading-2">
            <div class="row">
                <div class="col-11">{{ section.value }}</div>
                <div class="col-1 collapse-expand">
                    <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                        <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                        <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <div class="form-group">
                        <label class="col-12 bold padding">Assign Property Advert Creator.</label>
                        <mat-select class="col-12 mt-1" [(value)]="selectedUser" [compareWith]="compareUser">
                            <mat-option *ngFor="let user of AdminUsersList" [value]="user">
                                {{user.name }} {{user.surname}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="saveAdvertAppointed()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>