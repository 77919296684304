import { PlanningReportService, ReportGeneratorService, UserManagementService, PropertyListingService } from 'src/app/services/property-matrixV2/services';
import { ExportReportDto, PropertyListingDto, TaskListDto, UserDto } from 'src/app/services/property-matrixV2/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting-service/reporting.service';
import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';

import { ReportKeyDialogComponent } from '../report-key-dialog/report-key-dialog.component';

@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss', '../../../../../../../css/2-modules/_admin-portal.scss']
})
export class ExportReportComponent implements OnInit, OnChanges {

  @Input() exportReportData: ExportReportDto;
  @Input() listingId: string;
  @Input() multipleListingId: string;
  @Input() isMultiple: boolean;
  @Output() tabNumberEvent = new EventEmitter<number>();
  @Input() selectedListing: PropertyListingDto;

  loading: boolean = false;

  exportReportDto: ExportReportDto;
  canExport: boolean = false;
  sellerCompanyLogoFile: any;
  sellerCompanyLogoFileId: string = null;
  sellerCompanyLogoFileUrl: string = null;
  sellerCompanyHideButtons: boolean = false;

  plannerCompanyLogoFile: any;
  plannerCompanyLogoFileId: string = null;
  plannerCompanyLogoFileUrl: string = null;
  plannerCompanyHideButtons: boolean = false;

  seniorPlannerSignatureFile: any;
  seniorPlannerSignatureFileId: string = null;
  seniorPlannerSignatureFileUrl: string = null;
  seniorPlannerSignatureHideButtons: boolean = false;

  networkPlannerSignatureFile: any;
  networkPlannerSignatureFileId: string = null;
  networkPlannerSignatureFileUrl: string = null;
  networkPlannerSignatureHideButtons: boolean = false;

  currentUser: UserDto;

  sectionsNew = [
    { id: 1, value: 'Project summary', checked: true },
    { id: 2, value: 'Prepared For', checked: false },
    { id: 3, value: 'Prepared By', checked: false },
    { id: 4, value: 'SP Sign Off', checked: false },
    { id: 5, value: 'NP Sign Off', checked: false },
  ];

  constructor(
    public dialog: MatDialog,
    private keycloakService: KeycloakService,
    private reportingService: ReportingService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private planningReportService: PlanningReportService,
    private reportGeneratorService: ReportGeneratorService,
    private userManagementService: UserManagementService,
    private propertyListingService: PropertyListingService
  ) { }

  ngOnInit(): void {
    this.getUserId();
    this.loadAllExportReportData();
    this.loadSellerCompanyLogoFile();
    this.loadPlannerCompanyLogoFile();
    this.loadSeniorPlannerSignatureFile();
    this.loadNetworkPlannerSignatureFile();
    this.setFieldToExportData();
    this.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exportReportData) {
      this.loadAllExportReportData();
    }
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  loadAllExportReportData() {
    if (this.exportReportData) {
      this.exportReportDto = this.exportReportData || {};
      this.sellerCompanyLogoFileId = this.exportReportData?.sellerCompanyLogoFileId;
      this.plannerCompanyLogoFileId = this.exportReportData?.plannerCompanyLogoFileId;
      this.seniorPlannerSignatureFileId = this.exportReportData?.seniorPlannerSignatureFileId;
      this.networkPlannerSignatureFileId = this.exportReportData?.networkPlannerSignatureFileId;
      this.checkExportButton();
    } else {
      this.notificationService.showWarningMessage("Warning", "The exportReportData is null");
    }
  }

  checkExportButton(): void {
    if (this.exportReportData?.sellerCompanyLogoFileId &&
      this.exportReportData?.projectName &&
      this.exportReportData?.plannerCompanyLogoFileId &&
      this.exportReportData?.seniorPlannerSignatureFileId &&
      this.exportReportData?.networkPlannerSignatureFileId) {
      this.canExport = true;
      this.setPropertyListingTask();
    } else {
      this.canExport = false;
    }
  }

  saveExportReportDetails() {
    this.loading = true;
    this.planningReportService.apiV1PlanningReportAddOrUpdateExportReportPost({
      propertyListingId: this.listingId,
      body: this.exportReportData
    }).subscribe({
      next: () => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Successfully saved export details.');
        this.checkExportButton();
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not save export details.');
      }
    });
  }

  exportLevelOnePlanningReport() {
    if (this.canExport) {
      if (!this.isMultiple) {
        this.exportLevelOneSinglePlanningReport();
      } else {
        this.exportLevelOneMultiplePlanningReport();
      }
    } else {
      this.notificationService.showErrorMessage("Error", "The report can not be exported because it is not signed off.");
    }
  }

  exportLevelOneSinglePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelOneSingleReportGet({
      listingId: this.listingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  exportLevelOneMultiplePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet({
      multipleListingId: this.multipleListingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  exportLevelTwoPlanningReport() {
    if (this.canExport) {
      if (!this.isMultiple) {
        this.exportLevelTwoSinglePlanningReport();
      } else {
        this.exportLevelTwoMultiplePlanningReport();
      }
    } else {
      this.notificationService.showErrorMessage("Error", "The report can not be exported because it is not signed off.");
    }
  }

  exportLevelTwoSinglePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelTwoSingleReportGet({
      listingId: this.listingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  exportLevelTwoMultiplePlanningReport() {
    this.loading = true;
    this.reportGeneratorService.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet({
      multipleListingId: this.multipleListingId,
      userId: this.getUserId()
    }).subscribe({
      next: (_response: any) => {
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'Report generation job has been started.');
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not start report generation.');
      }
    });
  }

  async setSellerCompanyLogoFile(event: { target: { files: any[]; }; }) {
    try {
      this.sellerCompanyLogoFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.sellerCompanyLogoFileId = this.sellerCompanyLogoFileId;
      this.sellerCompanyLogoFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.sellerCompanyLogoFileUrl = event.target.result;
        this.sellerCompanyHideButtons = true;
      }

      reader.readAsDataURL(this.sellerCompanyLogoFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replaceSellerCompanyLogoFile() {
    this.sellerCompanyLogoFileUrl = null;
    this.sellerCompanyHideButtons = false;
  }

  async loadSellerCompanyLogoFile() {
    if (this.sellerCompanyLogoFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.sellerCompanyLogoFileId);
        this.sellerCompanyLogoFileUrl = fileUrl;
        this.sellerCompanyHideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async setPlannerCompanyLogoFile(event: { target: { files: any[]; }; }) {
    try {
      this.plannerCompanyLogoFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.plannerCompanyLogoFileId = this.plannerCompanyLogoFileId;
      this.plannerCompanyLogoFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.plannerCompanyLogoFileUrl = event.target.result;
        this.plannerCompanyHideButtons = true;
      }

      reader.readAsDataURL(this.plannerCompanyLogoFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replacePlannerCompanyLogoFile() {
    this.plannerCompanyLogoFileUrl = null;
    this.plannerCompanyHideButtons = false;
  }

  async loadPlannerCompanyLogoFile() {
    if (this.plannerCompanyLogoFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.plannerCompanyLogoFileId);
        this.plannerCompanyLogoFileUrl = fileUrl;
        this.plannerCompanyHideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async setSeniorPlannerSignatureFile(event: { target: { files: any[]; }; }) {
    try {
      this.seniorPlannerSignatureFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.seniorPlannerSignatureFileId = this.seniorPlannerSignatureFileId;
      this.seniorPlannerSignatureFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.seniorPlannerSignatureFileUrl = event.target.result;
        this.seniorPlannerSignatureHideButtons = true;
      }

      reader.readAsDataURL(this.seniorPlannerSignatureFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replaceSeniorPlannerSignatureFile() {
    this.seniorPlannerSignatureFileUrl = null;
    this.seniorPlannerSignatureHideButtons = false;
  }

  async loadSeniorPlannerSignatureFile() {
    if (this.seniorPlannerSignatureFileId) {
      try {
        const fileUrl = await this.reportingService.getFileUrl(this.seniorPlannerSignatureFileId);
        this.seniorPlannerSignatureFileUrl = fileUrl;
        this.seniorPlannerSignatureHideButtons = !!fileUrl;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  async setNetworkPlannerSignatureFile(event: { target: { files: any[]; }; }) {
    try {
      this.networkPlannerSignatureFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.exportReportData.networkPlannerSignatureFileId = this.networkPlannerSignatureFileId;
      this.networkPlannerSignatureFile = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.networkPlannerSignatureFileUrl = event.target.result;
        this.networkPlannerSignatureHideButtons = true;
      }

      reader.readAsDataURL(this.networkPlannerSignatureFile);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  replaceNetworkPlannerSignatureFile() {
    this.networkPlannerSignatureFileUrl = null;
    this.networkPlannerSignatureHideButtons = false;
  }

  async loadNetworkPlannerSignatureFile() {
    if (this.networkPlannerSignatureFileId) {
      try {
        const fileUrlNetwork = await this.reportingService.getFileUrl(this.networkPlannerSignatureFileId);
        this.networkPlannerSignatureFileUrl = fileUrlNetwork;
        this.networkPlannerSignatureHideButtons = !!fileUrlNetwork;
      } catch (error) {
        this.notificationService.showErrorMessage('Error', 'Could not load file.');
      }
    }
  }

  private getUserId(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed?.sub;
  }

  openReportKeyDialog(): void {
    this.dialog.open(ReportKeyDialogComponent, {
      width: '30vw',
      height: '35vh'
    });
  }

  goToAddendumThree() {
    this.tabNumberEvent.emit(4);
  }

  setFieldToExportData(): void {
    if (this.selectedListing) {
      this.exportReportData.projectName = this.selectedListing.addressTitle;
      let metroAbbreviation = this.getMetroAbbreviation(this.selectedListing.propertyDetail.riskscape?.riskscapePropertyInfo.municipality) ?? "NA";
      let yearMonth = this.datePipe.transform(this.exportReportDto.date, 'yyMM') ?? this.datePipe.transform(new Date(), 'yyMM');
      this.exportReportData.referenceNumber = "LIS/" + metroAbbreviation + "/" + yearMonth + "/001";
    } else {
      this.notificationService.showWarningMessage("Warning", "Could not retrieve exported data pre-populated fields.");
    }
  }

  getMetroAbbreviation(municipality: string): String {
    let metroAbbreviation = "NA";

    switch (municipality.toLowerCase()) {
      case "city of cape town": {
        metroAbbreviation = "CPT";
        break;
      }
      case "tbc": {
        metroAbbreviation = "TBC";
        break;
      }
      case "city of johannesburg": {
        metroAbbreviation = "JHB";
        break;
      }
      case "ekurhuleni": {
        metroAbbreviation = "EKH";
        break;
      }
      case "city of tshwane": {
        metroAbbreviation = "PTA";
        break;
      }
      case "ethekwini": {
        metroAbbreviation = "CPT";
        break;
      }
      default: {
        break;
      }
    }
    return metroAbbreviation;
  }

  setPropertyListingTask(): void {
    let changedTaskLists = [] as TaskListDto[];

    this.selectedListing.taskList.forEach(task => {
      if (task.taskNumber.number === 8) {
        if (task.taskStatus.taskStatusNumber != 3) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
      if (task.taskNumber.number === 9) {
        if (task.taskStatus.taskStatusNumber == 1) {
          task.user = this.currentUser;
          changedTaskLists.push(task);
        }
      }
    });

    if (changedTaskLists.length > 0) {
      this.propertyListingService.apiV1PropertyListingUpdatePropertyListingTaskPost({ body: changedTaskLists }).subscribe(result => {
        if (result == null) {
          this.notificationService.showErrorMessage('Error', 'Failed to update planners.');
        }
      });
    }
  }

  getCurrentUser(): void {
    this.userManagementService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }
}