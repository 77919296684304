<main class="dialog-container">
    <span class="paragraph bold user-dialog-heading">Add Agent</span>
    <div class="row-container pt-1 pb-1">
        <span class="paragraph bold">What agent are you adding?</span>
    </div>
    <form class="user-form" #agentForm="ngForm">
        <div class="row paragraph">
            <div class="col-12 mt-2">
                <label class="col-12 bold">First Name</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="firstName" name="firstName" placeholder="John">
            </div>
            <div class="col-12 mt-2">
                <label class="col-12 bold">Last Name</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="lastName" name="lastName" placeholder="Doe">
            </div>
            <div class="col-12 mt-2">
                <label class="col-12 bold">
                    <span class="required-text">*</span>Email
                </label>
                <input class="col-12 mt-1" type="email" [(ngModel)]="estateAgentInvite.email" name="email"
                    placeholder="johndoe@gmail.com" required email>
                <div *ngIf="agentForm.controls.email?.invalid && agentForm.controls.email?.touched"
                    class="required-text mt-1">
                    Please enter a valid email address.
                </div>
            </div>
            <div class="col-12 mt-2 hidden">
                <label class="col-12 bold">For a new agent to register, provide them with this link</label>
                <input class="col-12 mt-1" type="text" [(ngModel)]="newRegisterLink" name="newRegisterLink" readonly
                    placeholder="www.propertymatrix.agentregistration.com">
            </div>
        </div>
    </form>
    <div class="button-group mt-3">
        <button class="footer-button background-cloud" (click)="closeDialog()">
            Cancel
        </button>
        <button class="footer-button background-coral" (click)="sendInvite()" [disabled]="agentForm.invalid">
            Send link
        </button>
    </div>
</main>