import { NotAvailableDialogComponent } from 'src/app/shared/components/not-available-dialog/not-available-dialog.component';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { UserManagementService } from 'src/app/services/property-matrixV2/services';
import { GlobalMethods } from 'src/app/common/global-methods';
import { NavigationExtras, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-your-property',
  templateUrl: './sell-your-property.component.html',
  styleUrls: ['./sell-your-property.component.scss', '../../../css/2-modules/_admin-portal.scss']
})
export class SellYourPropertyComponent implements OnInit {

  isloggedIn: boolean = false;

  constructor(
    private _router: Router,
    private _notificationService: NotificationService,
    private _userManagementService: UserManagementService,
    private keycloakService: KeycloakService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then((loggedIn: boolean) => {
      this.isloggedIn = loggedIn;
    });
    GlobalMethods.scrollToTop();
  }

  onProceed(route: string, sellerType: string, available: boolean): void {
    if (available) {
      if (this.isloggedIn) {
        this.checkIfLoggedInUserIsAgent(route, sellerType);
      } else {
        this._notificationService.showWarningMessage("Warning", "Please login to create listing.");
      }
    }
    else {
      this.openDialog();
    }
  }
  private checkIfLoggedInUserIsAgent(route: string, sellerType: string): void {
    try {
      this._userManagementService.apiV1UserManagementGetCurrentUserLoggedInUserGet({}).subscribe(userDto => {
        if (userDto && userDto.userRoles) {
          const containsRoles = userDto.userRoles.some(r => (r.roleName === 'Network Agent') || (r.roleName === 'Principle Agent') || (r.roleName === 'Planner Portal Administrator'));
          if (containsRoles == false) {
            this._notificationService.showWarningMessage('Warning', 'The current user does not have the Agent role and will not be allowed to create a listing.');
          }
          else {
            const navigationExtras: NavigationExtras = {
              queryParams: {
                sellerType: sellerType
              }
            };
            this._router.navigate([route], navigationExtras);
          }
        } else {
          this._notificationService.showErrorMessage('Error', 'Could not load current logged in users to check the role.');
        }
      })
    }
    catch (e) {
      this._notificationService.showErrorMessage('Error', 'Could not load current logged in users to check the role.');
    }
  }

  openDialog(): void {
    this.dialog.open(NotAvailableDialogComponent);
  }
}
