import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { PropertyListingService, UserManagementService } from 'src/app/services/property-matrixV2/services';
import { MultiplePropertyListingDto, PropertyListingDto, TaskListDto } from 'src/app/services/property-matrixV2/models';
import { UserDto } from 'src/app/services/property-matrixV2/models';
import { MatSort } from '@angular/material/sort';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';

@Component({
  selector: 'app-listing-tasks',
  templateUrl: './listing-tasks.component.html',
  styleUrls: ['./listing-tasks.component.scss']
})
export class ListingTasksComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() selectedListing: PropertyListingDto;
  @Input() selectedListingGroup: MultiplePropertyListingDto;
  @Input() listingType: string;

  phase: number = 1;
  receivedDate: string;
  taskList: TaskListDto[] = [];
  activeUsers: UserDto[] = [];
  users = [];
  tasks = [];
  displayedColumns: string[] = ['number', 'task', 'user', 'notes', 'status'];
  dataSource = new MatTableDataSource(this.tasks);
  currentUser: UserDto;

  constructor(
    private router: Router,
    private listingService: PropertyListingService,
    private userService: UserManagementService,
    private notificationService: NotificationService
  ) {
    this.setPhase();
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.receivedDate = (this.listingType === 'single') ? this.selectedListing.createdDate : this.selectedListingGroup.createdDate;
    this.getUsers();
    this.getCurrentUser();
  }

  setPhase(): void {
    this.tasks = this.tasks.map(task => {
      const user = this.users.find(u => u.name === task.user);
      return {
        ...task,
        role: user.role,
        roleInitial: user.roleInitial,
        roleColor: user.roleColor
      };
    });

    this.dataSource = new MatTableDataSource(this.tasks);
  }

  getPropertyListingTasks(): void {
    this.listingService.apiV1PropertyListingGetTaskListsGet({ propertyListingId: this.selectedListing.id }).pipe().subscribe(result => {
      if (result.length === 0) {
        this.listingService.apiV1PropertyListingCreateAllTaskForPropertyListingGet({ propertyListingId: this.selectedListing.id }).subscribe(res => {
          this.taskList = res;
          this.taskList = this.taskList.sort((a, b) => a.taskNumber.number - b.taskNumber.number);
          let newUsers = [];
          let newTasks = [];
          this.taskList.forEach(task => {
            const roleColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

            if (task.user == null) {
              task.user = this.activeUsers.find(f => f.userRoles.some(a => a.roleName === 'Planner Portal Administrator'));
            }

            newUsers.push({
              'name': task.user.name + ' ' + task.user.surname,
              'role': task.user.userRoles[0].roleName,
              'roleInitial': task.user.userRoles[0].roleName.substring(0, 1),
              'roleColor': roleColor
            });
            newTasks.push({
              'number': task.taskNumber.number,
              'task': task.taskNumber.name,
              'user': task.user.name + ' ' + task.user.surname,
              'notes': task.comment ?? '',
              'status': task.taskStatus.value
            });
            this.users = newUsers;
            this.tasks = newTasks;
            this.setPhase();
          });
        });
        return;
      }
      else {
        this.taskList = result;
        this.taskList = this.taskList.sort((a, b) => a.taskNumber.number - b.taskNumber.number);
        let newUsers = [];
        let newTasks = [];
        this.taskList.forEach(task => {
          const roleColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

          if (task.user == null) {
            task.user = this.activeUsers.find(f => f.userRoles.some(a => a.roleName === 'Planner Portal Administrator'));
          }

          newUsers.push({
            'name': task.user.name + ' ' + task.user.surname,
            'role': task.user.userRoles[0].roleName,
            'roleInitial': task.user.userRoles[0].roleName.substring(0, 1),
            'roleColor': roleColor
          });
          newTasks.push({
            'number': task.taskNumber.number,
            'task': task.taskNumber.name,
            'user': task.user.name + ' ' + task.user.surname,
            'notes': task.comment ?? '',
            'status': task.taskStatus.value
          });
          this.users = newUsers;
          this.tasks = newTasks;
          this.setPhase();
        });
      }
    });
  }

  getUsers(): void {
    this.userService.apiV1UserManagementGetUsersGet().subscribe(user => {
      this.activeUsers = user;
      this.getPropertyListingTasks();
    });
  }

  goToTaskOverview(taskNumber: number, taskName: string) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'taskNumber': taskNumber,
        'taskName': taskName,
        'listingType': this.listingType
      }
    };
    this.router.navigate(['admin/listings/task-overview'], navigationExtras);
  }

  onRowClicked(row: any) {
    let correctUserFromTask = this.taskList.find(f => f.taskNumber.number === row.number).user;
    if (correctUserFromTask.id == this.currentUser.id
      || this.activeUsers.find(f => f.userRoles.some(a => a.roleName === 'Planner Portal Administrator' && f.id == this.currentUser.id))) {
      //only go to tasklist if all previous task up to seleced are completed
      let taskList = this.taskList;
      let taskIndex = taskList.findIndex(f => f.taskNumber.number === row.number);
      let previousTasks = taskList.slice(0, taskIndex);
      let previousTasksCompleted = previousTasks.every(f => f.taskStatus.value === 'Done');
      if (previousTasksCompleted) {
        this.goToTaskOverview(row.number, row.task);
      }
      else {
        this.notificationService.showWarningMessage('Task Not Available', 'You cannot access this task yet.');
      }
    }
    else {
      this.notificationService.showWarningMessage('Task Not Available', 'You cannot access this task.');
    }
  }

  getCurrentUser(): void {
    this.userService.apiV1UserManagementGetCurrentUserGet().subscribe(result => {
      if (result != null) {
        this.currentUser = result;
      }
      else {
        this.notificationService.showWarningMessage('No Current User', 'No current user found.');
      }
    });
  }
}
