<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<main class="main-container">
    <mat-card>
        <span class="paragraph bold user-heading">Users</span>
        <div class="row-container pt-1 pb-1">
            <span class="paragraph user-subheading">All users and their roles</span>
            <div class="d-flex">
                <button (click)="openAddEditDialog()" class="center-content add-button">
                    <mat-icon>add</mat-icon>
                    <span class="paragraph">Add User</span>
                </button>
                <div class="search-wrapper">
                    <mat-icon>search</mat-icon>
                    <input type="text" placeholder="Search" class="search-input" (input)="applyFilter($event)" />
                </div>
            </div>
        </div>
        <div class="row-container pt-1 pb-1">
            <div class="user-role-container">
                <div>
                    <button class="role-filter-button" (click)="allUsers()">
                        <mat-icon class="material-icons-outlined">person</mat-icon>
                        <span class="paragraph bold">All</span>
                    </button>
                    <button class="role-filter-button" (click)="archivedUsers()">
                        <mat-icon class="material-icons-outlined">archive</mat-icon>
                        <span class="paragraph bold">Archived</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row-container pt-1 pb-1">
            <div class="user-role-container">
                <div>
                    <button *ngFor="let role of usersRoles" class="role-filter-button hidden"
                        (click)="toggleRoleFilter($event)">
                        <mat-icon class="material-icons-outlined">person</mat-icon>
                        <span class="paragraph bold">{{ role.name }}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="row-container pt-1 pb-1">
            <div class="user-role-container">
                <div>
                    <button *ngFor="let role of roles" class="role-filter-button" (click)="toggleRoleFilter($event)">
                        <mat-icon class="material-icons-outlined">person</mat-icon>
                        <span class="paragraph bold">{{ role }}</span>
                    </button>
                    <button class="network-filter-button" (click)="toggleNetworkFilter()">
                        <mat-icon class="material-icons-outlined">group</mat-icon>
                        <span class="paragraph bold">Select Network User</span>
                        <mat-icon *ngIf="!networkUserSelected" class="material-icons-outlined">
                            keyboard_arrow_up
                        </mat-icon>
                        <mat-icon *ngIf="networkUserSelected" class="material-icons-outlined">
                            keyboard_arrow_down
                        </mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <app-data-table [data]="data" [columns]="columns" [actionOptions]="actionOptions"></app-data-table>
    </mat-card>
</main>