/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdvertCreatorFiltersDto } from '../models/advert-creator-filters-dto';
import { AdvertDto } from '../models/advert-dto';
import { AdvertGenerateAdditionalImagesDto } from '../models/advert-generate-additional-images-dto';
import { AdvertGenerateStatusDto } from '../models/advert-generate-status-dto';
import { AdvertGeneratorDto } from '../models/advert-generator-dto';
import { AdvertGeneratorDtoOperationResult } from '../models/advert-generator-dto-operation-result';
import { AdvertGeneratorLinksDtoListOperationResult } from '../models/advert-generator-links-dto-list-operation-result';
import { AdvertKeyFeaturesDto } from '../models/advert-key-features-dto';
import { AdvertLocationDto } from '../models/advert-location-dto';
import { AdvertSectorDto } from '../models/advert-sector-dto';
import { AdvertTypesDto } from '../models/advert-types-dto';

@Injectable({
  providedIn: 'root',
})
export class AdvertService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1AdvertGetAdvertFiltersCreatedByIdGet
   */
  static readonly ApiV1AdvertGetAdvertFiltersCreatedByIdGetPath = '/api/v1/Advert/GetAdvertFiltersCreatedById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAdvertFiltersCreatedByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertFiltersCreatedByIdGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertCreatorFiltersDto>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAdvertFiltersCreatedByIdGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertCreatorFiltersDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAdvertFiltersCreatedByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertFiltersCreatedByIdGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<AdvertCreatorFiltersDto> {

    return this.apiV1AdvertGetAdvertFiltersCreatedByIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertCreatorFiltersDto>) => r.body as AdvertCreatorFiltersDto)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertFiltersCreatedDelete
   */
  static readonly ApiV1AdvertDeleteAdvertFiltersCreatedDeletePath = '/api/v1/Advert/DeleteAdvertFiltersCreated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertFiltersCreatedDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertFiltersCreatedDelete$Response(params?: {
    advertFilterId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertFiltersCreatedDeletePath, 'delete');
    if (params) {
      rb.query('advertFilterId', params.advertFilterId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertFiltersCreatedDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertFiltersCreatedDelete(params?: {
    advertFilterId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1AdvertDeleteAdvertFiltersCreatedDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAllAdvertFiltersCreatedGet
   */
  static readonly ApiV1AdvertGetAllAdvertFiltersCreatedGetPath = '/api/v1/Advert/GetAllAdvertFiltersCreated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAllAdvertFiltersCreatedGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertFiltersCreatedGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertCreatorFiltersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAllAdvertFiltersCreatedGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertCreatorFiltersDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAllAdvertFiltersCreatedGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertFiltersCreatedGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertCreatorFiltersDto>> {

    return this.apiV1AdvertGetAllAdvertFiltersCreatedGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertCreatorFiltersDto>>) => r.body as Array<AdvertCreatorFiltersDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertDataPost
   */
  static readonly ApiV1AdvertSaveAdvertDataPostPath = '/api/v1/Advert/SaveAdvertData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertDataPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertDataPost$Response(params?: {
    context?: HttpContext
    body?: AdvertCreatorFiltersDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertDataPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertDataPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertDataPost(params?: {
    context?: HttpContext
    body?: AdvertCreatorFiltersDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertDataPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertLocationPost
   */
  static readonly ApiV1AdvertSaveAdvertLocationPostPath = '/api/v1/Advert/SaveAdvertLocation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertLocationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertLocationPost$Response(params?: {
    context?: HttpContext
    body?: AdvertLocationDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertLocationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertLocationPost(params?: {
    context?: HttpContext
    body?: AdvertLocationDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertLocationPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertSectorPost
   */
  static readonly ApiV1AdvertSaveAdvertSectorPostPath = '/api/v1/Advert/SaveAdvertSector';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertSectorPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertSectorPost$Response(params?: {
    context?: HttpContext
    body?: AdvertSectorDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertSectorPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertSectorPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertSectorPost(params?: {
    context?: HttpContext
    body?: AdvertSectorDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertSectorPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertTypesPost
   */
  static readonly ApiV1AdvertSaveAdvertTypesPostPath = '/api/v1/Advert/SaveAdvertTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertTypesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertTypesPost$Response(params?: {
    context?: HttpContext
    body?: AdvertTypesDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertTypesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertTypesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertTypesPost(params?: {
    context?: HttpContext
    body?: AdvertTypesDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertTypesPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAdvertFilterDataGet
   */
  static readonly ApiV1AdvertGetAdvertFilterDataGetPath = '/api/v1/Advert/GetAdvertFilterData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAdvertFilterDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertFilterDataGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAdvertFilterDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAdvertFilterDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertFilterDataGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertDto>> {

    return this.apiV1AdvertGetAdvertFilterDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertDto>>) => r.body as Array<AdvertDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertStatusPost
   */
  static readonly ApiV1AdvertSaveAdvertStatusPostPath = '/api/v1/Advert/SaveAdvertStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertStatusPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertStatusPost$Response(params?: {
    context?: HttpContext
    body?: AdvertGenerateStatusDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertStatusPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertStatusPost(params?: {
    context?: HttpContext
    body?: AdvertGenerateStatusDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertStatusPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertKeyFeaturePost
   */
  static readonly ApiV1AdvertSaveAdvertKeyFeaturePostPath = '/api/v1/Advert/SaveAdvertKeyFeature';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertKeyFeaturePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertKeyFeaturePost$Response(params?: {
    context?: HttpContext
    body?: AdvertKeyFeaturesDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertKeyFeaturePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertKeyFeaturePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertKeyFeaturePost(params?: {
    context?: HttpContext
    body?: AdvertKeyFeaturesDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertKeyFeaturePost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertGeneratorReportPost
   */
  static readonly ApiV1AdvertSaveAdvertGeneratorReportPostPath = '/api/v1/Advert/SaveAdvertGeneratorReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertGeneratorReportPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertGeneratorReportPost$Response(params?: {
    context?: HttpContext
    body?: AdvertGeneratorDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertGeneratorReportPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertGeneratorReportPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertGeneratorReportPost(params?: {
    context?: HttpContext
    body?: AdvertGeneratorDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertGeneratorReportPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertSaveAdvertGeneratorAdditionalImagesPost
   */
  static readonly ApiV1AdvertSaveAdvertGeneratorAdditionalImagesPostPath = '/api/v1/Advert/SaveAdvertGeneratorAdditionalImages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertSaveAdvertGeneratorAdditionalImagesPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertGeneratorAdditionalImagesPost$Response(params?: {
    context?: HttpContext
    body?: AdvertGenerateAdditionalImagesDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertSaveAdvertGeneratorAdditionalImagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertSaveAdvertGeneratorAdditionalImagesPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AdvertSaveAdvertGeneratorAdditionalImagesPost(params?: {
    context?: HttpContext
    body?: AdvertGenerateAdditionalImagesDto
  }
): Observable<void> {

    return this.apiV1AdvertSaveAdvertGeneratorAdditionalImagesPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetStatusDtOsGet
   */
  static readonly ApiV1AdvertGetStatusDtOsGetPath = '/api/v1/Advert/GetStatusDTOs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetStatusDtOsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetStatusDtOsGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertGenerateStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetStatusDtOsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertGenerateStatusDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetStatusDtOsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetStatusDtOsGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertGenerateStatusDto>> {

    return this.apiV1AdvertGetStatusDtOsGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertGenerateStatusDto>>) => r.body as Array<AdvertGenerateStatusDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetStatusDtoWithIdGet
   */
  static readonly ApiV1AdvertGetStatusDtoWithIdGetPath = '/api/v1/Advert/GetStatusDTOWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetStatusDtoWithIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetStatusDtoWithIdGet$Response(params?: {
    advertStatusId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertGenerateStatusDto>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetStatusDtoWithIdGetPath, 'get');
    if (params) {
      rb.query('advertStatusId', params.advertStatusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertGenerateStatusDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetStatusDtoWithIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetStatusDtoWithIdGet(params?: {
    advertStatusId?: string;
    context?: HttpContext
  }
): Observable<AdvertGenerateStatusDto> {

    return this.apiV1AdvertGetStatusDtoWithIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertGenerateStatusDto>) => r.body as AdvertGenerateStatusDto)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAllAdvertKeyFeaturesGet
   */
  static readonly ApiV1AdvertGetAllAdvertKeyFeaturesGetPath = '/api/v1/Advert/GetAllAdvertKeyFeatures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAllAdvertKeyFeaturesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertKeyFeaturesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertKeyFeaturesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAllAdvertKeyFeaturesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertKeyFeaturesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAllAdvertKeyFeaturesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertKeyFeaturesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertKeyFeaturesDto>> {

    return this.apiV1AdvertGetAllAdvertKeyFeaturesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertKeyFeaturesDto>>) => r.body as Array<AdvertKeyFeaturesDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAllAdvertGeneratorReportDataGet
   */
  static readonly ApiV1AdvertGetAllAdvertGeneratorReportDataGetPath = '/api/v1/Advert/GetAllAdvertGeneratorReportData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAllAdvertGeneratorReportDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertGeneratorReportDataGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertGeneratorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAllAdvertGeneratorReportDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertGeneratorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAllAdvertGeneratorReportDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertGeneratorReportDataGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertGeneratorDto>> {

    return this.apiV1AdvertGetAllAdvertGeneratorReportDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertGeneratorDto>>) => r.body as Array<AdvertGeneratorDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet
   */
  static readonly ApiV1AdvertGetAllAdvertGeneratorReportDataWithIdGetPath = '/api/v1/Advert/GetAllAdvertGeneratorReportDataWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet$Response(params?: {
    advertGeneratorId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDto>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAllAdvertGeneratorReportDataWithIdGetPath, 'get');
    if (params) {
      rb.query('advertGeneratorId', params.advertGeneratorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet(params?: {
    advertGeneratorId?: string;
    context?: HttpContext
  }
): Observable<AdvertGeneratorDto> {

    return this.apiV1AdvertGetAllAdvertGeneratorReportDataWithIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDto>) => r.body as AdvertGeneratorDto)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAllAdvertAdditionalImagesGet
   */
  static readonly ApiV1AdvertGetAllAdvertAdditionalImagesGetPath = '/api/v1/Advert/GetAllAdvertAdditionalImages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAllAdvertAdditionalImagesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertAdditionalImagesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertGenerateAdditionalImagesDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAllAdvertAdditionalImagesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertGenerateAdditionalImagesDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAllAdvertAdditionalImagesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertAdditionalImagesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertGenerateAdditionalImagesDto>> {

    return this.apiV1AdvertGetAllAdvertAdditionalImagesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertGenerateAdditionalImagesDto>>) => r.body as Array<AdvertGenerateAdditionalImagesDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAllAdvertAdditionalImageWithIdGet
   */
  static readonly ApiV1AdvertGetAllAdvertAdditionalImageWithIdGetPath = '/api/v1/Advert/GetAllAdvertAdditionalImageWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAllAdvertAdditionalImageWithIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertAdditionalImageWithIdGet$Response(params?: {
    advertAdditionalImageId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertGenerateAdditionalImagesDto>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAllAdvertAdditionalImageWithIdGetPath, 'get');
    if (params) {
      rb.query('advertAdditionalImageId', params.advertAdditionalImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertGenerateAdditionalImagesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAllAdvertAdditionalImageWithIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAllAdvertAdditionalImageWithIdGet(params?: {
    advertAdditionalImageId?: string;
    context?: HttpContext
  }
): Observable<AdvertGenerateAdditionalImagesDto> {

    return this.apiV1AdvertGetAllAdvertAdditionalImageWithIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertGenerateAdditionalImagesDto>) => r.body as AdvertGenerateAdditionalImagesDto)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertAdditionalImageWithIdDelete
   */
  static readonly ApiV1AdvertDeleteAdvertAdditionalImageWithIdDeletePath = '/api/v1/Advert/DeleteAdvertAdditionalImageWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertAdditionalImageWithIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertAdditionalImageWithIdDelete$Response(params?: {
    advertAdditionalImageId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertAdditionalImageWithIdDeletePath, 'delete');
    if (params) {
      rb.query('advertAdditionalImageId', params.advertAdditionalImageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertAdditionalImageWithIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertAdditionalImageWithIdDelete(params?: {
    advertAdditionalImageId?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AdvertDeleteAdvertAdditionalImageWithIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertTypesWithIdDelete
   */
  static readonly ApiV1AdvertDeleteAdvertTypesWithIdDeletePath = '/api/v1/Advert/DeleteAdvertTypesWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertTypesWithIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertTypesWithIdDelete$Response(params?: {
    advertTypeId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertTypesWithIdDeletePath, 'delete');
    if (params) {
      rb.query('advertTypeId', params.advertTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertTypesWithIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertTypesWithIdDelete(params?: {
    advertTypeId?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AdvertDeleteAdvertTypesWithIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertLocationWithIdDelete
   */
  static readonly ApiV1AdvertDeleteAdvertLocationWithIdDeletePath = '/api/v1/Advert/DeleteAdvertLocationWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertLocationWithIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertLocationWithIdDelete$Response(params?: {
    advertLocationId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertLocationWithIdDeletePath, 'delete');
    if (params) {
      rb.query('advertLocationId', params.advertLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertLocationWithIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertLocationWithIdDelete(params?: {
    advertLocationId?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AdvertDeleteAdvertLocationWithIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertSectorWithIdDelete
   */
  static readonly ApiV1AdvertDeleteAdvertSectorWithIdDeletePath = '/api/v1/Advert/DeleteAdvertSectorWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertSectorWithIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertSectorWithIdDelete$Response(params?: {
    advertSectorId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertSectorWithIdDeletePath, 'delete');
    if (params) {
      rb.query('advertSectorId', params.advertSectorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertSectorWithIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertSectorWithIdDelete(params?: {
    advertSectorId?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AdvertDeleteAdvertSectorWithIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertGenerateStatusWithIdDelete
   */
  static readonly ApiV1AdvertDeleteAdvertGenerateStatusWithIdDeletePath = '/api/v1/Advert/DeleteAdvertGenerateStatusWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertGenerateStatusWithIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertGenerateStatusWithIdDelete$Response(params?: {
    advertGenerateStatusId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertGenerateStatusWithIdDeletePath, 'delete');
    if (params) {
      rb.query('advertGenerateStatusId', params.advertGenerateStatusId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertGenerateStatusWithIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertGenerateStatusWithIdDelete(params?: {
    advertGenerateStatusId?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AdvertDeleteAdvertGenerateStatusWithIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDelete
   */
  static readonly ApiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDeletePath = '/api/v1/Advert/DeleteAdvertGenerateKeyFeatureWithId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDelete$Response(params?: {
    advertKeyFeatureId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDeletePath, 'delete');
    if (params) {
      rb.query('advertKeyFeatureId', params.advertKeyFeatureId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDelete(params?: {
    advertKeyFeatureId?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1AdvertDeleteAdvertGenerateKeyFeatureWithIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAdvertDataGet
   */
  static readonly ApiV1AdvertGetAdvertDataGetPath = '/api/v1/Advert/GetAdvertData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAdvertDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertDataGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<AdvertDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAdvertDataGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<AdvertDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAdvertDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertDataGet(params?: {
    context?: HttpContext
  }
): Observable<Array<AdvertDto>> {

    return this.apiV1AdvertGetAdvertDataGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<AdvertDto>>) => r.body as Array<AdvertDto>)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet
   */
  static readonly ApiV1AdvertGetAdvertReportDevelopedDataWithListingIdGetPath = '/api/v1/Advert/GetAdvertReportDevelopedDataWithListingId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDtoOperationResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAdvertReportDevelopedDataWithListingIdGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDtoOperationResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<AdvertGeneratorDtoOperationResult> {

    return this.apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDtoOperationResult>) => r.body as AdvertGeneratorDtoOperationResult)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet
   */
  static readonly ApiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGetPath = '/api/v1/Advert/GetAdvertReportDevelopAbleDataWithListingId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet$Response(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDtoOperationResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGetPath, 'get');
    if (params) {
      rb.query('propertyListingId', params.propertyListingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDtoOperationResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet(params?: {
    propertyListingId?: string;
    context?: HttpContext
  }
): Observable<AdvertGeneratorDtoOperationResult> {

    return this.apiV1AdvertGetAdvertReportDevelopAbleDataWithListingIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertGeneratorDtoOperationResult>) => r.body as AdvertGeneratorDtoOperationResult)
    );
  }

  /**
   * Path part for operation apiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGet
   */
  static readonly ApiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGetPath = '/api/v1/Advert/GetAdvertReportLinkedDataWithGenerateAdvertId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGet$Response(params?: {
    advertGeneratorId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<AdvertGeneratorLinksDtoListOperationResult>> {

    const rb = new RequestBuilder(this.rootUrl, AdvertService.ApiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGetPath, 'get');
    if (params) {
      rb.query('advertGeneratorId', params.advertGeneratorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<AdvertGeneratorLinksDtoListOperationResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGet(params?: {
    advertGeneratorId?: string;
    context?: HttpContext
  }
): Observable<AdvertGeneratorLinksDtoListOperationResult> {

    return this.apiV1AdvertGetAdvertReportLinkedDataWithGenerateAdvertIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<AdvertGeneratorLinksDtoListOperationResult>) => r.body as AdvertGeneratorLinksDtoListOperationResult)
    );
  }

}
