/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ReportGeneratorService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelOneSingleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelOneSingleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelOneSingleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelOneSingleReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Response(params?: {
    listingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelOneSingleReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneSingleReportGet(params?: {
    listingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1ReportGeneratorGenerateLevelOneSingleReportGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelOneMultipleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelOneMultipleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelOneMultipleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelOneMultipleReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Response(params?: {
    multipleListingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelOneMultipleReportGetPath, 'get');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelOneMultipleReportGet(params?: {
    multipleListingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1ReportGeneratorGenerateLevelOneMultipleReportGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelTwoSingleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelTwoSingleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelTwoSingleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelTwoSingleReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Response(params?: {
    listingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelTwoSingleReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoSingleReportGet(params?: {
    listingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1ReportGeneratorGenerateLevelTwoSingleReportGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateLevelTwoMultipleReportGetPath = '/api/v1/ReportGenerator/GenerateLevelTwoMultipleReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Response(params?: {
    multipleListingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateLevelTwoMultipleReportGetPath, 'get');
    if (params) {
      rb.query('multipleListingId', params.multipleListingId, {});
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet(params?: {
    multipleListingId?: string;
    userId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1ReportGeneratorGenerateLevelTwoMultipleReportGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet
   */
  static readonly ApiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGetPath = '/api/v1/ReportGenerator/GenerateOwnershipAdditionalDocumentReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet$Response(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportGeneratorService.ApiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGetPath, 'get');
    if (params) {
      rb.query('listingId', params.listingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet(params?: {
    listingId?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1ReportGeneratorGenerateOwnershipAdditionalDocumentReportGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

}
