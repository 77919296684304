<div class="loading" *ngIf="loading == true">Loading&#8230;</div>
<div class="loading" *ngIf="loadingCadaster == true">Loading&#8230;</div>
<div class="confirm-property-seller-details">
    <div *ngIf="propertyDataValid== true">
        <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
            <div class="col-12 heading-2">
                <div class="row">
                    <div class="col-11">{{ section.value }}</div>
                    <div class="col-1 collapse-expand">
                        <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                            <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                            <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">
                            Property Boundary
                        </label>
                    </div>
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <div class="upload-background">
                                    <div class="upload-block" appDragAndDrop>
                                        <input type="file" (change)="setFile($event)" class="upload-block-inner"
                                            multiple accept="image/*" id="input1" placeholder="image">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                                            </div>
                                            <div class="col-12">
                                                Click to upload.
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="fileUrl">
                                            <div class="col-12">
                                                <div class="image-upload">
                                                    <img [src]="fileUrl" alt="Image Preview" id="preview"
                                                        class="upload-block-inner">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="PropertyBoundaryImageURL">
                                            <div class="col-12">
                                                <div class="image-upload">
                                                    <img [src]="PropertyBoundaryImageURL" alt="Image Preview"
                                                        id="preview" class="upload-block-inner">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="hideButtons">
                            <div class="col-12 button-group mt-3 mb-3">
                                <a class="footer-button download-button background-charcoal text-center"
                                    [href]="PropertyBoundaryImageURL" [download]="file?.name">
                                    Download
                                </a>
                                <button type="button" class="footer-button background-charcoal"
                                    (click)="replace()">Replace</button>
                                <button type="button" class="footer-button background-charcoal"
                                    (click)="deleteFile()">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Property Key</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="Property Key" [(ngModel)]="riskscapePropertyInfoData.id"
                                    [value]="riskscapePropertyInfoData.id ? riskscapePropertyInfoData.id : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Unit Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="sectionalTitleUnitNumber"
                                    [(ngModel)]="riskscapePropertyInfoData.sectionalTitleUnitNumber"
                                    [value]="riskscapePropertyInfoData.sectionalTitleUnitNumber ? riskscapePropertyInfoData.sectionalTitleUnitNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Erf Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="erfNumber"
                                    [(ngModel)]="riskscapePropertyInfoData.erfNumber"
                                    [value]="riskscapePropertyInfoData.erfNumber ? riskscapePropertyInfoData.erfNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Portion Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="portionNumber" [(ngModel)]="riskscapePropertyInfoData.portionNumber"
                                    [value]="riskscapePropertyInfoData.portionNumber ? riskscapePropertyInfoData.portionNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Township</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="subPlaceName" [(ngModel)]="riskscapePropertyInfoData.subPlaceName"
                                    [value]="riskscapePropertyInfoData.subPlaceName ? riskscapePropertyInfoData.subPlaceName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Deeds Erf Extent</label>
                                <input class="col-12 mt-1 riskscape-data" type="number" readonly
                                    [(ngModel)]="riskscapePropertyInfoData.deedsErfExtent" placeholder="deedsErfExtent"
                                    [value]="riskscapePropertyInfoData.deedsErfExtent ? riskscapePropertyInfoData.deedsErfExtent : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Surveyor General Erf Extent</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="surveyorGeneralErfExtent"
                                    [(ngModel)]="riskscapePropertyInfoData.surveyorGeneralErfExtent"
                                    [value]="riskscapePropertyInfoData.surveyorGeneralErfExtent ? riskscapePropertyInfoData.surveyorGeneralErfExtent : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">S.G Code</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    [(ngModel)]="riskscapePropertyInfoData.surveyorGeneralKey"
                                    placeholder="surveyorGeneralKey"
                                    [value]="riskscapePropertyInfoData.surveyorGeneralKey ? riskscapePropertyInfoData.surveyorGeneralKey : 'n/a'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Street Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="streetNumber" [(ngModel)]="riskscapePropertyInfoData.streetNumber"
                                    [value]="riskscapePropertyInfoData.streetNumber ? riskscapePropertyInfoData.streetNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Street Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="streetName"
                                    [(ngModel)]="riskscapePropertyInfoData.streetName"
                                    [value]="riskscapePropertyInfoData.streetName ? riskscapePropertyInfoData.streetName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Complex Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="complexName"
                                    [(ngModel)]="riskscapePropertyInfoData.complexName"
                                    [value]="riskscapePropertyInfoData.complexName ? riskscapePropertyInfoData.complexName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Suburb Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="suburbName"
                                    [(ngModel)]="riskscapePropertyInfoData.suburbName"
                                    [value]="riskscapePropertyInfoData.suburbName ? riskscapePropertyInfoData.suburbName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Sub-Place Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="subPlaceName" [(ngModel)]="riskscapePropertyInfoData.subPlaceName"
                                    [value]="riskscapePropertyInfoData.subPlaceName ? riskscapePropertyInfoData.subPlaceName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">City/Town</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="city"
                                    [(ngModel)]="riskscapePropertyInfoData.city"
                                    [value]="riskscapePropertyInfoData.city ? riskscapePropertyInfoData.city : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Municipality</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="municipality" [(ngModel)]="riskscapePropertyInfoData.municipality"
                                    [value]="riskscapePropertyInfoData.municipality ? riskscapePropertyInfoData.municipality : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Province</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="province"
                                    [(ngModel)]="riskscapePropertyInfoData.province"
                                    [value]="riskscapePropertyInfoData.province ? riskscapePropertyInfoData.province : 'n/a'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 4" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">Lat-long Coordinates</label>
                        <input class="col-12 mt-1 riskscape-data" type="text" readonly [(ngModel)]="LatLongCoordinates"
                            placeholder="Lat-long Coordinates" />
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 5" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <table mat-table [dataSource]="dataRiskscapeDeedsCurrentOwnerInfo" matSort class="table">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element">{{ element.name ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="idNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Number </th>
                                <td mat-cell *matCellDef="let element">{{ element.idNumber ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="maritalStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Marital Status </th>
                                <td mat-cell *matCellDef="let element">{{ element.maritalStatus ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="ownerType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Type </th>
                                <td mat-cell *matCellDef="let element">{{ element.ownerType ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="ownerShare">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Share </th>
                                <td mat-cell *matCellDef="let element">{{ element.ownerShare ?? "N/a" }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator class="hidden" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </div>

            <div class="col-12 pb-2" *ngIf="section.key == 6" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <mat-checkbox [(ngModel)]="propertyDescriptionAddressCorrect">Property description
                            and address is
                            correct.</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="propertyBoundaryCorrect">Property boundary is
                            correct.</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="sellerInformationCorrect">All Seller's information is
                            correct.</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="confidentImportRiskscapeData">I am confident to import
                            Riskscape data.</mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div *ngIf="propertyDataValid== false">
        <mat-card class="mb-3" *ngFor="let section of sectionsIncorrect; let i = index">
            <div class="col-12 heading-2">
                <div class="row">
                    <div class="col-11">{{ section.value }}</div>
                    <div class="col-1 collapse-expand">
                        <button (click)="collapseOrExpandSection(section)" mat-icon-button>
                            <mat-icon *ngIf="section.checked">expand_less</mat-icon>
                            <mat-icon *ngIf="!section.checked">expand_more</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <label class="col-12 bold">
                            Property Boundary
                        </label>
                    </div>
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <div class="upload-background">
                                    <div class="upload-block" appDragAndDrop>
                                        <input type="file" (change)="setFile($event)" class="upload-block-inner"
                                            multiple accept="image/*" id="input" placeholder="image">
                                        <div class="row">
                                            <div class="col-12">
                                                <mat-icon class="file-icon">cloud_upload</mat-icon>
                                            </div>
                                            <div class="col-12">
                                                Click to upload.
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="fileUrl">
                                            <div class="col-12">
                                                <div class="image-upload">
                                                    <img [src]="fileUrl" alt="Image Preview" id="preview"
                                                        class="upload-block-inner">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="PropertyBoundaryImageURL">
                                            <div class="col-12">
                                                <div class="image-upload ">
                                                    <img class="padding mt-3 mb-3" [src]="PropertyBoundaryImageURL"
                                                        alt="Image Preview" id="preview" class="upload-block-inner">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 2" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12 position-relative">
                                <label class="col-12 bold">Seller Response</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="tag" />
                                <div class="button-container">
                                    <button *ngIf="confirmPropertyBoundary==1" class="incorrect-button"
                                        confirmPropertyBoundary="1">
                                        Incorrect
                                    </button>
                                    <button *ngIf="confirmPropertyBoundary==2" class="unsure-button"
                                        confirmPropertyBoundary="2">
                                        Unsure
                                    </button>
                                    <button *ngIf="confirmPropertyBoundary==3" class="correct-button"
                                        confirmPropertyBoundary="3">
                                        Correct
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Seller explanation</label>
                                <input class="col-12 mt-1 riskscape-data coral-text" type="text"
                                    placeholder="Seller explanation"
                                    [(ngModel)]="selectedListing.propertyDetail.propertyBoundaryConcern"
                                    [value]="selectedListing.propertyDetail.propertyBoundaryConcern ? selectedListing.propertyDetail.propertyBoundaryConcern : 'n/a'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 3" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Property Key</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="Property Key" [(ngModel)]="riskscapePropertyInfoData.id"
                                    [value]="riskscapePropertyInfoData.id ? riskscapePropertyInfoData.id : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Unit Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="sectionalTitleUnitNumber"
                                    [(ngModel)]="riskscapePropertyInfoData.sectionalTitleUnitNumber"
                                    [value]="riskscapePropertyInfoData.sectionalTitleUnitNumber ? riskscapePropertyInfoData.sectionalTitleUnitNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Erf Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly placeholder="erfNumber"
                                    [(ngModel)]="riskscapePropertyInfoData.erfNumber"
                                    [value]="riskscapePropertyInfoData.erfNumber ? riskscapePropertyInfoData.erfNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Portion Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="portionNumber" [(ngModel)]="riskscapePropertyInfoData.portionNumber"
                                    [value]="riskscapePropertyInfoData.portionNumber ? riskscapePropertyInfoData.portionNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Township</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="subPlaceName" [(ngModel)]="riskscapePropertyInfoData.subPlaceName"
                                    [value]="riskscapePropertyInfoData.subPlaceName ? riskscapePropertyInfoData.subPlaceName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Deeds Erf Extent</label>
                                <input class="col-12 mt-1 riskscape-data" type="number" readonly
                                    [(ngModel)]="riskscapePropertyInfoData.deedsErfExtent" placeholder="deedsErfExtent"
                                    [value]="riskscapePropertyInfoData.deedsErfExtent ? riskscapePropertyInfoData.deedsErfExtent : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Surveyor General Erf Extent</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" readonly
                                    placeholder="surveyorGeneralErfExtent"
                                    [(ngModel)]="riskscapePropertyInfoData.surveyorGeneralErfExtent"
                                    [value]="riskscapePropertyInfoData.surveyorGeneralErfExtent ? riskscapePropertyInfoData.surveyorGeneralErfExtent : 'n/a'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 4" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12 position-relative">
                                <label class="col-12 bold">Seller Response</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="tag" />
                                <div class="button-container">
                                    <button *ngIf="confirmPropertyBoundary==1" class="incorrect-button"
                                        confirmPropertyBoundary="1">
                                        Incorrect
                                    </button>
                                    <button *ngIf="confirmPropertyBoundary==2" class="unsure-button"
                                        confirmPropertyBoundary="2">
                                        Unsure
                                    </button>
                                    <button *ngIf="confirmPropertyBoundary==3" class="correct-button"
                                        confirmPropertyBoundary="3">
                                        Correct
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Property Address</label>
                                <input class="col-12 mt-1 riskscape-data coral-text" type="text"
                                    placeholder="Property Address"
                                    [(ngModel)]="selectedListing.propertyDetail.propertyAddress"
                                    [value]="selectedListing.propertyDetail.propertyAddress ? selectedListing.propertyDetail.propertyAddress : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Property Description</label>
                                <input class="col-12 mt-1 riskscape-data coral-text" type="text"
                                    placeholder="Property Description"
                                    [(ngModel)]="selectedListing.propertyDetail.propertyDescription"
                                    [value]="selectedListing.propertyDetail.propertyDescription ? selectedListing.propertyDetail.propertyDescription : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <span class="bold">Please select the correct property (land parcel) carefully on the
                                    map, you only have five attempts.</span>
                                <div class="row text-center map-container">
                                    <div class="map-plugin-container col-12 col-md-8">
                                        <app-map-search (selectedProperty)="handleSelectedPropertyChange($event)"
                                            (scriptLoadStatus)="handleScriptLoadStatus($event)">
                                        </app-map-search>
                                    </div>
                                    <div class="map-sidenav-container col-12 col-md-4">
                                        <div class="sidenav-header">
                                            <h2>Confirm Property</h2>
                                        </div>
                                        <div class="sidenav-body">
                                            <div *ngIf="cadasterPicture != 1">
                                                <div class="sidenav-sub-heading">
                                                    <h2>Property Boundary</h2>
                                                </div>
                                                <div id="sidebar-map" class="small-map">
                                                    <img [src]="PropertyBoundaryImageURL" class="cadastre-image"
                                                        alt="Cadaster Picture">
                                                </div>
                                                <div class="button-container hidden">
                                                    <button class="incorrect-button"
                                                        [class.selected]="selectedBoundaryButton === 1"
                                                        (click)="selectBoundaryButton(1)" confirmPropertyBoundary="1">
                                                        Incorrect
                                                    </button>
                                                    <button class="unsure-button"
                                                        [class.selected]="selectedBoundaryButton === 2"
                                                        (click)="selectBoundaryButton(2)" confirmPropertyBoundary="2">
                                                        Unsure
                                                    </button>
                                                    <button class="correct-button"
                                                        [class.selected]="selectedBoundaryButton === 3"
                                                        (click)="selectBoundaryButton(3)" confirmPropertyBoundary="3">
                                                        Correct
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="sidenav-sub-heading">
                                                <h2>Property Details</h2>
                                            </div>
                                            <div class="property-details-table">
                                                <table>
                                                    <tr>
                                                        <td>Unit Number</td>
                                                        <td>{{ this.unitNumber }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Erf/Portion Nr</td>
                                                        <td>{{ this.erfOrPortionNumber }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Short Address</td>
                                                        <td>{{ this.shortAddress }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Suburb Name</td>
                                                        <td>{{ this.suburbName }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>City/Town</td>
                                                        <td>{{ this.cityTown }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="button-container hidden">
                                                <button class="incorrect-button" [class.selected]="selectedButton === 1"
                                                    (click)="selectButton(1)" confirmPropertyDetail="1">
                                                    Incorrect
                                                </button>
                                                <button class="unsure-button" [class.selected]="selectedButton === 2"
                                                    (click)="selectButton(2)" confirmPropertyDetail="2">
                                                    Unsure
                                                </button>
                                                <button class="correct-button" [class.selected]="selectedButton === 3"
                                                    (click)="selectButton(3)" confirmPropertyDetail="3">
                                                    Correct
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 5" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <span class="col-6">I am confident to order Riskscape data for this property.</span>
                                <span class="col-3"></span>
                                <button class="col-3 button-background-green display-right"
                                    (click)="orderRiskscapeData()">Order</button>
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <span class="col-6">This property cannot be located from Riskscape data.</span>
                                <span class="col-3"></span>
                                <button class="col-3 button-background-coral display-right"
                                    (click)="blackRiskscapeData()">Black report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 6" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Property Key</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="Property Key"
                                    [(ngModel)]="riskscapePropertyInfoNewData.id"
                                    [value]="riskscapePropertyInfoNewData.id ? riskscapePropertyInfoNewData.id : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Unit Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text"
                                    placeholder="sectionalTitleUnitNumber"
                                    [(ngModel)]="riskscapePropertyInfoNewData.sectionalTitleUnitNumber"
                                    [value]="riskscapePropertyInfoNewData.sectionalTitleUnitNumber ? riskscapePropertyInfoNewData.sectionalTitleUnitNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Erf Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="erfNumber"
                                    [(ngModel)]="riskscapePropertyInfoNewData.erfNumber"
                                    [value]="riskscapePropertyInfoNewData.erfNumber ? riskscapePropertyInfoNewData.erfNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Portion Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="portionNumber"
                                    [(ngModel)]="riskscapePropertyInfoNewData.portionNumber"
                                    [value]="riskscapePropertyInfoNewData.portionNumber ? riskscapePropertyInfoNewData.portionNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Township</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="city/Township"
                                    [(ngModel)]="riskscapePropertyInfoNewData.city"
                                    [value]="riskscapePropertyInfoNewData.city ? riskscapePropertyInfoNewData.city : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Deeds Erf Extent</label>
                                <input class="col-12 mt-1 riskscape-data" type="number"
                                    [(ngModel)]="riskscapePropertyInfoNewData.deedsErfExtent"
                                    placeholder="deedsErfExtent"
                                    [value]="riskscapePropertyInfoNewData.deedsErfExtent ? riskscapePropertyInfoNewData.deedsErfExtent : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Surveyor General Erf Extent</label>
                                <input class="col-12 mt-1 riskscape-data" type="text"
                                    placeholder="surveyorGeneralErfExtent"
                                    [(ngModel)]="riskscapePropertyInfoNewData.surveyorGeneralErfExtent"
                                    [value]="riskscapePropertyInfoNewData.surveyorGeneralErfExtent ? riskscapePropertyInfoNewData.surveyorGeneralErfExtent : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">S.G Code</label>
                                <input class="col-12 mt-1 riskscape-data" type="text"
                                    [(ngModel)]="riskscapePropertyInfoNewData.surveyorGeneralKey"
                                    placeholder="surveyorGeneralKey"
                                    [value]="riskscapePropertyInfoNewData.surveyorGeneralKey ? riskscapePropertyInfoNewData.surveyorGeneralKey : 'n/a'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 7" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Street Number</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="streetNumber"
                                    [(ngModel)]="riskscapePropertyInfoNewData.streetNumber"
                                    [value]="riskscapePropertyInfoNewData.streetNumber ? riskscapePropertyInfoNewData.streetNumber : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Street Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="streetName"
                                    [(ngModel)]="riskscapePropertyInfoNewData.streetName"
                                    [value]="riskscapePropertyInfoNewData.streetName ? riskscapePropertyInfoNewData.streetName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Complex Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="complexName"
                                    [(ngModel)]="riskscapePropertyInfoNewData.complexName"
                                    [value]="riskscapePropertyInfoNewData.complexName ? riskscapePropertyInfoNewData.complexName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Suburb Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="suburbName"
                                    [(ngModel)]="riskscapePropertyInfoData.suburbName"
                                    [value]="riskscapePropertyInfoNewData.suburbName ? riskscapePropertyInfoNewData.suburbName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Sub-Place Name</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="subPlaceName"
                                    [(ngModel)]="riskscapePropertyInfoNewData.subPlaceName"
                                    [value]="riskscapePropertyInfoNewData.subPlaceName ? riskscapePropertyInfoNewData.subPlaceName : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">City/Town</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="city"
                                    [(ngModel)]="riskscapePropertyInfoNewData.city"
                                    [value]="riskscapePropertyInfoNewData.city ? riskscapePropertyInfoNewData.city : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Municipality</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="municipality"
                                    [(ngModel)]="riskscapePropertyInfoNewData.municipality"
                                    [value]="riskscapePropertyInfoNewData.municipality ? riskscapePropertyInfoNewData.municipality : 'n/a'" />
                            </div>
                        </div>
                        <div class="row paragraph mt-3">
                            <div class="col-12">
                                <label class="col-12 bold">Province</label>
                                <input class="col-12 mt-1 riskscape-data" type="text" placeholder="province"
                                    [(ngModel)]="riskscapePropertyInfoNewData.province"
                                    [value]="riskscapePropertyInfoNewData.province ? riskscapePropertyInfoNewData.province : 'n/a'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 8" [hidden]="!section.checked">
                <div class="row paragraph mt-3" [hidden]="hideLatLonPanel">
                    <div class="col-12">
                        <button class="footer-button background-coral padding display-right"
                            (click)="openLocationDialog()">Set</button>
                    </div>
                    <div class="col-12 hidden">
                        <div *ngIf="latitude && longitude">
                            <p>Selected Latitude: {{ latitude }}</p>
                            <p>Selected Longitude: {{ longitude }}</p>
                        </div>
                    </div>
                    <div class="col-12">
                        <label class="col-12 bold">Lat-long Coordinates</label>
                        <input class="col-12 mt-1 riskscape-data" type="text" readonly
                            [(ngModel)]="LatLongCoordinatesNew" placeholder="Lat-long Coordinates" />
                    </div>
                </div>
                <div class="row paragraph mt-3" [hidden]="!hideLatLonPanel">
                    <div class="col-12">
                        <label class="col-12 bold">Lat-long Coordinates</label>
                        <input class="col-12 mt-1 riskscape-data" type="text" readonly
                            [(ngModel)]="LatLongCoordinatesNew" placeholder="Lat-long Coordinates" />
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 9" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <button class="new-row display-right mb-1 hidden" mat-button (click)="addNewRow()">New</button>
                        <br>
                        <table mat-table [dataSource]="dataRiskscapeDeedsCurrentOwnerInfo" matSort class="table">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element">{{ element.name ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="idNumber">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Number </th>
                                <td mat-cell *matCellDef="let element">{{ element.idNumber ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="maritalStatus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Marital Status </th>
                                <td mat-cell *matCellDef="let element">{{ element.maritalStatus ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="ownerType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Type </th>
                                <td mat-cell *matCellDef="let element">{{ element.ownerType ?? "N/a" }}</td>
                            </ng-container>

                            <ng-container matColumnDef="ownerShare">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner Share </th>
                                <td mat-cell *matCellDef="let element">{{ element.ownerShare ?? "N/a" }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-paginator class="hidden" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
                    </div>
                </div>
            </div>
            <div class="col-12 pb-2" *ngIf="section.key == 10" [hidden]="!section.checked">
                <div class="row paragraph mt-3">
                    <div class="col-12">
                        <mat-checkbox [(ngModel)]="propertyDescriptionAddressCorrect">Property description
                            and address is
                            correct.</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="propertyBoundaryCorrect">Property boundary is
                            correct.</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="sellerInformationCorrect">All Seller's information is
                            correct.</mat-checkbox><br>
                        <mat-checkbox [(ngModel)]="confidentImportRiskscapeData">I am confident to import
                            Riskscape data.</mat-checkbox>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="saveData()" class="footer-button background-coral">Save</button>
            <button (click)="saveConfirmDetails()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>