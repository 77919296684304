import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { UserManagementService } from '../../../services/property-matrixV2/services/user-management.service';
import { NotificationService } from '../../../shared/services/notification-service/notification.service';
import { LookupService } from '../../../services/property-matrixV2/services';
import { GlobalMethods } from '../../../../app/common/global-methods';
import { UserDto } from '../../../services/property-matrixV2/models';

@Component({
  selector: 'app-property-listing-overview',
  templateUrl: './property-listing-overview.component.html',
  styleUrls: ['./property-listing-overview.component.scss']
})
export class PropertyListingOverviewComponent implements OnInit {

  sellerType: string | null = null;
  route: string = '/sell-your-property/steps';

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _notificationService: NotificationService,
    private _lookupService: LookupService,
    private _userManagementService: UserManagementService,
  ) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      this.sellerType = params['sellerType'];
    });
    GlobalMethods.scrollToTop();
  }

  onContinue(): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        sellerType: this.sellerType
      }
    };

    this._router.navigate([this.route], navigationExtras);
  }



  public navigateToSellYourProperty(): void {
    this._router.navigateByUrl('/sell-your-property');
  }
}
