import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyForSaleService } from 'src/app/services/property-matrix/services';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CheckboxVm } from 'src/app/services/property-matrix/models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import * as L from 'leaflet';

@Component({
  selector: 'app-property-for-sale-search',
  templateUrl: './property-for-sale-search.component.html',
  styleUrls: ['./property-for-sale-search.component.scss']
})
export class PropertyForSaleSearchComponent implements OnInit {

  @Output() pageNumberEvent = new EventEmitter<number>();

  private map;
  showSearchBlock: boolean = false;
  mapSearch: string = ''
  suburbs = [];
  polygons = [];
  activeSuburbId = '';
  // markerCluster = new MarkerClusterGroup({
  //   spiderfyOnMaxZoom: false,
  //   showCoverageOnHover: false,
  //   zoomToBoundsOnClick: true,
  //   animate: true,
  //   maxClusterRadius: 80,
  // });
  gautengActive: boolean = false;
  provincePolygons = [];
  activeRegionMarkers = [];
  activePolygon: any = {};
  southAfricaPolygon: any = {};
  propertyTypes: any = [];
  regionPolygons: any = [];

  searchFilters: MapFilters[] = [
    { id: 1, expanded: false, name: 'Location', subItems: [] },
    { id: 2, expanded: false, name: 'Sector', subItems: [] },
    { id: 3, expanded: false, name: 'Type', subItems: [] },
    { id: 4, expanded: false, name: 'Price', subItems: [] },
    { id: 5, expanded: false, name: 'Size: stand', subItems: [] },
    { id: 6, expanded: false, name: 'Size: bulk', subItems: [] },
    { id: 7, expanded: false, name: 'More options', subItems: [] },
  ];

  sectorOptions: CheckboxVm[] = [
    { id: 1, value: 'Developed', checked: false },
    { id: 2, value: 'Developable', checked: false },
  ];

  priceOptions: CheckboxVm[] = [
    { id: 1, value: 'Up to R5m', checked: false },
    { id: 2, value: 'R5m to R10m', checked: false },
    { id: 3, value: 'R10m to R30m ', checked: false },
    { id: 4, value: 'R30m to R50m', checked: false },
    { id: 5, value: 'R50m to R80m', checked: false },
    { id: 6, value: 'R80m to R120m', checked: false },
    { id: 7, value: 'R120m to R200m', checked: false },
    { id: 8, value: 'R200m and above', checked: false },
  ]

  standSizeOptions: CheckboxVm[] = [
    { id: 1, value: '0 - 2 500m²', checked: false },
    { id: 2, value: '2 501m² - 5 000 m²', checked: false },
    { id: 3, value: '5 001m² - 1ha', checked: false },
    { id: 4, value: '1ha - 3ha', checked: false },
    { id: 5, value: '3ha - 6ha', checked: false },
    { id: 6, value: '6ha - 10ha', checked: false },
    { id: 7, value: '10ha - 20ha ', checked: false },
    { id: 8, value: '20ha - 40ha', checked: false },
    { id: 9, value: '40h - 100ha', checked: false },
    { id: 10, value: 'Above 100ha', checked: false },
  ]

  blukSizeOptions: CheckboxVm[] = [
    { id: 1, value: '0 - 50m²', checked: false },
    { id: 2, value: '51m² - 100m²', checked: false },
    { id: 3, value: '101m² - 200m²', checked: false },
    { id: 4, value: '201m² - 400m²', checked: false },
    { id: 5, value: '401m² - 700m²', checked: false },
    { id: 6, value: '701m² - 1 000m²', checked: false },
    { id: 7, value: '1 001m² - 2 000m²', checked: false },
    { id: 8, value: '2 001m² - 4 000m²', checked: false },
    { id: 9, value: '4 001m² - 8 000m²', checked: false },
    { id: 10, value: '8 001m² - 15 000m²', checked: false },
    { id: 11, value: 'Above 15 000m²', checked: false },
  ]

  regionMarkers = [
    { id: 1, coordinates: [-23.5, 27], name: 'TSHWANE', isActive: true, icon: '../../../../assets/images/maps/icons/tshwane.png', provinceId: 3 },
    { id: 2, coordinates: [-25.5, 24], name: 'JOHANNESBURG', isActive: true, icon: '../../../../assets/images/maps/icons/johannesburg.png', provinceId: 3 },
    { id: 3, coordinates: [-25.8, 29], name: 'EKURHULENI', isActive: true, icon: '../../../../assets/images/maps/icons/ekurhuleni.png', provinceId: 3 },
    { id: 4, coordinates: [-32.8, 15.6], name: 'CAPE TOWN', isActive: true, icon: '../../../../assets/images/maps/icons/cape-town.png', provinceId: 9 },
    { id: 5, coordinates: [-28.5, 31], name: 'ETHEKWINI', isActive: true, icon: '../../../../assets/images/maps/icons/ethekwini.png', provinceId: 4 },
    { id: 6, coordinates: [-28.5, 23.5], name: 'MANGUANG', isActive: false, icon: '../../../../assets/images/maps/icons/manguang.png', provinceId: 2 },
    { id: 7, coordinates: [-32.5, 28], name: 'BUFFALO CITY', isActive: false, icon: '../../../../assets/images/maps/icons/buffalo-city.png', provinceId: 1 },
    { id: 8, coordinates: [-33.8, 24], name: 'NELSON MANDELA BAY', isActive: false, icon: '../../../../assets/images/maps/icons/mandela-bay.png', provinceId: 1 },
    { id: 9, coordinates: [-34.5, 22], name: 'GARDEN ROUTE', isActive: false, icon: '../../../../assets/images/maps/icons/garden-route.png', provinceId: 9 },
  ]

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.initMap();
    this.getPropertyTypes();
  }

  initMap() {
    let mapElement = document.getElementById('siodMap') as Element;
    if (mapElement) {
      mapElement.remove();
    }
    this.map = L.map('pfsMap', {
      zoomControl: true,
      scrollWheelZoom: false,
      zoom: 6
    }).setView([-29, 25]);

    setTimeout(() => {
      let zoomButton = document.getElementsByClassName('leaflet-control-zoom')[0] as HTMLElement;
      if (zoomButton) {
        zoomButton.style.display = "none";
      }
    }, 5);

    this.map.doubleClickZoom.disable();
    this.map.zoomControl.setPosition('bottomright');
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 50,
      minZoom: 1,
      transparent: false,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);

    const subPlacesLayer = L.tileLayer.wms('https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?', {
      maxZoom: 20,
      minZoom: 12,
      layers: 'Terisha:Subplaces',
      transparent: true,
      format: 'image/png',
      crs: L.CRS.EPSG4326,
      opacity: 0.2,
    });
    subPlacesLayer.addTo(this.map);
    let map = this.map;

    this.getSouthAfricaPolygon().subscribe({
      next: (result: any) => {
        let latLngs = result.features[0].geometry.coordinates;
        let reversedLatlngs = latLngs.map(function reverse(item) {
          return Array.isArray(item) && Array.isArray(item[0])
            ? item.map(reverse)
            : item.reverse();
        });
        this.southAfricaPolygon = L.polygon(reversedLatlngs, { color: '#b1b1b1', fillOpacity: 0, weight: 1 }).addTo(map);

        this.renderSouthAfricaPolygon();
      },
      error: (error: any) => {
        //this.notificationService.showErrorMessage('Error', 'Could not get South africa polygon coordinates for map Terisha.');
      }
    });

    this.getProvincePolygons().subscribe({
      next: (result: any) => {
        for (const element of result.features) {
          let latLngs: any = element.geometry.coordinates;
          let reversedLatlngs = latLngs.map(function reverse(item) {
            return Array.isArray(item) && Array.isArray(item[0])
              ? item.map(reverse)
              : item.reverse();
          });
          let id = element.properties.AG_PROV_ID
          let normalStyle = this.generateProvincePolygonStyle(false, id);
          let polygon = L.polygon(reversedLatlngs, normalStyle)
          this.provincePolygons.push(polygon);
          polygon.addTo(this.map)
        }
        for (const element of this.regionMarkers) {
          let markerDetails = element;
          let textIcon = L.divIcon({
            iconSize: 'auto',
            html: this.getIconString(markerDetails, false, markerDetails.isActive),
            className: 'div-icon'
          });

          let textHoverIcon = L.divIcon({
            iconSize: 'auto',
            html: this.getIconString(markerDetails, true, markerDetails.isActive),
            className: 'div-icon'
          })
          let marker = L.marker(markerDetails.coordinates, {
            icon: textIcon
          })

          let polygon = this.provincePolygons.find(f => f.options.id == markerDetails.provinceId);
          let normalStyle = this.generateProvincePolygonStyle(false, polygon.options.id);
          let hoverStyle = this.generateProvincePolygonStyle(true, polygon.options.id)

          marker.once('mouseover', function (e) {
            e.target.setIcon(textHoverIcon)
            polygon.setStyle(hoverStyle)
          });

          marker.on('mouseout', function (e) {
            e.target.setIcon(textIcon)
            polygon.setStyle(normalStyle)
            e.target.once('mouseover', function (e) {
              e.target.setIcon(textHoverIcon)
              polygon.setStyle(hoverStyle)
            });
          });

          marker.on('mouseup', function (e) {
            switch (markerDetails.id) {
              case 1:
                map.flyTo([-25.8, 28.25], 9);
                this.gautengActive = true;
                break;
              case 2:
                map.flyTo([-25.8, 28.25], 9);
                this.gautengActive = true;
                break;
              case 3:
                map.flyTo([-25.8, 28.25], 9);
                this.gautengActive = true;
                break;
              case 4:
                map.flyTo([-33.87456279044508, 18.569492243509142], 10);
                this.gautengActive = false;
                break;
              case 5:
                map.flyTo([-29.88, 30.828446326249566], 10);
                this.gautengActive = false;
                break;
              default:
                break;
            }
          }.bind(this));
          marker.addTo(this.map)
          this.activeRegionMarkers.push(marker)
        }
        this.renderProvincePolygons();
        this.renderRegionMarkers();
        this.renderRegionPolygons();
      },
      error: (error: any) => {
        //this.notificationService.showErrorMessage('Error', 'Could not get Province polygon coordinates  for map Terisha.');
      }
    })

    this.getRegionPolygons().subscribe({
      next: (result) => {
        for (let index = 0; index < result.features.length; index++) {
          if (result.features[index].geometry) {
            let latLngs: any = result.features[index].geometry.coordinates;
            let reversedLatlngs = latLngs.map(function reverse(item) {
              return Array.isArray(item) && Array.isArray(item[0])
                ? item.map(reverse)
                : item.reverse();
            });
            let id = result.features[index].properties.AG_PROV_ID
            let name = result.features[index].properties.REGION
            let polygon = L.polygon(reversedLatlngs, { color: 'grey', fillColor: 'grey', opacity: 1, fillOpacity: 0.1, id: id, name: name, direction: "center" });
            polygon.on('mouseover', function (e) {
              setTimeout(() => {
                this.bindPopup(this.options.name).openPopup()
              }, 25);
            })
            polygon.on('click', function (e) {
              map.flyTo([e.latLng.lat, e.latLng.lng], 12);
            })
            this.regionPolygons.push(polygon);
          }
        }
        setTimeout(() => {
          this.renderSouthAfricaPolygon();
        }, 1);
      },
      error: (error: any) => {
        //this.notificationService.showErrorMessage('Error', 'Could not get Region polygon coordinates  for map Terisha.');
      }
    })

    let zoom = L.control.zoom();
    let httpClient = this.http;

    this.map.on('zoom', function (e) {
      this.showOrHideSearchBlock(this.map._zoom);
      let backgroundColor = document.querySelector('.leaflet-pane .leaflet-overlay-pane svg') as HTMLElement;
      backgroundColor.style.background = this.map._zoom <= 7 ? "#262626" : "transparent"
      let zoomOutButton = document.getElementsByClassName('leaflet-control-zoom-out')[0] as HTMLElement;
      let zoomInButton = document.getElementsByClassName('leaflet-control-zoom-in')[0] as HTMLElement;
      let zoomButton = document.getElementsByClassName('leaflet-control-zoom')[0] as HTMLElement;
      if (zoomButton) {
        if (this.gautengActive) {
          zoomOutButton.style.display = this.map._zoom >= 10 ? "block" : "none";
          zoomInButton.style.display = this.map._zoom <= 9 ? "block" : "none";
          zoomButton.style.display = this.map._zoom >= 9 ? "block" : "none";
        } else {
          zoomOutButton.style.display = this.map._zoom >= 11 ? "block" : "none";
          zoomInButton.style.display = this.map._zoom <= 10 ? "block" : "none";
          zoomButton.style.display = this.map._zoom >= 10 ? "block" : "none";
        }
      }
    }.bind(this));

    const genericIcon = L.icon({
      iconUrl: '../../assets/images/maps/icons/map-marker.png',
      iconSize: [25, 32],
    });

    let markers = [
      { id: 1, coordinates: [-25.8, 28.25] },
      { id: 2, coordinates: [-26, 28] },
      { id: 3, coordinates: [-26.2, 28.5] },
      // { id: 3, coordinates: [-25.8 - 0.0015, 28.25] },
      // { id: 4, coordinates: [-25.8, 28.25 + 0.005] },
      // { id: 5, coordinates: [-25.8, 28.25 - 0.005] },
      // { id: 6, coordinates: [-25.8, 30] },
      // { id: 6, coordinates: [-25.8, 28.25 + 0.5] },
      // { id: 7, coordinates: [-25.8 + 0.002, 28.25] },
      // { id: 8, coordinates: [-25.8 - 0.002, 28.25] },
      // { id: 9, coordinates: [-25.8, 28.25 + 0.005] },
      // { id: 6, coordinates: [-25.8, 28.25 - 0.005] },
      // { id: 7, coordinates: [-25.8 + 0.01, 28.25] },
      // { id: 8, coordinates: [-25.8 + 0.0045, 28.25] },
    ]
    let loadedMarkers = []

    for (let index = 0; index < markers.length; index++) {
      let markerDetails = markers[index];
      let marker = L.marker(markerDetails.coordinates, { icon: genericIcon })

      marker.setIcon(genericIcon)
      marker.setOpacity(0.5)
      //this.markerCluster.addLayer(marker)
      loadedMarkers.push(marker);
      this.renderInactiveMarkers(marker)
    }
  }

  generateProvincePolygonStyle(hover: boolean, id: number): any {
    if (hover == true) {
      return { color: 'grey', fillColor: 'grey', opacity: 1, fillOpacity: 1, id: id }
    } else {
      return { color: '#181818', fillColor: '#181818', opacity: 1, fillOpacity: 1, id: id }
    }
  }

  getIconString(markerDetails: any, hover: boolean, active: boolean) {
    let borderStyle = hover && active ? 'border-bottom: 5px #f2825e solid;' : ''
    let launchSoonBlock = hover && !active ?
      `<div style="background-color: #727272;"><span style="color: black; font-family: MONTSERRAT; font-size: 14px">LAUNCHING SOON</span></div>`
      : '';
    let fontSize = active ? '18' : '15'
    let fontColour = active ? 'white' : '#5e5e5e'
    return `<div style="vertical-align: top; display: inline-block; text-align: center; width: unset !important;">
        <img src="${markerDetails.icon}" class="leaflet-zoom-animated leaflet-interactive" style="width: 60px; height: 60px;"/>
        <span style="font-family: MONTSERRAT; font-size: ${fontSize}px; font-weight: bold; color: ${fontColour}; display: block;white-space: nowrap;${borderStyle}">${markerDetails.name}</span>
      ${launchSoonBlock}
        </div>`
  }

  searchSuburbs() {
    if (this.mapSearch.length <= 2) {
      return;
    }
    let showSuburbs = this.map._zoom >= 13 && this.map._zoom <= 20;
    let showRegions = this.map._zoom >= 9 && this.map._zoom <= 12;
    let showProvinces = this.map._zoom >= 1 && this.map._zoom <= 8;
    let bounds = this.map.getBounds();
    let bBox = `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
    let size = this.map.getSize()
    let height = size.y;
    let width = size.x;
    let layer = showProvinces ? "Terisha:Provinces" : showRegions ? "Terisha:Metro_Regions_test" : "Terisha:Subplaces";

    searchSuburbLayer(this.http, this.mapSearch).subscribe({
      next: (result: any) => {
        this.suburbs = []
        if (result) {
          this.suburbs = Object.values(result.data);
        }
      },
      error: (error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not find suburb layer.');
      }
    })
  }

  backButtonPress() {
    let zoom = L.control.zoom();
    this.map.removeLayer(zoom)
    this.map.panTo([-29, 25])
    this.map.setZoom(6)
    this.mapSearch = '';
    this.map.closePopup();
  }

  selectedPlacesOfLoading($event: MatAutocompleteSelectedEvent) {
    const id = $event.option.id
    const suburb = this.suburbs.find(f => f.id == id);
    this.selectSuburb(suburb)
  }

  selectSuburb(suburb: any) {
    if (!suburb) {
      return;
    }
    if (this.activeSuburbId == suburb.id) {
      return;
    }
    this.activeSuburbId = suburb.id;
    this.map.closePopup();
    this.map.panTo([suburb.CENTROIDY, suburb.CENTROIDX]);
    this.suburbs = [];
    let showSuburbs = this.map._zoom >= 13 && this.map._zoom <= 20;
    let showRegions = this.map._zoom >= 9 && this.map._zoom <= 12;
    let showProvinces = this.map._zoom >= 1 && this.map._zoom <= 8;
    let bounds = this.map.getBounds();
    let bBox = `${bounds._southWest.lng},${bounds._southWest.lat},${bounds._northEast.lng},${bounds._northEast.lat}`;
    let size = this.map.getSize()
    let height = size.y;
    let width = size.x;
    let x = Math.round(suburb.CENTROIDX)
    let y = Math.round(suburb.CENTROIDY)
    let layer = showProvinces ? "Terisha:Provinces" : showRegions ? "Terisha:Metro_Regions_test" : "Terisha:Suburbs";

    const bigIcon = L.icon({
      iconUrl: '../../assets/images/maps/icons/map-marker.png',
      iconSize: [60, 75],
    });

    const genericIcon = L.icon({
      iconUrl: '../../assets/images/maps/icons/map-marker.png',
      iconSize: [40, 50],
    });
    let markers = [
      { id: 1, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX] },
      { id: 2, coordinates: [suburb.CENTROIDY + 0.001, suburb.CENTROIDX] },
      { id: 3, coordinates: [suburb.CENTROIDY - 0.001, suburb.CENTROIDX] },
      { id: 3, coordinates: [suburb.CENTROIDY - 0.0015, suburb.CENTROIDX] },
      { id: 4, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX + 0.001] },
      { id: 5, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX - 0.001] },
      { id: 6, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX - 0.002] },
      { id: 6, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX + 0.002] },
      { id: 7, coordinates: [suburb.CENTROIDY + 0.002, suburb.CENTROIDX] },
      { id: 8, coordinates: [suburb.CENTROIDY - 0.002, suburb.CENTROIDX] },
      { id: 9, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX + 0.005] },
      { id: 6, coordinates: [suburb.CENTROIDY, suburb.CENTROIDX - 0.005] },
      { id: 7, coordinates: [suburb.CENTROIDY + 0.005, suburb.CENTROIDX] },
      { id: 8, coordinates: [suburb.CENTROIDY + 0.0045, suburb.CENTROIDX] },
    ]
    let loadedMarkers = []
    let markerInfoHtml = generatePopupText();
    let loaderHtml: string =
      `<div class="loader"></div><style>.loader {
        border: 22px solid #f3f3f3;
        border-top: 22px solid #f2825e;
        border-radius: 50%;
        width: 5vh;
        height: 5vh;
        animation: spin 2s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }</style>`;
    let map = this.map

    for (let index = 0; index < markers.length; index++) {
      let markerDetails = markers[index];
      let marker = L.marker(markerDetails.coordinates, { icon: genericIcon })
        .on('click', function (e) {
          let lat = e.latLng.lat + 0.001;
          setTimeout(() => {
            this.bindPopup(loaderHtml).openPopup();
          }, 1);
          setTimeout(() => {
            this.bindPopup(markerInfoHtml, {
              maxWidth: 100000
            }).openPopup()
          }, 1000);
        });
      marker.on('mouseover', function (e) {
        e.target.setIcon(bigIcon)
      });
      marker.on('mouseout', function (e) {
        e.target.setIcon(genericIcon)
      });

      // this.markerCluster.addLayer(marker)
      loadedMarkers.push(marker);
    }
    if (this.map._zoom >= 9) {
      // this.map.addLayer(this.markerCluster)
    }
    // this.renderMarkerClusters(this.markerCluster)

    //KEPT FOR FUTURE USE WHEN WE HAVE ALL THE PFS DATA
    // getLayerDataFromSearch(this.http).subscribe({
    //   next: (result: any) => {
    //     let latLngs = result.features[0].geometry.coordinates;
    //     let reversedLatlngs = latLngs.map(function reverse(item) {
    //       return Array.isArray(item) && Array.isArray(item[0])
    //         ? item.map(reverse)
    //         : item.reverse();
    //     });
    //     let objectId = result.features[0].id;
    //     let properties = result.features[0].properties;
    //     this.map.removeLayer(this.activePolygon)
    //     this.activePolygon = L.polygon(reversedLatlngs, { fillColor: 'transparent', color: '#f2825e', objectId: objectId, properties: properties });
    //     this.activePolygon.addTo(this.map);
    //   }
    // });
  }

  renderMarkerClusters(markerCluster: any) {
    this.map.on('zoom', function (e) {
      if (this._zoom >= 11) {
        this.addLayer(markerCluster)
      } else {
        this.removeLayer(markerCluster)
      }
    });
  }

  renderInactiveMarkers(marker: any) {
    this.map.on('zoom', function (e) {
      if (this._zoom >= 8) {
        this.addLayer(marker)
      } else {
        this.removeLayer(marker)
      }
    });
  }

  renderRegionMarkers() {
    let activeRegionMarkers = this.activeRegionMarkers;
    this.map.on('zoom', function (e) {
      for (let index = 0; index < activeRegionMarkers.length; index++) {
        let marker = activeRegionMarkers[index];
        if (this._zoom <= 7) {
          marker.addTo(this)
        } else {
          this.removeLayer(marker)
        }
      }
    });
  }

  renderProvincePolygons() {
    let provincePolygons = this.provincePolygons;
    let map = this.map;
    this.map.on('zoom', function (e) {
      for (let index = 0; index < provincePolygons.length; index++) {
        let polygon = provincePolygons[index];
        if (this._zoom <= 7) {
          polygon.addTo(map)
        } else {
          map.removeLayer(polygon)
        }
      }
    });
  }

  renderRegionPolygons() {
    let regionPolygons = this.regionPolygons;
    let map = this.map;
    this.map.on('zoom', function (e) {
      for (let index = 0; index < regionPolygons.length; index++) {
        let polygon = regionPolygons[index];
        if (this._zoom >= 9 && this._zoom <= 11) {
          polygon.addTo(map)
        } else {
          map.removeLayer(polygon)
        }
      }
    });
  }

  renderSouthAfricaPolygon() {
    let southAfricaPolygon = this.southAfricaPolygon
    this.map.on('zoom', function (e) {
      if (this._zoom <= 7) {
        southAfricaPolygon.addTo(this)
      } else {
        this.removeLayer(southAfricaPolygon);
      }
    });


  }

  getSouthAfricaPolygon(): Observable<any> {
    let bbox = '6.416015625000001,-40.84706035607122,44.9560546875,-16.97274101999902';
    let width = '628';
    let height = '877';
    let x = '377';
    let y = '303';
    let layer = 'Terisha:South_Africa'
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${layer}&STYLES&LAYERS=${layer}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  getProvincePolygons(): Observable<any> {
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName=Terisha:Provinces_lite&maxFeatures=50&outputFormat=application/json`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  getRegionPolygons(): Observable<any> {
    let layerData = this.http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName=Terisha:Regions&maxFeatures=50&outputFormat=application/json`)
      .pipe(map(m => layerData = m))
    return layerData;
  }

  openSearchPanel(item: MapFilters) {
    item.expanded = item.expanded == false ? true : false;
  }

  gotoOptions() {
    this.pageNumberEvent.emit(2)
  }

  gotoCheckout() {
    this.pageNumberEvent.emit(3)
  }

  getPropertyTypes() {
    this.http.get(environment.api + PropertyForSaleService.ApiV1PropertyForSaleGetPropertyTypesGetPath).pipe(
      map((result) => {
        return result;
      })).subscribe({
        next: (result: any[]) => {
          this.propertyTypes = result;
        }
      });
  }

  showOrHideSearchBlock(zoom: number) {
    let element = document.getElementById('searchBlock');
    element.style.display = zoom >= 11 ? "block" : "none";
  }

}

export interface MapFilters {
  id: number,
  name: string,
  subItems: [],
  expanded: boolean
}

function renderMarkers(loadedMarkers, map) {
  if (map._zoom >= 11) {
    loadedMarkers.forEach(marker => {
      marker.addTo(map)
    });
  } else {
    loadedMarkers.forEach(marker => {
      map.removeLayer(marker)
    });
  }
}

function getLayerData(http: HttpClient, bbox: string, width: string, height: string, queryLayers: string, layers: string, x: any, y: any): Observable<any> {
  let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${queryLayers}&STYLES&LAYERS=${layers}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
    .pipe(map(m => layerData = m))
  return layerData;
}

function searchSuburbLayer(http: HttpClient, search: string): Observable<any> {
  if (search) {
    let searchString = capitalizeWords(search)
    let bbox = '4.570312500000001,-38.06539235133248,51.4599609375,-12.554563528593656'
    let width = '650'
    let height = '1067'
    let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=UTFGrid&TRANSPARENT=true&STYLES&LAYERS=Terisha%3ASuburbs&exceptions=application%2Fvnd.ogc.se_inimage&CQL_FILTER=SUBURB%20LIKE%20%27%25${searchString}%25%27&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`)
      .pipe(map(m => layerData = m))
    return layerData;
  }
  return null
}

function getLayerDataFromSearch(http: HttpClient): Observable<any> {
  let x = '28'
  let y = '-26'
  let bBox = '28.1535816192627,-25.850109317027734,28.194351196289066,-25.825852754548592'
  let width = '950'
  let height = '628'
  let layerData = http.get(`https://propertimatrix.dedicated.co.za:8443/geoserver/Terisha/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=Terisha%3ASuburbs&STYLES&LAYERS=Terisha:Subplaces&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application%2Fjson&FEATURE_COUNT=50&X=${x}&Y=${y}&SRS=EPSG%3A4148&WIDTH=${width}&HEIGHT=${height}&BBOX=${bBox}`)
    .pipe(map(m => layerData = m))
  return layerData;
}

function capitalizeWords(value) {
  if (value.includes(" ")) {
    let charArr = value.split(" ");
    for (let i = 0; i < charArr.length; i++) {
      if (charArr[i][0]) {
        charArr[i] = charArr[i][0].toUpperCase() + charArr[i].substr(1);
      }
    }
    return charArr.join(" ");
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

function generatePopupText() {
  return `<div id="examplePopup">
  <div style="background-color: white; width: 40vw; min-height: 38vh;">
    <div class="row">
      <div class="col-4">
        <div class="m-3" style="border: solid black 1px; width: 100%; height: 25vh;"></div>
      </div>
      <div class="col-8 mt-3 paragraph">
        <div class="row">
          <div class="col-4">
            <strong>Price:</strong>
          </div>
          <div class="col-8">
            <span>R4.5 m</span>
          </div>
          <div class="col-4">
            <strong>Parcel extent: </strong>
          </div>
          <div class="col-8">
            <span>4.3ha</span>
          </div>
          <div class="col-4">
            <strong>Current bulk (±):</strong>
          </div>
          <div class="col-8">
            <span>960m2</span>
          </div>
          <div class="col-4">
            <strong>Current zoning:</strong>
          </div>
          <div class="col-8">
            <span>Residential 2</span>
          </div>
          <div class="col-4">
            <strong>Current use:</strong>
          </div>
          <div class="col-8">
            <span>Multiple residential; Block of flats. Dae ma
              porporro dolo quam secat laborum orpostis.</span>
          </div>
          <div class="col-12">
            <strong>Current use:</strong>
          </div>
          <div class="col-12">
            <span>Rezone to Residential 3 and add 10 more units. Neque min
              nobisciam dolore evellauda net Ollupta volo.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-3">
      <div class="col-7">
        <strong class="paragraph coral-text">See planning report for more uses & options</strong>
      </div>
      <div class="col-4 mb-2">
        <button class="button paragraph" style="width:100%; margin-top: -20px;"><strong style="vertical-align: -webkit-baseline-middle;">MORE OPTIONS</strong></button>
      </div>
    </div>
  </div>
</div>`;
}
