import { PropertyListingStateService } from 'src/app/shared/services/sell-your-property/property-listing-state.service';
import { sellYourPropertyUploadDocumentTabTypes } from 'src/app/shared/interfaces/sell-your-property-filetypes';
import { SellMyPropertyService } from 'src/app/services/property-matrixV2/services/sell-my-property.service';
import { RoleBasedAccessDto, SellMyPropertyDocumentDto } from 'src/app/services/property-matrixV2/models';
import { NotificationService } from 'src/app/shared/services/notification-service/notification.service';
import { PropertyMatrixSharedService } from 'src/app/property-matrix-shared.service';
import { UserManagementService } from 'src/app/services/property-matrixV2/services';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { UserDto } from 'src/app/services/property-matrix/models/user-dto';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-listing-document-add-edit',
  templateUrl: './listing-document-add-edit.component.html',
  styleUrls: ['./listing-document-add-edit.component.scss', '../../../../../../css/2-modules/_admin-portal.scss']
})
export class ListingDocumentAddEditComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  uploadNewDataForm: FormGroup;
  titleText: String = "Add New upload";
  CategoryListOld: string[] = ["New Additional"];
  CategoryList: string[] = ["Property Info", "New Additional", "Required Document", "Additional Document", "Picture Document"];
  selectedCategory: string = "New Additional";
  sellYourPropertyFiletypesDefault: sellYourPropertyUploadDocumentTabTypes.None;
  selectedUploadedDate: string;
  selectedSellMyPropertyDocumentDto: SellMyPropertyDocumentDto;
  selectedProperDetailID: string;
  todayDateForUploadField: string;
  loggedInUser: UserDto;
  userRoleBasedAccess: RoleBasedAccessDto;
  fullName: string = '';

  constructor(
    public dialogRef: MatDialogRef<ListingDocumentAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private sellMyPropertyService: SellMyPropertyService,
    private _propertyMatrixSharedService: PropertyMatrixSharedService,
    private _propertyListingStateService: PropertyListingStateService,
    private _userManagementService: UserManagementService,
    private fb: FormBuilder) {
    this.selectedSellMyPropertyDocumentDto = data.fields;
    this.selectedProperDetailID = data.properDetailId;
  }
  ngAfterViewInit(): void {

  }

  setTitle(): void {
    if (this.data.fields != null) {
      this.titleText = "Edit Document";
    } else {
      this.titleText = "Add Document";
    }
  }

  ngOnInit() {
    try {
      this.setTitle();
      this.todayDateForUploadField = this.getTodaysDate();
      this.getServiceUserData();
      this.prePopulateFields();
    } catch (error) {
      this.notificationService.showErrorMessage("Error", 'Error during initialization:' + error);
    }
  }

  prePopulateFields(): void {
    if (this.data.fields) {
      this.uploadNewDataForm = this.fb.group({
        propertyDetailId: [this.selectedProperDetailID, [Validators.required]],
        DocumentID: [this.data.fields.documentInfo.id, [Validators.required]],
        DocumentName: [this.data.fields.documentInfo.name, [Validators.required]],
        uploadedBy: [this.data.fields.documentInfo.fullUserName, [Validators.required]],
        uploadedDate: [this.data.fields.documentInfo.updatedDate, [Validators.required]],
      });
    }
    else {
      this.uploadNewDataForm = this.fb.group({
        propertyDetailId: [this.selectedProperDetailID, [Validators.required]],
        DocumentID: ["# 1", [Validators.required]],
        DocumentName: ["", [Validators.required]],
        uploadedBy: ["", [Validators.required]],
        uploadedDate: [this.todayDateForUploadField, [Validators.required]],
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmSave(): void {
    try {

      if (this.selectedCategory != "New Additional") {
        this.notificationService.showErrorMessage("Error", "Currently you can only add New Additional type!");
        return;
      }

      if (this.uploadNewDataForm.valid) {
        this.loading = true;
        const formState: any = this._propertyListingStateService.getFormState();
        let additionalUploadList = formState.documents;

        if (additionalUploadList.length == 0) {
          this.notificationService.showErrorMessage("Error", "Please attach a files.");
          this.loading = false;
          return;
        }

        let fields = this.uploadNewDataForm.value;


        let selectedSellMyPropertyDocumentDtoItem = [{
          "isDeleted": false,
          "propertyDetailId": this.selectedProperDetailID,
          "documentInfo": additionalUploadList[0].documentInfo,
          "category": this.selectedCategory,
        }];

        this.sellMyPropertyService.apiV1SellMyPropertyAddAdditionalDocumentsPost({
          body: selectedSellMyPropertyDocumentDtoItem,
        }).subscribe({
          next: () => {
            this.loading = false;
            this.notificationService.showSuccessMessage('Success', 'User registration was successful.');
            this.dialogRef.close();
          },
          error: (_error: any) => {
            this.loading = false;
            this.notificationService.showErrorMessage('Error', 'An error occurred while registering user.');
          }
        });
      } else {
        this.validateAllFormFields(this.uploadNewDataForm);
      }
    }
    catch (e) {
      this.notificationService.showErrorMessage("Error", "Error saving a New additional upload, please contact your administrator.");
    }
    finally {
      this.loading = false;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  compareCategory(category1: string, category2: string) {
    return category1 === category2;
  }

  getTodaysDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  setUserField() {
    if (this.loggedInUser) {
      this.fullName = this.loggedInUser.name + " " + this.loggedInUser.surname;
    } else if (this.userRoleBasedAccess) {
      this.fullName = this.userRoleBasedAccess.userFullName;
      this.uploadNewDataForm.get('uploadedBy')?.setValue(this.userRoleBasedAccess.userFullName);
    } else {
      this.fullName = "";
    }
  }

  async getServiceUserData(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.getUserDetailLoggedIn();
        resolve();
      }, 2000);
    });
  }


  async getUserDetailLoggedIn(): Promise<void> {
    this.loggedInUser = this._propertyMatrixSharedService.userValue;
    if (this.loggedInUser == null) {
      this._userManagementService.apiV1UserManagementGetUserTokenGet({}).subscribe(result => {
        this.userRoleBasedAccess = result;
        this.setUserField();
      });
    } else {
      this.setUserField();
    }
  }
}