import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

import { AdvertLocationDto, AdvertSectorDto, AdvertTypesDto } from '../../../..//app/services/property-matrixV2/models';
import { AdvertGenerateStatusDto, AdvertKeyFeaturesDto } from '../../../services/property-matrixV2/models';
import { NotificationService } from '../../services/notification-service/notification.service';
import { AdvertService } from '../../../../app/services/property-matrixV2/services';

@Component({
  selector: 'app-advert-lookup-create',
  templateUrl: './advert-lookup-create.component.html',
  styleUrls: ['./advert-lookup-create.component.scss']
})
export class AdvertLookupCreateComponent implements OnInit {

  loading: boolean = false;
  advertLocationDto: AdvertLocationDto = {};
  advertSectorDto: AdvertSectorDto = {};
  advertTypeDto: AdvertTypesDto = {};
  advertGenerateStatusDto: AdvertGenerateStatusDto = {};
  advertKeyFeaturesDto: AdvertKeyFeaturesDto = {};
  description: string | null = "";
  advertType: number | null = 0;

  constructor(
    private advertService: AdvertService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AdvertLookupCreateComponent>
  ) { }

  ngOnInit(): void {
  }
  saveAdvertLocation(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;

        this.advertLocationDto = {
          description: this.description,
          isChecked: false,
          id: '00000000-0000-0000-0000-000000000000',
          isDeleted: false,
        }

        this.advertService.apiV1AdvertSaveAdvertLocationPost({
          body: this.advertLocationDto
        }).subscribe({
          next: (retult: any) => {
            if (retult) {
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert location.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert location.');
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", "Error caught on service call save Advert location.");
        resolve(false);
        reject("Error caught on service call save Advert location.");
      }
      finally {
        this.loading = false;
      }
    });
  }

  saveAdvertSectors(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;

        this.advertSectorDto = {
          description: this.description,
          isChecked: false,
          id: '00000000-0000-0000-0000-000000000000',
          isDeleted: false,
        }

        this.advertService.apiV1AdvertSaveAdvertSectorPost({
          body: this.advertSectorDto
        }).subscribe({
          next: (retult: any) => {
            if (retult) {
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert sectors.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert sectors.');
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", ex.toString());
        resolve(false);
        reject("Error caught on service call save Advert sectors.");
      }
      finally {
        this.loading = false;
      }
    });
  }

  saveAdvertTypes(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;

        this.advertTypeDto = {
          description: this.description,
          isChecked: false,
          id: '00000000-0000-0000-0000-000000000000',
          isDeleted: false,
        }

        this.advertService.apiV1AdvertSaveAdvertTypesPost({
          body: this.advertTypeDto
        }).subscribe({
          next: (retult: any) => {
            if (retult) {
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert types.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert types.');
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", ex.toString());
        resolve(false);
        reject("Error caught on service call save Advert types.");
      }
      finally {
        this.loading = false;
      }
    });
  }

  saveAdvertGenerateStatus(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;

        this.advertGenerateStatusDto = {
          description: this.description,
          isChecked: false,
          id: '00000000-0000-0000-0000-000000000000',
          isDeleted: false,
        }

        this.advertService.apiV1AdvertSaveAdvertStatusPost({
          body: this.advertGenerateStatusDto
        }).subscribe({
          next: (retult: any) => {
            if (retult) {
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert Status.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert Status.');
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", ex.toString());
        resolve(false);
        reject("Error caught on service call save Advert Status.");
      }
      finally {
        this.loading = false;
      }
    });
  }

  saveAdvertKeyFeatures(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.loading = true;

        this.advertKeyFeaturesDto = {
          description: this.description,
          id: '00000000-0000-0000-0000-000000000000',
          isDeleted: false,
        }

        this.advertService.apiV1AdvertSaveAdvertKeyFeaturePost({
          body: this.advertKeyFeaturesDto
        }).subscribe({
          next: (retult: any) => {
            if (retult) {
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert Key Feature.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert  Key Feature.');
            resolve(false);
          }
        });
      } catch (ex) {
        this.notificationService.showErrorMessage("Error", ex.toString());
        resolve(false);
        reject("Error caught on service call save Advert  Key Feature.");
      }
      finally {
        this.loading = false;
      }
    });
  }

  async saveDetails(): Promise<void> {
    try {
      this.loading = true;

      switch (this.advertType) {
        case 1: {
          let savedData = await this.saveAdvertLocation();
          if (savedData == true) {
            this.notificationService.showSuccessMessage("Saved", "Saved successfully.");

          } else {
            this.notificationService.showErrorMessage("Error", "Error on saving details");
          }
          break;
        }
        case 2: {
          let savedData = await this.saveAdvertSectors();
          if (savedData == true) {
            this.notificationService.showSuccessMessage("Saved", "Saved successfully.");
          } else {
            this.notificationService.showErrorMessage("Error", "Error on saving details");
          }
          break;
        }
        case 3: {
          let savedData = await this.saveAdvertTypes();
          if (savedData == true) {
            this.notificationService.showSuccessMessage("Saved", "Saved successfully.");

          } else {
            this.notificationService.showErrorMessage("Error", "Error on saving details");
          }
          break;
        }
        case 4: {
          let savedData = await this.saveAdvertGenerateStatus();
          if (savedData == true) {
            this.notificationService.showSuccessMessage("Saved", "Saved successfully.");

          } else {
            this.notificationService.showErrorMessage("Error", "Error on saving details");
          }
          break;
        }
        case 5: {
          let savedData = await this.saveAdvertKeyFeatures();
          if (savedData == true) {
            this.notificationService.showSuccessMessage("Saved", "Saved successfully.");

          } else {
            this.notificationService.showErrorMessage("Error", "Error on saving details");
          }
          break;
        }
        default:
          {
            this.notificationService.showErrorMessage("Warning", "Please select a type to add.");
            return;
          }
      }
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not complete the Confirm property and seller details because of errors.');
    }
    finally {
      this.loading = false;
      this.description = "";
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
