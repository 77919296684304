import { ListingStateService } from 'src/app/shared/services/listing-state-service/listing-state.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AdvertGenerateAdditionalImagesDto, AdvertGenerateStatusDto, AdvertGeneratorDto, AdvertKeyFeaturesDto, MultiplePropertyListingDto, PropertyListingDto, TaskListDto, UserDto } from '../../../../../services/property-matrixV2/models';
import { AdvertService, FileManagementService, PropertyListingService, UserManagementService } from '../../../../../services/property-matrixV2/services';
import { FileManagementExtensionService } from '../../../../../shared/services/file-management-extension-service/file-management-extension.service';
import { ProvideLatLonDialogComponent } from '../../../../../shared/components/provide-lat-lon-dialog/provide-lat-lon-dialog.component';
import { NotificationService } from '../../../../../shared/services/notification-service/notification.service';
import { ReportingService } from '../../../../../../app/shared/services/reporting-service/reporting.service';
import { AdvertStateService } from '../../../../../shared/services/advert-service/advert-state.service';
import { FileExtended } from '../../../../../shared/services/../interfaces/file-extended';

@Component({
  selector: 'app-advert-generate-developed',
  templateUrl: './advert-generate-developed.component.html',
  styleUrls: ['./advert-generate-developed.component.scss',
    '../../../../../../css/2-modules/_admin-portal.scss']
})
export class AdvertGenerateDevelopedComponent implements OnInit {

  @Input() selectedListing: PropertyListingDto | undefined;
  @Input() selectedListingGroup: MultiplePropertyListingDto | undefined;
  @Input() isMultiple: boolean | undefined;
  @Input() sectorType: string | undefined;
  @Output() booleanEvent = new EventEmitter<boolean>();

  listingType: string | undefined;
  doNotPublishPropertyUnderDevelopment: boolean | null | undefined = true;

  sections = [
    { key: 0, value: '', checked: true },
    { key: 1, value: 'Information for displaying on property card', checked: true },
    { key: 2, value: 'Status', checked: false },
    { key: 3, value: 'Key Features', checked: false },
    { key: 4, value: 'Property Report', checked: false },
    { key: 5, value: 'Property Images', checked: false },
    { key: 6, value: 'Lat-long Coordinates', checked: false },
  ];

  loading: boolean = false;
  canMoveToNextTab: boolean = false;
  AdvertGenerateDevelopedFormPropertyInfo: FormGroup | undefined;
  hideLatLonPanel: boolean = false;
  latitude: number | null = null;
  longitude: number | null = null;
  LatLongCoordinatesNew: string = "";
  files: FileExtended[] = [];
  propertyReportFileId: string = "";
  fileUrlPropertyReport: string | null | undefined = "";
  hidePropertyReportButtons: boolean = false;
  filePropertyReport: any;

  propertyImageFileId: string = "";
  filePropertyImage: any;
  hidePropertyImageButtons: boolean = false;
  filePropertyImageUrl: string | null = "";

  advertKeyFeaturesList: AdvertKeyFeaturesDto[] = [];
  advertStatusList: AdvertGenerateStatusDto[] = [];
  advertStatusFilteredList: AdvertGenerateStatusDto[] = [];
  advertGenerateDevelopedDTO: AdvertGeneratorDto = {};
  advertAdditionalImageList: AdvertGenerateAdditionalImagesDto[] = [];
  advertGenerateDTOPrepopulated: AdvertGeneratorDto = {};
  advertAdditionalImagesFiles: AdvertGenerateAdditionalImagesDto[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private advertService: AdvertService,
    private reportingService: ReportingService,
    private fileManagementService: FileManagementService,
    private advertStateService: AdvertStateService,
    private fileManagementExtensionService: FileManagementExtensionService,
  ) { }

  ngOnInit(): void {

    if (this.selectedListing == null) {
      this.notificationService.showErrorMessage("Error", "The listing is not found or null. Please contact your administrator.");
      return;
    }

    if (this.doNotPublishPropertyUnderDevelopment == null) {
      this.notificationService.showErrorMessage("Error", "The Checkbox publish property under developed, is null. Please contact your administrator.");
    }
    this.prePopulateFields();
    this.getAdvertTypes();
    this.preparePropertyInfoForm();
    this.doNotPublishPropertyUnderDevelopment = true;
  }

  collapseOrExpandSection(section: { checked: boolean; }) {
    section.checked = !section.checked;
  }

  preparePropertyInfoForm(): void {
    this.AdvertGenerateDevelopedFormPropertyInfo = this.fb.group({
      price: ["", [Validators.required]],
      headingOneSizeTypeLocation: ["", [Validators.required]],
      headingTwoAdvertType: ["", [Validators.required]],
      shortDescriptionOfProperty: ["", [Validators.required]],
    });
  }

  getCheckedStatus() {
    return this.advertStatusFilteredList.filter(status => status.isChecked);
  }

  openLocationDialog(): void {
    const dialogRef = this.dialog.open(ProvideLatLonDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.latitude = result.latitude;
        this.longitude = result.longitude;
        this.LatLongCoordinatesNew = this.latitude + " " + this.longitude;
      }
    });
  }

  replacePropertyReport(): void {
    this.fileUrlPropertyReport = "";
    this.hidePropertyReportButtons = false;
  }

  deletePropertyReport(): void {
    try {
      if (this.propertyReportFileId) {
        this.notificationService.showConfirmMessage('Confirm Deletion', 'Are you sure you want to delete this listing?').then((confirmed) => {
          if (confirmed) {
            this.fileManagementService.apiV1FileManagementDeleteFileFileIdDelete({ fileId: this.propertyReportFileId }).subscribe({
              next: () => {
                this.notificationService.showSuccessMessage('Success', 'Listing deleted successfully.');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              },
              error: (_error: any) => {
                this.notificationService.showErrorMessage('Error', 'Could not delete listing.');
              }
            });
          }
        });
      } else {
        this.notificationService.showErrorMessage("Error", "The file Id not found");
      }
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "The file Id not found");
    }
  }

  async setPropertyReportFile(event: { target: { files: any[]; }; }) {
    try {
      this.propertyReportFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.filePropertyReport = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.fileUrlPropertyReport = event.target.result;
        this.hidePropertyReportButtons = true;
      }

      reader.readAsDataURL(this.filePropertyReport);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload Property Report file.');
    }
  }

  replacePropertyImage(): void {
    this.filePropertyImageUrl = "";
    this.hidePropertyImageButtons = false;
  }

  deletePropertyImage(): void {
    try {
      if (this.propertyImageFileId) {
        this.notificationService.showConfirmMessage('Confirm Deletion', 'Are you sure you want to delete this property image?').then((confirmed) => {
          if (confirmed) {
            this.fileManagementService.apiV1FileManagementDeleteFileFileIdDelete({ fileId: this.propertyImageFileId }).subscribe({
              next: () => {
                this.notificationService.showSuccessMessage('Success', 'Listing deleted successfully.');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              },
              error: (_error: any) => {
                this.notificationService.showErrorMessage('Error', 'Could not delete listing.');
              }
            });
          }
        });
      } else {
        this.notificationService.showErrorMessage("Error", "The file Id not found");
      }
    } catch (err) {
      this.notificationService.showErrorMessage("Error", "The file Id not found");
    }
  }

  async setPropertyImageFile(event: { target: { files: any[]; }; }) {
    try {
      this.propertyImageFileId = await this.reportingService.uploadFile(event.target.files[0]);
      this.filePropertyImage = event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.filePropertyImageUrl = event.target.result;
        this.hidePropertyImageButtons = true;
      }

      reader.readAsDataURL(this.filePropertyImage);
    }
    catch (error) {
      this.notificationService.showErrorMessage('Error', 'Could not upload Property Report file.');
    }
  }

  removeAdvertKeyFeature(index: number): void {
    const element = this.advertKeyFeaturesList[index];
    this.advertKeyFeaturesList.splice(index, 1);
  }

  addNewKeyFeatureItem(): void {
    let advertKeyFeaturesDto: AdvertKeyFeaturesDto = {
      id: "00000000-0000-0000-0000-000000000000",
      description: "",
      isDeleted: false,
    };

    this.advertKeyFeaturesList.push(advertKeyFeaturesDto);
  }

  async getAdvertTypes() {
    await this.advertService.apiV1AdvertGetStatusDtOsGet().subscribe({
      next: (response: AdvertGenerateStatusDto[]) => {
        this.advertStatusList = response;
        if (this.sectorType) {
          this.advertStatusFilteredList = this.advertStatusList.filter(s => s.sectorType == this.sectorType)
        } else {
          this.notificationService.showWarningMessage('Warning', 'The sector type to filter the status is not defined and will not filter status. ');
        }
      },
      error: (_error: any) => {
        this.notificationService.showErrorMessage('Error', 'Could not load Advert types data.');
      }
    });
  }

  saveAdvertDevelopedData(): void {
    try {
      this.loading = true;
      this.saveData().then(result => {
        if (result == true) {
          this.notificationService.showSuccessMessage("Saved", "Saved advert filters successfully.");
        } else {
          this.notificationService.showErrorMessage("Error", "Error on saving advert filters");
        }
      });
      this.loading = false;
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert filters because of errors.');
      this.loading = false;
    }
  }

  completeAdvertDevelopedData(): void {
    try {
      this.loading = true;
      this.saveData().then(result => {
        this.loading = false;
        if (result == true) {
          this.notificationService.showSuccessMessage("Saved", "Saved advert filters successfully.");
        } else {
          this.loading = false;
          this.notificationService.showErrorMessage("Error", "Error on saving advert filters");
        }
      });
    }
    catch (ex) {
      this.notificationService.showErrorMessage('Error', 'Could not save advert filters because of errors.');
      this.loading = false;
    }
  }

  saveData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if (this.getCheckedStatus().length == 0) {
          this.notificationService.showErrorMessage("Error", "Please select status fields");
          console.error("Error", "Please select status fields");
          return;
        }
        if (this.advertKeyFeaturesList.length == 0) {
          this.notificationService.showErrorMessage("Error", "Error found on Key Feature fields. Make sure all has values to continue.");
          console.error("Error found on Key Feature fields. Make sure all has values to continue.");
          return;
        }

        if (this.AdvertGenerateDevelopedFormPropertyInfo?.valid == false) {
          this.notificationService.showErrorMessage("Error", "Error found on property Information fields. Make sure all has values to continue.");
          console.error("Error found on property Information fields. Make sure all has values to continue.");
          return;
        }

        if (this.propertyImageFileId.length == 0) {
          this.notificationService.showErrorMessage("Error", "Error found, There is no Property Image found . Make sure all has values to continue.");
          console.error("Error found, There is no Property Image found . Make sure all has values to continue.");
          return;
        }

        if (this.propertyReportFileId.length == 0) {
          this.notificationService.showErrorMessage("Error", "Error found, There is no Property Report found . Make sure all has values to continue.");
          console.error("Error found, There is no Property Report found . Make sure all has values to continue.");
          return;
        }

        const priceField = this.AdvertGenerateDevelopedFormPropertyInfo?.get('price')?.value ?? "";
        if (this.isDecimal(priceField) == false) {
          this.notificationService.showErrorMessage("Error", "Error found on property Information fields Price. Make sure the value is decimal without R in front to continue.");
          console.error("Error found on property Information fields Price. Make sure the value is decimal without R in front to continue.");
          return;
        }

        if (!this.LatLongCoordinatesNew) {
          this.notificationService.showErrorMessage("Error", "Error found on Coordinates. Make sure all has values to continue.");
          console.error("Error found on property Information fields. Make sure all has values to continue.");
          return;
        }

        if (!this.latitude) {
          this.notificationService.showErrorMessage("Error", "Error found on Coordinates. Make sure all has values to continue.");
          console.error("Error found on Coordinates. Make sure all has values to continue.");
          return;
        }

        this.advertGenerateDevelopedDTO = {
          coordinates: this.LatLongCoordinatesNew,
          advertGenerateAdditionalImageList: this.advertAdditionalImageList,
          generateStatus: this.getCheckedStatus(),
          id: this.advertGenerateDevelopedDTO.id == null ? "00000000-0000-0000-0000-000000000000" : this.advertGenerateDevelopedDTO.id,
          isDeleted: false,
          keyFeatures: this.advertKeyFeaturesList,
          latitude: this.latitude!.toString() ?? "",
          longitude: this.longitude!.toString() ?? "",
          propertyImageFileId: this.propertyImageFileId ?? "",
          propertyImageFileUrl: this.filePropertyImageUrl ?? "",
          propertyInfoAdvertType: this.AdvertGenerateDevelopedFormPropertyInfo?.get('headingTwoAdvertType')?.value ?? "",
          propertyInfoPrice: this.AdvertGenerateDevelopedFormPropertyInfo?.get('price')?.value ?? "",
          propertyInfoShortDescription: this.AdvertGenerateDevelopedFormPropertyInfo?.get('shortDescriptionOfProperty')?.value ?? "",
          propertyInfoSizeTypeLocation: this.AdvertGenerateDevelopedFormPropertyInfo?.get('headingOneSizeTypeLocation')?.value ?? "",
          propertyReportFileId: this.propertyReportFileId ?? "",
          propertyReportFileUrl: this.fileUrlPropertyReport ?? "",
          publishPropertyAdvertUnderDevelopAbles: false,
          publishPropertyAdvertUnderDeveloped: true,
          propertyListingId: this.selectedListing?.id,
          sectorTypeDevelopedDevelopable: "Developed",
        }
        this.loading = true;
        this.advertService.apiV1AdvertSaveAdvertGeneratorReportPost({
          body: this.advertGenerateDevelopedDTO
        }).subscribe({
          next: (result: any) => {
            this.loading = false;
            if (result) {
              this.advertStateService.setSelectedAdvertGeneratorReport(this.advertGenerateDevelopedDTO);
              resolve(true);
            } else {
              this.notificationService.showErrorMessage("Error", 'Error Updating Advert filters.');
              resolve(false);
            }
          },
          error: (_error: any) => {
            this.loading = false;
            this.notificationService.showErrorMessage('Error', 'Error Updating Advert filters.');
            resolve(false);
          }
        });
      } catch (ex) {
        this.loading = false;
        this.notificationService.showErrorMessage("Error", 'Error Updating Advert filters.');
        resolve(false);
        reject("Error caught on service call save Advert filters.");
      }
    });
  }

  onDragOver(event: Event): void {
    event.preventDefault();
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer) {
      const files = event.dataTransfer.files;
      this.addFiles(files);
    }
  }

  async onFileInput(event: Event): Promise<void> {
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;
    if (files && files.length > 0) {
      this.addFiles(files);
      element.value = '';
    } else {
      this.notificationService.showErrorMessage('Error', 'Could not upload file.');
    }
  }

  deleteFile(fileId: string, index: number): void {
    this.loading = true;
    this.fileManagementService.apiV1FileManagementDeleteFileFileIdDelete({ fileId })
      .subscribe({
        next: () => {
          this.files.splice(index, 1);
          this.notificationService.showSuccessMessage('Success', 'File deleted successfully.');
          this.loading = false;
        },
        error: () => {
          this.notificationService.showErrorMessage('Error', 'File could not be deleted.');
          this.loading = false;
        },
      });
  }

  private addFiles(files: FileList): void {
    let index: number = 0;
    Array.from(files).forEach((file: File) => {
      const FileExtended = file as FileExtended;
      if (!this.files.some((f) => f.name === FileExtended.name)) {
        this.uploadFile(FileExtended, index);
      } else {
        this.notificationService.showWarningMessage('Warning', 'Duplicate files are not allowed.');
      }
      index = index + 1;
    });
  }

  private async uploadFile(file: FileExtended, index: number): Promise<void> {
    try {
      this.loading = true;
      const result = await this.fileManagementExtensionService.uploadFile(file);
      file.id = result;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        file.url = e.target.result;
        this.files.push(file);
        const document: AdvertGenerateAdditionalImagesDto = {
          id: result,
          description: file.name,
          isDeleted: false,
          imageURL: file.url,
        };
        this.advertAdditionalImageList.push(document);
        this.loading = false;
        this.notificationService.showSuccessMessage('Success', 'File uploaded successfully.');
      };
      reader.readAsDataURL(file);
    } catch (error) {
      this.loading = false;
      this.notificationService.showErrorMessage('Error', 'File could not be uploaded.');
    }
  }

  goToListingOverViewRoute(): void {
    this.router.navigate(['admin/listings/listing-overview'], {
      queryParams: {
        listingType: this.listingType
      }
    });
  }

  async prePopulateFields(): Promise<void> {
    this.loading = true;
    this.advertService.apiV1AdvertGetAdvertReportDevelopedDataWithListingIdGet({ propertyListingId: this.selectedListing?.id }).subscribe({
      next: (response: any) => {
        if (response.success && response.data) {
          this.loading = false;
          this.advertGenerateDTOPrepopulated = response.data;

          if (this.advertGenerateDTOPrepopulated) {
            this.advertGenerateDevelopedDTO = response.data;
            this.doNotPublishPropertyUnderDevelopment = this.advertGenerateDTOPrepopulated.publishPropertyAdvertUnderDeveloped ?? false;
            this.latitude = Number(this.advertGenerateDTOPrepopulated.latitude) ?? 0;
            this.longitude = Number(this.advertGenerateDTOPrepopulated.longitude) ?? 0;
            this.LatLongCoordinatesNew = this.latitude + " " + this.longitude;
            if (this.advertGenerateDTOPrepopulated.keyFeatures) {
              this.advertKeyFeaturesList = this.advertGenerateDTOPrepopulated.keyFeatures;
            }
            this.fileUrlPropertyReport = this.advertGenerateDTOPrepopulated.propertyReportFileUrl ?? "";
            this.propertyReportFileId = this.advertGenerateDTOPrepopulated.propertyReportFileId ?? "";
            this.propertyImageFileId = this.advertGenerateDTOPrepopulated.propertyImageFileId ?? "";
            this.filePropertyImageUrl = this.advertGenerateDTOPrepopulated.propertyImageFileUrl ?? "";
            if (this.propertyReportFileId) {
              this.hidePropertyReportButtons = true;
            }
            if (this.propertyImageFileId) {
              this.hidePropertyImageButtons = true;
            }
            if (this.advertGenerateDTOPrepopulated) {
              this.AdvertGenerateDevelopedFormPropertyInfo?.setValue({ 'price': this.advertGenerateDTOPrepopulated.propertyInfoPrice, 'headingOneSizeTypeLocation': this.advertGenerateDTOPrepopulated.propertyInfoSizeTypeLocation, 'headingTwoAdvertType': this.advertGenerateDTOPrepopulated.propertyInfoAdvertType, 'shortDescriptionOfProperty': this.advertGenerateDTOPrepopulated.propertyInfoShortDescription });
            }
            if (this.advertGenerateDTOPrepopulated.generateStatus) {
              this.prePopulateStatus(this.advertGenerateDTOPrepopulated.generateStatus);
            }
            if (this.advertGenerateDTOPrepopulated.advertGenerateAdditionalImageList) {
              this.prePopulateAdditionalImages(this.advertGenerateDTOPrepopulated.advertGenerateAdditionalImageList!);
            }
          }
        } else {
          this.loading = false;
          this.notificationService.showErrorMessage('Error', 'Could not load Advert pre-populated data.');
        }
      },
      error: (_error: any) => {
        this.loading = false;
        this.notificationService.showErrorMessage('Error', 'Could not load Advert pre-populated data.');
      }
    });
  }

  isDecimal(value: any): boolean {
    return !isNaN(value) && /^-?\d+\.\d+$/.test(value.toString());
  }

  prePopulateStatus(statusList: AdvertGenerateStatusDto[]) {
    for (let index = 0; index < this.advertStatusFilteredList.length; index++) {
      const filterStatus = this.advertStatusFilteredList[index];
      for (let index = 0; index < statusList.length; index++) {
        const Status = this.advertStatusFilteredList[index];
        if (filterStatus.description == Status.description) {
          filterStatus.isChecked = true;
        }
      }
    }
  }

  async prePopulateAdditionalImages(additionalImageList: AdvertGenerateAdditionalImagesDto[]): Promise<void> {
    for (let index = 0; index < additionalImageList.length; index++) {
      const additionalImage = additionalImageList[index];
    };
    this.addAdditionalImages(additionalImageList);
  }

  addAdditionalImages(additionalImageList: AdvertGenerateAdditionalImagesDto[]): void {
    this.advertAdditionalImagesFiles = additionalImageList;
  }
}
