/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { PropertyMatrixBaseServiceV2 } from '../property-matrix-base-service-v-2';
import { PropertyMatrixConfigurationV2 } from '../property-matrix-configuration-v-2';
import { PropertyMatrixStrictHttpResponseV2 } from '../property-matrix-strict-http-response-v-2';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EstateAgentDto } from '../models/estate-agent-dto';
import { GenericResultDto } from '../models/generic-result-dto';
import { IActionResult } from '../models/i-action-result';
import { RoleBasedAccessDto } from '../models/role-based-access-dto';
import { RoleDto } from '../models/role-dto';
import { UserDto } from '../models/user-dto';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService extends PropertyMatrixBaseServiceV2 {
  constructor(
    config: PropertyMatrixConfigurationV2,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1UserManagementGetUsersGet
   */
  static readonly ApiV1UserManagementGetUsersGetPath = '/api/v1/UserManagement/GetUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetUsersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetUsersGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetUsersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetUsersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetUsersGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UserDto>> {

    return this.apiV1UserManagementGetUsersGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetUserGet
   */
  static readonly ApiV1UserManagementGetUserGetPath = '/api/v1/UserManagement/GetUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetUserGet$Response(params?: {
    id?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetUserGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetUserGet(params?: {
    id?: string;
    context?: HttpContext
  }
): Observable<UserDto> {

    return this.apiV1UserManagementGetUserGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementAddUserPost
   */
  static readonly ApiV1UserManagementAddUserPostPath = '/api/v1/UserManagement/AddUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementAddUserPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementAddUserPost$Response(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<GenericResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementAddUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<GenericResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementAddUserPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementAddUserPost(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<GenericResultDto> {

    return this.apiV1UserManagementAddUserPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<GenericResultDto>) => r.body as GenericResultDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementEditUserPost
   */
  static readonly ApiV1UserManagementEditUserPostPath = '/api/v1/UserManagement/EditUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementEditUserPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementEditUserPost$Response(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<GenericResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementEditUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<GenericResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementEditUserPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementEditUserPost(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<GenericResultDto> {

    return this.apiV1UserManagementEditUserPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<GenericResultDto>) => r.body as GenericResultDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetRolesGet
   */
  static readonly ApiV1UserManagementGetRolesGetPath = '/api/v1/UserManagement/GetRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetRolesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetRolesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<RoleDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetRolesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<RoleDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetRolesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetRolesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<RoleDto>> {

    return this.apiV1UserManagementGetRolesGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<RoleDto>>) => r.body as Array<RoleDto>)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetUserTokenGet
   */
  static readonly ApiV1UserManagementGetUserTokenGetPath = '/api/v1/UserManagement/GetUserToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetUserTokenGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetUserTokenGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<RoleBasedAccessDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetUserTokenGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<RoleBasedAccessDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetUserTokenGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetUserTokenGet(params?: {
    context?: HttpContext
  }
): Observable<RoleBasedAccessDto> {

    return this.apiV1UserManagementGetUserTokenGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<RoleBasedAccessDto>) => r.body as RoleBasedAccessDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementUnverifyUserUserIdPut
   */
  static readonly ApiV1UserManagementUnverifyUserUserIdPutPath = '/api/v1/UserManagement/UnverifyUser/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementUnverifyUserUserIdPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementUnverifyUserUserIdPut$Response(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<IActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementUnverifyUserUserIdPutPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<IActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementUnverifyUserUserIdPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementUnverifyUserUserIdPut(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<IActionResult> {

    return this.apiV1UserManagementUnverifyUserUserIdPut$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<IActionResult>) => r.body as IActionResult)
    );
  }

  /**
   * Path part for operation apiV1UserManagementRemoveUserUserIdDelete
   */
  static readonly ApiV1UserManagementRemoveUserUserIdDeletePath = '/api/v1/UserManagement/RemoveUser/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementRemoveUserUserIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementRemoveUserUserIdDelete$Response(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementRemoveUserUserIdDeletePath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as PropertyMatrixStrictHttpResponseV2<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementRemoveUserUserIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementRemoveUserUserIdDelete(params: {
    userId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiV1UserManagementRemoveUserUserIdDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserManagementDeleteCurrentUserPost
   */
  static readonly ApiV1UserManagementDeleteCurrentUserPostPath = '/api/v1/UserManagement/DeleteCurrentUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementDeleteCurrentUserPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementDeleteCurrentUserPost$Response(params?: {
    userId?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<IActionResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementDeleteCurrentUserPostPath, 'post');
    if (params) {
      rb.query('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<IActionResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementDeleteCurrentUserPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementDeleteCurrentUserPost(params?: {
    userId?: string;
    context?: HttpContext
  }
): Observable<IActionResult> {

    return this.apiV1UserManagementDeleteCurrentUserPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<IActionResult>) => r.body as IActionResult)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetCurrentUserByIdGet
   */
  static readonly ApiV1UserManagementGetCurrentUserByIdGetPath = '/api/v1/UserManagement/GetCurrentUserById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetCurrentUserByIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetCurrentUserByIdGet$Response(params?: {
    id?: string;
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetCurrentUserByIdGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetCurrentUserByIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetCurrentUserByIdGet(params?: {
    id?: string;
    context?: HttpContext
  }
): Observable<UserDto> {

    return this.apiV1UserManagementGetCurrentUserByIdGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetArchivedDeletedUsersGet
   */
  static readonly ApiV1UserManagementGetArchivedDeletedUsersGetPath = '/api/v1/UserManagement/GetArchivedDeletedUsers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetArchivedDeletedUsersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetArchivedDeletedUsersGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetArchivedDeletedUsersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetArchivedDeletedUsersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetArchivedDeletedUsersGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UserDto>> {

    return this.apiV1UserManagementGetArchivedDeletedUsersGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetAllUsersWithoutFiltersGet
   */
  static readonly ApiV1UserManagementGetAllUsersWithoutFiltersGetPath = '/api/v1/UserManagement/GetAllUsersWithoutFilters';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetAllUsersWithoutFiltersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetAllUsersWithoutFiltersGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetAllUsersWithoutFiltersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetAllUsersWithoutFiltersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetAllUsersWithoutFiltersGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UserDto>> {

    return this.apiV1UserManagementGetAllUsersWithoutFiltersGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetSeniorPlannerGet
   */
  static readonly ApiV1UserManagementGetSeniorPlannerGetPath = '/api/v1/UserManagement/GetSeniorPlanner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetSeniorPlannerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetSeniorPlannerGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetSeniorPlannerGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetSeniorPlannerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetSeniorPlannerGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UserDto>> {

    return this.apiV1UserManagementGetSeniorPlannerGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetJuniorPlannerGet
   */
  static readonly ApiV1UserManagementGetJuniorPlannerGetPath = '/api/v1/UserManagement/GetJuniorPlanner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetJuniorPlannerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetJuniorPlannerGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetJuniorPlannerGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<Array<UserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetJuniorPlannerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetJuniorPlannerGet(params?: {
    context?: HttpContext
  }
): Observable<Array<UserDto>> {

    return this.apiV1UserManagementGetJuniorPlannerGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetCurrentUserGet
   */
  static readonly ApiV1UserManagementGetCurrentUserGetPath = '/api/v1/UserManagement/GetCurrentUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetCurrentUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetCurrentUserGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetCurrentUserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetCurrentUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetCurrentUserGet(params?: {
    context?: HttpContext
  }
): Observable<UserDto> {

    return this.apiV1UserManagementGetCurrentUserGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementGetCurrentUserLoggedInUserGet
   */
  static readonly ApiV1UserManagementGetCurrentUserLoggedInUserGetPath = '/api/v1/UserManagement/GetCurrentUserLoggedInUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementGetCurrentUserLoggedInUserGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetCurrentUserLoggedInUserGet$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementGetCurrentUserLoggedInUserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as PropertyMatrixStrictHttpResponseV2<UserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementGetCurrentUserLoggedInUserGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementGetCurrentUserLoggedInUserGet(params?: {
    context?: HttpContext
  }
): Observable<UserDto> {

    return this.apiV1UserManagementGetCurrentUserLoggedInUserGet$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation apiV1UserManagementArchiveEstateAgentUserPost
   */
  static readonly ApiV1UserManagementArchiveEstateAgentUserPostPath = '/api/v1/UserManagement/ArchiveEstateAgentUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementArchiveEstateAgentUserPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementArchiveEstateAgentUserPost$Response(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementArchiveEstateAgentUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementArchiveEstateAgentUserPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementArchiveEstateAgentUserPost(params?: {
    context?: HttpContext
    body?: EstateAgentDto
  }
): Observable<boolean> {

    return this.apiV1UserManagementArchiveEstateAgentUserPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1UserManagementDeleteDoubleRolesDelete
   */
  static readonly ApiV1UserManagementDeleteDoubleRolesDeletePath = '/api/v1/UserManagement/DeleteDoubleRoles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementDeleteDoubleRolesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementDeleteDoubleRolesDelete$Response(params?: {
    context?: HttpContext
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementDeleteDoubleRolesDeletePath, 'delete');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementDeleteDoubleRolesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserManagementDeleteDoubleRolesDelete(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiV1UserManagementDeleteDoubleRolesDelete$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiV1UserManagementEndoreUserPost
   */
  static readonly ApiV1UserManagementEndoreUserPostPath = '/api/v1/UserManagement/EndoreUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserManagementEndoreUserPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementEndoreUserPost$Response(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<PropertyMatrixStrictHttpResponseV2<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserManagementService.ApiV1UserManagementEndoreUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PropertyMatrixStrictHttpResponseV2<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserManagementEndoreUserPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserManagementEndoreUserPost(params?: {
    context?: HttpContext
    body?: UserDto
  }
): Observable<boolean> {

    return this.apiV1UserManagementEndoreUserPost$Response(params).pipe(
      map((r: PropertyMatrixStrictHttpResponseV2<boolean>) => r.body as boolean)
    );
  }

}
