<div class="advert-send-link">
    <mat-card class="mb-3" *ngFor="let section of sections; let i = index">
        <div class="col-12 pb-2" *ngIf="section.key == 1" [hidden]="!section.checked">
            <div class="row paragraph mt-3">
                <div class="col-12">
                    <label class="col-12 bold padding">Send Link:</label>
                </div>
                <div class="col-12">
                    <mat-checkbox [(ngModel)]="sendLinkCheck" class="margin">
                        I have sent a link to the agent and seller via email to notify them that the advert has been placed.
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </mat-card>
    <div class="col-12 pb-2">
        <div class="button-group mb-3 mt-3">
            <button (click)="sendLink()" class="footer-button background-green">Completed</button>
        </div>
    </div>
</div>